<template>
  <div class="subtitle-1 pl-1 mb-2 ml-1">
    <slot />

    <v-progress-circular
      v-if="loading"
      :size="15"
      width="2"
      color="grey"
      class="ml-2"
      indeterminate
    />
    <v-btn
      v-else-if="refresh"
      icon
      :loading="loading"
      class="ml-1 mt-n1"
      x-small
      @click="$emit('refresh')"
      ><v-icon v-text="icon" />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    refresh: Boolean,
    icon: {
      type: String,
      default: "refresh",
    },
  },
};
</script>
