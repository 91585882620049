<template>
  <div>
    <v-progress-linear v-if="loading" absolute indeterminate />
    <m-crumbs v-if="crumbs" :text="crumbText" />
    <v-container fluid>
      <v-toolbar
        v-if="!noToolbar"
        dense
        flat
        color="grey lighten-5"
        class="mx-0"
      >
        <v-toolbar-title v-if="title"
          >{{ title }}
          <span v-if="subTitle" class="caption ml-1" v-text="subTitle" />
        </v-toolbar-title>
        <slot name="title-append" />
        <v-spacer />
        <slot name="actions" />
      </v-toolbar>
      <slot />
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    crumbs: Boolean,
    noToolbar: Boolean,
    crumbText: {
      type: String,
      default: null,
    },

    loading: Boolean,
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
}
</script>
