<template>
  <div class="text-center">
    <m-pages-visa v-if="cards" class="px-1" :width="size" :height="height" />
    <m-pages-master v-if="cards" class="px-1" :width="size" :height="height" />
    <m-pages-amex v-if="cards" class="px-1" :width="size" :height="height" />
    <m-pages-discovery
      v-if="cards"
      class="px-1"
      :width="size"
      :height="height"
    />
    <m-pages-pay-pal
      v-if="paypal"
      class="px-1"
      :width="size"
      :height="height"
    />
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: 70, //780,
    },
    heightx: {
      type: [String, Number],
      default() {
        return parseInt(this.width) * 0.64102564102;
      },
    },
    cards: Boolean,
    paypal: Boolean,
  },

  computed: {
    height() {
      if (isNaN(this.size)) {
        return "";
      }
      // let w = parseInt(this.size)
      return parseInt(this.size) * 0.64102564102;
    },
  },
};
</script>
