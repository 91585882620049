export default {
  content: {
    avatar: {
      tile: true,
    },
    img: {
      src: "https://source.unsplash.com/C8Z5DvtWQMw/1400x500",
    },
    heading: { text: "" },
    subheading: {
      text: "",
    },
    content: {
      html: "",
    },
  },
};
