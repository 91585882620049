<template>
  <div>
    <form @submit.prevent="addField('text')">
      <v-text-field
        v-model="fieldName"
        ref="fieldName"
        label="Add Field"
        placeholder="Enter field name..."
      />
    </form>
    <v-row class="mx-0">
      <v-btn
        v-for="item in items"
        :key="item.value"
        depressed
        :disabled="!fieldName"
        class="mr-1"
        @click="addField(item.value)"
      >
        {{ item.text }}
      </v-btn>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
import createField from '../models/Field'
export default {
  data() {
    return {
      fieldName: '',
      opts: {
        required: false,
      },
      items: [
        {
          text: 'Text',
          value: 'text',
        },
        {
          text: 'Textarea',
          value: 'textarea',
        },
      ],
    }
  },

  methods: {
    addField(type) {
      const field = createField(type, this.fieldName, this.opts)
      this.$emit('add', field)
    },
  },
}
</script>
