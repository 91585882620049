<template>
  <div>
    <v-row>
      <v-text-field
        v-model="item.from_name"
        :placeholder="config.email.from_name"
        label="From Name"
        class="mx-4"
      />
      <v-text-field
        v-model="item.from_email"
        :placeholder="config.email.from_email"
        label="From Email"
        class="mx-4"
      />
    </v-row>

    <v-text-field
      v-model="item.subject"
      label="Subject"
      class="mx-1"
      counter="65"
    />

    <app-editor v-model="item.html" />

    <v-textarea
      v-model="item.text"
      outlined
      label="Text Version"
      append-outer-icon="content_copy"
      auto-grow
      @click:append-outer="copyHTmlToText"
    />

    <v-btn :loading="loading" class="mr-2" @click="update">Save</v-btn>
    <v-btn
      :to="{
        name: this.storeName,
        params: { id: this.$route.params.id },
      }"
      >Continue
    </v-btn>

    <span v-if="refresh" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appEditor from '@/components/Tinymce'

export default {
  components: {
    appEditor,
  },

  props: {
    getItem: Boolean,
    storeName: {
      type: String,
      default: 'email',
    },
  },

  data() {
    return {
      refresh: false,
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      config: 'config',
    }),
    item() {
      return this.$store.getters[`${this.storeName}/data`]
    },
  },

  methods: {
    async update() {
      this.loading = true
      await this.$store.dispatch(`${this.storeName}/update`)
      this.loading = false
    },

    copyHTmlToText() {
      const tag = document.createElement('div')

      let html = this.item.html.stripSlashes()

      // let regexLinks = /(?<=a href=")[a-z:/.-_]{1,100}/; safari doesnt like it.
      let regexLinks = /(href=")[a-z:/.-_]{1,100}/
      let regexHtmlLinks = /(<a href=")(.*)(<\/a>)/

      // console.log('regexLinks')

      const links = html.match(regexLinks)
      const htmlLinks = html.match(regexHtmlLinks)

      let count = 0

      if (links && links.length) {
        links.forEach((li) => {
          li = li.replace('href="', '')
          html = html.replace(htmlLinks[count], li)
          count++
        })
      }

      tag.innerHTML = html

      this.item.text = tag.innerText

      this.refresh = !this.refresh
    },
  },

  beforeMount() {
    // console.log(`${this.storeName}/getItemById`)
    if (this.getItem) {
      this.$store.dispatch(
        `${this.storeName}/getItemById`,
        this.$route.params.id
      )
    }
  },
}
</script>
