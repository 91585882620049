export default [
  {
    text: "black",
    value: "black",
  },
  {
    text: "Bold",
    value: "bold",
  },
  {
    text: "Medium",
    value: "medium",
  },
  {
    text: "Regular",
    value: "regular",
  },
  {
    text: "Light",
    value: "light",
  },
  {
    text: "Thin",
    value: "thin",
  },
  // {
  //   text: "Italic",
  //   value: "italic",
  // },
];
