<template>
  <v-row>
    <component
      v-for="(child, i) in item.children"
      v-bind:is="`m-pages-${child.component}2`"
      :item="child"
      :key="i"
    />
  </v-row>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>