<template>
  <panel-layout :title="title">
    <v-row no-gutters>
      <v-col :cols="item.cols || 6" v-for="item in spacing.dirs" :key="`${value}${item.value}`">
        <editor-slider v-model="block[target][`${value}${item.value}`]" :min="min" />
        <div class="text-center mt-n8 mb-2 primary--text text-capitalize">{{ item.text }}</div>
      </v-col>
    </v-row>

    <v-btn text color="primary" @click="reset">Reset All</v-btn>
  </panel-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { spacing } from "../../helpers/spacing";

import EditorSlider from "./Slider";
import PanelLayout from "../../layouts/Panel";

export default {
  components: {
    EditorSlider,
    PanelLayout
  },

  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: "container"
    },
    min: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      spacing
    };
  },

  computed: {
    ...mapGetters({
      block: "editor/block"
    })
  },

  methods: {
    reset() {
      console.log("reset all");
      spacing.dirs.forEach(dir => {
        const key = this.value + dir.value;
        console.log(key);
        this.block[this.target][key] = 0;
      });
    }
  }
};
</script>