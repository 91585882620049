<template>
  <div>
    <v-tabs>
      <v-tab>Toolbar</v-tab>
      <v-tab>Items</v-tab>
      <v-tab v-if="val.options.primaryButton" v-text="'Primary Button'" />
      <v-tab v-if="val.options.secondaryButton" v-text="'Secondary Button'" />

      <v-tabs-slider></v-tabs-slider>

      <v-tab-item>
        <v-row>
          <v-col>
            <m-pages-toolbar-form v-model="val.toolbar" />
          </v-col>
          <v-col>
            <v-row>
              <v-switch
                v-for="option in options"
                :key="option.value"
                v-model="val.options[option.value]"
                :label="option.text"
                class="mx-3"
                hide-details
              />
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <m-autocomplete
          v-model="val.menu"
          label="Menu"
          endpoint="admin/menus"
          class="mx-2"
        />
        <m-pages-links-form
          v-model="val.items"
          title="Navbar Links"
          :items="blockList"
          :levels="1"
        />
      </v-tab-item>
      <v-tab-item v-if="val.options.primaryButton">Primary Button</v-tab-item>
      <v-tab-item v-if="val.options.primarySecondary"
        >Secondary Button</v-tab-item
      >
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import { toolbar } from "../../js/forms";

export default {
  props: {
    blockList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  mixins: [model],
  data() {
    return {
      forms: {
        toolbar,
      },
      options: [
        {
          text: "Primary Button",
          value: "primaryButton",
        },
        {
          text: "Secondary Button",
          value: "secondaryButton",
        },
      ],
    };
  },
};
</script>
