// import spacing from "./spacing-direction";

// let directions = {};

// spacing
//   .map((a) => a.value)
//   .forEach((k) => {
//     directions[k] = "";
//   });

export default {
  // dark: false,
  // light: false,
  // inner: {},
  // outer: {},
  m: {
    t: 0,
    b: 0,
    l: 0,
    r: 0,
    s: 0,
    e: 0,
    x: 0,
    y: 0,
    a: 0,
  },
  p: {
    t: 0,
    b: 0,
    l: 0,
    r: 0,
    s: 0,
    e: 0,
    x: 0,
    y: 0,
    a: 0,
  },
};
