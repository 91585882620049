<template>
  <div>
    <v-container fluid class="pr-6 pl-9">
      <v-row align="center" justify="space-between">
        <!-- LEFT  -->
        <v-row align="center" justify="start">
          <m-pages-navigation-drawer
            v-if="block.img && block.img.src"
            :img-src="block.img.src"
            :title="block.name.text"
            :subtitle="block.slogan.text"
          />

          <!-- site logo  -->

          <img
            v-if="block.options.img && block.img.src"
            :src="block.img.src ? block.img.src : setup.business.logo"
            :alt="block.img.alt"
            :style="`height:${block.img.height || 80}px`"
            class="mr-4"
          />

          <!-- site name  -->
          <div>
            <m-pages-heading-v2 :item="block.heading" />
          </div>
        </v-row>

        <!-- <v-row> -->
        <!-- <v-btn>Register</v-btn> -->

        <div v-if="block.options.button">
          <m-pages-button :item="block.button" />
        </div>
        <div v-if="block.options.button2">
          <m-pages-button :item="block.button2" />
        </div>
        <!-- </v-row> -->

        <!-- Right  -->
        <!-- setup: {{setup}} -->
        <!-- showActionBanner: {{showActionBanner}} -->
        <m-pages-action-banner v-if="showActionBanner" :item="setup.actionBanner" :theme="theme" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import blockMixin from "../../mixins/block";

export default {
  mixins: [blockMixin],
  computed: {
    showActionBanner() {
      return (
        this.setup.actionBanner &&
        this.setup.actionBanner.active &&
        this.block.options.actionBanner
      );
    },
  },
};
</script>
