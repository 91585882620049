// import blockContainer from "../components/BlockContainer";

export default {
  components: {
    // blockContainer,
  },

  props: {
    //   index: {
    //       type: Number,
    //       required: true,
    //   },
    block: {
      type: Object,
      required: true,
    },
  },
};
