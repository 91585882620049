<template>
  <v-autocomplete
    v-model="model"
    :search-input.sync="form.q"
    :label="label"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    outlined
    multiple
    hide-details
    @keyup="onKeyup"
    @focus="onFocus"
    @input="$emit('input', [...$event])"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    endpoint: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      default: "name"
    },
    itemValue: {
      type: String,
      default: "id"
    },
    queryLimit: {
      type: [Number, String],
      default: 250
    }
  },

  data() {
    return {
      searched: false,
      loading: false,
      model: null,
      form: {
        q: "",
        limit: this.queryLimit
        // page: 1
      },
      items: [],
      searches: []
    };
  },

  methods: {
    onFocus() {
      if (!this.searched) {
        this.searched = true;
        this.getData();
      }
    },

    onKeyup() {
      if (this.loading || this.searches.indexOf(this.q) > -1) {
        return;
      }

      this.getData();
    },

    async getData(page = 1) {
      this.loading = true;
      const { data } = await this.$api.get(this.endpoint, { ...this.form });

      if (data.data && data.data.length > 0) {
        data.data.forEach(item => {
          const index = this.items.findIndex(a => a.id === item.id);
          if (index < 0) {
            this.items.push(item);
          }
        });
      }
      this.loading = false;
    }
  }
};
</script>