export const spacingValues = {
  props: ["m", "p"], // properties
  dirs: ["t", "b", "l", "r", "s", "e", "x", "y", "a"], // directions
};

export const spacing = {
  props: [
    {
      text: "margin",
      value: "m",
    },
    {
      text: "padding",
      value: "p",
    },
  ],
  dirs: [
    {
      text: "all",
      value: "a",
      cols: 12,
    },
    {
      text: "x",
      value: "x",
    },
    {
      text: "y",
      value: "y",
    },
    {
      text: "top",
      value: "t",
    },
    {
      text: "bottom",
      value: "b",
    },
    {
      text: "left",
      value: "l",
    },
    {
      text: "right",
      value: "r",
    },
    // {
    //   text: "margin-left / padding-left",
    //   value: "s",
    //   advanced: true,
    // },
    // {
    //   text: "margin-right / padding-right",
    //   value: "e",
    //   advanced: true,
    // },
  ],
};

export const defaultValues = {
  pa: 0,
  px: 0,
  py: 0,
  pt: 0,
  pb: 0,
  pl: 0,
  pr: 0,
  ma: 0,
  mx: 0,
  my: 0,
  mt: 0,
  mb: 0,
  ml: 0,
  mr: 0,
};
