export default {
	onPlayerReady(value) {
		// set duration if missing
		if (!this.video.duration) {
			this.video.duration = Math.floor(value);
		}
	},
	onPlayerTimeUpdate(value) {
		// const video = this.video;
		const video = this.items[this.index];

		video.currentTime = Math.floor(value);

		if (!video.progress || video.progress < value) {
			video.progress = value;
		}

		if (!this.storingProgress) {
			this.queStoreProgress();
		}
	},
	onPlayerPlay() {
		//
	},
	onPlayerPause() {
		//
	},
	onPlayerEnded() {
		//
	},
};
