// import downloadMixin from "./actions/download";
// import toSectionMixin from "./actions/section";
import mixins from "./actions";
export default {
  mixins,

  methods: {
    clickAction(e, item = null) {
      if (!item) {
        item = this.item;
      }
      const action = item.clickAction;

      if (action == "href") {
        return;
      }

      e.preventDefault();

      const value = item[action];

      console.log("action", action, value);
      // console.log("value", value);

      // pause video

      return this[action](value, item.target);
    },
  },
};
