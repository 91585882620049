<template>
  <!-- could by auto type  -->
  <v-autocomplete
    v-model="timezone"
    :label="label"
    :items="items"
    :hide-details="hideDetails"
    :outlined="outlined"
    @input="$emit('input', timezone)"
  />
</template>

<script>
import items from "@/helpers/time/timezones";

export default {
  props: {
    hideDetails: Boolean,
    outlined: Boolean,
    value: {
      type: String,
      required: false
    },
    label: {
      type: String,
      default: "Timezone"
    }
  },

  data() {
    return {
      items,
      timezone: ""
    };
  },

  beforeMount() {
    if (this.value) {
      this.timezone = this.value;
    }
  }
};
</script>