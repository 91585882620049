var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.form.rows && _vm.form.rows.length)?_vm._l((_vm.form.rows),function(row,rowIndex){return _c('v-row',{key:("row" + rowIndex)},_vm._l((row.cols),function(col,colIndex){return _c('v-col',{key:("col" + colIndex),attrs:{"cols":col.cols ? col.cols : row.colCols ? row.colCols : 12}},[(col.panels && col.panels.length)?_vm._l((col.panels),function(panel,panelIndex){return _c('m-pages-panel',{key:("panel" + panelIndex),attrs:{"title":panel.title}},_vm._l((panel.groups),function(group,groupIndex){return _c('v-sheet',{key:("group" + groupIndex),staticClass:"whitex pa-4 mb-4",attrs:{"elevation":group.elevation,"color":group.color}},[(groupIndex > 0 && group.divider)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),(group.title)?_c('v-subheader',{staticClass:"ma-n4",domProps:{"textContent":_vm._s(group.title)}}):_vm._e(),_vm._l((group.fields),function(field,fieldIndex){return _c('v-row',{key:("row" + rowIndex + "panel" + panelIndex + fieldIndex),attrs:{"no-gutters":group.noGutters || field.noGutters || _vm.form.noGutters,"align":field.align
                    ? field.align
                    : group.align
                    ? group.align
                    : _vm.align,"justify":field.align
                    ? field.align
                    : group.align
                    ? group.align
                    : _vm.form.justify
                    ? _vm.form.justify
                    : _vm.justify}},_vm._l((field.fields),function(item){return _c('v-col',{key:item.value,attrs:{"cols":item.cols
                      ? item.cols
                      : field.itemCols
                      ? field.itemCols
                      : 12}},[(field.value)?_c('m-pages-form-field',{attrs:{"item":item},model:{value:(_vm.val[field.value][item.value]),callback:function ($$v) {_vm.$set(_vm.val[field.value], item.value, $$v)},expression:"val[field.value][item.value]"}}):_c('m-pages-form-field',{attrs:{"item":item},model:{value:(_vm.val[item.value]),callback:function ($$v) {_vm.$set(_vm.val, item.value, $$v)},expression:"val[item.value]"}})],1)}),1)})],2)}),1)}):_vm._e(),(col.groups && col.groups.length)?_vm._l((col.groups),function(group,groupIndex){return _c('v-sheet',{key:("group" + groupIndex),staticClass:"whitex pa-4 mb-4",attrs:{"elevation":group.elevation,"color":group.color}},[(groupIndex > 0 && group.divider)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),(group.title)?_c('v-subheader',{staticClass:"ma-n4",domProps:{"textContent":_vm._s(group.title)}}):_vm._e(),_vm._l((group.fields),function(field,fieldIndex){return _c('v-row',{key:("row" + rowIndex + "col" + colIndex + fieldIndex),attrs:{"no-gutters":group.noGutters || field.noGutters || _vm.form.noGutters,"align":field.align ? field.align : group.align ? group.align : _vm.align,"justify":field.align
                  ? field.align
                  : group.align
                  ? group.align
                  : _vm.form.justify
                  ? _vm.form.justify
                  : _vm.justify}},_vm._l((field.fields),function(item){return _c('v-col',{key:item.value,staticClass:"px-2",class:_vm.getColClass({ item: item, field: field, group: group }),attrs:{"cols":item.cols
                    ? item.cols
                    : field.itemCols
                    ? field.itemCols
                    : group.cols}},[(field.value)?_c('m-pages-form-field',{attrs:{"item":item},model:{value:(_vm.val[field.value][item.value]),callback:function ($$v) {_vm.$set(_vm.val[field.value], item.value, $$v)},expression:"val[field.value][item.value]"}}):_c('m-pages-form-field',{attrs:{"item":item},model:{value:(_vm.val[item.value]),callback:function ($$v) {_vm.$set(_vm.val, item.value, $$v)},expression:"val[item.value]"}})],1)}),1)})],2)}):_vm._e()],2)}),1)}):_vm._e(),(_vm.form.groups && _vm.form.groups.length)?_vm._l((_vm.form.groups),function(group,groupIndex){return _c('v-sheet',{key:("group" + groupIndex),class:group.class,attrs:{"elevation":group.elevation,"color":group.color}},[(group.title)?_c('m-pages-form-subheader',{domProps:{"textContent":_vm._s(group.title)}}):_vm._e(),_vm._l((group.fields),function(field,fieldIndex){return _c('v-row',{key:("group" + groupIndex + "field" + fieldIndex),attrs:{"no-gutters":group.noGutters || field.noGutters || _vm.form.noGutters,"align":field.align ? field.align : group.align ? group.align : _vm.align,"justify":field.align
            ? field.align
            : group.align
            ? group.align
            : _vm.form.justify
            ? _vm.form.justify
            : _vm.justify}},[(field.fields)?_vm._l((field.fields),function(item){return _c('v-col',{key:item.value,class:_vm.getColClass({ item: item, field: field, group: group }),attrs:{"cols":item.cols
                ? item.cols
                : field.itemCols
                ? field.itemCols
                : group.cols}},[(field.value)?_c('m-pages-form-field',{attrs:{"item":item,"label":_vm.getLabel(item)},model:{value:(_vm.val[field.value][item.value]),callback:function ($$v) {_vm.$set(_vm.val[field.value], item.value, $$v)},expression:"val[field.value][item.value]"}}):_c('m-pages-form-field',{attrs:{"item":item,"label":_vm.getLabel(item)},model:{value:(_vm.val[item.value]),callback:function ($$v) {_vm.$set(_vm.val, item.value, $$v)},expression:"val[item.value]"}})],1)}):_vm._e()],2)})],2)}):_vm._e(),(_vm.form.fields && _vm.form.fields.length)?_vm._l((_vm.form.fields),function(field,fieldIndex){return _c('v-row',{key:("field" + fieldIndex),attrs:{"no-gutters":_vm.form.noGutters || field.noGutters || _vm.form.noGutters,"align":field.align ? field.align : _vm.group.align ? _vm.group.align : _vm.align,"justify":field.align
          ? field.align
          : _vm.group.align
          ? _vm.group.align
          : _vm.form.justify
          ? _vm.form.justify
          : _vm.justify}},[(field.fields)?_vm._l((field.fields),function(item){return _c('v-col',{key:item.value,class:_vm.getColClass({ item: item, field: field, group: _vm.group })},[(field.value)?_c('m-pages-form-field',{attrs:{"item":item},model:{value:(_vm.val[field.value][item.value]),callback:function ($$v) {_vm.$set(_vm.val[field.value], item.value, $$v)},expression:"val[field.value][item.value]"}}):_c('m-pages-form-field',{attrs:{"item":item},model:{value:(_vm.val[item.value]),callback:function ($$v) {_vm.$set(_vm.val, item.value, $$v)},expression:"val[item.value]"}})],1)}):_c('v-col',{class:[
          field.colClass,
          field.requires && !_vm.val[field.requires] ? 'd-none' : '' ],attrs:{"cols":field.cols || _vm.group.cols}},[_c('m-pages-form-field',{class:field.class,attrs:{"item":field,"label":_vm.getLabel(field)},model:{value:(_vm.val[field.value]),callback:function ($$v) {_vm.$set(_vm.val, field.value, $$v)},expression:"val[field.value]"}})],1)],2)}):_vm._e(),(_vm.form.fields && _vm.form.fields.length)?_c('v-row',{attrs:{"no-gutters":_vm.form.noGutters,"align":_vm.form.align ? _vm.form.align : _vm.align,"justify":_vm.form.justify ? _vm.form.justify : _vm.justify}},_vm._l((_vm.form.fields),function(item){return _c('v-col',{key:item.value,staticClass:"px-2",class:_vm.getColClass({ item: item, field: _vm.field }),attrs:{"cols":_vm.field.itemCols}},[_c('m-pages-form-field',{attrs:{"item":item,"label":_vm.getLabel(item)},model:{value:(_vm.val[item.value]),callback:function ($$v) {_vm.$set(_vm.val, item.value, $$v)},expression:"val[item.value]"}})],1)}),1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }