<template>
  <div>
    <!-- Extra Tools  -->
    <v-expand-transition>
      <v-toolbar v-if="more" dense flat>
        <v-slider
          v-model="val.elevation"
          label="Shadow"
          hide-details
          min="0"
          max="12"
          :class="spacing"
        />

        <v-slider v-model="val.p.a" label="Padding" hide-details min="0" max="12" />
        <m-pages-spacing-slider v-model="val.m.a" label="Margin" />

        <v-text-field v-model="val.align" label="Align" hide-details />
        <v-text-field v-model="val.justify" label="Justify" hide-details />
        <m-color-picker v-model="val.color" />
        <m-color-picker v-model="val.bgColor" icon="format_paint" />
      </v-toolbar>
    </v-expand-transition>

    <!-- Row Toolbar  -->
    <v-toolbar>
      <v-toolbar-items class="mr-2" dense flat>
        <v-btn
          v-for="(col, index) in colsLen"
          :key="col"
          text
          :class="colIndex == index ? 'grey lighten-5 primary--text' : ''"
          @click="$emit('onCol', index)"
        >col {{ col }}</v-btn>
      </v-toolbar-items>

      <v-btn outlined @click="$emit('addCol')">Add Column</v-btn>

      <!-- <m-pages-add-block-dialog btn-text="Add Column" is-col @input="addCol" /> -->
      <v-spacer />
      <v-switch v-model="val.dense" label="dense" hide-details :class="spacing" />
      <v-switch v-model="val.noGutters" label="no-gutters" hide-details :class="spacing" />
      <v-switch v-model="val.dark" label="Dark" hide-details :class="spacing" />

      <v-btn icon @click="more = !more">
        <v-icon>menu</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import model from "../../mixins/model";

export default {
  props: {
    colsLen: {
      type: Number,
      default: 0,
    },
    colIndex: {
      type: Number,
      default: 0,
    },
  },

  mixins: [model],

  data() {
    return {
      more: false,
      isNewCol: false,
      spacing: "mx-2",
    };
  },

  methods: {
    addCol({ value }) {
      this.$emit("addCol", value);
    },
  },
};
</script>
