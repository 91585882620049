<template>
  <div>
    <m-pages-player
      v-if="!refreshing"
      :video="video"
      :id="`${id}_player`"
      @ready="$emit('onEvent', { name: 'ready', value: $event })"
      @ended="$emit('onEvent', { name: 'ended' })"
      @play="$emit('onEvent', { name: 'play' })"
      @pause="$emit('onEvent', { name: 'pause' })"
    />
  </div>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
  watch: {
    refresh() {
      this.refreshing = true;
      setTimeout(() => {
        this.refreshing = false;
      });
    }
  },
  data() {
    return {
      refreshing: false
    };
  },

  computed: {
    playerColor() {
      return this.block.video.color
        ? this.block.video.color
        : this.theme.color
        ? this.theme.color
        : "";
    },
    video() {
      return { ...this.block.video, color: this.playerColor };
    }
  }
};
</script>
