<template>
  <div>
    <component
      v-if="item.component"
      v-bind:is="item.component"
      v-model="val"
      :label="label ? label : item.text"
      :endpoint="item.endpoint"
      :clearable="item.clearable"
      :item="item"
      hide-details
    />

    <v-text-field
      v-else-if="!item.type || ['number', 'text'].includes(item.type)"
      v-model="val"
      :label="label ? label : item.text"
      :type="item.type || 'text'"
      :placeholder="item.placeholder"
      :clearable="item.clearable"
      :min="item.min"
      :max="item.max"
      hide-details
    />

    <m-color-picker
      v-else-if="item.type == 'color'"
      v-model="val"
      :label="label ? label : item.text"
      :btn-class="item.btnClass || 'mt-3'"
      :icon="item.icon"
      :outlined="item.outlined"
      :text="item.isText"
      :depressed="item.depressed"
      :icon-only="item.iconOnly"
      hide-details
    />

    <v-textarea
      v-else-if="item.type == 'textarea'"
      v-model="val"
      :label="label ? label : item.text"
      hide-details
    />

    <v-select
      v-else-if="item.type == 'select'"
      v-model="val"
      :label="label ? label : item.text"
      :items="item.items"
      :clearable="item.clearable"
      hide-details
    />

    <v-slider
      v-else-if="item.type == 'slider'"
      v-model="val"
      :label="label ? label : item.text"
      :min="item.min"
      :max="item.max"
      thumb-label
      hide-details
    />

    <v-switch
      v-else-if="item.type == 'switch'"
      v-model="val"
      :label="label ? label : item.text"
      hide-details
    />

    <v-checkbox
      v-else-if="item.type == 'checkbox'"
      v-model="val"
      :label="label ? label : item.text"
      hide-details
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Boolean, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      val: "",
      ready: false,
    };
  },

  watch: {
    val() {
      if (!this.ready) {
        return;
      }
      this.$emit("input", this.val);
    },
    value() {
      if (this.value != this.val) {
        this.val = this.value;
      }
    },
  },

  beforeMount() {
    if (typeof this.value != "undefined") {
      this.val = this.value;
    }
    this.ready = true;
  },
};
</script>
