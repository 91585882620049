<template>
  <div>
    <v-expand-transition>
      <v-toolbar v-if="more" dense flat>
        <m-pages-cols-form v-model="val" />
      </v-toolbar>
    </v-expand-transition>
    <v-toolbar title="COL" color="grey lighten-5" dense flat>
      <v-slider
        v-model="val.elevation"
        label="Shadow"
        hide-details
        min="0"
        max="12"
        :class="spacing"
      />

      <!-- <v-select v-model="val.align" :items="alignItems" label="Align" hide-details />
      <v-select v-model="val.justify" :items="justifyItems" label="Justify" hide-details />-->
      <v-switch v-model="val.fluid" label="Expanded" hide-details :class="spacing" />
      <v-switch v-model="val.dark" label="Dark" hide-details :class="spacing" />

      <m-color-picker v-model="val.color" />

      <!-- show for advanced  -->
      <!-- <m-color-picker v-model="val.bgColor" icon="format_paint" /> -->

      <v-toolbar-items :class="spacing">
        <v-btn
          v-for="(panel, index) in items"
          :key="panel.value"
          text
          :class="tabIndex == index ? 'white primary--text' : ''"
          @click="$emit('onTab', index)"
        >
          <v-icon
            v-if="panel.value == 'auth'"
            :color="authActive ? 'active' : 'grey lighten-1'"
            class="mr-1"
            v-text="`lock${!authActive ? '_open' : ''}`"
          />
          {{ panel.text }}
        </v-btn>
      </v-toolbar-items>
      <v-btn icon @click="more = !more">
        <v-icon>menu</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import model from "../../mixins/model";

export default {
  props: {
    authActive: Boolean,
    tabIndex: {
      type: Number,
      default: 0,
    },
  },

  mixins: [model],

  data() {
    return {
      more: true,
      spacing: "mx-2",
      items: [
        {
          text: "Content",
          value: "block",
        },
        {
          text: "Spacing",
          value: "spacing",
        },
        // {
        //   text: "Auth",
        //   value: "auth"
        // }
      ],
      alignItems: [
        {
          text: "",
          value: "",
        },
      ],
      justifyItems: [
        {
          text: "",
          value: "",
        },
      ],
    };
  },

  methods: {
    onTab(index) {
      this.$emit("onTab", index);
    },
  },
};
</script>