import button from "./button";
import heading from "./heading";
// import fields from "./fields";
import img from "./img";

const options = {
  groups: [
    {
      fields: [
        // advanced
        {
          justify: "space-around",
          fields: [
            {
              text: "Logo",
              value: "img",
              type: "switch",
            },
            {
              text: "Button",
              value: "button",
              type: "switch",
            },
            {
              text: "Second Button",
              value: "button2",
              type: "switch",
            },
            {
              text: "Action Banner",
              value: "actionBanner",
              type: "switch",
              cols: 5,
            },
          ],
        },
        // {
        //   fields: [
        //     // fields.dark,
        //     // fields.flat,
        //     // fields.tile,
        //     { ...fields.spacingY, cols: 12 },
        //   ],
        // },
      ],
    },
  ],
};
export default {
  form: {
    button: {},
    heading: {},
    img: {},
  },
  img,
  options,
  button,
  heading,
};
