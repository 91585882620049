<template>
  <div>
    <m-pages-google-map :height="block.options.mapHeight" />
    <m-pages-footer :item="block.options">
      <div style="min-height:25px;" class="mx-auto">
        <div class="mb-2">
          <m-pages-button
            v-for="(item, index) in block.links"
            :key="index"
            :item="item"
            is-text
            class="mx-2"
          />
        </div>

        <v-card-text v-if="block.options.content" class="mb-2" v-html="block.content.html"></v-card-text>

        <div v-if="block.options.copyright" class="caption my-2">
          <span class="mr-1">&#169;</span>
          <template v-if="!block.copyright.hideYear">{{ new Date().getFullYear() }}</template>
          <span v-if="block.options.copyright && !block.copyright.hideYear" class="mx-1"></span>

          <m-pages-text :item="block.copyright" tag="span" />
        </div>
      </div>
    </m-pages-footer>
  </div>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
  computed: {
    card() {
      const item = { ...this.block.options };

      item.color = "rgba(0,0,0,0)";

      return item;
    },
  },

  beforeMount() {
    console.log("getMenu", this.block);
  },
};
</script>
