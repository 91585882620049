import axios from 'axios'

export default {
  install(Vue, options = {}) {
    // create api
    const baseURL = options.url ? options.url : process.env.VUE_APP_API_URL
    const Api = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
      },
    })

    Vue.prototype.$api = {
      setAuth(token = null) {
        if (token && token.indexOf('Bearer') < -1) {
          token = `Bearer ${token}`
        }
        Api.defaults.headers['x-ml-authorization'] = token
      },

      getAuthHeaders() {
        return {
          'x-ml-authorization': Api.defaults.headers['x-ml-authorization'],
          Accept: 'application/json',
        }
      },

      getHeaders() {
        return Api.defaults.headers
      },

      getBaseURL(ext = '') {
        return baseURL + ext
      },

      get(endpoint = '', params = {}) {
        return Api.get(endpoint, { params })
      },

      $get(endpoint = '', params = {}) {
        return Api.get(endpoint, { params }).then(({ data }) => {
          return data
        })
      },

      downloadBlob(endpoint, params = {}, onDownloadProgress = () => {}) {
        return Api.get(endpoint, {
          onDownloadProgress,
          responseType: 'blob',
          timeout: 300000,
          params,
        })
      },
      download(endpoint = '', params = {}) {
        const headers = { 'Content-Type': 'application/octet-stream' }
        const responseType = 'stream'
        const onDownloadProgress = (e) => {
          console.log('onDownloadProgress', e)
        }
        return Api.get(endpoint, {
          onDownloadProgress,
          headers,
          responseType,
          params,
        })
      },

      post(
        endpoint = '',
        data = {},
        params = {},
        remove = ['id', 'identifier', 'created', 'updated']
      ) {
        const form = new URLSearchParams()

        for (let k in data) {
          if (!remove.includes(k)) {
            // bools
            if (typeof data[k] == 'boolean') {
              data[k] = data[k] ? 1 : 0
            }

            // objects
            if (typeof data[k] == 'object') {
              data[k] = JSON.stringify(data[k])
            }
            form.append(k, data[k])
          }
        }

        return Api.post(endpoint, form, { params })
      },
    }
  },
}
