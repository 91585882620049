<template>
  <v-row class="my-n3">
    <v-col>
      <v-select
        v-model="h"
        label="Hour"
        :items="hOptions"
        :hide-details="hideDetails"
        :outlined="outlined"
        @input="onInput"
      />
    </v-col>
    <v-col>
      <v-select
        v-model="m"
        label="Mins"
        :items="mOptions"
        :hide-details="hideDetails"
        :outlined="outlined"
        @input="onInput"
      />
    </v-col>
  </v-row>
</template>

<script>
import getCount from "@/helpers/getters/get-count";

// format
export default {
  props: {
    outlined: Boolean,
    hideDetails: Boolean,
    value: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      h: "00",
      m: "00",
      hOptions: getCount(23, 0, "digits"),
      mOptions: getCount(59, 0, "digits")
    };
  },

  methods: {
    onInput() {
      this.$emit("input", `${this.h}:${this.m}`);
    }
  },

  beforeMount() {
    if (this.value) {
      const [h, m] = this.value.split(":");
      this.h = h;
      this.m = m;
    }
    console.log("timePicker", this.value);
  }
};
</script>