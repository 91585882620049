import { defaultValues as spacingDefaults } from "../../helpers/spacing";

export default [
  {
    name: "header", // same as type
    id: "header1", // autogenerated
    container: {
      color: "grey lighten-2",
      fluid: false,
      // mt: "6",
      ...spacingDefaults,
      elevation: 0,
      width: null,
      minWidith: null,
      height: null,
    },
    title: {
      text: "Ginosko",
    },
    slogan: {
      text: "Couples counselling",
    },
    logo: {
      src: "https://ginoskocounselling.com/img/logo-temp.2340367b.png",
      height: 60,
      width: null,
    },
    call: {
      icon: {
        icon: "phone",
        size: 50,
        color: "grey",
      },
      headline: {
        text: "Call Us Now",
      },
      number: {
        text: "000 000 000",
        number: "+45678765456",
      },
      footer: {
        text: "Geelong, Australia",
      },
    },
  },
];
