<template>
  <v-container>
    <v-row class="center py-9">
      <div class="mx-auto">
        <v-avatar size="300" :tile="block.avatar.tile">
          <v-img :src="block.img.src"></v-img>
        </v-avatar>
      </div>
    </v-row>

    <m-pages-heading v-if="block.heading && block.heading.text" :item="block.heading" />
    <m-pages-subheading v-if="block.subheading && block.subheading.text" :item="block.subheading" />
    <slot>
      <div v-html="block.content.html ? block.content.html : ''">{{ block.content.html }}</div>
    </slot>
  </v-container>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
