const fields = [
	{
		text: 'ID',
		value: 'id',
		header: true,
	},
	{
		text: 'Name',
		value: 'name',
		editable: true,
	},
	{
		text: 'Public Key',
		value: 'public_key',
		editable: false,
	},
	// {
	//   text: "Private Key",
	//   value: "private_key",
	//   placeholder: "hidden",
	//   editable: true,
	//   disabled: true,
	// },
	{
		text: 'File Type',
		value: 'file_type',
		editable: true,
	},

	{
		text: 'Url',
		value: 'url',
		editable: true,
	},
	{
		text: 'File',
		value: 'file',
		editable: true,
	},
	{
		text: 'Version',
		value: 'version',
		editable: true,
	},
	{
		text: 'Active',
		value: 'active',
		editable: true,
		type: 'switch',
	},

	{
		text: 'Updated',
		value: 'updatedAt',
		header: true,
	},
	{
		text: '',
		value: 'actions',
		header: true,
	},
]

export const headers = fields.filter((a) => a.header)

export const form = fields.filter((a) => a.editable)

export default fields
