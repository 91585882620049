import item from '../../store/helpers/item'
import sub from '../../store/helpers/sub'
const state = {
	...item.state,
	...sub.state(['contacts']),
}

const mutations = {
	...item.mutations,
	...sub.mutations,
	//
}

const getters = {
	...item.getters,
	...sub.getters(['contacts']),
}

const actions = {
	...item.actions,
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
