export const loadComponents = (
  Vue,
  context,
  prefix = '',
  append = '',
  checkRootComp = true,
  checkRootMatch = false
) => {
  if (typeof context == 'function') {
    context = [context]
  }

  context.forEach((context) => {
    context.keys().forEach((fileName) => {
      const componentConfig = context(fileName)
      const componentName = fileName
        .replace(/^\.\/_/, '')
        .replace(/\.\w+$/, '')
        .split('-')
        .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
        .join('')
        .split('/')
        .pop()

      if (checkRootMatch && componentName.indexOf(prefix) === 0) {
        // console.log('ROOTMATCH', prefix)
        Vue.component(componentName, componentConfig.default || componentConfig)
      } else if (checkRootComp && componentName == prefix) {
        Vue.component(componentName, componentConfig.default || componentConfig)
      } else {
        let name = ''
        if (componentName.toLowerCase() === 'index') {
          name = prefix + append
        } else {
          name = prefix + componentName + append
        }
        Vue.component(name, componentConfig.default || componentConfig)
      }
    })
  })

  // if(typeof context)
}
