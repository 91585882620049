export default {
  container: {
    spacing: {},
    elevation: 0,
    dark: true,
    fluid: true,
    noGutters: true,
  },
  wrapper: {
    spacing: {},
    elevation: 0,
  },
  noContentSpacing: true,
  content: {
    img: {
      src: "https://source.unsplash.com/0hPxXa82y_I/1200x500",
    },
    parallax: true,
    matchHeight: false,
    height: 313,
    heading: {
      heading: {
        marginX: 0,
        marginY: 0,
        paddingX: 0,
        paddingY: 0,
        elevation: 0,
        text: "{business.slogan}",
        textSize: "h3",
      },
      subheading: {
        marginX: 0,
        marginY: 0,
        paddingX: 0,
        paddingY: 0,
        elevation: 0,
        text: "{contact.name}",
        textSize: "h6",
      },
      advanced: false,
    },
    subheading: {
      text: "We can make your life easier.",
    },
    downArrow: true,
    to: "welcome",
  },
  noPageSpacingY: true,
  noPageSpacingX: true,
};
