/**
 * Item PLUS includes background image, PLUS theme.
 */

import { spacingKeys } from "../js/forms/fields";
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      theme: "MPages/theme",
      isBuilder: "MPages/isBuilder",
    }),

    itemClass() {
      const classes = [];
      const item = this.item;

      // Spacing
      if (item.spacing) {
        spacingKeys.forEach((k) => {
          if (item.spacing[k]) {
            classes.push(k + "-" + item.spacing[k]);
          }
        });
      }

      return classes;
    },

    itemStyle() {
      const style = {};
      const item = this.item;
      /**
       * Background Image
       */
      if (item.src) {
        style["background-image"] = `url(${item.src})`;
        style["background-size"] = item.backgroundSize
          ? item.backgroundSize
          : "cover";
        style["background-position"] = item.backgroundPosition
          ? item.backgroundPosition
          : "center center";
      }

      return style;
    },
    /**
     * VERSION 1
     */
    // itemClass() {
    //   let cl = [];

    //   ["px", "pb", "pt"].forEach((key) => {
    //     if (this.item[key]) {
    //       const val = this.item[key];
    //       cl.push(`${key}-${val}`);
    //     }
    //   });

    //   return cl;
    // },

    // itemStyle() {
    //   const style = {};

    //   if (this.item.textColor) {
    //     style.color = this.item.textColor;
    //   }

    //   return style;
    // },
  },
};
