import item from "./item";

const state = (subs = ["users", "addons"]) => {
  //
  const values = {};

  subs.forEach((sub) => {
    values[sub] = {
      ready: false,
      data: [],
    };
  });

  return values;
};

const mutations = {
  SET_SUB(state, payload) {
    const [sub, values] = payload;
    for (let k in values) {
      state[sub][k] = values[k];
    }
  },
};

const getters = (subs = []) => {
  let items = {};

  subs.forEach((sub) => {
    items[sub] = function(state) {
      return state[sub];
    };
  });

  return items;
};

export default {
  state,
  mutations,
  getters,
};
