export default [
  {
    title: "Business Details",
    value: "business",
    items: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Slogan",
        value: "slogan",
      },
      {
        text: "Phone",
        value: "phone",
      },
      {
        text: "Phone Number",
        value: "phone_number",
      },
      {
        text: "Location",
        value: "location",
      },
      {
        text: "Logo",
        value: "logo",
        placeholder: "https://",
      },
      {
        text: "Google Maps Code",
        value: "gmap",
        component: "m-pages-google-maps-field",
        rows: 3,
      },
    ],
  },
  {
    title: "Contact Person",
    value: "contact",
    items: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Contact Avatar (Profile Picture)",
        value: "avatar",
        placeholder: "https://",
      },
    ],
  },
  {
    title: "Action Banner",
    value: "actionBanner",
    copyBusiness: true,
    items: [
      {
        text: "Active",
        value: "active",
        fieldType: "switch",
      },
      {
        text: "Greeting / Title",
        value: "title",
        cols: 4,
      },
      {
        text: "Action",
        value: "text",
        cols: 4,
      },
      {
        text: "Location",
        value: "footer",
        cols: 4,
      },
      {
        text: "Mobile Greeting / Title",
        value: "mobileTitle",
        cols: 4,
      },
      {
        text: "Mobile Action",
        value: "mobileText",
        cols: 4,
      },
      {
        text: "Mobile Location",
        value: "mobileFooter",
        cols: 4,
      },
      // {
      //   text: "Button Text",
      //   value: "buttonText",
      //   cols: 6,
      // },
      {
        text: "Image",
        value: "image",
        cols: 6,
      },
      {
        text: "Button Icon",
        value: "buttonIcon",
        cols: 6,
      },
      {
        text: "Button Text",
        value: "button",
        // component: "m-link-field",
        component: "m-pages-link-form",
      },
      // {
      //   text: "Width",
      //   value: "width",
      //   fieldType: "slider",
      //   min: 200,
      //   max: 500,
      // },
      {
        text: "Button Color",
        value: "buttonColor",
        component: "m-color-picker",
        cols: 6,
      },
      {
        text: "Shadow",
        value: "elevation",
        fieldType: "slider",
        min: 0,
        max: 24,
        cols: 6,
      },
      {
        text: "Show Button On Desktop",
        value: "showButtonOnDesktop",
        fieldType: "checkbox",
        cols: 6,
      },
      {
        text: "Square Image",
        value: "imageTile",
        fieldType: "checkbox",
        cols: 6,
      },
      {
        text: "Left",
        value: "left",
        fieldType: "checkbox",
        cols: 4,
      },
      {
        text: "Top",
        value: "top",
        fieldType: "checkbox",
        cols: 4,
      },
      {
        text: "Dark",
        value: "dark",
        fieldType: "checkbox",
        cols: 4,
      },
    ],
  },
  {
    title: "Contact Channels",
    value: "social",
    items: [
      {
        text: "Email",
        icon: "mdi-email",
        color: "#ffa500",
        value: "email",
        hint: "Email Address",
      },
      {
        text: "Call",
        icon: "mdi-phone",
        color: "#ffa500",
        value: "phone",
        hint: "Full phone number including internation code (+ optional)",
      },
      {
        text: "Facebook",
        icon: "mdi-facebook",
        color: "#3b5998",
        value: "facebook",
        hint: "Facebook Page URL",
      },
      {
        text: "LinkedIn",
        icon: "mdi-linkedin",
        color: "#0075B2",
        value: "linkedin",
        hint: "LinkedIn  Page URL",
      },
      {
        text: "Messenger",
        icon: "mdi-facebook-messenger",
        color: "#0084ff",
        value: "messenger",
        hint: "Facebook Username",
      },
      {
        text: "Skype",
        icon: "mdi-skype",
        color: "#01ABEA",
        value: "skype",
        hint: "Skype username",
      },
      {
        text: "Twitter",
        icon: "mdi-twitter",
        color: "#2AA9E0",
        value: "twitter",
        hint: "Twitter Page URL",
      },
      {
        text: "WhatsApp",
        icon: "mdi-whatsapp",
        color: "#4DCB5B",
        value: "whatsapp",
        hint:
          "WhatsApp full phone number including internation code (+ optional)",
      },
      {
        text: "YouTube",
        icon: "mdi-youtube",
        color: "#FF0100",
        value: "youtube",
        hint: "YouTube Channel ID or URL",
      },
    ],
  },
  {
    title: "SEO",
    value: "seo",
    copyBusiness: true,
    items: [
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Favicon",
        value: "favicon",
      },
      {
        text: "Keywords",
        value: "keywords",
      },
    ],
  },
  {
    title: "Open Graph",
    value: "og",
    copyBusiness: true,
    items: [
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Image",
        value: "image",
      },
    ],
  },
  {
    title: "Tracking",
    value: "tracking",
    items: [
      {
        text: "Google Analytics ID",
        value: "googleAnalytics",
      },
      {
        text: "Google Ads Conversion Tracking Code",
        value: "googleAds",
      },
      {
        text: "Facebook Pixel ID",
        value: "facebookPixel",
      },
      {
        component: "m-pages-code-field",
        text: "Custom Header Code",
        value: "headerCode",
      },
      {
        component: "m-pages-code-field",
        text: "Custom Footer Code",
        value: "footerCode",
      },
    ],
  },
];
