<template>
  <v-toolbar
    :src="item.src"
    :collapse="item.collapse"
    :dense="item.dense"
    :extended="item.extended"
    :prominent="item.prominent"
    :short="item.short"
    :flat="item.flat"
    :color="item.color"
    :roundedx="item.rounded"
    :outlined="item.outlined"
    :rounded="item.rounded"
    :elevation="item.elevation ? item.elevation : ''"
    :dark="item.dark"
    :light="item.light"
  >
    <slot />
  </v-toolbar>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>