export default {
	playVideo() {
		this.player.play();
	},

	unmute() {
		// this.player.unmute()
		console.log('unmute', this.player);
		this.player.muted = false;

		setTimeout(() => {
			// this.player.muted = false;
			this.player.volume = 1;
		});
	},
};
