<template>
  <div class="m-draggable">
    <draggable
      tag="ul"
      :style="`padding-left:${isNested ? nestedOffset : 0}px;`"
      :list="items"
      :group="{ name: 'g1' }"
      @sort="onSort"
    >
      <div v-for="(item, index) in items" :key="index">
        <li>
          <v-card class="mb-1 m-pointer" :flat="flat">
            <v-row no-gutters align="center">
              <v-col>
                <slot name="header">
                  <v-card-title class="py-3">{{
                    typeof itemText == "function"
                      ? itemText(item)
                      : itemText && item[itemText]
                      ? item[itemText]
                      : "Title"
                  }}</v-card-title>
                </slot>
              </v-col>
              <v-col v-if="canEdit || canDelete" class="text-right pr-2 shrink">
                <v-sheet :min-width="isDelete == item.id ? 174 : 100">
                  <slot name="actions">
                    <v-btn
                      v-if="canEdit && isDelete != item.id"
                      @click="onEdit(item)"
                      icon
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>

                    <template v-if="isDelete == item.id">
                      <v-btn
                        color="error"
                        small
                        class="mr-1"
                        @click="onDelete(item)"
                        >Delete?</v-btn
                      >
                      <v-btn depressed small @click="isDelete = ''"
                        >Cancel</v-btn
                      >
                    </template>
                    <v-btn v-else-if="canDelete" @click="onDelete(item)" icon>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </slot>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card>
          <m-draggable
            v-if="nested && item.children && (!levels || levelIndex < levels)"
            :items="item.children"
            :can-edit="canEdit"
            :can-delete="canDelete"
            :levels="levels"
            :level-index="levelIndex + 1"
            nested
            :nested-offset="nestedOffset"
            is-nested
            @onEdit="onEdit"
            @onDelete="onDelete"
            @onSort="onSort"
          />
        </li>
      </div>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  props: {
    flat: Boolean,
    canEdit: Boolean,
    canDelete: Boolean,
    deleteConfirm: Boolean,
    primaryKey: {
      type: String,
      default: "id",
    },
    nested: Boolean,
    levelIndex: {
      type: Number,
      default: 0,
    },
    levels: {
      type: Number,
      required: false,
    },
    nestedOffset: {
      type: Number,
      default: 20,
    },
    isNested: Boolean, // programatically
    items: {
      type: Array,
      default() {
        return [];
      },
    },

    itemText: {
      type: [String, Function],
      default: "text",
    },

    headerWidth: {
      type: [Number, String],
      default: 260,
    },
  },
  components: {
    draggable,
  },
  name: "m-draggable",
  data() {
    return {
      isDelete: "",
    };
  },
  methods: {
    onSort(value) {
      // console.log("onSort", value);
      this.$emit("onSort", value);
    },
    onEdit(item) {
      this.$emit("onEdit", item);
    },
    onDelete(item) {
      if (!this.deleteConfirm && !this.isDelete) {
        this.isDelete = item[this.primaryKey];
        return;
      }
      this.$emit("onDelete", item);
      this.isDelete = "";
    },
  },
};
</script>
