export default {
	autoplay: Boolean,
	muted: Boolean,
	changed: Boolean, // video changed
	start: {
		type: Number,
		default: 0,
	},
	src: {
		type: [String, Object],
		default: null,
	},
	color: {
		type: String,
		default: 'yellow',
	},

	video: {
		type: Object,
		default() {
			return null;
		},
	},

	source: {
		type: Object,
		default() {
			return {};
		},
	},
	index: {
		type: Number,
		default: 0,
	},
	id: {
		type: String,
		default: 'VideoGalleryPlayer',
	},
};
