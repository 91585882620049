<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="item in items" :key="item.value">
      <v-expansion-panel-header>
        {{ item.text }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot :name="item.value" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Header',
          value: 'header',
        },
        {
          text: 'Fields',
          value: 'fields',
        },
        {
          text: 'Footer',
          value: 'footer',
        },
        {
          text: 'Success',
          value: 'success',
        },
        {
          text: 'Options',
          value: 'options',
        },
      ],
    }
  },
}
</script>
