export default {
  disable: false,
  admin: false,
  name: "auth",
  url: "/auth",
  // siteName: '', // for recovery, def: process.env.VUE_APP_APP_NAME
  // loginUrl: '/logout',
  // logoutUrl: '/login',
  // helpUrl: '/login-help'
  // recoveryTokenValidateUrl: "/validate-token",
  // recoveryTokenLoginUrl: '/token-login'
  // recoveryUrl: process.env.VUE_APP_SITE_URL,
  // meUrl: /auth/me
  // localKey: ``,
  apiUserKey: "data",
  apiTokenKey: "meta.token",
  useComponents: true,
  updatableFields: ["email", "firstName", "lastName", "password"],

  //
  useRouterGuard: false, // if you want to guard routes
  loginPath: "/login",
  unauthPath: "/",
  useLoginPage: false, // turn off to create own login page
  useLoginPageComponent: true,
  loginPage: {
    bg: {
      // filters: "blur(1px) brightness(50%)",
      filters: "grayscale(50%)",
      // keywords: "",
      // src: "WkRKRoBzKZk",
      quality: 0.75,
      lighten: 0.75,
      // darken: 0.4,
    },
  },
  logoutTo: { name: "auth-login" },
  loginTo: { path: "/dashboard" },
  unauthTo: { path: "/" },
  userFields: [
    {
      name: "first_name",
      label: "First Name",
    },
    {
      name: "last_name",
      label: "Last Name",
    },
    {
      name: "email",
      label: "Email",
    },
  ],
};
