<template>
  <v-sheet
    tile
    :height="height"
    :color="color"
    :class="`elevation-${shadow} editor-navbar`"
    style="position:relative;z-index:2"
  >
    <v-container fill-height class="py-0" :fluid="fluid">
      <v-row no-gutters fill-height>
        <slot />
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin]
};
</script>
