<template>
  <v-toolbar dense flat rounded>
    <v-text-field v-model="field.name" label="Name" hide-details class="mx-2" />
    <v-text-field
      v-model="field.attrs.label"
      label="Label"
      hide-details
      class="mx-2"
    />
    <v-text-field
      v-model="field.attrs.placeholder"
      label="Placeholder"
      hide-details
      class="mx-2"
    />

    <v-switch v-model="field.attrs.required" label="Required" hide-details />

    <v-btn v-if="isDelete" depressed class="mx-2" @click="isDelete = false">
      Cancel
    </v-btn>

    <v-btn icon small class="ml-2 mr-1" @click="onDelete">
      <v-icon :color="isDelete ? 'error' : ''">delete</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      watcherReady: false,
      isDelete: false,
      field: {},
    }
  },

  methods: {
    onDelete() {
      if (!this.isDelete) return (this.isDelete = true)

      this.$emit('delete')
    },
  },

  beforeMount() {
    this.field = { ...this.value }
  },

  watch: {
    field: {
      handler: function() {
        if (!this.watcherReady) return (this.watcherReady = true)

        console.log('changed')
        this.$emit('input', { ...this.field })
      },
    },
    deep: true,
  },
}
</script>
