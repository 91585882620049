import item from "../../store/helpers/item";

const state = {
  ...item.state,
  stats: {},
  baseEndpoint: "admin/broadcasts",
};

const mutations = {
  ...item.mutations,
  SET_STATS(state, stats) {
    state.stats = stats;
  },
  //
};

const getters = {
  ...item.getters,
  stats(state) {
    return state.stats;
  },
};

const actions = {
  ...item.actions,
  setStats({ commit }, stats) {
    commit("SET_STATS", stats);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
