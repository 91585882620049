export default {
  // methods: {
  //     onScroll() {

  //     }
  // },

  mounted() {
    window.addEventListener("scroll", this.onScroll, false);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
