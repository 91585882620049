<template>
	<div class="black white--text elevation-4" style="position:relative">
		<div
			:class="changing ? 'm-changing' : ''"
			ref="playerContainer"
			:style="minHeight ? `min-height:${minHeight}px` : ''"
		>
			<video :id="id"></video>
		</div>

		<!-- Poster  -->
		<div v-if="showPoster" class="m-layer-container">
			<v-img :src="poster" class="m-poster" />
		</div>

		<!-- Loading  -->
		<div class="m-layer-container">
			<v-layout fill-height>
				<v-row justify="center" align="center">
					<v-progress-circular v-if="loading" :size="180" :color="color" indeterminate />
					<v-btn
						tile
						height="84"
						color="rgba(0,0,0,0.7)"
						width="130"
						dark
						v-else-if="showCenterPlay"
						style="pointer-events:auto"
						@click="togglePlay"
					>
						<v-icon size="80" v-text="status == 'replay' ? '' : 'play_arrow'" />
					</v-btn>
				</v-row>
			</v-layout>
		</div>
	</div>
</template>

<script>
import js from './video-gallery-player';
export default js;
</script>

<style scoped>
.m-changing {
	opacity: 0;
}
.m-layer-container {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	pointer-events: none;
}
.m-poster {
	position: absolute;
	width: 100%;
	height: 100%;
	/* opacity: 0.5; */
}
</style>
