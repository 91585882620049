<template>
  <v-container>
    <m-pages-heading :block="block" :setup="setup" :theme="theme" />
    <!-- {{block.contain}} -->
    <v-text-field
      v-model="q"
      prepend-inner-icon="search"
      label="Find product..."
      clearable
    ></v-text-field>

    <v-row>
      <m-pages-col
        v-for="(product, index) in items"
        :key="`prod${index}`"
        :col="block.cols"
      >
        <m-pages-my-products-block-card
          :product="product"
          :contain="block.contain"
          :promote-open-new="block.promoteOpenNew"
        />
      </m-pages-col>
    </v-row>
  </v-container>
</template>

<script>
import blockMixin from '../../mixins/block'
import mergeObj from '../../mixins/merge-obj'

export default {
  mixins: [blockMixin, mergeObj],

  data() {
    return {
      q: '',
      products: [],
    }
  },

  computed: {
    items() {
      if (!this.q) return this.products

      const q = this.q.toLowerCase()

      const filter = ({ name }) => {
        if (name.toLowerCase().indexOf(q) > -1) {
          return true
        }
      }

      return this.products.filter(filter)
    },
  },

  async beforeMount() {
    const { data } = await this.$api.get('my-products')

    this.products = data.data
  },
}
</script>
