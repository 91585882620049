// import brandbar from "./brandbar";
// import heading from "./heading";
// import video from "./video";
import content from "./content";
// import button from "./button";
// import footer from "./mini-footer";
// import buyButton from "./buy-button";
// fields
import fields from "./fields";

const links = {
  // menu: "",
  groups: [
    {
      fields: [
        {
          fields: [
            {
              text: "Menu",
              value: "menu",
              component: "m-autocomplete",
              endpoint: "admin/menus",
            },
          ],
        },
      ],
    },
  ],
};

const copyright = {
  groups: [
    {
      fields: [
        {
          fields: [
            {
              text: "Text",
              value: "text",
            },
            {
              text: "Hide Year",
              value: "hideYear",
              type: "switch",
            },
          ],
        },
      ],
    },
  ],
};

const options = {
  groups: [
    {
      fields: [
        {
          // fields: [...fields.padding],
          fields: [fields.px, fields.pt, fields.pb, fields.elevation],
        },
        {
          fields: [
            { ...fields.height, requires: "useHeight" },
            { ...fields.width, requires: "useWidth" },
            fields.maxWidth,
            fields.maxHeight,
            {
              text: "Custom Map Height",
              value: "mapHeight",
              type: "number",
              clearable: true,
              requires: "showMap",
            },
            fields.rounded,
          ],
        },
        // {
        //   fields: [...fields.margin],
        // },
        {
          // ...fields.justify,
          itemCols: 3,
          fields: [
            {
              text: "Links",
              value: "links",
              type: "switch",
            },
            {
              text: "Content",
              value: "content",
              type: "switch",
            },
            {
              text: "Copyright",
              value: "copyright",
              type: "switch",
              cols: 2,
            },
            {
              text: "Menu",
              value: "menu",
              type: "switch",
              cols: 2,
            },
            {
              text: "Map",
              value: "showMap",
              type: "switch",
              cols: 2,
            },
          ],
        },

        // advanced
        {
          itemCols: 3,
          fields: [
            fields.dark,
            fields.light,
            fields.flat,
            fields.absolute,
            fields.app,
            fields.fixed,
            fields.useWidth,
            fields.useHeight,
            fields.inset,
            fields.outlined,
            fields.padless,
            fields.tile,
            // fields.tile,
            // {
            //   ...fields.rounded,
            // },

            fields.color,
            fields.textColor,
          ],
        },
      ],
    },
  ],
};

export default {
  options,
  links,
  copyright,
  content,
};
