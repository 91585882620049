export default {
  video: {
    active: false,
  },
  date: {
    active: false,
  },
  page: {
    active: false,
  },
};
