const padding = (v, shortText) => {
  return {
    shortText,
    text: "Padding " + v.toUpperCase(),
    value: `p${v}`,
    type: "slider",
    min: 0,
    max: 12,
    thumbLabel: true,
  };
};

const pa = padding("a", "All");
const px = padding("x", "X");
const py = padding("y", "Y");
const pt = padding("t", "Top");
const pb = padding("b", "Bottom");
const pl = padding("l", "Left");
const pr = padding("r", "Right");
const ps = padding("s", "PS");
const pe = padding("e", "PE");

export default {
  pa,
  px,
  py,
  pt,
  pb,
  pl,
  pr,
  ps,
  pe,
};

export const names = ["pa", "px", "py", "pt", "pb", "pl", "pr", "ps", "pe"];
