<template>
  <div
    :style="`background-color:${theme.bgColor}`"
    :class="`px-${theme.marginX || 0} py-${theme.marginY || 0}`"
  >
    <v-sheet
      :max-width="theme.mini ? 800 : ''"
      class="m-pages m-pages-builder mx-auto"
      :elevation="theme.elevation"
      :tile="!theme.rounded"
      :rounded="theme.rounded"
      :color="theme.contentBgColor"
      :dark="theme.dark"
    >
      <slot />
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      theme: 'MPages/theme',
    }),
  },
}
</script>
