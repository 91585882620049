import { nanoid } from 'nanoid'
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const MakeField = (type, name = '', opts = {}, attrs = {}) => {
  if (!name) name = type

  if (!attrs.label) {
    attrs.label = capitalize(name)
  }
  return {
    id: nanoid(),
    type,
    name,
    opts,
    attrs,
  }
}

export default MakeField
