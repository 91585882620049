export default {
	play(index) {
		const same = this.index == index;

		this.index = index;

		this.autoplay = true;
		// this.muted = false;

		if (this.video.currentTime) {
			this.start = this.video.currentTime;
		}

		if (!same) {
			this.changed = !this.changed;
			this.storeProgress();
		}
	},

	async queStoreProgress() {
		this.storingProgress = true;

		setTimeout(this.storeProgress, 1000);
	},

	async storeProgress() {
		const value = {
			index: this.index,
			items: this.items.map((a) => {
				return {
					id: a.id,
					currentTime: Math.floor(a.currentTime) || 0,
				};
			}),
		};

		await this.$localforage.setItem(this.getKey('progress'), value);

		this.storingProgress = false;
	},

	saveProgress(force = false, wait = 2000) {
		if (this.saveWaitPending && !force) {
			return;
		}

		this.saveWaitPending = true;

		setTimeout(
			() => {
				const value = {
					index: this.itemIndex,
					items: this.items.map((a) => {
						return {
							id: a.id,
							currentTime: a.currentTime || 0,
						};
					}),
				};
				this.$localforage.setItem(`${this.id}_progress_v2`, value);
				this.saveWaitPending = false;
				console.log('saved!', value);
			},
			force ? 0 : wait
		);
	},
	selectVideo(index, play = true) {
		console.log('selectVideo', index);
	},

	getKey(value) {
		return `${this.id}_${value}`;
	},
};
