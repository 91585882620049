export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      valReady: false,
      val: {},
    };
  },

  computed: {
    valStr() {
      return JSON.stringify(this.val);
    },
  },

  watch: {
    valStr(str) {
      if (this.valReady) {
        this.$emit("input", { ...this.val });
        if (typeof this.onModel == "function") {
          this.onModel({ ...this.val });
        }
      }
    },
  },

  beforeMount() {
    this.val = { ...this.value };

    if (typeof this.beforeReady == "function") {
      this.beforeReady();
    }

    if (typeof this.modelItem == "object") {
      for (let k in this.modelItem) {
        if (typeof this.val[k] == "undefined") {
          this.val[k] = this.modelItem[k];
          console.log("set", k);
        }
      }
    }

    this.valReady = true;
  },
};
