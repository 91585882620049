const mixins = (store) => {
  return {
    computed: {
      authReady() {
        return store.state.auth.ready;
      },
      authenticated() {
        return store.state.auth.loggedIn;
      },
      user() {
        return store.state.auth.user;
      },
    },
  };
};

export default mixins;
