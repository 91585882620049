export default {
  text: "Screen",
  value: "screen",
  type: "select",
  items: [
    {
      text: "App",
      value: "app",
    },
    {
      text: "My Products",
      value: "my-products",
    },
    {
      text: "List / Index",
      value: "list",
    },
    {
      text: "Item",
      value: "item",
    },
    {
      text: "Left Menu",
      value: "left-menu",
    },
    {
      text: "List",
      value: "list",
    },
  ],
};
