<template>
  <v-row dense>
    <v-col>
      <m-pages-panel title="Options">
        <v-text-field
          v-model="val.src"
          :label="src.text"
          :placeholder="src.placeholder"
        />

        <v-expand-transition>
          <v-row v-if="val.src" class="mb-n4">
            <v-col>
              <m-pages-background-size-field
                v-model="val[backgroundSize.value]"
                :label="backgroundSize.text"
              />
            </v-col>
            <v-col>
              <m-pages-background-position-field
                v-model="val[backgroundPosition.value]"
                :label="backgroundPosition.text"
              />
            </v-col>
          </v-row>
        </v-expand-transition>

        <m-pages-rounded-field v-model="val.rounded" />
        <v-slider
          v-model="val.elevation"
          :label="elevation.text"
          :min="elevation.min"
          :max="elevation.max"
          class="mt-2 mb-n2"
        />

        <v-row no-gutters justify="space-between">
          <v-col v-for="option in options" :key="option.value" cols="4">
            <v-checkbox v-model="val[option.value]" :label="option.text" />
          </v-col>
          <v-col class="pt-3 pr-2 text-right">
            <m-color-picker v-model="val.color" label="Color" depressed />
          </v-col>
        </v-row>
      </m-pages-panel>
    </v-col>
    <v-col cols="8" class="pl-4">
      <m-pages-spacing-form v-model="val.spacing" />
    </v-col>
  </v-row>
</template>

<script>
import model from "../../mixins/model";
import {
  elevation,
  // fluid,
  noGutters,
  // dark,
  // light,
  src,
  rounded,
  backgroundSize,
  backgroundPosition,
} from "../../js/forms/fields";

export default {
  mixins: [model],

  data() {
    return {
      backgroundSize,
      backgroundPosition,
      src,
      elevation,
      options: [
        // fluid,
        // dark, light,
        noGutters,
      ],
    };
  },
};
</script>
