<template>
  <v-list :dense="menu.dense" class="mb-8">
    <template v-for="(item, index) in menu.items">
      <m-menu-group v-if="item.children && item.children.length" :key="index" :group="item" />
      <m-menu-item v-else :key="index" :item="item" />
    </template>
  </v-list>
</template>
<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>
