import bools from './bools';
import margins from './margins';
import numbers from './numbers';
import paddings from './paddings';
import selects from './selects';
import sliders from './sliders';
import textFields from './text-fields';
import headingFields from './heading';

export const heading = headingFields;

export const mediaTimeSlider = {
	component: 'm-pages-media-time-slider',
	text: 'Media Time',
	value: 'mediaTime',
};
export const showTime = {
	component: 'm-pages-media-time-slider',
	text: 'Show Time',
	value: 'showTime',
};
export const hideTime = {
	component: 'm-pages-media-time-slider',
	text: 'Hide Time',
	value: 'hideTime',
};

export const backgroundSize = {
	component: 'm-pages-background-size',
	text: 'Background Size',
	value: 'backgroundSize',
};

export const backgroundPosition = {
	component: 'm-pages-background-position',
	text: 'Background Position',
	value: 'backgroundPosition',
};

export const color = {
	component: 'm-color-picker',
	text: 'Color',
	value: 'color',
	class: 'mt-3',
};

export const html = {
	value: 'html',
	component: 'm-editor',
};

export const textColor = {
	component: 'm-color-picker',
	text: 'Text',
	value: 'textColor',
};

const fields = {
	color,
	textColor,
	html,
	margins,
	paddings,
	// heading,
	...textFields,
	...bools,
	...margins,
	...numbers,
	...paddings,
	...selects,
	...sliders,
};

export default fields;

/**
 * Export bools
 */
export const app = bools.app;
export const active = bools.active;
export const absolute = bools.absolute;
export const advanced = bools.advanced;
export const block = bools.block;
export const dark = bools.dark;
export const dense = bools.dense;
export const depressed = bools.depressed;
export const disabled = bools.disabled;
export const collapse = bools.collapse;
export const extended = bools.extended;
export const expanded = bools.expanded;
export const fixed = bools.fixed;
export const flat = bools.flat;
export const fluid = bools.fluid;
export const inset = bools.inset;
export const isText = bools.isText;
export const light = bools.light;
export const image = bools.image;
export const outlined = bools.outlined;
export const padless = bools.padless;
export const priminent = bools.priminent;
export const prominent = bools.prominent;
export const isRounded = bools.isRounded;
export const shaped = bools.shaped;
export const short = bools.short;
export const tile = bools.tile;
export const authenticated = bools.authenticated;
export const unAuthenticated = bools.unAuthenticated;
export const useHeight = bools.useHeight;
export const useWidth = bools.useWidth;
export const useIcon = bools.useIcon;
export const mxAuto = bools.mxAuto;
export const noGutters = bools.noGutters;
export const showOnReplay = bools.showOnReplay;
export const showOnReturn = bools.showOnReturn;
export const useShowTime = bools.useShowTime;
export const useHideTime = bools.useHideTime;
export const noPageSpacingY = bools.noPageSpacingY;
export const noPageSpacingX = bools.noPageSpacingX;
export const noFollow = bools.noFollow;
export const noIndex = bools.noIndex;
export const sticky = bools.sticky;

/**
 * Numbers
 */
export const width = numbers.width;
export const height = numbers.height;
export const maxWidth = numbers.maxWidth;
export const maxHeight = numbers.maxHeight;
export const minWidth = numbers.minWidth;
export const minHeight = numbers.minHeight;
/**
 * Sliders
 */

export const spacingX = sliders.spacingX;
export const spacingY = sliders.spacingY;
export const elevation = sliders.elevation;
export const opacity = sliders.opacity;
export const hover = sliders.hover;

/**
 * Selects
 */
export const clickAction = selects.clickAction;
export const fontWeights = selects.fontWeights;
export const rounded = selects.rounded;
export const size = selects.size;
export const textSize = selects.textSize;
export const justify = selects.justify;
export const align = selects.align;
export const target = selects.target;

export const margin = margins;
export const padding = paddings;

/**
 * Margins
 */
export const mx = margins.mx;
export const my = margins.my;
export const ms = margins.ms;
export const me = margins.me;
export const mt = margins.mt;
export const mb = margins.mb;
export const ml = margins.ml;
export const mr = margins.mr;

/**
 * Paddings
 */
export const px = paddings.px;
export const py = paddings.py;
export const ps = paddings.ps;
export const pe = paddings.pe;
export const pt = paddings.pt;
export const pb = paddings.pb;
export const pl = paddings.pl;
export const pr = paddings.pr;

/**
 * Text
 */
// console.log("sourceField", textFields);
export const src = textFields.src;
export const href = textFields.href;

export const spacingKeys = [...Object.keys(margins), ...Object.keys(padding)];

// export const spacingFieldKeys = [ ma,
//   mx,
//   my,
//   mt,
//   mb,
//   ml,
//   mr,
//   ms,
//   me,
// ]
