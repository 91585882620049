<template>
  <v-tabs v-model="tab">
    <v-tab
      v-for="(block, index) in getBlocks"
      :key="`tab${index}`"
      v-text="block.title"
    />
    <v-tab-item v-for="(group, index) in getBlocks" :key="`item${index}`">
      <v-row no-gutters align="center" justify="start">
        <v-col
          cols="4"
          class="px-4"
          v-for="(block, index2) in group.items"
          :key="`item${index2}`"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 10 : 2"
              class="mx-auto text-center"
              @click="add(block.value)"
            >
              <v-card-text class="my-4 text-center title">
                <div>
                  <v-icon v-text="block.icon" size="50" class="mb-4" />
                </div>
                <div v-text="block.text" />
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-tab-item>
  </v-tabs>
</template>

<script>
export default {
  props: {
    isCol: Boolean,
    btnText: {
      type: String,
      default: 'Add Row',
    },
  },

  data() {
    return {
      tab: 0,
      buildingBlocks: [
        {
          title: 'Building Blocks',
          items: [
            {
              text: 'Header',
              value: 'header',
              icon: 'store',
            },
            {
              text: 'Navbar',
              value: 'navbar',
              icon: 'menu',
            },
            {
              text: 'Hero',
              value: 'hero',
              icon: 'insert_photo',
            },
            {
              text: 'Welcome',
              value: 'welcome',
              icon: 'tag_faces',
            },
            {
              text: 'Services',
              value: 'services',
              icon: 'category',
            },
            {
              text: 'Parallax',
              value: 'parallax',
              icon: 'insert_photo',
            },
            {
              text: 'Text',
              value: 'text',
              icon: 'create',
            },
            {
              text: 'Author',
              value: 'author',
              icon: 'face',
            },
            {
              text: 'Faqs',
              value: 'faqs',
              icon: 'help',
            },
            {
              text: 'Video',
              value: 'video',
              icon: 'play_arrow',
            },
            {
              text: 'Video Gallery',
              value: 'videoGallery',
              icon: 'video_library',
            },
            {
              text: 'Smart Button',
              value: 'button',
              icon: 'crop_7_5',
            },
            {
              text: 'Footer',
              value: 'footer',
              icon: 'vertical_align_bottom',
            },
            {
              text: 'Columns',
              value: 'row',
              icon: 'view_column',
            },
            {
              text: 'Form',
              value: 'form',
              icon: 'edit_note',
            },
          ],
        },
        {
          title: 'Columns',
          items: [
            {
              text: 'Split',
              value: 'row',
              colset: '2',
            },
            {
              text: '3 Columns',
              value: 'row',
              colset: '3',
            },
            {
              text: '4 Columns',
              value: 'row',
              colset: '4',
            },
            {
              text: '1/3',
              value: 'col_13',
            },
            {
              text: '2/3',
              value: 'col_23',
            },
            {
              text: 'Custom',
              value: 'col_custom',
            },
          ],
        },
        {
          title: 'Sales',
          items: [
            {
              text: 'Offer',
              value: 'offer',
              icon: 'local_offer',
            },
          ],
        },
        {
          title: 'Members',
          items: [
            {
              text: 'My Products',
              value: 'myProducts',
              icon: 'my_products',
            },
          ],
        },
        {
          title: 'Components',
          items: [
            {
              text: 'Image',
              value: 'image',
              icon: 'image',
            },
            {
              text: 'Text Content',
              value: 'text',
              icon: 'text',
            },
            {
              text: 'Placeholder',
              value: 'placeholder',
              icon: 'code',
            },
          ],
        },
      ],
    }
  },

  computed: {
    getBlocks() {
      if (this.isCol) {
        const items = this.buildingBlocks[2]

        return [items]
      }

      return this.buildingBlocks
    },
  },

  methods: {
    // add(block) {
    //   this.$emit("add", { ...block });
    // },
    add(value) {
      this.$emit('add', value)
    },
  },
}
</script>
