<template>
  <div class="text-center">
    <v-sheet
      v-if="block.jBox"
      :class="
        `pt-5 pb-4 mx-auto${
          block.jBoxElevation ? ' elevation-' + block.jBoxElevation : ''
        }`
      "
      :style="
        `${
          block.jBoxBorder
            ? `border:dashed ${block.jBoxBorderWidth || 4}px ${
                block.jBoxBorderColor
                  ? block.jBoxBorderColor
                  : block.color
                  ? block.color
                  : '#1C70B9'
              }`
            : ''
        };background:${
          block.jBoxColor ? block.jBoxColor : '#fffade'
        };max-width:${block.jBoxWidth || 500}px`
      "
      :dark="block.jBoxDark"
    >
      <slot />
    </v-sheet>

    <slot v-else />
  </div>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
