// import all from "./all";

// console.log("all", all);
export default {
  // ...all,
  content: {
    options: {
      spacingY: 4,
      paddingX: 4,
    },
    brandbar: {},
    heading: {},
    // subheading: {},
    video: {},
    content: {},
    // button: {},
    buyButton: {},
    footer: {},
    // faqs: {},
    // testimonials: {},
  },
};
