// import { isEmpty } from "lodash";
// import { mapActions } from "vuex";

export default async function() {
  // Clear Data
  this.clearData()

  if (this.value) {
    this.data = JSON.parse(this.value)
  } else {
    // required to restore empty state
    this.trackHistory()
  }

  const options = ['auth', 'setup', 'content', 'theme']

  options.forEach((k) => {
    if (!this.data[k]) {
      this.data[k] = {}
    }
  })
  if (this.data.theme) {
    this.$store.dispatch('MPages/setTheme', this.data.theme)
  }

  this.$store.dispatch(
    'MPages/setSetup',
    this.data.setup ? this.data.setup : {}
  )

  await this.$store.dispatch('MPages/appIni', { builder: true })

  this.$store.dispatch('MPagesBuilder/setProp', {
    blocks: [...this.data.blocks],
  })

  // set blocks without triggerning watcher
  this.updateBlockList()

  // this.$store.setDataProp({ setup: this.data.setup });

  // this.$store.dispatch(
  //   "MPages/setParsedSetup",
  //   JSON.parse(this.parsedSetupStr)
  // );

  // TODO:: IF OPTIONS, SET TO STORE
  console.log('before-mount dev')
  // this.onContainerClick(0)

  this.ready = true
}
