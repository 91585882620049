<template>
  <item-layout crumbs :title="item.name">
    <ml-form
      slotx="actions"
      :fields="formFields"
      name="partial"
      title="Edit Partial"
      dialog
      dialog-icon="create"
      :endpoint="`admin/partials/${this.$route.params.id}`"
    />
    <!-- {{ page.content }} -->
    <v-btn :to="{ name: 'partial-builder', params: { id: $route.params.id } }">
      <v-icon class="mr-1">build</v-icon>Partial Builder
    </v-btn>
  </item-layout>
</template>

<script>
import { mapGetters } from "vuex";
import formFields from "./js/partial-form";

export default {
  data() {
    return {
      formFields,
      ready: false,
    };
  },

  computed: {
    ...mapGetters({
      item: "partial/data",
    }),
  },

  beforeMount() {
    this.$store
      .dispatch("partial/getItem", `admin/partials/${this.$route.params.id}`)
      .then(() => {
        // console.log("partial READY!");
        this.ready = true;
      });
  },
};
</script>
