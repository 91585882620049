<template>
  <div>
    <m-subtitle :loading="sorting || loading" refresh @refresh="getItem">
      Menu Items
    </m-subtitle>

    <m-draggable
      :items="items"
      nested
      can-edit
      can-delete
      @onSort="onSort"
      @onEdit="onEdit"
      @onDelete="onDelete"
    />

    <v-btn color="primary" outlined class="mt-3" @click="dialog = true">
      <v-icon class="mr-1">add</v-icon>
      Add New
    </v-btn>

    <v-dialog v-model="dialog" width="450" @click:outside="itemId = ''">
      <menu-item-form
        :item-id="itemId"
        @created="onCreated"
        @updated="onUpdated"
      />
    </v-dialog>
  </div>
</template>

<script>
import menuItemForm from "./MenuItemForm";

export default {
  components: {
    menuItemForm,
  },

  props: {
    menuId: {
      type: [Number, String],
      default() {
        return this.$route.params.id;
      },
    },
    endpoint: {
      type: String,
      default() {
        return `${this.$route.path}/items`;
      },
    },
    menuItemEndpoint: {
      type: String,
      default: "admin/menu-items",
    },
  },

  data() {
    return {
      ready: false,
      loading: false,
      sorting: false,
      dialog: false,
      itemId: "",
      itemIndex: 0,
      items: [],
      baseForm: {},
    };
  },

  methods: {
    async onDelete(item) {
      this.loading = true;

      await this.deleteItem(item);
      await this.getItem();

      this.loading = false;
    },

    onUpdated() {
      this.dialog = false;
      this.itemId = "";
      this.getItem();
    },

    onCreated() {
      this.dialog = false;

      this.getItem();
    },

    onEdit({ id }) {
      if (this.itemId == id) {
        this.itemId = "";
        return;
      }
      this.itemId = id;

      this.dialog = true;
    },

    async deleteItem(item) {
      if (item.children && item.children.length) {
        item.children.forEach(async ({ id }, index) => {
          await this.$api.post(this.getMenuItemEndpoint(id, "delete"));
        });
      }
      await this.$api.post(this.getMenuItemEndpoint(item.id, "delete"));
    },

    onSort() {
      if (!this.items.length) {
        return;
      }

      this.loading = true;

      this.items.forEach(async (item, index) => {
        const form = {
          menu_id: this.menuId,
          parent_id: 0,
          order: index + 1,
        };

        await this.$api.post(this.getMenuItemEndpoint(item.id), form);

        if (item.children && item.children.length) {
          await this.onSortChildren(item.children, item.id);
        }
      });

      this.loading = false;
    },

    async onSortChildren(items, parentId) {
      items.forEach(async (item, index) => {
        const form = {
          menu_id: 0,
          parent_id: parentId,
          order: index + 1,
        };

        await this.$api.post(this.getMenuItemEndpoint(item.id), form);

        if (item.children && item.children.length) {
          await this.onSortChildren(item.children, item.id);
        }
      });
    },

    getMenuItemEndpoint(id, append = "") {
      if (append != "") {
        append = `/${append}`;
      }
      return `${this.menuItemEndpoint}/${id}${append}`;
    },

    async getItem() {
      let { data } = await this.$api.get(this.endpoint);

      data.data.forEach((item, index) => {
        if (!data.data[index].children) {
          data.data[index].children = [];
        }
      });
      this.items = data.data;

      this.ready = true;
    },
  },

  beforeMount() {
    this.getItem();
  },
};
</script>
