<template>
  <v-select v-model="val" :label="label" :items="blockList" hide-details clearable />
</template>

<script>
import model from "../../mixins/model-str";
import { mapGetters } from "vuex";
export default {
  props: {
    label: {
      type: String,
      default: "Dialog",
    },
  },
  mixins: [model],

  computed: {
    ...mapGetters({
      blockList: "MPagesBuilder/blockList",
    }),
  },
};
</script>