<template>
  <div>
    <v-tabs>
      <v-tab>Image</v-tab>
      <v-tab>Quote</v-tab>
      <v-tab>Author</v-tab>

      <v-tabs-slider />

      <v-tab-item>
        <v-text-field v-model="val.img.src" label="Image" placeholder="https://" />
        <v-slider v-model="val.height" label="Height" min="100" max="1000" thumb-label />
        <v-switch v-model="val.dark" label="Dark" />
      </v-tab-item>

      <v-tab-item>
        <m-pages-heading-form v-model="val.heading" label="Quote" />
      </v-tab-item>

      <v-tab-item>
        <m-pages-heading-form v-model="val.subheading" label="Author" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>


<script>
import model from "../../mixins/model";
export default {
  mixins: [model],
};
</script>

