export default {
  doQuickEdit(id) {
    this.quickEditId = id
    this.isQuickEdit = true
  },

  async doRefresh() {
    this.form.page = 1

    this.ready = false

    await this.getItems()

    if (this.emitTotal) {
      this.$emit('total', this.total)
    }

    this.ready = true
  },

  selectRow(id) {
    console.log('select', id, this.singular)
    return this.$router.push({
      name: this.singular,
      params: { id },
    })
  },

  deleteSelected() {
    const ids = this.selected.map((a) => a.id).join(',')
    const url = `${this.endpoint}/${ids}/delete`
    this.loading = true

    this.$api.post(url).then(({ data }) => {
      this.deletingSelected = false

      this.selected = []
      this.form.page = 1
      this.getItems()
    })
  },

  setTableHeaders(headers) {
    // let addWidth = this.rowToggleActive ? 100 : 0;
    this.tableHeaders = [
      ...headers,
      {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: 152 * this.actions.length + 80, // + addWidth,
        sortable: false,
      },
    ]
  },

  hasHeaderField(value) {
    return this.tableHeaders.indexOf((a) => a.value == value) > -1
  },

  selectAll() {
    this.selectingAll = true
    this.form.page = 1

    this.selected = []

    this.selectAllFetch(1)
  },

  async selectAllFetch(page) {
    const form = { ...this.form, page: page, limit: 500 }

    console.log('form', form)

    const { data } = await this.$api.get(this.endpoint, form)

    this.selected.push(...data.data)

    if (data.pagin.pages > page) {
      page++
      return this.selectAllFetch(page)
    }

    this.selectingAll = false
    this.form.page = 1
  },

  async getItems() {
    this.loading = true

    const form = {
      ...this.form,
    }

    const { data } = await this.$api.get(this.endpoint, form)

    if (!data.data || data.data.length < 1) {
      this.items = []
      this.total = 0
      this.pages = 1
    } else {
      this.items = data.data
      this.total = data.pagin.total
      this.pages = data.pagin.pages
    }

    if (data.headers && !this.tableHeaders.length) {
      this.setTableHeaders(data.headers)
    }

    if (data.permissions) {
      this.permissions = data.permissions
    }

    if (form.q !== this.form.q) {
      return this.getItems()
    }
    this.loading = false

    // test
    // this.selected.push({ ...this.items[0] });
  },
}
