<template>
  <v-slider
    v-model="val"
    :label="label"
    thumb-label="always"
    :max="max"
    :min="0"
    @input="onInput"
  >
    <template v-slot:thumb-label="{ value }">
      <v-chip color="primary" dark class="mb-2">{{
        value | prettyTime
      }}</v-chip>
    </template>
  </v-slider>
</template>

<script>
import { getHMSDisplay } from "@/helpers/time";
export default {
  props: {
    label: {
      type: String,
      default: "Media Time",
    },
    value: {
      type: [Number, String],
      required: false,
    },
    duration: {
      type: [Number, String],
    },
  },

  watch: {
    value(v) {
      if (parseInt(v) != this.val) {
        this.val = parseInt(v);
      }
    },
  },

  data() {
    return {
      val: 0,
    };
  },

  computed: {
    max() {
      if (this.duration) {
        return parseInt(this.duration);
      }
      return 60 * 10;
    },
  },

  beforeMount() {
    if (this.value) {
      this.val = parseInt(this.value);
    }
  },

  methods: {
    onInput() {
      this.$emit("input", this.val);
    },
  },

  filters: {
    prettyTime(t) {
      return getHMSDisplay(t);
    },
  },
};
</script>
