import { mapGetters } from 'vuex'
import parseContent from '../helpers/parse-content'
export default {
  ...mapGetters({
    blockList: 'MPagesBuilder/blockList',
    videoBlocks: 'MPagesBuilder/videoList',
    parsedSetup: 'MPages/parsedSetup',
    // parsedBlocks: "MPages/parsedBlocks",
  }),
  parsedBlocks() {
    if (!this.data.setup.business) {
      return this.data.blocks
    }

    // return parseContent(this.data.blocks, this.data.setup);
    const business = this.data.setup.business
    const contact = this.data.setup.contact
    let blocks = JSON.stringify(this.data.blocks)

    return JSON.parse(parseContent(blocks, { business, contact }))
  },

  // parsedSetupStr() {
  //   if (!this.data.setup.business) {
  //     return {};
  //   }

  //   const business = this.data.setup.business;
  //   const contact = this.data.setup.contact;
  //   const setupStr = JSON.stringify(this.data.setup);
  //   return parseContent(setupStr, { business, contact });
  // },

  // parsedSetup() {
  //   // console.log("update", parsedSetup);?
  //   const value = JSON.parse(this.parsedSetupStr);

  //   this.$store.dispatch("MPages/setParsedSetup", value);
  //   return value;
  // },

  // videoBlocks() {
  //   let blocks = this.data.blocks
  //     .filter((a) => a.name == "video")
  //     .map((a) => {
  //       return {
  //         value: a.id,
  //         text: a.content.name ? a.content.name : a.name,
  //         duration: a.content.video.duration,
  //         src: a.content.video.src,
  //       };
  //     });

  //   console.log("videoblocks changed");

  //   return blocks;
  // },

  valueData() {
    return JSON.parse(this.value)
  },

  content() {
    return JSON.stringify({ ...this.data })
  },
  // theme() {
  //   return this.data.theme;
  // },
}
