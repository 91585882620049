<template>
  <v-container>
    <m-pages-heading v-if="block.heading && block.heading.text" :item="block.heading" />

    <m-pages-subheading v-if="block.subheading && block.subheading.text" :item="block.subheading" />

    <v-row>
      <m-pages-col
        v-for="service in block.items"
        :key="service.id"
        :col="mergeObj(service.col, block.cols)"
      >
        <v-card>
          <v-img :src="service.img.src" :height="service.img.height || 300" />
          <v-card-title v-text="getContent(service.title.text)" />
          <v-card-text v-html="getContent(service.content.html)" />
          <v-card-actions>
            <!-- <v-btn text color="primary">{{ service.primaryAction.text }}</v-btn> -->
            <m-pages-button :item="service.primaryAction" color="primary" is-text />
            <v-spacer />

            <m-pages-button :item="service.secondaryAction" color="secondary" is-text />
            <!-- <v-btn text color="secondary">
              {{ service.secondaryAction.text }}
            </v-btn>-->
            <template v-for="(action, index) in service.actions">
              <v-spacer v-if="index > 0" :key="`${action.id}_spacer`" />
              <v-btn :key="action.id" :color="action.color || 'primary'" text>{{ action.text }}</v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </m-pages-col>
    </v-row>
  </v-container>
</template>

<script>
import blockMixin from "../../mixins/block";
import mergeObj from "../../mixins/merge-obj";

export default {
  mixins: [blockMixin, mergeObj],
};
</script>
