<template>
  <v-footer
    :absolute="item.absolute && !isBuilder ? true : false"
    :app="item.app && !isBuilder ? true : false"
    :color="item.color ? item.color : item.dark ? `grey darken-4` : ''"
    :dark="item.dark"
    :elevation="item.elevation"
    :fixed="item.fixed"
    :height="item.useHeight && item.height ? item.height : ''"
    :inset="item.inset"
    :light="item.light"
    :max-height="item.maxHeight"
    :max-width="item.maxWidth"
    :min-height="item.minHeight"
    :min-width="item.minWidth"
    :outlined="item.outlined"
    :padless="item.padless"
    :rounded="item.rounded"
    :shaped="item.shaped"
    :tile="item.title"
    :width="item.useWidth && item.width ? item.width : ''"
    :class="itemClass"
    class="text-center"
    :style="itemStyle"
  >
    <slot />
  </v-footer>
</template>

<script>
import itemMixin from "../mixins/item";
export default {
  mixins: [itemMixin],
};
</script>