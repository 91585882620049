<template>
  <v-bottom-sheet v-model="sheet" hide-overlay persistent no-click-animation>
    <v-container>
      <v-tabs v-model="tabs">
        <v-tab>Main</v-tab>
        <v-tab>Container</v-tab>

        <v-tab-item>MAIN</v-tab-item>

        <v-tab-item>
          <form-container></form-container>
        </v-tab-item>
      </v-tabs>
    </v-container>

    <!-- Spacing  -->
  </v-bottom-sheet>
</template>

<script>
// form
import FormContainer from "./Container";
import formMixin from "../../mixins/form";

export default {
  components: {
    FormContainer
  },

  mixins: [formMixin],

  data() {
    return {
      sheet: true,
      tabs: 1
    };
  }
};
</script>