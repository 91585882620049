<template>
  <div class="text-centerx">
    <div>
      <m-pages-text :item="item.heading" text-size="h2" />
    </div>
    <div>
      <m-pages-text :item="item.subheading" text-size="subtitle-1" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
