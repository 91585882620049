<template>
  <v-slider
    v-model="val"
    :label="label"
    :min="-12"
    :max="12"
    thumb-label
    hide-details
    @input="onInput"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      default: "",
    },
  },

  beforeMount() {
    if (this.value && typeof this.value == "string") {
      this.setVal(this.value);
    }
  },

  watch: {
    value(v) {
      if (v != this.lastValue) {
        if (!this.value) {
          this.val = 0;
          return;
        }
        this.setVal(this.value);
      }
    },
  },

  data() {
    return {
      val: 0,
      lastValue: "",
    };
  },

  methods: {
    onInput() {
      let value;

      if (this.val < 0) {
        value = this.val - this.val - this.val;
        value = `n${value}`;
      } else {
        value = this.val.toString();
      }

      this.lastValue = value;

      this.$emit("input", value);

      return value;
    },

    setVal(val) {
      console.log("val", val);
      if (val.indexOf("n") > -1) {
        let [, n] = val.split("n");
        val = `-${n}`;
      }
      this.val = parseInt(val);
    },
  },
};
</script>
