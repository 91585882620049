<template>
  <v-container
    :fluid="item.fluid || expanded"
    class="pa-0 ma-0 mx-auto"
    :id="`${blockId}_container`"
  >
    <v-sheet
      :color="item.color ? item.color : 'rgba(255,255,255,0)'"
      :dark="item.dark"
      :light="item.light"
      :elevation="item.elevation"
      :rounded="item.rounded"
      :src="item.src"
      :tile="!item.rounded"
      :class="itemClass"
      :style="itemStyle"
    >
      <div :class="item.noGutters || noGutters ? '' : 'px-3'">
        <slot />
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
import itemMixin from "../mixins/item-plus";

export default {
  props: {
    expanded: Boolean,
    noGutters: Boolean,
    blockId: {
      type: String,
      required: true,
    },
  },

  mixins: [itemMixin],
};
</script>
