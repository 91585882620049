export default {
  // form: {
  // },
  groups: [
    {
      fields: [
        {
          fields: [
            {
              value: "html",
              component: "m-editor",
            },
          ],
        },
      ],
    },
  ],
};
