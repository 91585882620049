<template>
  <v-row>
    <v-col xs="12" sm="7">
      <m-pages-panel title="Video">
        <v-text-field v-model="val.name" label="Name" />
        <m-pages-video-field
          v-model="val.video.src"
          get-duration
          @duration="val.video.duration = $event"
        />
        <v-text-field v-model="val.video.poster" label="Image" />
        <v-text-field v-model="val.video.endedPoster" label="Ended Image" />
        <v-text-field v-model="val.video.endedPosterLink" label="Ended Link" />

        <v-row>
          <v-switch
            v-for="option in options"
            v-model="val.video[option.value]"
            :key="option.value"
            :label="option.text"
            :disabled="option.vslActive && !val.video.vslActive"
            class="mx-4"
          />
        </v-row>
      </m-pages-panel>
    </v-col>
    <v-col>
      <m-pages-panel title="Player">
        <v-row>
          <v-col cols="12">
            <v-slider
              v-model="val.video.border"
              min="0"
              max="12"
              label="Border"
              thumb-label
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="val.video.inset"
              min="0"
              max="12"
              label="Inset"
              thumb-label
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-slider
              v-model="val.video.elevation"
              min="0"
              max="24"
              label="Shadow"
              thumb-label
              hide-details
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="val.video.maxWidth"
              label="Max Width"
              type="number"
              min="200"
              max="1280"
              hide-details
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <m-color-picker
            v-model="val.video.color"
            depressed
            btn-class="mx-4 mt-3"
            label="Color"
            @input="onPlayerColor"
          />
          <v-switch v-model="val.video.tile" label="Tile" class="mx-4" />
        </v-row>
      </m-pages-panel>
    </v-col>
  </v-row>
</template>

<script>
import model from "../../mixins/model";
// import htmlEditor from "@/components/Tinymce";
import setPlayerColor from "../../helpers/set-player-color";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },

  // components: {
  //   htmlEditor
  // },
  mixins: [model],

  data() {
    return {
      options: [
        {
          text: "Hide Controls",
          value: "noControls",
        },
        {
          text: "No Center Play",
          value: "noPlayLarge",
        },
        {
          text: "Trimmer",
          value: "trimmerActive",
        },
        {
          text: "VSL Model",
          value: "vslActive",
        },
        {
          text: "VSL Play",
          value: "vslPlay",
          vslActive: true,
        },
        {
          text: "VSL Image",
          value: "vslImage",
          vslActive: true,
        },
      ],
    };
  },

  methods: {
    onPlayerColor(color) {
      setPlayerColor(this.id, color);
    },
  },
};
</script>
