export default {
  data() {
    return {
      history: {
        changes: [],
        undos: [],
      },
    };
  },

  methods: {
    // called from content watcher
    trackHistory() {
      if (this.history.changes.length > this.opts.historyChanges) {
        this.history.changes.shift();
      }
      this.history.changes.push(this.content);
    },

    redo() {
      this.blockId = "";
      const data = this.history.undos.pop();
      this.data = JSON.parse(data);
      //
    },
    undo() {
      this.blockId = "";
      const undo = this.history.changes.pop();
      this.history.undos.push(undo);

      const data = this.history.changes.pop();
      this.data = JSON.parse(data);
    },
  },
};
