<template>
  <div>
    <m-draggable
      :items="val"
      can-edit
      can-delete
      nested
      :levels="levels"
      @onEdit="onEdit"
      @onDelete="onDelete"
    />
    <div v-if="!val.length" class="grey pa-4">No links</div>

    <v-btn class="my-4" outlined @click="onAdd">Add Link</v-btn>

    <v-dialog v-model="dialog" width="450" persistent>
      <v-card>
        <v-toolbar dense flat tile>
          <v-toolbar-title v-text="title"></v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <m-pages-link-form v-if="editingIndex > -1" v-model="val[editingIndex]" @done="onDone" />
          <m-pages-link-form v-else v-model="form" is-create @done="addLink" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import genId from "@/helpers/gen-id";
import model from "../../mixins/model-arr";

import removeItem from "@/helpers/remove/remove-deep-item";

export default {
  props: {
    title: {
      type: String,
      default: "Add Link",
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    levels: {
      type: Number,
      default: null,
    },
  },

  mixins: [model],

  data() {
    return {
      dialog: false,
      editingIndex: -1,
      form: {},
    };
  },

  methods: {
    onDone() {
      this.dialog = false;
      setTimeout(() => {
        this.editingIndex = -1;
      }, 300);
    },

    onAdd() {
      this.editingIndex = -1;
      this.dialog = true;
    },

    addLink() {
      const item = { ...this.form, children: [] };
      item.id = genId("link_");
      this.val.push(item);
      this.dialog = false;
      this.form = {};
    },

    onDelete(item) {
      removeItem(item.id, this.val);
    },

    onEdit(item) {
      this.editingIndex = this.val.findIndex((a) => a.id == item.id);
      this.dialog = true;
    },
  },
};
</script>
