<template>
  <v-col>
    <!-- <v-card color="blue" dark>
      <v-card-text>col</v-card-text>
    </v-card>-->
    <component
      v-for="(child, i) in item.children"
      v-bind:is="`m-pages-${child.component}2`"
      :item="child"
      :key="i"
    />
  </v-col>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>