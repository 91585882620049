export default {
  noPageSpacingY: true,
  noPageSpacingX: true,
  content: {
    options: {
      img: true,
      actionBanner: true,
      button: false,
    },
    name: {
      text: "{business.name}",
    },
    slogan: {
      text: "{business.slogan}",
    },
    img: {
      src: "{business.logo}",
    },
    button: {
      img: {},
      elevation: 0,
      ml: 0,
      mr: 0,
      mt: 0,
      mb: 0,
      text: "{business.phone}",
      icon: "phone",
      to_name: "url",
      to_value: "tel:0000000",
    },
    button2: {},
    heading: {
      heading: {
        marginX: 0,
        marginY: 0,
        paddingX: 0,
        paddingY: 0,
        elevation: 0,
        text: "{business.name}",
        textSize: "h4",
      },
      subheading: {
        marginX: 0,
        marginY: 0,
        paddingX: 0,
        paddingY: 0,
        elevation: 0,
        text: "{business.slogan}",
      },
      advanced: false,
    },
  },
};
