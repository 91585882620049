const Plugin = {
  install(Vue, options = []) {
    //
    const prefix = 'MPages'

    const components = []

    components.push(
      require.context(
        '../../components',
        // subdirectories
        false,
        /\.vue$/i
      )
    )

    components.push(
      require.context(
        '../../components/blocks',
        // subdirectories
        false,
        /\.vue$/i
      )
    )

    components.forEach((requireComponent) => {
      requireComponent.keys().forEach((fileName) => {
        const componentConfig = requireComponent(fileName)
        const componentName = fileName
          .replace(/^\.\/_/, '')
          .replace(/\.\w+$/, '')
          .split('-')
          .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
          .join('')
          .split('/')
          .pop()

        // Globally register the component

        Vue.component(
          prefix + componentName,
          componentConfig.default || componentConfig
        )
      })
    })

    Vue.component('m-page', require('../../Page.vue').default)
  }, // / install
}

export default Plugin
