export const getVimeoId = (url, fail = false) => {
  if (!url) {
    return fail;
  }

  if (!isNaN(url) && url.length > 5 && url.length < 20) {
    return url;
  }

  const regex = "(https://)(www.){0,4}(vimeo.com)";
  if (!url.match(regex)) {
    return fail;
  }

  const getId = url.match(/[0-9]{6,20}/);

  return getId && getId.length ? getId[0] : fail;
};

export const getYouTubeId = (url, fail = false) => {
  if (url.length == 11 && url.indexOf(":") < 0) {
    return url;
  }

  let id = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    id = url[2].split(/[^0-9a-z_-]/i);
    id = id[0];
  } else {
    id = url;
  }

  if (id.length != 11) {
    return fail;
  }
  return id;
};

export const getVideoProvider = (url = "", fail = false) => {
  if (!url) {
    return fail;
  }

  if (
    !isNaN(url) ||
    url.indexOf("://vimeo.com") > -1 ||
    url.indexOf("://www.vimeo.com") > -1
  ) {
    return "vimeo";
  }

  if (getYouTubeId(url, false)) {
    return "youtube";
  }

  if (url.indexOf("://") > -1) {
    return "file";
  }
};

export const getVideoMin = (url) => {
  if (!url) {
    return url;
  }

  if (getVimeoId(url)) {
    return getVimeoId(url);
  }

  if (getYouTubeId(url)) {
    return getYouTubeId(url);
  }

  return url;
};
