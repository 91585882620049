export default {
  // form: {
  // },
  groups: [
    {
      fields: [
        {
          value: "html",
          component: "m-editor",
        },
      ],
    },
  ],
};
