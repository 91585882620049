import placementField from "../../mixins/fields/placement-field";
const fields = [
  {
    text: "ID",
    value: "id",
    header: true,
  },
  {
    text: "Admin",
    type: "subheader",
    value: "admin1",
    editable: true,
  },
  {
    text: "Name",
    value: "name",
    header: true,
    editable: true,
  },
  {
    text: "Description",
    value: "description",
    type: "textarea",
    header: false,
    editable: true,
  },
  {
    text: "Display",
    type: "subheader",
    value: "subheader2",
    editable: true,
  },
  {
    text: "Title",
    value: "title",
    header: true,
    editable: true,
  },
  {
    text: "IconA",
    value: "icon",
    editable: true,
  },
  {
    ...placementField,
    // text: "Placement",
    // value: "placement",
    // type: "select",
    // items: [
    //   {
    //     text: "Left",
    //     value: "left",
    //   },
    //   {
    //     text: "Right",
    //     value: "right",
    //   },
    //   {
    //     text: "Top",
    //     value: "top",
    //   },
    //   {
    //     text: "Footer",
    //     value: "footer",
    //   },
    // ],
    // editable: true,
    // clearable: true,
  },

  {
    text: "Options",
    type: "subheader",
    value: "subheader3",
    editable: true,
  },
  {
    text: "Highlighted",
    value: "highlight",
    type: "switch",
    editable: true,
  },
  {
    text: "Dense",
    value: "dense",
    type: "switch",
    editable: true,
  },
  {
    text: "Active",
    value: "active",
    type: "switch",
    header: true,
    editable: true,
  },
  {
    text: "Created",
    value: "createdAt",
    header: true,
  },
  {
    text: "Updated",
    value: "updatedAt",
    header: true,
  },
  // {
  //   text: "",
  //   value: "actions",
  //   header: true,
  // },
];

export const headers = fields.filter((a) => a.header);

export const form = fields.filter((a) => a.editable);
