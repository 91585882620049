<template>
  <!-- <div> -->
  <!-- Page Preview -->
  <page-builder-preview-window />
  <!-- </div> -->
</template>

<script>
import pageBuilderPreviewWindow from "@/mods/m-pages/PreviewWindow";
export default {
  components: {
    pageBuilderPreviewWindow,
  },
};
</script>