export default [
  {
    text: "Checkout",
    value: "checkout",
  },
  {
    text: "Call Phone",
    value: "phone",
  },
  {
    text: "Download",
    value: "download",
  },
  {
    text: "Dialog",
    value: "dialog",
  },
  {
    text: "Email",
    value: "email",
  },
  {
    text: "Link",
    value: "href",
  },
  {
    text: "Section",
    value: "section",
  },
];
