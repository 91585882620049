<template>
	<v-autocomplete
		v-model="val"
		:search-input.sync="q"
		label="Download"
		clearable
		:items="items"
		:loading="loading"
		@focus="onKeyup"
		@keyup="onKeyup"
		@click:clear="onClear"
		@input="onInput"
	/>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: '',
		},
	},

	watch: {
		value(v) {
			if (this.val != v) {
				this.val = v
			}
		},
	},

	data() {
		return {
			loading: false,
			q: '',
			val: '',
			items: [],
		}
	},

	methods: {
		onInput() {
			this.$emit('input', this.val)
		},

		onClear() {
			this.onKeyup()
		},

		onKeyup() {
			if (this.loading) {
				return
			}

			this.getItems()
		},

		getItems() {
			this.loading = true

			const form = {
				value: 'public_key',
				q: this.q,
			}

			this.$api
				.get(`admin/list/download`, form)
				.then(({ data }) => {
					data.data.forEach((item) => {
						const index = this.items.findIndex((a) => a.value == item.value)
						if (index < 0) {
							this.items.push(item)
						}
					})
					// console.log('data', data)
					if (this.q != form.q) {
						return this.getItems()
					}
					this.loading = false
				})
				.catch(() => {
					this.loading = false
				})
		},

		async getValue() {
			const { data } = await this.$api.get(`admin/list/download/${this.val}`, { value: 'public_key' })

			// console.log('itemResponse', data)

			if (data.data && data.data.value) {
				const item = data.data
				const exists = this.items.findIndex((a) => a.value == item.value)
				if (exists < 0) {
					this.items.push(item)
				}
			}
			// console.log('data', data)
		},
	},

	beforeMount() {
		this.val = this.value

		if (this.val) {
			this.getValue()
		}
	},
}
</script>
