export const getObjectValue = (obj, key) => {
  const index = (obj, i) => {
    return obj[i];
  };
  return key.split(".").reduce(index, obj);
};

export const tidyUrl = (url) => {
  if (url.indexOf("?") > -1) {
    return url.split("?")[0];
  }
  return url;
};

export const passStrength = (str) => {
  let value = 0,
    hint = "password strengh indicator",
    color = "error";

  if (str.length) {
    value = str.length * 5;

    const hasUpper = /[A-Z]/.test(str) === true;
    const hasLower = /[a-z]/.test(str) === true;
    const hasSpecial = /^[a-zA-Z0-9- ]*$/.test(str) === false;

    if (str.length < 5) {
      color = "error";
      hint = "password is too short";
    } else if (hasSpecial && hasUpper && hasLower) {
      color = "success";
      hint = "password is good! Make sure to save it.";
    } else if (!hasUpper || !hasLower) {
      hint = "include both upper and lowercase letters";
      color = "orange";
      if (value > 50) {
        value = 50;
      }
    } else if (!hasSpecial) {
      hint = "add one or more special characters";
      color = "primary";
      if (value > 50) {
        value = 50;
      }
    }

    if (hasUpper && hasLower) {
      value += 15;
    }

    if (hasSpecial) {
      value += 15;
    }
  }

  if (color === "orange" && value >= 60) {
    color = "primary";
  }

  if (value > 100) {
    value = 100;
  }

  return {
    value,
    hint,
    color,
  };
};
