// import rounded from "./fields/rounded";
import fields from "./fields";
export default {
  form: {
    title: "Brandbar Title",
    logo: {},
    avatar: {},
    items: [],
  },
  groups: [
    {
      title: "Main Setup",
      fields: [
        {
          // itemCols: 4,
          text: "Title",
          value: "title",
          type: "text",
        },
        {
          text: "Image",
          value: "src",
          placeholder: "https://",
          requires: "image",
        },
        {
          value: "logo",
          requires: "useLogo",
          fields: [
            {
              text: "Logo",
              value: "src",
              placeholder: "https://",
            },
            {
              text: "Tile",
              value: "tile",
              type: "switch",
              class: "shrink ml-3",
            },
          ],
        },
        {
          text: "Name",
          value: "name",
        },
        {
          value: "avatar",
          requires: "useAvatar",
          fields: [
            {
              text: "Avatar",
              value: "src",
              placeholder: "https://",
            },
            {
              text: "Tile",
              value: "tile",
              type: "switch",
              class: "shrink ml-3",
            },
          ],
        },
      ],
    },
    {
      title: "Options",
      fields: [
        {
          justify: "start",
          itemCols: 2,
          fields: [
            {
              text: "Image",
              value: "image",
              type: "switch",
            },
            {
              text: "Logo",
              value: "useLogo",
              type: "switch",
            },
            {
              text: "Avatar",
              value: "useAvatar",
              type: "switch",
            },
            {
              text: "Outlined",
              value: "outlined",
              type: "switch",
            },
            // {
            //   text: "Rounded",
            //   value: "rounded",
            //   type: "switch",
            // },
            // {
            //   text: "Collapse",
            //   value: "collapse",
            //   type: "switch",
            // },
            {
              text: "Dense",
              value: "dense",
              type: "switch",
            },
            {
              text: "Extended",
              value: "extended",
              type: "switch",
            },
            {
              text: "Prominent",
              value: "prominent",
              type: "switch",
            },
            {
              text: "Short",
              value: "short",
              type: "switch",
            },
            {
              text: "Flat",
              value: "flat",
              type: "switch",
            },
            {
              text: "Dark",
              value: "dark",
              type: "switch",
            },
            {
              text: "Light",
              value: "light",
              type: "switch",
            },
            {
              text: "Closable",
              value: "closeable",
              type: "switch",
            },
            {
              text: "Minimize",
              value: "minimizeable",
              type: "switch",
            },
            {
              ...fields.elevation,
              cols: 4,
            },
            {
              component: "m-color-picker",
              text: "Color",
              value: "color",
              class: "mt-3",
            },
          ],
        },
        {
          fields: [fields.rounded],
        },
      ],
    },
  ],
};
