<template>
  <div>
    <v-btn-toggle v-if="isDelete">
      <v-btn small dark color="error" :loading="deleting" @click="onDelete">Delete</v-btn>
      <v-btn depressed small @click="isDelete = false">Cancel</v-btn>
    </v-btn-toggle>
    <!--     
    <v-row v-if="isDelete">
      <v-col class="shrink" cols="6">
        
      </v-col>
      <v-col cols="6">
        
      </v-col>
    </v-row>-->
    <div v-else>
      <template v-for="(action, index) in actions">
        <v-spacer v-if="action.spacer || action.cardSpacer && card" :key="`action${index}`" />
        <v-btn
          v-if="!actions.spacer"
          :text="!action.icon"
          :icon="action.icon ? true : false"
          hide-details
          single-line
          small
          :key="`action${index}`"
          :class="action.icon ? 'mx-1' : ''"
          @click="onAction(action.value)"
        >
          <v-icon v-if="action.icon" v-text="action.icon" />
          <template v-else>{{ action.text }}</template>
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emitAsAction: Boolean,
    card: Boolean,
    cardSpacer: Boolean,
    endpoint: {
      type: String,
      required: false,
    },

    actions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isDelete: false,
      deleting: false,
    };
  },

  methods: {
    async onDelete() {
      this.deleting = true;
      if (this.emitAsAction) {
        return this.$emit("action", "delete");
      }

      // if (this.endpoint) {
      //   await this.$api.post(`${this.endpoint}/delete`);
      // }

      // this.loading = false;

      // this.$emit("onDeleted");

      // this.$emit("delete");
    },

    onAction(value) {
      if (value === "delete" && !this.isDelete) {
        return (this.isDelete = true);
      }

      if (this.emitAsAction) {
        return this.$emit("action", value);
      }

      this.$emit(value);
    },
  },
};
</script>