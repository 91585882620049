<template>
  <div class="grey lighten-4">
    <v-toolbar dense color="white" flat>
      <v-spacer />
      <!-- <v-toolbar-items> -->
      <v-btn
        v-for="item in items"
        :key="item.value"
        :text="preview != item.value"
        :outlined="preview == item.value"
        :class="preview == item.value ? 'primary--text' : ''"
        @click="onPreview(item.value)"
      >
        <v-icon v-text="item.icon" class="mr-2" />
        {{ item.text }}
      </v-btn>
      <!-- </v-toolbar-items> -->
      <v-spacer />
    </v-toolbar>

    <div
      :style="{
        width: item.width ? item.width+'px' : '100%',
        height: `${previewHeight}px`
      }"
      :class="`mx-auto ${item.width ? 'mt-6x' : ''}`"
      style="position:relative;transition: all 0.3s ease-in-out;"
    >
      <!-- PREVIEW HERE -->
      <iframe
        :src="previewSrc"
        ref="previewWindow"
        :style="{
            width: '100%',
            height: `${item.height < previewHeight+'px' ? item.height : '100%'}`,
            transition: 'all 0.3s ease-in-out',
            maxHeight: previewHeight-100+'px'
          }"
        frameborder="0"
        :class="item.value != 'desktop' ? 'elevation-8' : ''"
      ></iframe>
    </div>
  </div>
</template>

<script>
import parseContent from "./helpers/parse-content";
import appPage from "./Page";
import { mapGetters } from "vuex";

export default {
  components: {
    appPage,
  },

  props: {
    blocks: {
      type: Array,
      required: true,
    },
    previewSrc: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      preview: "desktop",
      previewHeight: 500,
      connectWait: null,
      items: [
        {
          text: "Computer",
          value: "desktop",
          icon: "computer",
        },
        {
          text: "Tablet",
          value: "tablet",
          icon: "tablet_mac",
          width: 768,
          height: 1024,
        },
        {
          text: "Phone",
          value: "phone",
          icon: "smartphone",
          width: 400,
          height: 800,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      theme: "MPages/theme",
      setup: "MPages/setup",
    }),
    item() {
      const index = this.items.findIndex((a) => a.value == this.preview);
      return this.items[index];
    },

    page() {
      return {
        setup: { ...this.setup },
        theme: { ...this.theme },
        blocks: JSON.parse(
          parseContent(JSON.stringify(this.blocks), this.setup)
        ),
      };
    },
  },

  watch: {
    page: {
      handler: function () {
        this.sendPage();
      },
      deep: true,
    },
  },

  methods: {
    onPreview(mPagesPreview) {
      this.preview = mPagesPreview;
      this.$refs.previewWindow.contentWindow.postMessage(
        { mPagesPreview },
        "*"
      );
    },

    onMessage({ data }) {
      if (typeof data == "object" && data.mPagesPreviewWindowConnected) {
        clearInterval(this.connectWait);
      }
    },
    onResize() {
      this.previewHeight =
        window.innerHeight - this.$refs.previewWindow.offsetTop - 103; // 103
    },

    sendPage() {
      const mPagesPage = { ...this.page };

      if (this.$refs.previewWindow) {
        this.$refs.previewWindow.contentWindow.postMessage({ mPagesPage }, "*");
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
    window.removeEventListener("resize", this.onResize);
  },

  mounted() {
    // setup listener

    window.addEventListener("resize", this.onResize, false);
    this.onResize();
    window.addEventListener("message", this.onMessage, false);

    // start sending message
    this.connectWait = setInterval(() => {
      this.sendPage();
    }, 1000);
  },
};
</script>