export default [
  {
    text: "Seconds",
    value: "s",
  },
  {
    text: "Minutes",
    value: "m",
  },
  {
    text: "Hours",
    value: "h",
  },
  {
    text: "Days",
    value: "d",
  },
  {
    text: "Weeks",
    value: "w",
  },
  {
    text: "Months",
    value: "mo",
  },
  {
    text: "Years",
    value: "y",
  },
];
