<template>
  <!-- <div> -->
  <m-pages-text :item="getItem" tag="div" />
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapGetters({
      theme: "MPages/theme",
    }),
    getItem() {
      return {
        ...this.theme.heading,
        ...this.item,
      };
    },
  },
};
</script>
