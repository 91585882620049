export default {
  content() {
    if (!this.ready) {
      return;
    }

    // Track History
    this.trackHistory();

    // string version
    this.$emit("input", this.content);

    this.$store.dispatch("MPagesBuilder/setProp", {
      blocks: [...this.data.blocks],
    });
  },

  // parsedSetup() {
  //   // console.log('parsedSetup')
  // },

  // theme() {
  //   console.log("theme changed");
  // },
};
