<template>
  <div>
    <a v-if="href" :href="href" :target="openNew ? '_blank' : '_top'">
      <slot />
    </a>
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    openNew: Boolean,
    href: {
      type: String,
      default: ""
    }
  }
};
</script>