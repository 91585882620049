export default async function() {
  if (this.cards || this.cardsOnly) {
    this.view = 'cards'
  }

  if (this.headers) {
    this.setTableHeaders(this.headers)
  }

  // table actions col

  if (this.fields) {
    this.dbCreateFields = this.fields.filter((a) => a.editable)
  }

  await this.getItems()

  if (this.emitTotal) {
    this.$emit('total', this.total)
  }

  this.ready = true

  // this.isAllSelectedV2 = true
}
