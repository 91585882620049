export default {
	autoplay(v) {
		this.play();
	},

	changed() {
		this.canplay = false;
		this.status = false;
		this.changing = false;
		this.loadSource();
	},
};
