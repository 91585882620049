<template>
  <div>
    <v-textarea
      v-model="val"
      label="Google Maps"
      placeholder="Paste in Google Maps Embed a Map Code code (any size)"
      rows="3"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      val: "",
    };
  },

  beforeMount() {
    if (this.value) {
      this.val = this.value;
    }
  },

  methods: {
    onInput() {
      if (this.val.indexOf('"') < 0) {
        return this.$emit("input", this.val);
      }

      const [, a] = this.val.split('src="');
      const [url] = a.split('"');

      this.val = url;
      this.$emit("input", url);
    },
  },
};

/*
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21575.023994927193!2d8.30526452977084!3d47.472806819068246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47906d299a38f2d9%3A0x9f467057910cc7ef!2sRestaurant%20Freihof!5e0!3m2!1sen!2sch!4v1599555339660!5m2!1sen!2sch" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>



*/
</script>