<template>
  <div>
    <v-sheet :color="props.extended ? props.color : ''" tile elevation="3">
      <v-container class="pa-0" :fluid="props.fluid">
        <v-row no-gutters>
          <v-col>
            <v-toolbar :color="!props.extended ? props.color : ''" flat style="background: none">
              <v-toolbar-title v-if="content.title.text">{{ content.title.text }}</v-toolbar-title>
              <v-toolbar-items>
                <v-btn text>First</v-btn>
              </v-toolbar-items>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- navbar-toolbar  -->
    <m-pages-navbar-toolbar v-model="props" />
    <v-container>
      <v-row>
        <v-col>
          <v-text-field v-model="content.title.text" label="Title"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      props: {
        name: "Navbar",
        fluid: false,
        extended: true,
        color: "green"
      },
      content: {
        title: {}
      }
    };
  }
};
</script>