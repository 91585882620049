export default {
  stringFormat: Boolean,
  emitAuth: Boolean,
  isPartial: Boolean,
  title: {
    type: String,
    default: "Page Builder",
  },
  options: {
    type: Object,
    required: false,
  },

  value: {
    type: [String, Object],
    default: "",
  },

  previewSrc: {
    type: String,
    default: "",
  },
};
