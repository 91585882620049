import textAlign from "./select-options/align";
import textSizes from "./select-options/text-size";
import textDecoration from "./select-options/text-decoration";
import textTransform from "./select-options/text-transform";
import fontWeight from "./select-options/font-weight";
import textOpacity from "./select-options/text-opacity";
import rounded from "./select-options/rounded";

import margins from "./margins";
import paddings from "./paddings";
// import { elevation } from "./index";

export default {
  textSize: {
    text: "Size",
    value: "textSize",
    type: "select",
    items: [...textSizes],
    clearable: true,
    // requires: "advanced",
    // cols: 2,
  },
  fontWeight: {
    text: "Font Weight",
    value: "fontWeight",
    type: "select",
    items: [...fontWeight],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  textAlign: {
    text: "Text Align",
    value: "textAlign",
    type: "select",
    items: [...textAlign],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  textDecoration: {
    text: "Decoration",
    value: "textDecoration",
    type: "select",
    items: [...textDecoration],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  textTransform: {
    text: "Transform",
    value: "textTransform",
    type: "select",
    items: [...textTransform],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  textOpacity: {
    text: "Text Opacity",
    value: "textOpacity",
    type: "select",
    items: [...textOpacity],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  rounded: {
    text: "Rounded",
    value: "rounded",
    type: "select",
    items: [...rounded],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  ...margins,
  ...paddings,
  // marginX: {
  //   text: "Margin X",
  //   value: "marginX",
  //   type: "slider",
  //   min: -12,
  //   max: 12,
  //   cols: 3,
  //   requires: "advanced",
  // },
  // marginY: {
  //   text: "Margin Y",
  //   value: "marginY",
  //   type: "slider",
  //   min: -12,
  //   max: 12,
  //   cols: 3,
  //   requires: "advanced",
  // },
  // paddingX: {
  //   text: "Padding X",
  //   value: "paddingX",
  //   type: "slider",
  //   min: 0, //-12,
  //   max: 12,
  //   cols: 3,
  //   requires: "advanced",
  // },
  // paddingY: {
  //   text: "Padding Y",
  //   value: "paddingY",
  //   type: "slider",
  //   min: 0, //-12,
  //   max: 12,
  //   // cols: 3,
  //   requires: "advanced",
  // },
  // elevation,
  fontItalic: {
    text: "Italic",
    value: "fontItalic",
    type: "switch",
    class: "shrink",
    requires: "advanced",
  },
  mxAuto: {
    text: "MX Auto",
    value: "mxAuto",
    type: "switch",
  },
  // {
  //   text: "Flex",
  //   value: "flex",
  //   type: "switch",
  //   requires: "advanced",
  // },
  inlineFlex: {
    text: "Inline Flex",
    value: "inlineFlex",
    type: "switch",
    requires: "advanced",
  },
  color: {
    type: "color",
    text: "Text Color",
    value: "color",
    isText: true,
    class: "shrink",
    requires: "advanced",
  },
  backgroundColor: {
    type: "color",
    text: "Background Color",
    value: "backgroundColor",
    isText: true,
    class: "shrink",
    requires: "advanced",
  },
};

// import camelToText from "../../../helpers/camel-to-text";

// const items = [
//   {
//     text: "Background Image",
//     value: "src",
//     placeholder: "https://",
//   },
// ];

// const fields = {};

// items.forEach((value) => {
//   if (typeof value == "object") {
//     fields[value.value] = {
//       type: "text",
//       text: camelToText(value.value),
//       ...value,
//     };
//   } else {
//     fields[value] = {
//       type: "text",
//       text: camelToText(value),
//       value,
//     };
//   }
// });

// export default fields;
