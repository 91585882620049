<template>
  <div>
    <v-tabs>
      <v-tab>Services</v-tab>
      <v-tab>Heading</v-tab>
      <v-tab>Subheading</v-tab>

      <v-tabs-slider />

      <v-tab-item>
        <v-row>
          <v-col>
            <m-pages-panel title="Services" edged>
              <div v-if="!val.items.length" class="pa-4 grey--text">You do not have any services</div>
              <m-draggable
                :items="val.items"
                :item-text="(a) => a.title.text"
                can-edit
                can-delete
                @onEdit="onEdit"
                @onDelete="onDelete"
              />
            </m-pages-panel>

            <v-btn color="primary" outlined @click="onCreate">Add Service</v-btn>
          </v-col>

          <v-col>
            <m-pages-panel title="Grid Options">
              <m-pages-cols-form v-model="val.cols" />
            </m-pages-panel>
          </v-col>
        </v-row>
        <!-- <m-raw-displayer :value="getForm" title="getForm" /> -->
        <!-- /  -->
      </v-tab-item>

      <v-tab-item>
        <m-pages-heading-form v-model="val.heading" />
      </v-tab-item>

      <v-tab-item>
        <m-pages-heading-form v-model="val.subheading" />
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="dialog" width="600" persistent>
      <v-card v-if="dialog">
        <v-toolbar dense color="grey lighten-4" tile flat>
          <v-toolbar-title tile v-text="`${editItemId ? 'Edit' : 'Add'} Service`"></v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-text-field v-model="getForm.title.text" label="Title" :autofocus="!editItemId" />
          <v-text-field v-model="getForm.img.src" label="Image" placeholder="https://" />
          <html-editor v-model="getForm.content.html" flat min-height="380" class="mb-5" />

          <v-card-title class="pb-0 mb-n3">Optional</v-card-title>
          <v-card flat color="grey lighten-5 mt-4 ">
            <v-card-text class="py-0">
              <m-pages-link-form v-model="getForm.primaryAction" label="Primary Action" no-btn />
              <br />
              <m-pages-link-form v-model="getForm.secondaryAction" label="Secondary Action" no-btn />
              <br />

              <div>
                <m-pages-cols-form v-model="getForm.col" />
              </div>
            </v-card-text>
          </v-card>
          <v-btn v-if="editItemId" class="my-4" color="primary" @click="onDone">Done</v-btn>
          <v-btn v-else class="my-4" color="primary" @click="addService">Add Service</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import model from "../../mixins/model";
import form from "../../mixins/form";
import blockList from "../../mixins/block-list";
import htmlEditor from "@/components/Tinymce";
import genId from "@/helpers/gen-id";
export default {
  components: {
    htmlEditor,
  },
  mixins: [model, blockList, form],

  data() {
    return {
      dialog: false,
      editItemId: "",
      form: {
        title: {},
        img: {},
        content: {},
        primaryAction: {
          to_name: "",
          to_value: "",
          target: "",
        },
        secondaryAction: {
          to_name: "",
          to_value: "",
          target: "",
        },
        col: {},
      },
    };
  },

  computed: {
    getForm() {
      if (this.editItemId) {
        const index = this.val.items.findIndex((a) => a.id === this.editItemId);
        return this.val.items[index];
      }
      return this.form;
    },
  },

  methods: {
    onDone() {
      this.dialog = false;
      setTimeout(() => {
        this.editItemId = "";
      }, 300);
    },

    addService() {
      const item = {
        id: genId("service_"),
        ...this.form,
      };

      this.val.items.push(item);
      this.clearForm();
    },

    onCreate() {
      this.editItemId = "";
      this.dialog = true;
    },

    onEdit(item) {
      this.editItemId = item.id;
      this.dialog = true;
    },
    onDelete(item) {
      const index = this.val.items.findIndex((a) => a.id == item.id);
      this.val.items.splice(index, 1);
    },

    onInput() {
      console.log("onInput");
    },
  },
};
</script>
