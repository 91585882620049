import { mapGetters } from "vuex";

export default {
  props: {
    isEditor: Boolean,
    refresh: Boolean,
    id: {
      type: String,
      default: "",
    },

    block: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      theme: "MPages/theme",
      setup: "MPages/parsedSetup",
      isBuilder: "MPages/isBuilder",
    }),
    block2() {
      return JSON.parse(this.getContent(JSON.stringify(this.block)));
    },
  },

  methods: {
    getContent(str) {
      if (!str) {
        return str;
      }
      let k, k2, group, regex;

      for (k in this.setup) {
        group = this.setup[k];

        for (k2 in group) {
          regex = `({)[\\s]{0,2}(${k}\\.${k2})[\\s]{0,2}(})`;
          regex = new RegExp(regex, "g");
          str = str.replace(regex, group[k2]);
        }
      }

      return str;
    },
  },
};
