<template>
  <div>
    <v-row>
      <v-col>
        <m-pages-form-field
          v-for="(field, i) in fields"
          :key="i"
          v-model="val[field.value]"
          :item="field"
        />
      </v-col>
      <v-col>
        <m-pages-panel title="Label Options">
          <v-row>
            <m-color-picker
              v-model="val.color"
              label="color"
              depressed
              btn-class="mt-3 mx-3"
            />
            <v-checkbox
              v-for="option in options"
              :key="option.value"
              v-model="val[option.value]"
              :label="option.text"
            />
          </v-row>
        </m-pages-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import fields from "../../js/forms/fields";
import model from "../../mixins/model";
export default {
  data() {
    return {
      options: [fields.dark],
      fields: [
        fields.dark,
        fields.flat,
        fields.tile,
        {
          ...fields.rounded,
        },
        {
          ...fields.maxWidth,
          cols: 2,
        },
        fields.colorPicker,
      ],
    };
  },
  mixins: [model],
};
</script>
