export default [
  {
    text: "Lowercase",
    value: "lowercase",
  },
  {
    text: "Uppercase",
    value: "uppercase",
  },
  {
    text: "Capitalize",
    value: "capitalize",
  },
  {
    text: "None",
    value: "none",
  },
];
