export default {
  // ...all,
  content: {
    // formId: '',
    // multiSubmit: false,
    // sendAdminEmail: false,
    // showAdminAlert: false,
    // emailTo: '',
    // submittedContent: {},
    formId: '2',
    multiSubmit: false,
    sendAdminEmail: false,
    showAdminAlert: false,
    emailTo: '',
    allowEdit: false,
    allowMultiSubmit: false,
    sendEmail: false,
    forwardOnSubmit: false,
    limitShows: false,
    email: '',
    successContent: '',
  },
}
