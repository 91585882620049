<template>
  <v-toolbar dense flat>
    <v-toolbar-title class="text-uppercase grey--text text--lighten-1">{{title}}</v-toolbar-title>
    <v-spacer />

    <slot />

    <!-- actions  -->
    <v-btn
      v-for="action in actions"
      :key="action.value"
      text
      :disabled="first && action.value == 'up' || last && action.value == 'down'"
      @click="onAction(action.value)"
    >
      <v-icon class="mr-1" v-text="action.icon" />
      {{ action.text }}
    </v-btn>
  </v-toolbar>
</template>

<script>
// import uiMixin from "../mixins/ui";

export default {
  props: {
    first: Boolean,
    last: Boolean,
    title: {
      type: String,
      default: "",
    },
  },

  // mixins: [uiMixin],

  data() {
    return {
      actions: [
        {
          text: "Up",
          value: "up",
          icon: "arrow_upward",
        },
        {
          text: "Down",
          value: "down",
          icon: "arrow_downward",
        },
        {
          text: "Edit",
          value: "edit",
          icon: "edit",
        },
        {
          text: "Duplicate",
          value: "duplicate",
          icon: "content_copy",
        },
        {
          text: "Delete",
          value: "delete",
          icon: "delete",
        },
      ],
    };
  },

  methods: {
    onAction(value) {
      this.$emit("action", value);
    },
  },
};
</script>