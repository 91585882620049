<template>
  <div>
    <v-tabs>
      <v-tab
        v-for="panel in panels"
        :key="`tab${panel.value}`"
        :class="
          panel.value == 'options' || val.options[panel.value] ? '' : 'd-none'
        "
        v-text="panel.text"
      />

      <v-tabs-slider></v-tabs-slider>

      <v-tab-item
        v-for="panel in panels"
        :class="
          panel.value == 'options' || val.options[panel.value] ? '' : 'd-none'
        "
        :key="panel.value"
      >
        <component v-if="panel.component" v-bind:is="panel.component" v-model="val[panel.value]" />
        <m-pages-form v-else v-model="val[panel.value]" :form="forms[panel.value]" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import forms from "../../js/forms/footer-2";

export default {
  mixins: [model],
  data() {
    return {
      forms,
      panel: null,
      panels: [
        {
          text: "Footer",
          value: "options",
        },
        {
          text: "Links",
          value: "links",
          component: "m-pages-links-form",
        },
        {
          text: "Content",
          value: "content",
        },
        {
          text: "Copyright",
          value: "copyright",
        },
      ],
    };
  },
};
</script>
