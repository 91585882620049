import author from './author'
import faqs from './faqs'
import header from './header'
import hero from './hero'
import navbar from './navbar'
import parallax from './parallax'
import services from './services'
import text from './text'
import welcome from './welcome'
import row from './row'
import image from './image'
import auth from './auth'
import container from './container'
import col from './col'
import video from './video'
import videoGallery from './video-gallery'
import button from './button'
import schedule from './schedule'
import myProducts from './my-products'
import offer from './offer'
import footer from './footer'
import placeholder from './placeholder'
import form from './form'

export default {
  author,
  faqs,
  header,
  hero,
  navbar,
  parallax,
  services,
  text,
  welcome,
  row,
  image,
  auth,
  container,
  col,
  video,
  videoGallery,
  button,
  schedule,
  myProducts,
  offer,
  footer,
  placeholder,
  form,
}
