<template>
  <v-container>
    <m-pages-heading v-if="block.heading && block.heading.text" :item="block.heading" />
    <m-pages-subheading v-if="block.subheading && block.subheading.text" :item="block.subheading" />

    <v-expansion-panels class="mb-8">
      <v-expansion-panel v-for="(item, i) in block.items" :key="i">
        <v-expansion-panel-header v-text="item.title.text" class="title" />
        <v-expansion-panel-content v-html="item.content.html" class="px-6 py-4" />
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
