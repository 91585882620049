export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      val: "",
    };
  },

  watch: {
    val() {
      this.$emit("input", this.val);
    },
    value() {
      if (this.value != this.val) {
        this.val = this.value;
      }
    },
  },

  beforeMount() {
    this.val = this.value;
  },
};
