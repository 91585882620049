const state = {
  ready: false,
  loading: false,
  endpoint: '',
  baseEndpoint: '',
  data: {},
  itemId: '',
  updateIgnoreFields: ['id', 'identifier', 'created', 'updated'],
}

const mutations = {
  SET(state, payload) {
    for (let k in payload) {
      state[k] = payload[k]
    }
  },

  SET_DATA(state, payload) {
    for (let k in payload) {
      // console.log("SET_DATA", k, payload[k]);
      state.data[k] = payload[k]
    }
  },

  SET_PROP(state, payload) {
    if (Array.isArray(payload)) {
      const [key, value] = payload
      if (typeof value == 'object') {
        // console.log("OBJECT VALUE");
        for (let k in value) {
          state[key][k] = value[k]
        }
      } else {
        state[key] = value
      }
    } else {
      for (let k in payload) {
        state[k] = payload[k]
      }
    }
    // console.log("SET_PROP", typeof payload);
  },
}

const getters = {
  data(state) {
    return state.data
  },
  all(state) {
    return state
  },
}

const actions = {
  // clear item from store, used to create new
  clearItem({ commit }) {
    commit('SET', { itemId: '', data: {} })
  },

  getItemById({ commit, state }, itemId) {
    commit('SET', { itemId })

    const endpoint = `${state.baseEndpoint}/${itemId}`

    return this._vm.$api.get(endpoint).then(({ data }) => {
      commit('SET', { data: data.data, ready: true })
    })
  },

  async update({ commit, state }) {
    const endpoint = `${state.baseEndpoint}/${state.itemId}`
    const form = { ...state.data }

    if (state.updateIgnoreFields) {
      state.updateIgnoreFields.forEach((k) => {
        if (typeof form[k] != 'undefined') {
          delete form[k]
        }
      })
    }

    const { data } = await this._vm.$api.post(endpoint, form)

    if (data.data && data.data.updated) {
      commit('SET_DATA', { updated: data.data.updated })
    }
    return true
  },

  getItem({ commit }, endpoint) {
    commit('SET', { endpoint })
    return this._vm.$api.get(endpoint).then(({ data }) => {
      if (data.data && data.data.name) {
        document.title = data.data.name
      }
      commit('SET', { data: data.data, ready: true })
    })
  },

  setItem({ commit }, data) {
    commit('SET_DATA', data)

    if (data.data && data.name) {
      document.title = data.name
    }

    commit('SET', { ready: true })
  },

  getSub({ commit, state }, sub) {
    const endpoint = `${state.endpoint}/${sub}`
    return this._vm.$api.get(endpoint).then(({ data }) => {
      commit('SET_SUB', [sub, { data: data.data, ready: true }])
    })
  },

  setData({ commit }, payload) {
    commit('SET_DATA', payload)
  },

  setProp({ commit }, value) {
    commit('SET_PROP', value)
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
