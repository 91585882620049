export default async function() {
	const items = [...this.block.items];

	const progress = await this.$localforage.getItem(this.getKey('progress'));

	if (progress) {
		// index
		if (progress.index !== undefined) {
			this.index = progress.index;
		}

		// items
		if (progress.items) {
			progress.items.forEach((item) => {
				const index = items.findIndex((a) => a.id == item.id);

				if (index > -1) {
					for (let k in item) {
						items[index][k] = item[k];
					}
				}
			});
		}
	}

	if (items.length && items[0].currentTime) {
		this.start = items[0].currentTime;
	}

	this.items = items;

	this.ready = true;
}
