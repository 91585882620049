<template>
  <m-pages-card v-if="!closed" :item="block.options">
    <!-- Brandbar  -->
    <div
      v-if="block.options.brandbar"
      :class="[
        !minimized ? `pb-${block.options.spacingY}` : '',
        block.options.px ? `mx-n${block.options.px}` : '',
      ]"
    >
      <m-pages-brandbar
        :item="block.brandbar"
        :rounded="block.options.rounded"
        @close="onClose"
        @minimize="minimized = !minimized"
      />
    </div>

    <v-expand-transition>
      <div v-if="!minimized">
        <!-- Heading  -->
        <div
          v-if="block.options.heading"
          :class="`py-${block.options.spacingY}`"
        >
          <m-pages-heading-v2 :item="block.heading" />
          <!-- subheading  -->
        </div>

        <!-- Video  -->
        <div
          v-if="block.options.video"
          :class="[
            `py-${block.options.spacingY}`,
            block.options.px && block.video.edged
              ? `mx-n${block.options.px}`
              : '',
          ]"
        >
          <m-pages-player :video="block.video" />
        </div>

        <!-- Content  -->
        <div
          v-if="block.options.footer"
          :class="`py-${block.options.spacingY}`"
        >
          <m-pages-html :item="block.footer" />
        </div>

        <!-- Button  -->
        <div
          v-if="block.options.buyButton"
          :class="`py-${block.options.spacingY}`"
        >
          <!-- <m-pages-button-block-v2 :block="block.button" /> -->
          <m-pages-buy-button :item="block.buyButton" />
        </div>

        <!-- Footer  -->
        <div
          v-if="block.options.footer"
          :class="`py-${block.options.spacingY}`"
        >
          <m-pages-html :item="block.footer" />
        </div>
      </div>
    </v-expand-transition>
  </m-pages-card>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
  data() {
    return {
      closed: false,
      minimized: false,
    };
  },
  methods: {
    onClose() {
      this.closed = true;
    },
  },
};
</script>
