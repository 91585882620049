<template>
  <v-toolbar :flat="flat" :color="color">
    <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
    <slot />
  </v-toolbar>
</template>

<script>
export default {
  props: {
    flat: Boolean,
    title: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: "grey lighten-4",
    },
  },
};
</script>