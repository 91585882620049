<template>
  <v-progress-circular
    v-if="value.loading"
    :indeterminate="value.indeterminate"
    :rotate="value.rotate"
    :size="38"
    :value="value.progress ? value.progress : 0"
    :width="3"
    :color="color"
  >
    <span class="caption" v-text="`${value.progress}%`" />
  </v-progress-circular>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
