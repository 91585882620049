import camelToText from "../../../helpers/camel-to-text";
const items = [
  "app",
  "active",
  "absolute",
  "advanced",
  "dark",
  "dense",
  "depressed",
  "disabled",
  "collapse",
  "extended",
  "expanded",
  "fixed",
  "flat",
  "fluid",
  "inset",
  "isText",
  "light",
  "image",
  "outlined",
  "padless",
  "priminent",
  "prominent",
  "isRounded",
  "shaped",
  "short",
  "tile",
  "authenticated",
  "unAuthenticated",
  "useHeight",
  "useWidth",
  "useIcon",
  "mxAuto",
  "noGutters",
  "showOnReplay",
  "showOnReturn",
  "block",
  {
    text: "Show Time",
    value: "useShowTime",
  },
  {
    text: "Hide Time",
    value: "useHideTime",
  },
  "noPageSpacingY",
  "noPageSpacingX",
  "noFollow",
  "noIndex",
  "sticky",
];

const fields = {};

items.forEach((value) => {
  if (typeof value == "object") {
    fields[value.value] = {
      text: value.value,
      value: value,
      type: "checkbox",
      ...value,
    };
  } else {
    fields[value] = {
      text: camelToText(value),
      value,
      type: "checkbox",
    };
  }
});

export default fields;
