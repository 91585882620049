import camelToText from "../../../helpers/camel-to-text";

const items = [
  {
    value: "elevation",
    min: 0,
    max: 24,
  },
  {
    value: "spacingY",
    min: 0,
    max: 12,
  },
  {
    value: "spacingX",
    min: 0,
    max: 12,
  },
  {
    value: "opacity",
    min: 0,
    max: 100,
  },
  {
    value: "hover",
    min: 0,
    max: 24,
  },
];

const fields = {};

items.forEach((item) => {
  fields[item.value] = {
    type: "slider",
    text: camelToText(item.value),
    ...item,
  };
});

export default fields;
