import camelToText from "../../../helpers/camel-to-text";

import sizes from "./select-options/size";
import textSizes from "./select-options/text-size";
import fontWeights from "./select-options/font-weight";
import justifyItems from "./select-options/justify";
import roundedOptions from "./select-options/rounded";
import alignOptions from "./select-options/align";
import targetOptions from "./select-options/target-options";
import clickActionOptions from "./select-options/click-actions";
const items = [
  {
    value: "fontWeight",
    items: fontWeights,
  },
  {
    value: "rounded",
    component: "m-pages-rounded-field",
    items: roundedOptions,
  },
  {
    value: "size",
    items: sizes,
  },
  {
    value: "textSize",
    items: textSizes,
  },
  {
    value: "justify",
    items: justifyItems,
  },
  {
    value: "align",
    items: alignOptions,
  },
  {
    value: "target",
    items: targetOptions,
  },
  {
    value: "clickAction",
    items: clickActionOptions,
  },
];

const fields = {};

items.forEach((item) => {
  fields[item.value] = {
    type: "select",
    text: camelToText(item.value),
    ...item,
  };
});

export default fields;
