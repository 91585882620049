export default {
  methods: {
    section(
      el,
      options = {
        offset: 60,
      }
    ) {
      // default id
      if (el.indexOf("#") < 0 && el.indexOf(".") < 0) {
        el = `#${el}`;
      }

      this.$vuetify.goTo(el, options);
    },
  },
};
