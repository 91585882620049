const state = {
	ready: false,
	loading: false,
	data: {
		dialog: false,
		drawer: null,
	},
}

const getters = {
	data(state) {
		return state.data
	},
	loading(state) {
		return state.loading
	},
	ready(state) {
		return state.ready
	},
}

const mutations = {
	SET_DATA(state, data) {
		state.data = data
	},

	SET(state, payload) {
		for (let k in payload) {
			state[k] = payload
		}
	},
}

const actions = {
	async getData({ commit }) {
		let { data } = await this._vm.$api.get('admin/dashboard')

		commit('SET_DATA', data.data)
	},
}
export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
