const state = {
  model: false,
  available: false,
};

const getters = {
  menu(state) {
    return state;
  },
};

const mutations = {
  TOGGLE(state) {
    state.model = !state.model;
    console.log("toggle");
  },
  SET(state, payload) {
    for (let k in payload) {
      state[k] = payload[k];
    }
  },
};

const actions = {
  toggle({ commit }) {
    console.log("action toggle");
    commit("TOGGLE");
  },
  setAvailable({ commit }, available) {
    commit("SET", { available });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
