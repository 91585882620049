<template>
  <card-layout
    :width="width"
    :dialog="dialog"
    :persistent="persistent"
    :title="title"
    :btnColor="btnColor"
    :loading="!ready"
    :dialog-icon="dialogIcon"
  >
    <v-row no-gutters>
      <v-col
        v-for="field in fields"
        :key="field.value"
        :cols="field.cols || 12"
      >
        <v-text-field
          v-if="
            !field.type || ['text', 'number', 'password'].includes(field.type)
          "
          v-model="data[field.value]"
          class="text-capitalize"
          :label="field.text || field.value"
          :type="field.type || 'text'"
          :min="field.min"
          :max="field.max"
          :disabled="field.disabled && !canEditDisabled && !create"
          :hint="field.hint"
          :placeholder="field.placeholder"
        />

        <template v-else-if="field.type == 'subheader'">
          <v-divider v-if="field.divider" />
          <v-subheader>{{ field.text }}</v-subheader>
        </template>

        <!-- <v-divider v-else-if="field.type === 'divider'">a</v-divider> -->

        <v-textarea
          v-else-if="field.type === 'textarea'"
          v-model="data[field.value]"
          :label="field.text || field.value"
          class="text-capitalize"
          rows="3"
          :disabled="field.disabled && !canEditDisabled && !create"
        />

        <v-select
          v-else-if="field.type == 'select'"
          v-model="data[field.value]"
          :label="field.text || field.value"
          :items="field.items || field.options"
          :clearable="field.clearable"
        />
        <v-switch
          v-else-if="field.type == 'switch'"
          v-model="data[field.value]"
          :label="field.text || field.value"
          :items="field.items"
          :clearable="!field.noClearable"
        />

        <hidden-field
          v-else-if="field.type == 'hidden'"
          v-model="data[field.value]"
          :field="field"
        />

        <!-- Custom Field  -->
        <component
          v-else
          :is="`ml-${field.type}`"
          v-model="data[field.value]"
          :field="field"
          :endpoint="field.endpoint || `${endpoint}/${field.value}`"
          :itemText="field.itemText"
          :itemValue="field.itemValue"
          :disabled="field.disabled && !canEditDisabled && !create"
        />

        <!-- :label="field.label || field.value" -->
      </v-col>
    </v-row>

    <v-row class="text-left">
      <v-col>
        <v-btn
          :color="btnColor"
          :disabled="!canSave"
          :loading="saving"
          @click="onSave"
          >{{ create ? 'Add New' : 'Save Changes' }}</v-btn
        >
      </v-col>

      <v-col v-if="!create && hasDisabled" class="shrink">
        <v-btn icon @click="canEditDisabled = !canEditDisabled">
          <v-icon>{{ canEditDisabled ? 'lock_open' : 'lock' }}</v-icon>
        </v-btn>
      </v-col>

      <template v-if="isDelete">
        <v-col class="shrink px-0">
          <v-btn color="error" dark :loading="deleting" @click="onDelete"
            >Delete?</v-btn
          >
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="grey lighten-4"
            depressed
            :disabled="deleting"
            @click="isDelete = false"
            >Cancel</v-btn
          >
        </v-col>
      </template>

      <v-col v-else-if="!create" class="shrink">
        <v-btn icon @click="isDelete = true">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- form: {{ form }}, fields: {{ fields }} -->
    <m-raw-displayer v-if="debug" :value="data" />
    <slot name="below" />
  </card-layout>
</template>

<script>
import hiddenField from './fields/HiddenField'
export default {
  components: {
    hiddenField,
  },

  props: {
    persistent: Boolean,
    debug: Boolean,
    create: Boolean,
    disableWatch: Boolean,

    dialog: Boolean, // feature
    fields: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: '',
    },
    dialogIcon: {
      type: String,
      default: '',
    },
    getter: {
      type: String,
      default: null,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    btnColor: {
      type: String,
      default: 'primary',
    },
    endpoint: {
      type: String,
      default() {
        return this.$route.path
      },
    },
    deletedRoute: {
      type: [String, Object],
      default() {
        let part = this.endpoint.split('/')
        part.pop()
        return { path: part.join('/') }
      },
    },

    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      canEditDisabled: false,
      isDelete: false,
      deleting: false,
      saving: false,
      watching: false,
      str: '',
      form: {},
      ready: false,
    }
  },

  async beforeMount() {
    if (!this.disableWatch && this.data.id) {
      this.watching = true
      this.str = JSON.stringify(this.data)
    }

    this.hasDisabled = this.fields.findIndex((a) => a.disabled) > -1

    // support model
    // if (this.value) {
    //   this.form = { ...this.value };
    //   this.isModel = true;
    // }
    if (!this.name && !this.create) {
      const { data } = await this.$api.get(this.endpoint)

      if (data.data) {
        this.form = data.data
      }
    }

    this.ready = true
  },

  computed: {
    data() {
      if (!this.name) {
        return this.form
      }
      return this.$store.getters[
        this.getter ? this.getter : `${this.name}/data`
      ]
    },
    changed() {
      return (
        this.disableWatch || (this.str && this.str != JSON.stringify(this.data))
      ) // :TODO
    },
    canSave() {
      return this.changed && !this.deleting
    },
  },

  watch: {
    data: {
      deep: true,
      handler() {
        if (this.isModel) {
          this.$emit('input', { ...this.form })
        }

        //
        if (this.disableWatch || this.watching) {
          return
        }
        this.watching = true
        this.str = JSON.stringify(this.data)
      },
    },
  },

  methods: {
    onSave() {
      this.saving = true

      const form = {}

      this.fields.forEach((field) => {
        field = field.value
        if (typeof this.data[field] !== 'undefined') {
          form[field] = this.data[field]
        }
      })

      this.$api
        .post(this.endpoint, form)
        .then(({ data }) => {
          this.saving = false
          if (data.message) {
            this.$msg.success(data.message)
          } else {
            this.$msg.success('Saved!')
          }
          this.str = JSON.stringify(this.data)

          if (this.create) {
            this.$emit('onCreated', data.data)
          } else {
            this.$emit('onUpdated', data.data)
          }
        })
        .catch((err) => {
          this.saving = false
          this.$msg.error('Unable to save')
        })
    },
    onDelete() {
      this.deleting = true

      this.$api
        // .delete(this.endpoint)
        .post(`${this.endpoint}/delete`)
        .then(({ data }) => {
          this.deleting = false
          if (data.message) {
            this.$msg.success(data.message)
          }
          this.$router.replace(this.deletedRoute)
        })
        .catch((err) => {
          this.$msg.error('Unable to delete')
        })
    },
  },
}
</script>
