var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grey lighten-4"},[_c('v-toolbar',{attrs:{"dense":"","color":"white","flat":""}},[_c('v-spacer'),_vm._l((_vm.items),function(item){return _c('v-btn',{key:item.value,class:_vm.preview == item.value ? 'primary--text' : '',attrs:{"text":_vm.preview != item.value,"outlined":_vm.preview == item.value},on:{"click":function($event){return _vm.onPreview(item.value)}}},[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.text)+" ")],1)}),_c('v-spacer')],2),_c('div',{class:("mx-auto " + (_vm.item.width ? 'mt-6x' : '')),staticStyle:{"position":"relative","transition":"all 0.3s ease-in-out"},style:({
      width: _vm.item.width ? _vm.item.width+'px' : '100%',
      height: (_vm.previewHeight + "px")
    })},[_c('iframe',{ref:"previewWindow",class:_vm.item.value != 'desktop' ? 'elevation-8' : '',style:({
          width: '100%',
          height: ("" + (_vm.item.height < _vm.previewHeight+'px' ? _vm.item.height : '100%')),
          transition: 'all 0.3s ease-in-out',
          maxHeight: _vm.previewHeight-100+'px'
        }),attrs:{"src":_vm.previewSrc,"frameborder":"0"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }