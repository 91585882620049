import state from "./state";

export default {
  data(state) {
    return state.data;
  },
  blocks(state) {
    return state.data.blocks;
  },
  block(state) {
    if (state.blockIndex !== null) {
      return state.data.blocks[state.blockIndex];
    }
    return {};
  },
  blockIndex(state) {
    return state.blockIndex;
  },
};
