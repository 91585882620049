export default {
  async onQuickEditUpdated() {
    await this.getItems()
    this.isQuickEdit = false
    this.quickEditId = ''
  },

  onRowActive(item) {
    const url = `${this.endpoint}/${item.id}`
    this.activatingId = item.id
    const form = {
      active: item.active ? 1 : 0,
    }
    this.$api.post(url, form).then(() => {
      this.activatingId = ''
    })
  },

  onCreated(item) {
    // if(this.closeOnCreated) {

    // } closeOnCreated

    if (this.emitCreated) {
      return this.$emit('created', item)
    }
    this.$router.push({ name: this.singular, params: { id: item.id } })
  },

  onFilterWith(value) {
    this.form.fwi = value
    this.form.page = 1
    this.getItems()
  },
  onFilterWithout(value) {
    this.form.fwo = value
    this.form.page = 1
    this.getItems()
  },

  onSearch() {
    if (this.loading) {
      return
    }
    this.searched = true
    this.form.page = 1
    this.getItems()
  },

  onClearSearch() {
    // this.search = "";
    this.q = ''
    this.onSearch()
  },

  onClickRow(e) {
    // console.log('onClickRow', e)
  },

  async onAction(action, item) {
    if (action === 'edit') {
      return this.selectRow(item.id)
      // return this.$router.push({
      //   name: this.singular,
      //   params: { id: item.id },
      // });
    }

    if (action === 'delete') {
      await this.$api.post(`${this.endpoint}/${item.id}/delete`)
      return this.getItems()
    }
    console.log(action, item)
  },

  onPage(value) {
    this.form.page = value
    this.getItems()
  },

  onLimit(v) {
    if (this.form.limit === v) {
      // no change
      return
    }
    this.form.limit = v
    this.form.page = 1
    this.getItems()
  },

  onSortBy(by) {
    this.form.orderby = by[0]
    this.onSort()
  },

  onSortDesc(desc) {
    this.form.order = desc[0] ? 'desc' : 'asc'
    console.log('onSortDesc', desc, this.form.order)
    this.onSort()
  },
  onSort() {
    setTimeout(() => {
      if (!this.loading) {
        this.getItems()
      }
    })
  },
}
