<template>
  <m-pages-button-block-container :block="block">
    <div class="display-1 mb-2" v-if="block.headingText" v-html="getContent(block.headingText)" />

    <div class="mb-4" v-if="block.subheadingText">
      <v-icon v-if="block.subheadingArrow" class="mt-n1 mr-n1" color="red">arrow_forward</v-icon>

      <span class="pl-1" v-html="getContent(block.subheadingText)" />
    </div>

    <v-btn
      v-if="block.simple"
      large
      :elevation="block.elevation"
      :color="block.color ? block.color : theme.color ? theme.color : ''"
      depressed
      :dark="block.dark"
      :light="!block.dark"
      :href="href"
      :target="block.openNew ? '_blank' : ''"
      class="mx-auto mb-3"
      @click="onClick"
    >
      <v-row justify="space-around" block>
        <v-icon v-if="block.buttonIcon" size="18" class="ml-1" v-text="block.buttonIcon" />
        <div class="mx-2">{{ block.buttonText || "Button Text" }}</div>
      </v-row>
    </v-btn>

    <v-card
      v-else
      :width="
        block.block ? '' : block.width && block.customSize ? block.width : 400
      "
      :height="block.height && block.customSize ? block.height : 80"
      class="mx-auto mb-3 pa-1"
      :elevation="block.elevation"
      :href="href"
      ripple
      :color="block.color ? block.color : theme.color ? theme.color : ''"
      flat
      :dark="block.dark"
      :light="!block.dark"
      :target="block.openNew ? '_blank' : ''"
      @click="onClick"
    >
      <v-layout class="pa-0" fill-height>
        <v-row dense no-gutters align="center" fill-height justify="center">
          <v-icon v-if="block.buttonIcon" size="45" class="ml-n8 mr-3" v-text="block.buttonIcon" />

          <div class="display-1 font-weight-bold">{{ block.buttonText || "Button Text" }}</div>
        </v-row>
      </v-layout>
    </v-card>

    <div v-if="block.cards || block.paypal" class="mb-3">
      <m-pages-cards :cards="block.cards" :paypal="block.paypal" />
    </div>

    <div v-if="block.secureNotice" class="my-3">
      <v-icon class="mt-n1" color="orange">lock</v-icon>100% safe & secure
      <v-icon class="mt-n1 mr-n1 font-weight-bold" color="green">check</v-icon>
    </div>
  </m-pages-button-block-container>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
  computed: {
    href() {
      return this.block.url || "#";
    },
  },

  methods: {
    onClick() {
      if (!this.block.url && this.block.download) {
        const url = `/download/${this.block.download}`;

        console.log("url", url);

        this.$api.post(url).then(({ data }) => {
          if (data.data.url) {
            window.location.href = data.data.url;
          }
        });
      }
    },
  },
};
</script>
