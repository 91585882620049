const arrayMove = require('array-move')

import genId from '@/helpers/gen-id'
import blockFields from './block-fields'

import clearObj from '@/helpers/clear-obj'

export default {
  clearData() {
    this.data = {
      setup: {
        business: {},
        social: {},
        actionBanner: {},
      },
      theme: {
        contentSpacingY: 5,
      },
      blocks: [],
    }
  },

  updateBlockList() {
    const blockList = this.data.blocks.map((a) => {
      return {
        text: a.name,
        value: a.id,
      }
    })
    this.$store.dispatch('MPagesBuilder/setBlockList', blockList)
  },

  blockAction(action, index) {
    if (action == 'up') {
      arrayMove.mutate(this.data.blocks, index, index - 1)
      this.refresh = !this.refresh
      return
    }

    if (action == 'down') {
      arrayMove.mutate(this.data.blocks, index, index + 1)
      this.refresh = !this.refresh
      console.log('refresh1')
      return
    }

    if (action === 'duplicate') {
      const block = { ...this.data.blocks[index] }
      block.id = genId('block_')
      this.data.blocks = JSON.parse(JSON.stringify(this.data.blocks))
      this.data.blocks.splice(index, 0, block)
      this.refresh = !this.refresh
      this.updateBlockList()
      return
    }

    const id = this.data.blocks[index].id

    if (action === 'delete') {
      if (this.blockId === id) {
        this.blockId = '' // clear delete index
      }
      this.data.blocks.splice(index, 1)
      this.refresh = !this.refresh
      this.updateBlockList()
      return
    }

    // default: edit
    if (this.blockId == id) {
      this.blockId = ''
    } else {
      this.blockId = id
    }
  },

  newBlock(index = -1) {
    this.newBlockIndex = index
    this.isNewBlock = true
  },

  addBlock(name) {
    const id = genId(`${name}_`)
    let block = {
      id,
      name,
      auth: { ...blockFields.auth },
      schedule: { ...blockFields.schedule },

      container: {
        spacing: {},
      },
      wrapper: {
        spacing: {},
      },
      ...blockFields[name],
    }

    console.log('block', block)

    if (name == 'row') {
      block.cols = []
    }

    // prevent watchers from dublicating previous changes
    block = clearObj(block)

    if (this.newBlockIndex > -1) {
      this.data.blocks.splice(this.newBlockIndex + 1, 0, block)
      this.newBlockIndex++
      this.updateBlockList()
      return
    }

    this.data.blocks.push(block)
    this.newBlockIndex = 0

    this.updateBlockList()
  },
}
