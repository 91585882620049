export default {
  noContentSpacing: true,
  wrapper: {
    spacing: {},
    elevation: 8,
  },
  container: {
    spacing: {},
    noGutters: true,
  },
  content: {
    options: {
      primaryButton: false,
      secondaryButton: false,
      spacingY: 4,
      paddingX: 4,
    },
    toolbar: {
      flat: true,
      color: "",
    },
    button: {},
    primaryButton: {
      text: "Register",
      ml: 2,
      dark: true,
      depressed: true,
    },
    secondaryButton: {
      text: "Login",
      ml: 2,
      dark: true,
      outlined: true,
    },
    items: [
      {
        text: "Welcome",
        children: [],
        id: "link_navbar_1",
      },
      {
        text: "Services",
        value: "",
        url: "",
        children: [],
        id: "link_navbar_2",
      },
      {
        text: "About Us",
        value: "",
        url: "",
        children: [],
        id: "link_navbar_3",
      },
    ],
    flat: true,
    alignLinks: "left",
  },
  noPageSpacingY: true,
  noPageSpacingX: true,
};
