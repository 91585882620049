/** @format */

export default {
  props: {
    width: {
      type: [String, Number],
      default: 780
    },
    height: {
      type: [String, Number],
      default() {
        return parseInt(this.width) * 0.64102564102;
      }
    }
  }
};
