import props from './props'
import data from './data'
import computed from './computed'
import watch from './watch'
import events from './events'
import methods from './methods'
import beforeMount from './before-mount'
import historyMixin from './history'
import copyPageMixin from './copy-page'
// import onResize from "../mixins/on-resize";
// temp
// import devMixin from "../app/dev-mixin";

export default {
  props,

  mixins: [
    // devMixin,
    historyMixin,
    copyPageMixin,
    // onResize,
  ],

  data() {
    return {
      ...data,
    }
  },
  computed,
  watch,
  methods: {
    ...events,
    ...methods,
  },
  beforeMount,
  // mounted() {
  //   this.$store.dispatch("MPages/appMounted");
  // },
}
