<template>
  <component
    :is="tag"
    :class="textClass"
    class=""
    :style="textStyle"
    v-html="item.html"
  />
</template>

<script>
import htmlTextField from "../mixins/html-text-field";
export default {
  mixins: [htmlTextField],
  // mixins: [
  //   {
  //     ...htmlTextField,
  //   },
  // ],
};
</script>
