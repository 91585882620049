export default {
  data() {
    return {
      size: {
        ratio: 16 / 9,
        windowHeight: 500,
      },
    };
  },

  computed: {
    maxWidth() {
      return this.size.windowHeight * this.size.ratio - 100;
    },
  },

  methods: {
    onResize() {
      this.size.windowHeight = window.innerHeight;
    },
  },

  mounted() {
    this.size.windowHeight = window.innerHeight;
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
