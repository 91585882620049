<template>
  <div>
    <v-row v-for="(row, rowIndex) in rows" :key="`row${rowIndex}`">
      <v-col
        v-for="(col, colIndex) in row.cols"
        :key="`col${colIndex}`"
        :cols="col.cols ? col.cols : row.colCols ? row.colCols : 12"
      >
        <m-pages-panel
          v-for="(panel, panelIndex) in col.panels"
          :key="`panel${panelIndex}`"
          :title="panel.title"
        >
          <!-- GROUP  -->
          <v-sheet
            v-for="(group, groupIndex) in panel.groups"
            :key="`group${groupIndex}`"
            class="whitex pa-4 mb-4"
            :elevation="group.elevation"
            :color="group.color"
          >
            <!-- <v-divider v-if="groupIndex > 0" class="my-4" /> -->

            <v-subheader v-if="group.title" v-text="group.title" class="ma-n4" />

            <v-row v-for="field in group.fields" :key="field.value" no-gutters class="mx-n2">
              <v-col
                v-for="item in field.items"
                :key="item.value"
                :cols="item.cols ? item.cols : field.itemCols ? field.itemCols : 12"
                class="px-2"
              >
                <component
                  v-if="item.component"
                  v-bind:is="item.component"
                  v-model="val[field.value][item.value]"
                  :label="item.text"
                />

                <v-text-field
                  v-else-if="!item.type || ['number', 'text'].includes(item.type)"
                  v-model="val[field.value][item.value]"
                  :label="item.text"
                  :type="item.type || 'text'"
                  :placeholder="item.placeholder"
                />
                <v-textarea
                  v-else-if="item.type == 'textarea'"
                  v-model="val[field.value][item.value]"
                  :label="item.text"
                />

                <v-slider
                  v-else-if="item.type == 'slider'"
                  v-model="val[field.value][item.value]"
                  :label="item.text"
                  :min="item.min"
                  :max="item.max"
                />

                <v-switch
                  v-else-if="item.type == 'switch'"
                  v-model="val[field.value][item.value]"
                  :label="item.text"
                />
                <v-checkbox
                  v-else-if="item.type == 'checkbox'"
                  v-model="val[field.value][item.value]"
                  :label="item.text"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <!-- <v-row v-for="field in panel.fields" :key="field.value" no-gutters class="mx-n2">
            <v-col
              v-for="item in field.items"
              :key="item.value"
              :cols="item.cols ? item.cols : field.itemCols ? field.itemCols : 12"
              class="px-2"
            >
              <component
                v-if="item.component"
                v-bind:is="item.component"
                v-model="val[field.value][item.value]"
                :label="item.text"
              />

              <v-text-field
                v-else-if="!item.type"
                v-model="val[field.value][item.value]"
                :label="item.text"
                :placeholder="item.placeholder"
              />
              <v-textarea
                v-else-if="item.type == 'textarea'"
                v-model="val[field.value][item.value]"
                :label="item.text"
              />

              <v-slider
                v-else-if="item.type == 'slider'"
                v-model="val[field.value][item.value]"
                :label="item.text"
                :min="item.min"
                :max="item.max"
              />

              <v-switch
                v-else-if="item.type == 'switch'"
                v-model="val[field.value][item.value]"
                :label="item.text"
              />
              <v-checkbox
                v-else-if="item.type == 'checkbox'"
                v-model="val[field.value][item.value]"
                :label="item.text"
              />
            </v-col>
          </v-row>-->
        </m-pages-panel>
      </v-col>
    </v-row>
    <m-pages-panel v-for="obj in objects" :key="obj.value" :title="obj.title">CONTENT</m-pages-panel>
    <v-text-field v-model="val.img.src" label="Image Url" />
  </div>
</template>

<script>
import model from "../../mixins/model";
export default {
  mixins: [model],
  data() {
    return {
      rows: [
        {
          // cols
          colCols: 7,
          cols: [
            {
              panels: [
                {
                  title: "Image Options",
                  // value: "img",
                  groups: [
                    {
                      title: "Main Setup",
                      fields: [
                        {
                          name: "Image",
                          value: "img",
                          itemCols: 4,
                          items: [
                            {
                              text: "Url",
                              value: "src",
                              cols: 12,
                            },
                            {
                              text: "Contain",
                              value: "contain",
                              type: "switch",
                            },
                            {
                              text: "Dark",
                              value: "dark",
                              type: "switch",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      title: "Extra Options",
                      fields: [
                        {
                          name: "Image",
                          value: "img",
                          itemCols: 4,
                          items: [
                            {
                              text: "Pre-Loader Url",
                              value: "lazy-src",
                              cols: 12,
                            },

                            {
                              text: "Height",
                              value: "height",
                              cols: 3,
                            },
                            {
                              text: "Width",
                              value: "width",
                              cols: 3,
                            },
                            {
                              text: "Aspect Ratio",
                              value: "aspect-ratio",
                              cols: 3,
                            },
                            {
                              text: "Position",
                              value: "position",
                              placeholder: "center enter",
                              cols: 3,
                            },
                            {
                              text: "Max Height",
                              value: "max-height",
                              type: "number",
                              cols: 3,
                            },
                            {
                              text: "Max Width",
                              value: "max-width",
                              type: "number",
                              cols: 3,
                            },
                            {
                              text: "Min Height",
                              value: "min-height",
                              type: "number",
                              cols: 3,
                            },
                            {
                              text: "Min Width",
                              value: "min-width",
                              type: "number",
                              cols: 3,
                            },

                            {
                              text: "Light",
                              value: "light",
                              type: "switch",
                            },
                            // sizes,
                            // srcset,
                            // transition (from preloader)
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],

      objects: [
        {
          title: "Image Options",
          value: "img",
          items: [
            {
              text: "Url",
              value: "src",
            },
            {
              text: "Pre-Loader Url",
              value: "lazy-src",
            },
            {
              text: "Aspect Ratio",
              value: "aspect-ratio",
            },
            {
              text: "Contain",
              value: "contain",
            },
            {
              text: "Dark",
              value: "dark",
            },
            {
              text: "Light",
              value: "light",
            },
            // gradient
            {
              text: "Height",
              value: "height",
            },
            {
              text: "Width",
              value: "width",
            },
            {
              text: "Max Height",
              value: "max-height",
            },
            {
              text: "Max Width",
              value: "max-width",
            },
            {
              text: "Min Height",
              value: "min-height",
            },
            {
              text: "Min Width",
              value: "min-width",
            },
            {
              text: "Position",
              value: "position",
            },
            // sizes,
            // srcset,
            // transition (from preloader)
          ],
        },
      ],
    };
  },
};
</script>
