import parseContent from '../helpers/parse-content'

const state = {
  ready: false,
  // isBuilder: false,
  isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
  options: {},
  parsedSetup: {},
  // parsedBlocks: {},
  data: {
    setup: {},
    theme: {},
    // auth
    navItems: [],
    blocks: [],
  },
  layoutFooter: null,
  pauseVideo: false, // will be toggled in click events
}

const getters = {
  layoutFooter(state) {
    return state.layoutFooter
  },

  theme(state) {
    return state.data.theme
  },
  setup(state) {
    return state.data.setup
  },
  navItems(state) {
    return state.data.navItems
  },
  isBuilder(state) {
    return state.options.builder
  },
  isMobile(state) {
    return state.isMobile
  },
  pauseVideo(state) {
    return state.pauseVideo
  },
  parsedSetup(state) {
    const business = state.data.setup.business ? state.data.setup.business : {}
    const contact = state.data.setup.contact ? state.data.setup.contact : {}

    let str = JSON.stringify(state.data.setup)
    return JSON.parse(parseContent(str, { business, contact }))
  },
  actionBanner(state) {
    const business = state.data.setup.business ? state.data.setup.business : {}
    const contact = state.data.setup.contact ? state.data.setup.contact : {}

    let str = JSON.stringify(state.data.setup.actionBanner)
    return JSON.parse(parseContent(str, { business, contact }))
  },

  videoBlocks(state) {
    return [{ name: 'video_1' }]
  },
}

const mutations = {
  TOGGLE_PROP(state, key) {
    state[key] = !state[key]
  },

  SET_OPTIONS(state, value) {
    for (let k in value) {
      state.options[k] = value[k]
    }
  },

  SET_PROP(state, value) {
    for (let k in value) {
      state[k] = value[k]
    }
  },

  SET_DATA_PROP(state, value) {
    for (let k in value) {
      state.data[k] = value[k]
    }
  },

  SET_IS_BUILDER(state, value) {
    state.isBuilder = value
  },

  SET_NAV_ITEMS(state, items = []) {
    if (state.data.navItems.length) {
      return
    }
    state.data.navItems = items
    // console.log(typeof state.data.navItems, typeof items);
  },

  SET_PARSED_SETUP(state, setup) {
    console.log('SET_PARSED_SETUP')
    state.parsedSetup = setup
  },
}

const actions = {
  loadData({ commit }, data) {
    console.log('loaddata', data)
  },
  setNavItems({ commit }, items) {
    commit('SET_NAV_ITEMS', items)
  },
  setIsBuilder({ commit }, value) {
    commit('SET_IS_BUILDER', value)
  },
  appIni({ commit }, options = {}) {
    // options.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    commit('SET_OPTIONS', options)
  },
  setProp({ commit }, payload) {
    commit('SET_PROP', payload)
  },
  setTheme({ commit }, theme) {
    commit('SET_DATA_PROP', { theme })
  },
  // appMounted({ commit }) {
  //   // console.log("appMounted ", options);
  // },
  pauseVideo({ commit }) {
    commit('TOGGLE_PROP', 'pauseVideo')
  },
  setSetup({ commit }, setup) {
    commit('SET_DATA_PROP', { setup })
  },
  setDataProp({ commit }, blocks) {
    commit('SET_DATA_PROP', { blocks })
  },

  // addVideoToList({ commit }, video) {
  //   commit("ADD_VIDEO_TO_LIST", video);
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
