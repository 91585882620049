/* eslint-disable */

import defaultOptions from "./options";
import msg from "./store";

export default {
  install(Vue, options) {
    // options
    for (let k in defaultOptions) {
      if (typeof options[k] === "undefined") {
        options[k] = defaultOptions[k];
      }
    }

    // components
    if (options.useComponents) {
      Vue.component("msg-snackbar", require("./components/Snackbar").default);
    }

    const { store } = options;

    // register store meodule
    store.registerModule("msg", msg);

    // register plugin
    Vue.prototype.$msg = {
      success(payload) {
        return store.dispatch("msg/success", payload);
      },
      warning(payload) {
        return store.dispatch("msg/warning", payload);
      },
      error(payload) {
        return store.dispatch("msg/error", payload);
      },
      info(payload) {
        return store.dispatch("msg/info", payload);
      },
      close() {
        return store.dispatch("msg/close");
      },
      clearAll() {
        return store.dispatch("msg/clearAll");
      },
    };
  },
};
