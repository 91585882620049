// import auth from "./store";
import { getObjectValue } from "./helpers";

const setup = async (options) => {
  const { store, router, useWrapper } = options; // unauthTo

  const isUnauthenticated = () => {
    // place above ready so no page flash
    if (
      router.app._route &&
      router.app._route.meta &&
      router.app._route.meta.auth
    ) {
      if (!useWrapper) {
        router.replace({ name: "auth-login" });
      }
      //
    }

    store.commit("auth/SET", { ready: true });
  };

  let token = await localStorage.getItem(options.localKey);

  if (!token) {
    return isUnauthenticated();
  }

  const Api = store._vm.$api;

  // set token
  Api.setAuth(token);

  const url = options.meUrl || `${options.url}/me`;

  // return;
  Api.get(url)
    .then(({ data }) => {
      const user = getObjectValue(data, options.apiUserKey);

      // move away from unauth: e.g. login page
      if (!useWrapper && router.app._route.meta.unauth) {
        router.replace({ path: "/" });
      }

      store.commit("auth/SET", { user, loggedIn: true, ready: true });
    })
    .catch(() => {
      return isUnauthenticated();
    });
};

export default setup;
