<template>
  <div>
    <v-text-field
      :value="value"
      v-bind="attrs"
      hide-details
      @input="$emit('input', $event)"
    ></v-text-field>
  </div>
</template>

<script>
import fieldsMixin from '../mixins/fields'
export default {
  mixins: [fieldsMixin],
}
</script>
