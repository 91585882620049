<template>
	<div>
		<m-pages-button-block-container :block="block">
			<div v-if="block.heading && block.heading.text" class="text-h4">
				<m-pages-text :item="block.heading" />
			</div>

			<v-row class="text-center" align="center" justify="center">
				<div v-if="block.heading && block.heading.text" class="text-h6 mb-2">
					<m-pages-text v-if="block.subheading && block.subheading.text" :item="block.subheading" tag="div">
						<v-icon v-if="block.subheadingArrow" color="red">arrow_forward</v-icon>
						{{ block.subheading.text }}
					</m-pages-text>
				</div>
			</v-row>

			<m-pages-button :item="block.button" :disabled="download.loading">
				<v-layout class="pa-0" fill-height>
					<v-row dense no-gutters align="center" fill-height justify="center">
						<v-progress-circular
							v-if="download.loading"
							:indeterminate="download.indeterminate"
							:rotate="download.rotate"
							:size="52"
							:value="download.progress"
							:width="3"
							color="white"
						>
							{{ download.progress }}
							<span class="caption">%</span>
						</v-progress-circular>

						<v-progress-circular v-else-if="loading" indeterminate></v-progress-circular>
						<template v-else>
							<m-pages-text v-if="block.text && block.text.text" :item="block.text" />
							<template v-else>Button Text</template>
						</template>
					</v-row>
				</v-layout>
			</m-pages-button>

			<div v-if="block.footer && block.footer.text" class="caption my-2">
				<m-pages-text :item="block.footer" />
			</div>

			<div v-if="block.cards || block.paypal" class="mb-3">
				<m-pages-cards :cards="block.cards" :paypal="block.paypal" />
			</div>

			<div v-if="block.secureNotice" class="my-3">
				<v-icon class="mt-n1" color="orange">lock</v-icon>100% safe & secure
				<v-icon class="mt-n1 mr-n1 font-weight-bold" color="green">check</v-icon>
			</div>
		</m-pages-button-block-container>
	</div>
</template>

<script>
const FileDownload = require('js-file-download')
import blockMixin from '../../mixins/block'
export default {
	mixins: [blockMixin],
	data() {
		return {
			loading: false,
			// progress: 0,
			download: {
				loading: false,
				progress: 0,
				loaded: 0,
				total: 0,
				indeterminate: false,
				rotate: 0,
			},
		}
	},

	computed: {
		href() {
			return this.block.url || '#'
		},
	},

	methods: {
		onClick() {
			if (!this.block.url && this.block.download) {
				let url

				if (this.block.download) {
					this.download.loaded = 0
					this.download.loading = true

					url = `/download/${this.block.download}`

					let fileName = this.block.file_name

					if (!fileName) {
						return this.getDownloadFileName(url)
					}
					return this.downloadFile(url, fileName)
				}
			}

			this.loading = true
		},

		getDownloadFileName(url) {
			this.$api
				.get(url, { get_file_name: 1 })
				.then(({ data }) => {
					if (data.file_name) {
						this.downloadFile(url, data.file_name)
					}
				})
				.catch((err) => {
					this.loading = false
					this.$msg.error('Download not available')
				})
		},
		downloadFile(url, fileName) {
			const onDownloadProgress = ({ loaded, total }) => {
				if (loaded < total) {
					this.download.progress = parseInt((loaded / total) * 100)
				} else {
					this.download.progress = 100
				}

				this.download.loaded = loaded
				this.download.total = total
			}
			this.$api
				.downloadBlob(url, {}, onDownloadProgress)
				.then((response) => {
					if (response.data) {
						setTimeout(() => {
							FileDownload(response.data, fileName)
						}, 550)
					} else {
						this.$msg.error('Unable to download now')
					}
					// this.loading = false;
				})
				.catch((err) => {
					this.loading = false
					this.$msg.error('Unable to download')
				})
		},
	},
}
</script>
