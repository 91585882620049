<template>
  <v-toolbar
    :src="item.image ? item.src : ''"
    :collapse="item.collapse"
    :dense="item.dense"
    :extended="item.extended"
    :prominent="item.prominent"
    :short="item.short"
    :flat="item.flat"
    :color="item.color"
    :roundedx="item.rounded"
    :outlined="item.outlined"
    :rounded="item.rounded"
    :elevation="item.elevation ? item.elevation : ''"
    :dark="item.dark"
    :light="item.light"
  >
    <v-avatar v-if="item.useLogo" class="mr-3" :tile="item.logo.tile">
      <v-img :src="item.logo.src" />
    </v-avatar>
    <v-toolbar-title v-text="item.title" />

    <v-spacer />
    <v-toolbar-title v-if="item.name" v-text="item.name" class="body-2" />
    <v-avatar v-if="item.useAvatar" class="ml-3" :tile="item.avatar.tile">
      <v-img :src="item.avatar.src" />
    </v-avatar>

    <v-btn
      v-if="item.minimizeable"
      class="ml-2"
      :class="item.closeable ? 'mr-n6' : ''"
      icon
      @click="$emit('minimize')"
    >
      <v-icon>minimize</v-icon>
    </v-btn>
    <v-btn v-if="item.closeable" class="ml-2" icon @click="$emit('close')">
      <v-icon>close</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
