<template>
  <v-textarea
    rows="1"
    v-model="val"
    :label="label"
    hide-details
    @input="onInput"
  />
</template>

<script>
import { encode, decode } from "js-base64";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "Checkout Code",
    },
  },

  data() {
    return {
      val: "",
    };
  },

  beforeMount() {
    if (this.value) {
      this.val = decode(this.value);
    }
  },

  watch: {
    value() {
      if (this.value != this.encodedStr) {
        this.val = decode(this.value);
      }
    },
  },

  computed: {
    encodedStr() {
      return encode(this.val);
    },
  },

  methods: {
    onInput() {
      this.$emit("input", this.encodedStr);
    },
  },
};
</script>
