import { decode } from "js-base64";

export default {
  methods: {
    checkout(value, target = false) {
      const id = "_m_pages_checkout_wrapper";

      const html = decode(value);
      let el;

      if (!document.querySelector(`#${id}`)) {
        el = document.createElement("div");
        el.id = id;
        el.style.display = "none";
        el.innerHTML = html;
        document.body.appendChild(el);
      } else {
        el = document.querySelector(`#${id}`);
        el.innerHTML = html;
      }

      const form = document.querySelector(`#${id} form`);

      // if open new
      if (target) {
        form.setAttribute("target", target);
      }
      //

      form.submit();
    },
  },
};
