<template>
  <div>CARD</div>
</template>

<script>
import model from "../../mixins/model";
// import appAutocomplete from "@/components/Autocomplete";
// import htmlEditor from "@/components/Tinymce";
export default {
  components: {
    // appAutocomplete,
  },
  mixins: [model],

  data() {
    return {};
  },
};
</script>
