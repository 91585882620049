import genId from '@/helpers/gen-id';
export default {
	refresh: Boolean,
	pause: Boolean,
	autoplay: Boolean,
	play: {
		type: Boolean,
		default: false,
	},
	currentTime: {
		type: Number,
		default: -1,
	},
	seekto: {
		type: Number,
		default: null,
	},
	video: {
		type: Object,
		default() {
			return {};
		},
	},
	src: {
		type: String,
		required: false,
	},
	id: {
		type: String,
		default() {
			return genId('player_');
		},
	},
};
