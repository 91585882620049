<template>
  <list-layout crumbs>
    <ml-table edit create toggle-active :fields="form" endpoint="admin/menus" />
  </list-layout>
</template>

<script>
import appAutocomplete from "@/components/Autocomplete";
import { form } from "./js/menu-fields";
export default {
  components: {
    appAutocomplete,
  },

  data() {
    return {
      form,
      // filterProductId: "",
    };
  },

  computed: {
    endpoint() {
      if (this.filterProductId) {
        return `admin/products/${this.filterProductId}/addons`;
      }
      return this.$route.path;
    },
  },
};
</script>
