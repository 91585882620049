<template>
	<div>
		<v-btn @click="$emit('refresh')">Refresh</v-btn>

		<v-tabs>
			<v-tab>Gallery</v-tab>
			<v-tab>Heading</v-tab>
			<v-tab>Subheading</v-tab>

			<v-tabs-slider />

			<v-tab-item>
				<v-row>
					<v-col>
						<m-pages-panel title="Videos" edged>
							<div v-if="!val.items.length" class="px-4 py-4 grey--text">No videos...</div>
							<m-draggable
								:items="val.items"
								:item-text="(a) => a.name || a.src"
								can-edit
								can-delete
								@onEdit="onEdit"
								@onDelete="onDelete"
							/>
						</m-pages-panel>

						<m-pages-panel ref="videoForm" :title="`${itemId ? 'Edit' : 'Add'} Video`">
							<v-text-field ref="name" v-model="getForm.name" label="Name (your reference)" />
							<v-text-field v-if="!val.noTitle" v-model="getForm.title" label="Title" />
							<v-text-field v-if="!val.noSubtitle" v-model="getForm.subtitle" label="Subtitle" />

							<m-pages-video-field
								v-model="getForm.src"
								get-duration
								@duration="getForm.duration = $event"
							/>
							<v-text-field v-model="getForm.poster" label="Image" />
							<v-text-field v-model="getForm.endedPoster" label="Ended Image" />
							<v-text-field v-model="getForm.endedPosterLink" label="Ended Link" />
							<v-btn v-if="itemId" class="my-4" @click="itemId = ''">Done</v-btn>
							<v-btn v-else class="my-4" @click="addItem">Add Video</v-btn>
							<v-row>
								<v-switch
									v-for="option in options"
									v-model="getForm[option.value]"
									:key="option.value"
									:label="option.text"
									:disabled="option.vslActive && !getForm.vslActive"
									class="mx-4"
								/>
							</v-row>
						</m-pages-panel>
					</v-col>
					<v-col>
						<m-pages-panel title="Options">
							<v-row no-gutters>
								<v-col v-for="option in galleryOptions" :key="option.id" md="6">
									<v-checkbox v-model="val.gallery[option.value]" :label="option.text" hide-details />
								</v-col>
							</v-row>

							<v-row>
								<v-col v-for="item in colsOptions" :key="item.value">
									<v-text-field
										v-model="val.gallery[item.value]"
										:label="item.text"
										type="number"
										min="0"
										max="12"
									/>
								</v-col>
							</v-row>

							<v-text-field
								v-if="!val.gallery.noTitle"
								v-model="val.gallery.fallbackTitle"
								label="Fallback Title"
							/>
							<v-text-field v-model="val.gallery.groupSubtitle" label="Fallback Subtitle" />
						</m-pages-panel>

						<m-pages-panel title="Player">
							<v-row>
								<v-slider
									v-model="val.player.border"
									min="0"
									max="12"
									label="Border"
									class="mx-4"
									thumb-label
								/>
								<v-slider
									v-model="val.player.inset"
									min="0"
									max="12"
									label="Inset"
									class="mx-2"
									thumb-label
								/>
								<v-slider
									v-model="val.player.elevation"
									min="0"
									max="24"
									label="Shadow"
									class="mx-4"
									thumb-label
								/>
							</v-row>
							<v-row>
								<m-color-picker
									v-model="val.player.color"
									depressed
									btn-class="mx-4 mt-3"
									label="Color"
									@input="onPlayerColor"
								/>
								<v-switch v-model="val.player.tile" label="Tile" class="mx-4" />
							</v-row>
						</m-pages-panel>
					</v-col>
				</v-row>
			</v-tab-item>

			<v-tab-item>
				<m-pages-heading-form v-model="val.heading" />
			</v-tab-item>

			<v-tab-item>
				<m-pages-heading-form v-model="val.subheading" />
			</v-tab-item>
		</v-tabs>
	</div>
</template>

<script>
import setPlayerColor from '../../helpers/set-player-color';
import genId from '@/helpers/gen-id';
import model from '../../mixins/model';
import options from '../../mixins/video-options';
import blockList from '../../mixins/block-list';
import form from '../../mixins/form';

export default {
	mixins: [model, blockList, form],
	data() {
		return {
			options,
			form: {},
			itemId: '',
			colsOptions: [
				// {
				//   text: "Cols",
				//   value: "cols",
				// },
				{
					text: 'Small',
					value: 'sm',
				},
				{
					text: 'Medium',
					value: 'md',
				},
				{
					text: 'Large',
					value: 'lg',
				},
				{
					text: 'X-Large',
					value: 'xl',
				},
			],
			galleryOptions: [
				{
					text: 'No Title',
					value: 'noTitle',
				},
				{
					text: 'No Subtitle',
					value: 'noSubtitle',
				},
				{
					text: 'No Image',
					value: 'noImage',
				},
				// {
				//   text: "Play",
				//   value: "noPlay"
				// },
				{
					text: 'No Progress',
					value: 'noProgress',
				},
				{
					text: 'No Duration',
					value: 'noDuration',
				},
				{
					text: 'No Time',
					value: 'noCurrentTime',
				},
				{
					text: 'Can Collapse',
					value: 'canCollapse',
				},
				{
					text: 'Load Collapsed',
					value: 'startCollapsed',
				},
				{
					text: 'Hover FX',
					value: 'noHover',
				},
				{
					text: 'Dark',
					value: 'dark',
				},
			],
		};
	},

	computed: {
		getForm() {
			if (this.itemId) {
				const index = this.val.items.findIndex((a) => a.id === this.itemId);
				return this.val.items[index];
			}
			return this.form;
		},
	},

	methods: {
		onPlayerColor(color) {
			setPlayerColor(this.id, color);
		},
		addItem() {
			const item = {
				id: genId('video_'),
				...this.form,
			};

			this.val.items.push(item);
			this.clearForm();
		},

		onEdit(item) {
			this.itemId = item.id;
			this.$vuetify.goTo(this.$refs.videoForm, { offset: 14 });
			setTimeout(() => {
				this.$refs.name.focus();
			}, 300);
		},
		onDelete(item) {
			const index = this.val.items.findIndex((a) => a.id == item.id);
			this.val.items.splice(index, 1);
		},
	},

	beforeMount() {
		console.log('before', this.val);
	},
};
</script>
