export default [
  {
    text: "X Large",
    value: "x-large",
  },
  {
    text: "Large",
    value: "large",
  },
  {
    text: "Normal",
    value: "",
  },
  {
    text: "Small",
    value: "small",
  },
  {
    text: "X Small",
    value: "x-small",
  },
];
