import placementField from "../../mixins/fields/placement-field";
import screenField from "../../mixins/fields/screen-field";

export default [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Description",
    value: "description",
  },
  {
    ...screenField,
  },
  {
    ...placementField,
  },
];
