<template>
  <v-container>
    container
    <template v-for="(child, index) in item.children">
      <component v-bind:is="`m-pages-${child.component}2`" :item="child" :key="index" />
    </template>
  </v-container>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>