<template>
  <v-card class="my-2">
    <v-toolbar v-if="title || close" dense flat color="grey lighten-4">
      <v-toolbar-title v-if="title" v-text="title" />
      <slot name="toolbar" />
      <v-spacer />

      <v-btn v-if="close" icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-expand-transition>
      <div v-if="!hideContent" class="pt-3">
        <slot v-if="edged" />
        <v-card-text v-else class="pt-0">
          <slot />
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    close: Boolean,
    edged: Boolean,
    hideContent: Boolean,
    title: {
      type: String,
      required: false,
    },
  },
};
</script>
