// auth module
const state = {
  loggedIn: false,
  ready: false,
  user: {},
  userFields: [],
  toPath: "", // return after logged
  loginPage: {},
  loginToken: null,
  requireLogin: false,
};

const getters = {
  requireLogin(state) {
    return state.requireLogin;
  },
};

const mutations = {
  SET(state, props) {
    let k;
    for (k in props) {
      state[k] = props[k];
    }
  },
};

const actions = {
  requireLogin({ commit }, requireLogin = true) {
    commit("SET", { requireLogin });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
