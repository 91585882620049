<template>
  <list-layout crumbs>
    <ml-table
      edit
      delete
      toggle-active
      create
      :create-fields="createFields"
      endpoint="admin/partials"
    >
      <template slot="row-actions" slot-scope="{ item }">
        <v-btn icon :to="{ name: 'partial-builder', params: { id: item.id } }">
          <v-icon class="mr-1">build</v-icon>
        </v-btn>
      </template>
    </ml-table>
  </list-layout>
</template>

<script>
import placementField from "../mixins/fields/placement-field";
import screenField from "../mixins/fields/screen-field";
export default {
  data() {
    return {
      createFields: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          ...screenField,
        },
        {
          ...placementField,
        },
      ],
    };
  },
};
</script>
