export default {
  methods: {
    download(value) {
      this.isLoading = true;

      const url = `/download/${value}`;

      this.$api.get(url).then(({ data }) => {
        if (data.data && data.data.download_url) {
          window.location.href = data.data.download_url;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      });
    },
  },
};
