<template>
  <v-card class="text-center" flat color="grey lighten-4" style="opacity:1; cursor: pointer">
    <v-card-text class="title text-uppercase grey--text">- {{ block.name }} Placeholder -</v-card-text>
  </v-card>
</template>

<script>
import blockMixin from "../../mixins/block";

export default {
  mixins: [blockMixin],
  beforeMount() {
    // get component for viewing
  },
};
</script>
