<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="hover ? 12 : 2" close-delay="100">
      <v-card
        flat
        tile
        :to="{ name: 'my-product', params: { slug: product.slug } }"
      >
        <v-img
          v-if="product.image"
          :src="product.image"
          height="150"
          :contain="contain"
        />
        <v-sheet v-else color="grey lighten-4" height="150">
          <v-layout fill-height>
            <v-row
              fill-height
              align="center"
              justify="center"
              class="text-uppercase title grey--text text--lighten-2 px-4 text-center"
              >{{ product.name }}</v-row
            >
          </v-layout>
        </v-sheet>
      </v-card>
      <v-card-title v-text="product.name" />
      <v-card-actions>
        <v-btn
          text
          color="primary"
          exact
          :to="{ name: 'my-product', params: { slug: product.slug } }"
          >Select</v-btn
        >
        <!-- <v-btn text>Upgrade</v-btn> -->
        <v-spacer />

        <!-- high ticket  -->
        <v-btn
          v-if="product.can_promote"
          text
          exact
          :color="product.promote_hot ? 'red' : ''"
          :to="{ name: 'promote-product', params: { slug: product.slug } }"
          :target="promoteOpenNew ? '_blank' : ''"
        >
          <v-icon
            :color="product.promote_hot ? 'red' : 'success'"
            class="mr-1"
            v-text="product.promote_hot ? 'whatshot' : 'monetization_on'"
          />Promote
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    contain: Boolean,
    promoteOpenNew: Boolean,
    product: {
      type: Object,
      required: true,
    },
  },
}
</script>
