<template>
  <item-layout crumbs :title="menu.name">
    <v-fade-transition>
      <div v-if="ready">
        <v-row>
          <v-col cols="6">
            <ml-form
              slotx="actions"
              :fields="form"
              name="menu"
              title="Edit Menu"
              dialogx
              :endpoint="`admin/menus/${this.$route.params.id}`"
            />
          </v-col>
          <v-col cols="6">
            <menu-items />
            <br />
            <order-menu :placement="menu.placement" />
          </v-col>
        </v-row>

        <br />
        <m-pages-auth-form
          v-model="menu.auth"
          title="Menu Authorization"
          :active="menu.auth_active"
          hide-inactive
        >
          <v-btn
            slot="toolbar"
            class="mr-2"
            color="primary"
            :disabled="!canSaveAuth"
            :loading="savingAuth"
            @click="saveAuth"
          >Save</v-btn>
          <v-switch slot="active" v-model="menu.auth_active" label="Auth Active" hide-details />
        </m-pages-auth-form>
      </div>
    </v-fade-transition>

    <!-- <m-raw-displayer :value="menu" title="Menu" /> -->
  </item-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { form } from "./js/menu-fields";
import MenuItems from "./components/MenuItems";
import OrderMenu from "./components/OrderMenu";

export default {
  components: {
    MenuItems,
    OrderMenu,
  },

  data() {
    return {
      ready: false,
      // canSaveAuth: false,
      savingAuth: false,
      form,
      menuItems: [],
      loadAuthStr: "",
    };
  },

  computed: {
    ...mapGetters({
      menu: "menu/data",
    }),
    canSaveAuth() {
      return (
        this.loadAuthStr !=
        JSON.stringify({
          auth: this.menu.auth,
          auth_active: this.menu.auth_active,
        })
      );
    },
  },

  methods: {
    async saveAuth() {
      this.savingAuth = true;
      const form = {
        auth: this.menu.auth,
        auth_active: this.menu.auth_active,
      };

      await this.$api.post(`admin/menus/${this.$route.params.id}`, form);

      this.savingAuth = false;
      this.ready = false;
      this.getItem();
    },

    async getItem() {
      await this.$store.dispatch(
        "menu/getItem",
        `admin/menus/${this.$route.params.id}`
      );

      this.loadAuthStr = JSON.stringify({
        auth: this.menu.auth,
        auth_active: this.menu.auth_active,
      });

      this.ready = true;
    },
  },

  beforeMount() {
    this.getItem();
  },
};
</script>
