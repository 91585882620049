<template>
  <div>
    <v-toolbar v-if="title" dense color="grey lighten-4" flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td>{{ row.field }}</td>
            <td>{{ row.value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },

    item: {
      type: Object,
      default: null
    },

    fields: {
      type: [Array, String],
      default: ""
    }
  },

  computed: {
    data() {
      const data = [];

      if (this.fields) {
        let fields = this.fields;
        if (typeof fields === "string") {
          fields = fields.split(",");
        }

        fields.forEach(field => {
          data.push({
            field,
            value: this.item[field]
          });
        });
        return data;
      }

      for (let k in this.item) {
        data.push({
          field: k,
          value: this.item[k]
        });
      }
      return data;
    }
  }
};
</script>