<template>
  <v-row>
    <v-col cols="7">
      <m-pages-panel title="Content">
        <v-text-field v-model="val.headingText" label="Heading" clearable />
        <v-text-field
          v-model="val.subheadingText"
          label="Subheading"
          clearable
        />

        <v-row>
          <v-text-field
            v-model="val.buttonText"
            label="Button Text"
            class="mx-4"
          />
          <v-text-field
            v-model="val.buttonIcon"
            label="Button Icon"
            class="mx-4"
          />
        </v-row>

        <v-row>
          <v-text-field
            v-model="val.url"
            label="Order Link"
            placeholder="https://"
            class="mx-4"
          />

          <app-autocomplete
            v-model="val.download"
            class="mx-4"
            label="Download"
            endpoint="admin/list/download"
            :disabled="val.url ? true : false"
            item-value="value"
            item-text="text"
          />
        </v-row>
      </m-pages-panel>

      <m-pages-panel title="J-Box" :hide-content="!val.jBox">
        <template slot="toolbar">
          <v-spacer />
          <v-switch v-model="val.jBox" label="Active" hide-details />
        </template>
        <br />
        <!-- -->
        <v-slider
          v-model="val.jBoxWidth"
          label="J-Box Width"
          min="400"
          max="1000"
          thumb-label
        />

        <v-row class="mx-0">
          <v-slider
            v-model="val.jBoxBorderWidth"
            label="J-Box Border Width"
            min="0"
            max="24"
            thumb-label
          />
          <v-slider
            v-model="val.jBoxElevation"
            label="J-Box Shadow"
            min="0"
            max="24"
            thumb-label
          />
        </v-row>
        <v-row>
          <m-color-picker
            v-model="val.jBoxColor"
            label="J-Box Color"
            btn-class="mx-3"
          />

          <m-color-picker
            v-model="val.jBoxBorderColor"
            label="J-Box Color"
            btn-class="mr-2"
          />

          <v-checkbox
            v-for="option in jBoxOptions"
            :key="option.value"
            v-model="val[option.value]"
            :label="option.text"
            :disabled="option.jBox && !val.jBox"
            class="mx-3 mt-1"
          />
        </v-row>
      </m-pages-panel>
    </v-col>
    <v-col>
      <m-pages-panel title="Options">
        <v-slider
          v-model="val.elevation"
          label="Shadow"
          min="0"
          max="12"
          thumb-label
        />

        <v-expand-transition>
          <div v-if="val.customSize">
            <v-slider
              v-model="val.width"
              label="Width"
              min="200"
              max="500"
              thumb-label
            />

            <v-slider
              v-model="val.height"
              label="Height"
              min="50"
              max="400"
              thumb-label
            />
          </div>
        </v-expand-transition>

        <m-color-picker v-model="val.color" label="color" />

        <v-row>
          <v-checkbox
            v-for="option in options"
            :key="option.value"
            v-model="val[option.value]"
            :label="option.text"
            class="mx-3"
          />
        </v-row>
      </m-pages-panel>
    </v-col>
  </v-row>
</template>

<script>
import model from "../../mixins/model";
import appAutocomplete from "@/components/Autocomplete";
// import htmlEditor from "@/components/Tinymce";
export default {
  components: {
    appAutocomplete,
  },
  mixins: [model],

  data() {
    return {
      options: [
        {
          text: "Dark",
          value: "dark",
        },
        {
          text: "Simple",
          value: "simple",
        },
        {
          text: "Sub Arrow",
          value: "subheadingArrow",
        },
        {
          text: "Cards",
          value: "cards",
        },
        {
          text: "PayPal",
          value: "paypal",
        },
        {
          text: "Secure",
          value: "secureNotice",
        },
        {
          text: "Block",
          value: "block",
        },
        {
          text: "Custom Size",
          value: "customSize",
        },
        {
          text: "Open In New",
          value: "openNew",
        },
        // {
        //   text: "J-Box",
        //   value: "jBox",
        // },
      ],
      jBoxOptions: [
        {
          text: "J-Box Dark",
          value: "jBoxDark",
          jBox: true,
        },
        {
          text: "J-Box Border",
          value: "jBoxBorder",
          jBox: true,
        },
      ],
    };
  },
};
</script>
