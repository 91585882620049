export default {
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      _formStr: "",
    };
  },

  methods: {
    clearForm() {
      this.form = JSON.parse(this._formStr);
    },
  },

  beforeMount() {
    this._formStr = JSON.stringify(this.form);
  },
};
