<template>
	<v-row :no-gutters="noGutters" style="min-width:150px">
		<v-col cols="6">
			<v-select v-model="val" label="Rounded" :items="items" clearable hide-details @input="onInput" />
		</v-col>
		<v-col cols="6">
			<v-select v-model="side" label="Sides" :items="sides" clearable hide-details @input="onInput" />
		</v-col>
	</v-row>
</template>

<script>
// import { rounded } from "../../js/forms/fields";
export default {
	props: {
		noGutters: Boolean,
		value: {
			type: [String, Boolean],
			required: false,
		},
	},

	watch: {
		value() {
			if (!this.inputed) {
				this.setValue()
			}
		},
	},

	beforeMount() {
		if (this.value) {
			this.setValue()
		}
	},

	data() {
		return {
			// rounded,
			val: '',
			side: '',
			inputed: false,
			items: [
				{
					text: 'Square',
					value: '0',
				},
				{
					text: 'Small',
					value: 'sm',
				},
				{
					text: 'Medium',
					value: '',
				},
				{
					text: 'Large',
					value: 'lg',
				},
				{
					text: 'X-Large',
					value: 'xl',
				},
				{
					text: 'Pill',
					value: 'pill',
				},
				{
					text: 'Circle',
					value: 'circle',
				},
				{
					text: 'Top',
					value: 't-xl',
				},
				{
					text: 'Bottom',
					value: 'b-xl',
				},
				{
					text: 'Left',
					value: 'l-xl',
				},
				{
					text: 'Right',
					value: 'r-xl',
				},
				{
					text: 'Top Left',
					value: 'tl-xl',
				},
				{
					text: 'Top Right',
					value: 'tr-xl',
				},
				{
					text: 'Bottom Left',
					value: 'bl-xl',
				},
				{
					text: 'Bottom Right',
					value: 'br-pill',
				},
			],
			sides: [
				{
					text: 'All',
					value: '',
				},
				{
					text: 'Top',
					value: 't',
				},
				{
					text: 'Bottom',
					value: 'b',
				},
				{
					text: 'Left',
					value: 'l',
				},
				{
					text: 'Right',
					value: 'r',
				},
				{
					text: 'Top Left',
					value: 'tl',
				},
				{
					text: 'Top Right',
					value: 'tr',
				},
				{
					text: 'Button Left',
					value: 'bt',
				},
				{
					text: 'Button Right',
					value: 'br',
				},
			],
		}
	},

	methods: {
		setValue(v) {
			if (typeof this.value != 'string') {
				return
			}
			if (this.value.indexOf('-') < 0) {
				this.val = this.value
				return
			}
			let [side, val] = this.value.split('-')

			this.val = val
			this.side = side || ''
		},

		onInput() {
			this.inputed = true
			const value = !this.val ? '' : this.side ? `${this.side}-${this.val}` : this.val

			return this.$emit('input', value)
		},
	},
}
</script>
