<template>
  <!-- <div>
    <v-row>
      <v-col> -->
  <m-pages-panel titlex="Toolbar Options">
    <v-row dense>
      <v-col cols="4" v-for="option in options" :key="option.value">
        <v-checkbox
          v-model="val[option.value]"
          :label="option.text"
          hide-details
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="val.align"
          :label="align.text"
          :items="align.items"
          clearable
          hide-details
        />
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <m-color-picker
        v-for="color in colors"
        :key="color.value"
        v-model="val[color.value]"
        :label="color.text"
        depressed
        btn-class="ml-2"
      />
    </v-row>
  </m-pages-panel>
  <!-- </v-col>
      <v-col> </v-col>
    </v-row>
  </div> -->
</template>

<script>
import model from "../../mixins/model";
import {
  dark,
  light,
  dense,
  extended,
  collapse,
  flat,
  prominent,
  short,
  elevation,
  color,
  textColor,
  align,
} from "../../js/forms/fields";

export default {
  mixins: [model],
  data() {
    return {
      options: [dark, light, dense, extended, collapse, flat, prominent, short],
      colors: [color, textColor],
      align,
    };
  },
};
</script>
