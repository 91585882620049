// import all from "./all";

// console.log("all", all);
export default {
  noContentSpacing: true,
  // ...all,
  content: {
    heading: {},
    subheading: {},
    content: {},
    video: {
      duration: 5,
    },
  },
};

/**
 *
 * YuTube: YdtqgjhsQSc
 * Countdown: plJe0uDszaY
 * Vimeo: 432293976
 * MP4: http://techslides.com/demos/sample-videos/small.mp4
 */
