import fields from "./fields";
import { headingFields } from "./heading";
export default {
  form: {
    title: {},
    heading: {},
    subheading: {},
  },
  rows: [
    {
      cols: [
        {
          cols: 9,
          groups: [...headingFields],
        },
        {
          cols: 3,
          panels: [
            {
              title: "Options",
              groups: [
                {
                  fields: [
                    {
                      fields: [fields.dark, fields.colorPicker],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  // groups: [...headingFields],
  // rows: [
  //   {
  //     cols: [
  //       {
  //         panels: [
  //           {
  //             title: "Label Content",
  //             // groups: [...headingFields],
  //             groupsx: [
  //               // ...headingFields,
  //               {
  //                 title: "First Group",
  //                 fields: [
  //                   {
  //                     value: "title",
  //                     fields: [
  //                       {
  //                         text: "Title Text",
  //                         value: "text",
  //                         cols: 7,
  //                       },
  //                       { ...fields.textSize, cols: 5 },
  //                       // {...fields.colorPicker},
  //                     ],
  //                   },
  //                   {
  //                     fields: [fields.rounded],
  //                   },
  //                   {
  //                     itemCols: 4,
  //                     fields: [fields.px, fields.py, fields.elevation],
  //                   },
  //                   {
  //                     itemCols: 3,
  //                     fields: [
  //                       fields.dark,
  //                       // fields.flat,
  //                       // fields.tile,
  //                       fields.colorPicker,
  //                     ],
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ],
  // groups: [
  //   {
  //     fields: [
  //       {
  //         fields: [fields.dark, fields.flat, fields.tile],
  //       },
  //       {
  //         fields: [
  //           {
  //             ...fields.rounded,
  //           },
  //           {
  //             ...fields.maxWidth,
  //             cols: 2,
  //           },
  //           fields.colorPicker,
  //         ],
  //       },
  //     ],
  //   },
  // ],
};
