<template>
  <div>
    <v-progress-linear v-if="loading" absolute indeterminate />
    <m-crumbs v-if="crumbs" />
    <v-container fluid>
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    crumbs: Boolean
  }
};
</script>