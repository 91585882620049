<template>
  <div class="m-pages" v-if="ready">
    <m-pages-page-container :theme="data.theme">
      <template v-for="block in data.blocks">
        <m-pages-wrapper
          v-if="showBlock(block.schedule)"
          :key="block.id"
          :blockId="block.id"
          :item="block.wrapper ? block.wrapper : {}"
          :dark="block.dark"
          :light="block.light"
          :color="block.color"
          :sticky="block.sticky"
          :noPageSpacingY="block.noPageSpacingY"
        >
          <m-pages-container
            :item="block.container"
            :blockId="block.id"
            :expanded="block.expanded"
          >
            <m-pages-row v-if="block.name == 'row'" :row="block.row">
              <div v-if="!block.cols.length"></div>
              <m-pages-col
                v-for="col in block.cols"
                :key="col.id"
                :col="col.col"
              >
                <m-pages-player
                  v-if="block.name == 'video'"
                  :video="
                    block.content && block.content.video
                      ? block.content.video
                      : ''
                  "
                  :pause="playingId != block.id"
                  @play="onPlayerPlay(block.id)"
                />
                <component
                  v-else
                  v-bind:is="`m-pages-${col.name}-block`"
                  :id="col.id"
                  :block="col.content"
                  :setup="data.setup"
                  :theme="data.theme"
                  @onEvent="onEvent(block, $event)"
                />
              </m-pages-col>
            </m-pages-row>

            <template v-else>
              <m-pages-player
                v-if="block.name == 'video'"
                :video="
                  getVideoBlock(
                    block.content && block.content.video
                      ? block.content.video
                      : ''
                  )
                "
                :pause="playingId != block.id"
                @ready="onPlayerReady(block.id, $event)"
                @play="onPlayerPlay(block.id)"
                @timeUpdate="onPlayerTimeUpdate(block.id, $event)"
              />
              <component
                v-else
                v-bind:is="`m-pages-${block.name}-block`"
                :block="block.content"
                :id="block.id"
                :setup="data.setup"
                :theme="data.theme"
                @onEvent="onEvent(block, $event)"
              />
            </template>
          </m-pages-container>
        </m-pages-wrapper>
      </template>
    </m-pages-page-container>

    <!-- dom refresh  -->
    <span v-if="status.refresh"></span>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  // page
  name: 'm-page',
  props: {
    page: {
      type: Object,
      required: false,
    },
  },

  watch: {
    page() {
      this.setup()
    },
  },

  data() {
    return {
      progressStoring: false,
      localKey: '',
      ready: false,
      playingId: '',
      data: {},
      watchingStatus: false,
      status: {
        refresh: false,
        // player: {},
      },
      localStatusStr: '',
    }
  },

  methods: {
    getVideoBlock(block) {
      // console.log("getVideoBlock", block);
      if (!block.color && this.data.theme.color) {
        block.color = this.data.theme.color
      }
      return block
    },
    onEvent(block, event) {
      // console.log("onEvent", block, event);
    },
    showBlock(data) {
      if (!data || !data.video || !data.video.active) {
        return true
      }

      const { video } = data

      // Video Show
      const show = video.show
      const status = this.status[video.value]

      if (status.currentTime >= show) {
        return true
      }

      if (!video.keep) {
        return false
      }

      let progress
      if (
        video.keep &&
        status.storeProgress &&
        status.storeProgress > status.progress
      ) {
        progress = status.storeProgress
      } else {
        progress = status.progress
      }

      return progress >= show
    },

    onPlayerPlay(id) {
      this.playingId = id

      if (!this.watchingStatus) {
        this.watchingStatus = true
        this.watchStatus()
      }
    },

    watchStatus() {
      setInterval(() => {
        this.status.refresh = !this.status.refresh
      }, 250)
    },

    onPlayerReady(id, duration) {},

    onPlayerTimeUpdate(id, t) {
      // this.status[id] = t + 1;
      this.status[id].currentTime = t
      if (this.status[id].progress < t) {
        this.status[id].progress = t

        if (!this.progressStoring) {
          this.storeProgress(id, parseInt(t))
        }
      }
    },

    storeProgress(id, t) {
      this.progressStoring = true

      setTimeout(() => {
        this.progressStoring = false
        this.$localforage.setItem(`${id}_progress`, t)
      }, 2500)
    },

    setup() {
      this.$store.dispatch('MPages/appIni', { builder: false })

      this.data = { ...this.page }

      this.data.blocks.forEach((item, index) => {
        item.progress = 100
        if (item.name == 'video') {
          this.status[item.id] = {
            currentTime: 0,
            progress: 0,
            storeProgress: 0,
          }

          this.getStoreProgress(item.id)
        }
      })

      if (this.data.theme) {
        this.$store.dispatch('MPages/setTheme', this.data.theme)
      }

      this.$store.dispatch(
        'MPages/setSetup',
        this.data.setup ? this.data.setup : {}
      )

      // await this.$store.dispatch("MPages/appIni", { builder: true });

      // this.$store.dispatch("MPagesBuilder/setProp", {
      //   blocks: [...this.data.blocks],
      // });

      // Footer Block

      const layoutFooter = this.data.blocks[this.data.blocks.length - 1]
      if (layoutFooter.content.copyright) {
        this.data.blocks.pop()
        this.$store.dispatch('MPages/setProp', { layoutFooter })
      }

      this.ready = true
    },

    getStoreProgress(id) {
      this.$localforage.getItem(`${id}_progress`).then((value) => {
        if (value) {
          this.status[id].storeProgress = value
          this.status.refresh = !this.status.refresh // refresh
        }
      })
    },
  },

  beforeMount() {
    if (!isEmpty(this.page)) {
      this.setup()
    }
  },
}
</script>

<style lang="scss">
@import './scss/m-pages';
</style>
