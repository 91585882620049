<template>
  <div class="mb-8">
    <v-toolbar
      v-if="!noToolbar"
      flat
      dense
      color="grey lighten-4"
      class="elevation-2x mb-4"
    >
      <v-badge color="secondary" :content="total ? total : '0'">
        <v-toolbar-title class="text-capitalize">{{ title }}</v-toolbar-title>
      </v-badge>

      <v-fade-transition>
        <v-chip
          v-if="selected.length"
          class="ml-8"
          close
          :disabled="selectingAll"
          @click:close="selected = []"
          >{{ selected.length }} selected</v-chip
        >
      </v-fade-transition>

      <v-fade-transition>
        <v-chip
          v-if="selected.length && selected.length < total"
          class="ml-2"
          @click="selectAll"
          >Select All {{ total }}</v-chip
        >
      </v-fade-transition>

      <v-chip
        class="ml-5"
        :color="isAllSelectedV2 ? 'success' : ''"
        :close="isAllSelectedV2"
        @click="isAllSelectedV2 = !isAllSelectedV2"
        @click:close="isAllSelectedV2 = false"
        >Select All v2</v-chip
      >

      <v-progress-circular
        v-if="selectingAll"
        :width="2"
        size="20"
        color="secondary"
        class="ml-4"
        indeterminate
      />
      <v-spacer />

      <v-btn v-if="searchFilters" icon @click="showFilters = !showFilters">
        <v-icon>filter_list</v-icon>
      </v-btn>

      <!-- Search  -->
      <v-text-field
        v-model="form.q"
        hide-details
        single-line
        clearable
        prepend-icon="search"
        class="mr-4"
        @keyup="onSearch"
      />

      <v-chip
        v-if="search"
        close
        @click:close="onClearSearch"
        v-text="search"
      />
      <slot v-if="canCreate" name="add-new">
        <ml-form
          title="Add New"
          :fields="getCreateFields"
          :endpoint="endpoint"
          create
          dialog
          @onCreated="onCreated"
        />
      </slot>

      <v-toolbar-items>
        <template v-if="!cardsOnly">
          <v-btn icon @click="view = 'cards'">
            <v-icon>view_module</v-icon>
          </v-btn>
          <v-btn icon @click="view = 'table'">
            <v-icon>view_list</v-icon>
          </v-btn>
        </template>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text color="primary" dark v-on="on">{{ form.limit }}</v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="n in [10, 25, 50, 100]"
              :key="n"
              @click="onLimit(n)"
            >
              <v-list-item-title>{{ n }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <!-- Filters  -->

    <v-expand-transition v-if="searchFilters">
      <slot v-if="showFilters" name="search-filters">
        <ml-query-filters
          :items="filterItems"
          @fwi="onFilterWith"
          @fwo="onFilterWithout"
        />
      </slot>
    </v-expand-transition>

    <v-expand-transition>
      <div v-if="selected.length || isAllSelectedV2">
        <div>
          <slot name="delete-selected">
            <v-btn
              v-if="canDelete"
              class="mb-4"
              :loading="deletingSelected"
              @click="deleteSelected"
              >Delete Selected</v-btn
            >
          </slot>
        </div>

        <slot
          name="select-operations"
          :selected="selected"
          :form="form"
          :total="total"
          :isAllSelectedV2="isAllSelectedV2"
        />
      </div>
    </v-expand-transition>

    <!-- Cards  -->
    <template v-if="view === 'cards'">
      <v-row>
        <v-col
          v-for="(item, index) in filteredItems"
          :cols="cols || 4"
          :xl="xl"
          :lg="lg"
          :md="md"
          :sm="xs"
          :xs="xs"
          :key="`item${index}`"
        >
          <slot name="card" v-bind:item="item">
            <ml-card
              :item="item"
              :actions="actions"
              :no-more="cardsNoMore"
              @action="onAction($event, item)"
            />
          </slot>
        </v-col>
      </v-row>
    </template>

    <!-- Table  -->
    <v-data-table
      v-else-if="!cardsOnly"
      v-model="selected"
      :loading="loading"
      :headers="tableHeaders"
      :items="filteredItems"
      :server-items-length="total"
      :items-per-page="form.limit"
      :show-select="showSelect && !isAllSelectedV2"
      :show-expand="showExpand"
      :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
      hide-default-footer
      class="elevation-2x mb-4"
      @update:sort-by="onSortBy"
      @update:sort-desc="onSortDesc"
      @click:row="onClickRow"
    >
      <!-- update:page -->
      <slot />

      <template v-slot:[`item.id`]="{ item }">
        <v-checkbox
          v-if="isAllSelectedV2"
          :input-value="true"
          disabled
        ></v-checkbox>

        <template v-else>
          <v-row>
            <v-btn
              small
              text
              :to="{ name: singular, params: { id: item.id } }"
              >{{ item.id }}</v-btn
            >
            <v-btn
              icon
              small
              :to="{ name: singular, params: { id: item.id } }"
              target="_blank"
            >
              <v-icon small>open_in_new</v-icon>
            </v-btn>
          </v-row>
        </template>
      </template>

      <template v-slot:[`item.identifier`]="{ item }">
        <v-btn
          class="text-none"
          text
          :to="{ name: singular, params: { id: item.id } }"
        >
          {{ item.identifier }}
        </v-btn>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <v-btn
          class="text-none"
          text
          :to="{ name: singular, params: { id: item.id } }"
        >
          {{ item.email }}
        </v-btn>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-btn
          class="text-none"
          text
          :to="{ name: singular, params: { id: item.id } }"
        >
          {{ item.name }}
        </v-btn>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-icon v-if="!toggleActive">check</v-icon>
        <v-switch
          v-else
          v-model="item.active"
          label="Active"
          hide-details
          class="mt-0 ml-2"
          :loading="activatingId == item.id"
          @change="onRowActive(item)"
        />
      </template>

      <template v-if="actions" v-slot:[`item.actions`]="{ item }">
        <v-row justify="end" align="center">
          <v-btn small text @click="duplicateItemV2(item.id)">
            DUB v2
          </v-btn>
          <v-btn small text @click="doQuickEdit(item.id)">QE</v-btn>
          <slot name="row-actions" :item="item" />

          <div>
            <ml-actions
              :actions="actions"
              :endpoint="`${endpoint}/${item.id}`"
              emit-as-action
              @action="onAction($event, item)"
            />
          </div>
        </v-row>
      </template>

      <!-- expanded  -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <slot name="row-expand" :item="item" />
        </td>
      </template>
    </v-data-table>

    <div v-if="pages > 1" class="text-center mx-4">
      <v-pagination
        v-model="form.page"
        :length="pages"
        :disabled="loading"
        flat
        color="secondary"
        total-visible="8"
        @input="onPage"
      />
    </div>

    <v-expand-transition>
      <div v-if="selected.length || isAllSelectedV2">
        <slot
          name="select-operations-bottom"
          :selected="selected"
          :form="form"
          :total="total"
          :isAllSelectedV2="isAllSelectedV2"
        />
      </div>
    </v-expand-transition>

    <!-- quick edit  -->
    <v-dialog v-model="isQuickEdit" width="500">
      <v-card v-if="isQuickEdit">
        <v-toolbar flat color="grey lighten-4" dense>
          <v-toolbar-title>Quick Edit</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="isQuickEdit = false">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-toolbar>
        <ml-form
          :fields="getCreateFields"
          :endpoint="`${endpoint}/${quickEditId}`"
          @onUpdated="onQuickEditUpdated"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="duplicateV2.dialog" width="600">
      <m-duplicate-v2
        v-if="duplicateV2.dialog"
        :endpoint="endpoint"
        :item-id="duplicateV2.itemId"
        @duplicated="onDuplicatedV2"
      />
    </v-dialog>
  </div>
</template>

<script>
import props from './mixins/props'
import computed from './mixins/computed'
import methods from './mixins/methods'
import events from './mixins/events'
import beforeMount from './mixins/before-mount'
import watch from './mixins/watch'
import mlQueryFilters from '../query-filters/QueryFilters'
import mAddNew from './components/AddNew'
import mDuplicateV2 from './components/DuplicateV2'

import duplicateV2 from './mixins/duplicate-v2'
export default {
  components: {
    mlQueryFilters,
    mAddNew,
    mDuplicateV2,
  },
  mixins: [duplicateV2],

  props,
  watch,
  data() {
    return {
      ready: false,
      loading: false,
      selectingAll: false,
      showFilters: false,
      deletingSelected: false,
      isQuickEdit: false,
      quickEditId: '',
      expanded: null,
      activatingId: '',
      permissions: {},
      apiHeaders: [],
      // filterValue: null,
      // filterMatch: '',
      total: 0,
      pages: 1,
      // perpage: 10,
      form: {
        q: '',
        page: 1,
        limit: 10,
        orderby: 'id',
        order: 'desc',
        fwi: '', // filter with
        fwo: '', // filter without
        fmatch: 'all',
      },
      view: 'table',
      searched: false,
      search: '',
      selected: [],
      items: [],
      q: '',
      tableHeaders: [],
      dbCreateFields: [],
      pagin: {
        total: 0,
        page: 1,
        pages: 1,
        perpage: 25,
        orderby: 'id',
        order: 'desc',
      },

      // v2
      isAllSelectedV2: false,
    }
  },

  computed: {
    ...computed,
  },
  methods: {
    ...methods,
    ...events,
    async duplicateItem(itemId) {
      const { data } = await this.$api.get(`${this.endpoint}/${itemId}`)
      const form = {
        ...data.data,
        active: false,
      }

      await this.$api.post(this.endpoint, form)

      this.doRefresh()
    },
  },
  beforeMount,
}
</script>
