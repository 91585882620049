export default {
  //
  groups: {
    type: Array,
    default() {
      return [
        {
          text: "With",
          value: "fwi",
        },
        {
          text: "Without",
          value: "fwo",
        },
      ];
    },
  },
  items: {
    type: Array,
    default() {
      return [
        {
          text: "Product",
          value: "products",
          endpoint: "admin/products",
          itemText: "name",
          itemValue: "id",
        },
        {
          text: "Addon",
          value: "addons",
          endpoint: "admin/addons",
          itemText: "name",
          itemValue: "id",
        },
      ];
    },
  },
  matches: {
    type: Array,
    default() {
      return [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Any",
          value: "any",
        },
      ];
    },
  },

  matchLabel: {
    type: String,
    default() {
      let str = "",
        first = true;

      this.groups.forEach((m) => {
        if (!first) {
          str += " OR ";
        }
        str += m.text;
        first = false;
      });

      return str;
    },
  },

  matchDefault: {
    type: String,
    default: "all",
  },
};
