import opts from './options'
import {
  disabled,
  expanded,
  dark,
  light,
  noPageSpacingY,
  noPageSpacingX,
  sticky,
} from '../js/forms/fields'

export default {
  isRawEdit: false,
  // page,
  opts,
  testStr: 'n5',
  testObj: {},
  isThemeToolbar: false,
  ready: false,
  isNewBlock: false,
  isPreview: false,
  refresh: false,
  newBlockIndex: -1,
  selectColIndex: null,
  pendingSelectColIndex: null,
  window: 'builder',
  blockId: '',
  overId: '',

  blockMasterOptions: [
    { ...disabled, color: 'error' },
    // expanded,
    // { ...dark, noRequiresTheme: "dark" },
    // { ...light, requiresTheme: "dark" },
  ],
  blockToolbarOptions: [
    expanded,
    { ...dark, noRequiresTheme: 'dark' },
    { ...light, requiresTheme: 'dark' },
    noPageSpacingY,
    noPageSpacingX,
    sticky,
  ],

  data: {
    setup: {
      business: {},
      social: {},
      actionBanner: {},
      contact: {},
    },
    theme: {
      contentSpacingY: 5,
    },
    blocks: [],
  },

  // groups
}
