<template>
  <div :class="noMargin ? '' : `mb-${mb}`">
    EDITOR
    <tinymce-editor
      v-if="ready"
      v-model="val"
      :id="id"
      :init="options"
      @input="onInput"
    />
  </div>
</template>

<script>
import genId from '@/helpers/gen-id'
import tinymceEditor from '@tinymce/tinymce-vue'
export default {
  components: {
    tinymceEditor,
  },

  props: {
    preserveNewLine: Boolean,
    noMargin: Boolean,
    mb: {
      type: [Number, String],
      default: 8,
    },
    id: {
      type: String,
      default() {
        return genId('editor_')
      },
    },
    height: {
      type: [Number, String],
      default: 300,
    },

    value: {
      type: String,
      default: '',
    },
  },

  watch: {
    value() {
      if (this.value != this.val) {
        this.val = this.value
      }
    },
  },

  beforeMount() {
    if (this.value) {
      this.val = this.value
    }
  },

  data() {
    return {
      ready: false,
      // refreshing: false,
      val: '',
      options: {
        relative_urls: false,
        remove_script_host: false,
        height: this.height,
        content_style: 'body { font-family: "Roboto", sans-serif; }',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor textcolor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount ',
        ],
        toolbar:
          'undo redo | formatselect | bold italic | forecolor backcolor | underline strikethrough | \
           alignleft aligncenter alignright alignjustify | \
           link image | \
           bullist numlist outdent indent | removeformat',

        // menu: {
        //   format: { title: "Format", items: "forecolor backcolor" },
        // },
      },
    }
  },

  methods: {
    onInput(str) {
      return this.$emit('input', str)
    },
  },

  mounted() {
    // required for it to work correctly
    setTimeout(() => {
      this.ready = true
    })
  },
}
</script>
