export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      val: "",
    };
  },

  watch: {
    value(val) {
      if (this.val != val) {
        this.val = val;
      }
    },
  },

  beforeMount() {
    if (this.value) {
      this.val = this.value;
    }
  },

  methods: {
    onInput() {
      this.$emit("input", this.val);
    },
  },
};
