/* eslint-disable */

import { getObjectValue } from "./helpers";
// import localforage from "localforage";
// Check Token
export const validateRecoveryToken = ({ options, token }) => {
  return new Promise((resolve, reject) => {
    const { store } = options;
    const Api = store._vm.$api;

    const url = options.recoveryTokenValidateUrl || `${options.url}/recovery/`;

    Api.get(`${url}${token}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const recoveryTokenLogin = ({ options, data }) => {
  return new Promise((resolve, reject) => {
    const { store, loginTo, router, apiTokenKey } = options;
    const Api = store._vm.$api;
    const { token, newpass } = data;
    const url =
      options.recoveryTokenLoginUrl || `${options.url}/recovery/login`;

    Api.post(url, { token, newpass })
      .then(({ data }) => {
        // setup user
        const token = getObjectValue(data, apiTokenKey);
        // set authorization header
        Api.setAuth(token);

        return localStorage.setItem(options.localKey, token);
      })
      .then(() => {
        const meUrl = options.meUrl || `${options.url}/me`;

        return Api.get(meUrl);
      })
      .then(({ data }) => {
        const user = getObjectValue(data, options.apiUserKey);

        store.commit("auth/SET", { user, loggedIn: true });

        if (store.state.auth.toPath) {
          router.replace({ path: store.state.auth.toPath });
          store.commit("auth/SET", { toPath: "" });
        } else {
          router.replace(loginTo);
        }
        resolve();
      })
      .catch(({ response }) => {
        // console.log("ERROR", response);
        reject(response.data);
      });

    //
  });
};
