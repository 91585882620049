/**
 *
 * @param {Number} max
 * @param {Number} start
 * @param {Boolean,String} asString
 */
export default function(max, start = 0, asString = false) {
  const n = [];
  let i;
  for (i = start; i <= max; i++) {
    if (asString) {
      i = i.toString();

      if (asString == "digits" && i.length < 2) {
        i = "0" + i;
      }
    }
    n.push(i);
  }
  return n;
}
