import img from "./img";

import componentForms from "./component";
// SHOULD BE IMG

export const toolbar = componentForms.toolbar;

export default {
  ...componentForms,
  img,
};
