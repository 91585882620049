<template>
  <v-container fill-height>
    <v-row fill-height>
      <auth-login-page no-bg-imagex>
        <auth-header />
      </auth-login-page>
    </v-row>
  </v-container>
</template>

<script>
import authHeader from "./components/AuthHeader";
export default {
  components: {
    authHeader,
  },
};
</script>
