<template>
  <div>
    <v-row class="mb-n4">
      <v-col v-if="!noText" cols="6">
        <v-text-field v-model="val.text" :label="label" clearable />
      </v-col>

      <v-col :cols="noText ? 4 : 2" v-for="k in primaries" :key="k">
        <v-select v-model="val[k]" :label="heading[k].text" :items="heading[k].items" clearable />
      </v-col>
    </v-row>

    <v-expand-transition>
      <div v-if="showAdvanced">
        <v-row>
          <v-col v-for="k in secondaries" :key="k">
            <v-select
              v-model="val[k]"
              :label="heading[k].text"
              :items="heading[k].items"
              clearable
            />
          </v-col>
          <v-col class="my-n3">
            <m-pages-rounded-field v-model="val.rounded" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field v-model="val.src" label="Background Image" placeholder="https://" />
          </v-col>
          <v-col>
            <m-pages-background-size-field
              v-model="val[backgroundSize.value]"
              :label="backgroundSize.text"
            />
          </v-col>
          <v-col>
            <m-pages-background-position-field
              v-model="val[backgroundPosition.value]"
              :label="backgroundPosition.text"
            />
          </v-col>
        </v-row>

        <!-- Sizes  -->
        <v-row>
          <v-col v-for="size in sizes" :key="size">
            <v-text-field
              v-model="val[size]"
              :label="sizeFields[size].text"
              type="number"
              clearable
            />
          </v-col>
        </v-row>
        <!-- Sliders  -->

        <v-row v-for="(spacing, index) in spacings" :key="`spacing${index}`">
          <v-col v-for="k in spacing" :key="k">
            <m-pages-spacing-slider
              v-model="val[k]"
              :label="heading[k].text"
              :items="heading[k].items"
              :min="heading[k].min"
              :max="heading[k].max"
              thumb-label
            />
          </v-col>
        </v-row>

        <!-- Options  -->
        <v-row class="mt-6">
          <m-color-picker
            v-for="k in colors"
            :key="k"
            v-model="val[k]"
            :label="heading[k].text"
            depressed
            btn-class="mx-2"
          />
          <v-checkbox
            v-for="item in options"
            :key="item"
            v-model="val[item]"
            :label="heading[item].value"
            class="mx-2 mt-0"
          />

          <v-slider
            v-model="val.elevation"
            label="Elevation"
            min="0"
            max="24"
            thumb-label
            class="ml-6"
          />
        </v-row>
      </div>
    </v-expand-transition>

    <v-row align="end" justify="end" class="mt-n8">
      <v-switch v-model="showAdvanced" label="Advanced" class="mx-3" />
    </v-row>
  </div>
</template>

<script>
import model from "../../mixins/model";
import {
  heading,
  backgroundSize,
  backgroundPosition,
  width,
  height,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
} from "../../js/forms/fields";
import numberFields from "../../js/forms/fields";

// import {backgroundSize,backgroundPosition} from
export default {
  props: {
    noText: Boolean,
    label: {
      type: String,
      default: "Heading",
    },
  },
  mixins: [model],
  data() {
    return {
      showAdvanced: false,
      heading,
      backgroundSize,
      backgroundPosition,
      sizeFields: {
        width,
        height,
        maxWidth,
        maxHeight,
        minWidth,
        minHeight,
      },
      primaries: ["textSize", "fontWeight", "textAlign"],
      secondaries: [
        "textDecoration",
        "textTransform",
        "textOpacity",
        // "rounded",
      ],
      // spacings: ["marginY", "paddingX", "paddingY"],
      spacings: [
        ["mt", "mb", "ml", "mr"],
        ["pt", "pb", "pl", "pr"],
      ],
      // spacings: ["mt", "mb", "ml", "mr"],
      sliders: ["elevation"],
      options: ["fontItalic", "inlineFlex", "mxAuto"],
      sizes: [
        "width",
        "height",
        "minWidth",
        "minHeight",
        "maxWidth",
        "maxHeight",
      ],
      colors: ["color", "backgroundColor"],
    };
  },

  // computed: {
  //   fields() {
  //     const items = [];
  //     for (let k in heading) {
  //       items.push(k);
  //     }
  //     return items;
  //   },
  // },
};
</script>
