import { mapGetters } from "vuex";
import { getVideoMin, getVideoProvider } from "@/helpers/video";
import playerOptions from "./player-options";

export default {
  ...mapGetters({
    vuexPauseVideo: "MPages/pauseVideo",
  }),
  showEndedPoster() {
    return this.status == "ended" && this.video.endedPoster ? true : false;
  },

  showPoster() {
    if (!this.video.poster) {
      return false;
    }

    return !["playing"].includes(this.status);
  },

  playerOptions() {
    const options = {
      ...playerOptions,
    };

    if (this.video.noControls) {
      options.controls = ["play-large"];
    }

    if (this.video.noPlayLarge) {
      options.controls.splice(options.controls.indexOf("play-large"), 1);
    }

    return options;
  },

  videoSrc() {
    let src = this.src ? this.src : this.video.src;

    if (!src) {
      return src;
    }

    src = getVideoMin(src);

    if (this.videoProvider == "vimeo") {
      src = `https://player.vimeo.com/video/${src}?loop=false&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media`;
    }

    if (this.videoProvider == "youtube") {
      return `https://www.youtube.com/embed/${src}?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1`;
    }

    return src;
  },

  videoProvider() {
    return getVideoProvider(this.src || this.video.src);
  },

  useIframe() {
    return ["vimeo", "youtube"].includes(this.videoProvider);
  },

  player() {
    return this.$refs.player.player;
  },

  wrapperClass() {
    const value = [];

    if (this.video.border) {
      value.push(`pa-${this.video.border}`);
    }

    if (this.video.inset) {
      value.push(`ma-${this.video.inset}`);
    }

    return value;
  },
};
