export default {
  methods: {
    mergeObj(a = {}, b = {}) {
      // override b with all a
      const value = { ...b };
      const primary = { ...a };
      for (let k in primary) {
        // if (typeof a[k] !== "undefined" && a[k] != "") {
        if (primary[k]) {
          value[k] = primary[k];
        }
      }

      return value;
    },
  },
};
