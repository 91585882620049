export default {
  // alignSelf: "",
  // cols: "",
  // lg: "",
  // md: "",
  // sm: "",
  // xl: "",
  // tag: "", // ??
  // offset: "",
  // offsetLg: "",
  // offsetMd: "",
  // offsetSm: "",
  // offsetXl: "",
  // order: "",
  // orderLg: "",
  // orderMd: "",
  // orderSm: "",
  // orderXl: "",
  // col: {
  m: { t: 0, b: 0, l: 0, r: 0, s: 0, e: 0, x: 0, y: 0, a: 0 },
  p: { t: 0, b: 0, l: 0, r: 0, s: 0, e: 0, x: 0, y: 0, a: 0 },
  elevation: "",
  dark: false,
  // },
  // content: {},
};
