<template>
  <v-text-field
    :label="field.label"
    :placeholder="field.placeholder ? field.placeholder : '*** hidden ***'"
    :outlined="field.outlined"
    :readonly="!show"
    :value="show ? val : ''"
    @input="onInput"
  >
    <v-btn
      @click="show = !show"
      slot="append"
      text
      small
      class="mt-n1 grey--text text--darken-1"
      >{{ show ? "Hide" : "Reveal" }}</v-btn
    >
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    field: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      val: "",
      show: false,
    };
  },

  methods: {
    onInput(v) {
      this.val = v;
      this.$emit("input", v);
    },
  },

  beforeMount() {
    if (this.value) {
      this.val = this.value;
    }
  },

  watch: {
    value(val) {
      if (this.val != val) {
        this.val = val;
      }
    },
  },
};
</script>
