<template>
  <div>
    <m-pages-img :img="block.img" />
  </div>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
