<template>
  <div v-if="ready">
    <m-crumbs />

    <!-- {{page.content}} -->
    <div class="pt-3">
      <page-builder
        v-model="page.content"
        :title="page.name"
        string-format
        :preview-src="previewUrl"
        emit-auth
        @auth="onAuth"
      >
        <template slot="toolbar">
          <v-btn
            color="primary"
            :disabled="savedStr == page.content"
            :loading="saving"
            class="mr-2"
            @click="save"
          >Save</v-btn>
          <v-btn :href="`/${page.slug}`" class="mr-4" depressed target="_blank">View Page</v-btn>
        </template>
      </page-builder>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import pageBuilder from "@/mods/m-pages/PageBuilder";
import { dbEncodeStr, dbDecodeStr } from "@/helpers/db/db-encode";

export default {
  components: {
    pageBuilder,
  },

  data() {
    return {
      ready: false,
      saving: false,
      endpoint: "",
      pageContent: "",
      savedStr: "",
      page: {},
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "home" },
        },
        {
          text: "Pages",
          disabled: false,
          to: { name: "pages" },
        },
        {
          text: "Page " + this.$route.params.id,
          disabled: false,
          href: "#a",
          to: { name: "page", params: { id: this.$route.params.id } },
        },
        {
          text: "Builder",
          disabled: true,
          href: "",
        },
      ],
    };
  },

  computed: {
    previewUrl() {
      let url = window.location.href;
      if (url.indexOf("#") > -1) {
        [url] = url.split("#");
      }
      return `${url}/preview-window`;
    },
  },

  methods: {
    ...mapActions({
      setData: "page/setData",
      clearItem: "page/clearItem",
    }),

    onAuth(value) {
      const auth = { ...value };
      this.page.auth_active = auth.active ? 1 : 0;
      this.page.auth = JSON.stringify(auth);
    },

    async save() {
      this.saving = true;
      const form = { ...this.page };
      delete form.id;
      delete form.identifier;
      delete form.created;
      delete form.updated;

      // form.content = dbEncodeStr(form.content);

      let response = await this.$api.post(
        `admin/pages/${this.$route.params.id}`,
        form
      );

      this.savedStr = this.page.content;

      this.saving = false;
    },
  },

  beforeDestroy() {
    this.clearItem();
  },

  beforeMount() {
    this.clearItem();
    this.endpoint = `admin/pages/${this.$route.params.id}`;

    this.$api.get(this.endpoint).then(({ data }) => {
      if (data.data) {
        this.crumbs[2].text = data.data.name;
        this.page = data.data;
        // if (this.page.content) {
        //   this.page.content = dbDecodeStr(this.page.content);
        // }
      }

      this.savedStr = this.page.content;

      this.ready = true;
    });
  },
};
</script>
