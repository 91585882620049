<template>
  <div ref="hero">
    <!-- <v-sheet tile :dark="block.dark"> -->
    <v-parallax
      v-if="parallax || block.parallax"
      :height="
        block.matchHeight
          ? imageHeight
          : block.height
          ? block.height
          : imageHeight
      "
      :src="block.img.src"
      :alt="block.img.alt"
      :dark="block.dark"
    >
      <slot />
    </v-parallax>
    <v-img
      v-else
      :height="
        block.matchHeight
          ? imageHeight
          : block.height
          ? block.height
          : imageHeight
      "
      :src="block.img.src"
      :alt="block.img.alt"
      :dark="block.dark"
      @load="onImageLoaded"
    >
      <!--  -->
      <slot />
    </v-img>
    <!-- </v-sheet> -->
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      parallax: false,
      imgHeight: 500,
    };
  },
  computed: {
    imageHeight() {
      // if fixed height, set that
      return this.imgHeight;
    },
  },

  methods: {
    onImageLoaded() {
      //onImageLoaded
    },
  },

  mounted() {
    // if match height
    if (this.block.matchHeight) {
      let top = this.$refs.hero.offsetTop;
      let setHeight = window.innerHeight - top;
      this.imgHeight = setHeight;
    } else if (this.block.height) {
      this.imgHeight = this.block.height;
    }
  },
};
</script>
