<template>
  <div>
    <form-builder-panels>
      <template v-slot:header>
        <v-text-field v-model="form.title" label="Title" filled />
        <v-textarea
          rows="3"
          v-model="form.intro"
          label="Introduction"
          filled
          hide-details
        />
      </template>

      <template v-slot:fields>
        <v-row dense class="mx-n7">
          <v-col
            v-for="(field, i) in form.fields"
            :key="field.id"
            :cols="field.cols || 12"
            draggable
            @dragstart="dragstart(i)"
            @dragenter="dragenter(i)"
            @dragend="dragend"
            @drop="drop"
            ondragover="return false"
          >
            <form-builder-drag-spacer v-if="i === dragOver && dragAbove" />

            <form-builder-edit-field
              v-model="form.fields[i]"
              @delete="onDeleteField(i)"
            />

            <form-builder-drag-spacer v-if="i === dragOver && dragBelow" />
          </v-col>
        </v-row>

        <v-divider class="my-6" />
        <form-builder-add-field @add="onAddField" />
      </template>

      <template v-slot:options>
        <v-text-field
          v-model="form.btnText"
          label="Button Text"
          placeholder="Submit"
          hide-details
        />

        <v-row>
          <v-switch
            v-for="opt in options"
            :key="opt.value"
            v-model="form[opt.value]"
            :label="opt.text"
            class="mx-4"
          />
        </v-row>
      </template>

      <temlate v-slot:footer>
        FOOTER
        <v-textarea rows="3" v-model="form.footer" label="Footer" />
      </temlate>

      <template v-slot:success>
        <v-textarea
          v-model="form.successContent"
          label="Success Message"
          hide-details
        ></v-textarea>
      </template>
    </form-builder-panels>
  </div>
</template>

<script>
import arrayMove from 'array-move'
import createField from './models/Field'
import dragMixin from '@/mixins/drag'

export default {
  mixins: [dragMixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  beforeMount() {
    if (this.value) {
      this.form = { ...this.value }
    }
  },

  data() {
    return {
      isPreview: true,
      saving: false,
      watcherReady: false,
      options: [
        {
          text: 'Hide Footer on Success',
          value: 'successNoFooter',
        },
        {
          text: 'Align Left',
          value: 'left',
        },
      ],
      form: {
        successNoFooter: false,
        title: '',
        intro: '',
        footer: '',
        btnText: '',
        successContent: '',
        fields: [
          //   {
          //     id: 'ROscEjWcugXbgtivRZlKq',
          //     type: 'text',
          //     name: 'name',
          //     attrs: {
          //       label: 'Name',
          //     },
          //   },
          //   {
          //     id: 'U2iEWHEyqARRx7W0s8_Wa',
          //     type: 'text',
          //     name: 'email',
          //     attrs: {
          //       label: 'Email',
          //     },
          //   },
          //   {
          //     id: 'Q8iOsee7N9XEoZyTk8FO_',
          //     type: 'text',
          //     name: 'phone',
          //     attrs: {
          //       label: 'Phone',
          //     },
          //   },
          //   {
          //     id: 'MTaklw8Bd6KOa5HVHFn1X',
          //     type: 'text',
          //     name: 'location',
          //     opts: {},
          //     attrs: {
          //       label: 'Location',
          //     },
          //   },
          //   {
          //     id: 'YYbC3xgDL8tXy83iLeTzF',
          //     type: 'text',
          //     name: 'website',
          //     opts: {},
          //     attrs: {
          //       label: 'Website',
          //     },
          //   },
          //   {
          //     id: 'dZceLpyUw4WHQusnge58a',
          //     type: 'text',
          //     name: 'logo',
          //     opts: {},
          //     attrs: {
          //       label: 'Logo',
          //     },
          //   },
          //   {
          //     id: 'pVPCP6yBB-POCFeo0Ad0Y',
          //     type: 'text',
          //     name: 'color',
          //     opts: {},
          //     attrs: {
          //       label: 'Color',
          //     },
          //   },
          //   {
          //     id: '57cIGtVL2vc03XRKLCogQ',
          //     type: 'textarea',
          //     name: 'Notes',
          //     opts: {},
          //     attrs: {
          //       label: 'Notes',
          //     },
          //   },
        ],
      },
    }
  },

  methods: {
    onAddField(field) {
      this.form.fields.push(field)
    },
    onDeleteField(index) {
      this.form.fields.splice(index, 1)
    },
    onDrop(a, b) {
      this.form.fields = arrayMove(this.form.fields, a, b)
    },
  },

  watch: {
    form: {
      handler: function() {
        if (!this.watcherReady) return (this.watcherReady = true)

        this.$emit('input', { ...this.form })
      },
      deep: true,
    },
  },
}
</script>
