<template>
  <v-row>
    <v-col xs="12" sm="7">
      <m-pages-panel title="Video">
        <!-- <v-text-field v-model="val.name" label="Name" /> -->
        <m-pages-video-field v-model="val.src" get-duration @duration="onDuration" />
        <v-text-field v-model="val.poster" label="Image" />
        <v-text-field v-model="val.endedPoster" label="Ended Image" />
        <v-text-field v-model="val.endedPosterLink" label="Ended Link" />

        <v-row>
          <v-switch
            v-for="option in options"
            v-model="val[option.value]"
            :key="option.value"
            :label="option.text"
            :disabled="option.vslActive && !val.vslActive"
            class="mx-4"
          />
        </v-row>
      </m-pages-panel>
    </v-col>
    <v-col>
      <m-pages-panel title="Player">
        <v-slider v-model="val.border" min="0" max="12" label="Border" class="mx-4" thumb-label />
        <v-slider v-model="val.inset" min="0" max="12" label="Inset" class="mx-2" thumb-label />
        <v-slider v-model="val.elevation" min="0" max="24" label="Shadow" class="mx-4" thumb-label />

        <v-row>
          <m-color-picker
            v-model="val.color"
            depressed
            btn-class="mx-4 mt-3"
            label="Color"
            @input="onPlayerColor"
          />
          <v-switch v-model="val.tile" label="Tile" class="mx-4" />
          <v-switch v-model="val.edged" label="Edged" class="mx-4" />

          <v-text-field
            v-model="val.maxWidth"
            label="Max Width"
            type="number"
            min="200"
            max="1280"
            clearable
          />
        </v-row>
      </m-pages-panel>

      <slot name="right-bottom" />
    </v-col>
  </v-row>
</template>

<script>
import model from "../../mixins/model";
// import htmlEditor from "@/components/Tinymce";
import setPlayerColor from "../../helpers/set-player-color";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },

  // components: {
  //   htmlEditor
  // },
  mixins: [model],

  data() {
    return {
      options: [
        {
          text: "Hide Controls",
          value: "noControls",
        },
        {
          text: "No Center Play",
          value: "noPlayLarge",
        },
        {
          text: "Trimmer",
          value: "trimmerActive",
        },
        {
          text: "VSL Model",
          value: "vslActive",
        },
        {
          text: "VSL Play",
          value: "vslPlay",
          vslActive: true,
        },
        {
          text: "VSL Image",
          value: "vslImage",
          vslActive: true,
        },
      ],
    };
  },

  methods: {
    onDuration(value) {
      this.val.duration = value;
      this.$emit("input", { ...this.val });
    },

    onPlayerColor(color) {
      console.log("set player color");
      setPlayerColor(this.id, color);
    },
  },
};
</script>
