const state = () => ({
  items: [],
});

const mutations = {
  SET(state, msg) {
    return state.items.push(msg);
  },

  CLOSE(state, id) {
    const i = state.items.findIndex((a) => a.id === id);
    if (i > -1) {
      state.items.splice(i, 1);
    }
  },

  CLEAR_ALL(state) {
    state.items = [];
  },
};

const getters = {
  data(state) {
    return state.data;
  },
};

const actions = {
  set({ commit, state }, { type, payload }) {
    // create unique id
    let msg = {},
      id = new Date().getTime();

    // set ID

    const exists = state.items.findIndex((a) => a.id === id);

    if (exists > -1) {
      id = id + id * Math.random();
    }

    // compile msg
    if (typeof payload === "string") {
      msg = {
        title: "",
        text: payload,
      };
    } else {
      msg = { ...payload };
    }

    msg.id = id;
    msg.type = type; //

    if (msg.clearPrevious) {
      commit("CLEAR_ALL");
    }

    commit("SET", msg);

    // support custom timeout, or disabled timeout
    if (msg.timeout === null || msg.timeout !== 0) {
      setTimeout(() => {
        commit("CLOSE", id);
      }, msg.timeout || 5000);
    }
  },

  success({ dispatch }, payload) {
    dispatch("set", { type: "success", payload });
  },

  warning({ dispatch }, payload) {
    dispatch("set", { type: "warning", payload });
  },

  error({ dispatch }, payload) {
    dispatch("set", { type: "error", payload });
  },

  info({ dispatch }, payload) {
    dispatch("set", { type: "info", payload });
  },

  close({ commit }, id) {
    commit("CLOSE", id);
  },

  clearAll({ commit }) {
    commit("CLEAR_ALL");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
