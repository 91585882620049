import computed from './computed';
import data from './data';
import events from './events';
import loaders from './loaders';
import methods from './methods';
import props from './props';
import watch from './watch';

export default {
	props,
	data,
	computed,

	watch,
	methods: {
		...events,
		...methods,
		...loaders,
	},

	beforeDestroy() {
		this.player.destroy();
	},
	mounted() {
		this.loadPlayer();
	},
};
