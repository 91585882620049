<template>
  <v-row>
    <v-col>
      <m-pages-panel title="Content">
        <html-editor v-model="val.content.html" flat />

        {{ val }}
      </m-pages-panel>
    </v-col>
  </v-row>
</template>

<script>
import model from '../../mixins/model'
import htmlEditor from '@/components/Tinymce'
export default {
  components: {
    htmlEditor,
  },
  mixins: [model],
}
</script>
