import fields from "./fields";

export default {
  form: {
    img: {},
  },
  groups: [
    {
      // title: "Options",
      fields: [
        {
          fields: [
            {
              text: "Button Text",
              value: "text",
              cols: 6,
            },
            {
              text: "Icon",
              value: "icon",
              cols: 3,
            },
            { ...fields.buttonSize, cols: 3 },
            {
              text: "Image Src",
              value: "src",
              cols: 12,
              placeholder: "https://",
              requires: "image",
            },
            {
              text: "Link Type",
              value: "to_name",
              component: "m-pages-to-name-field",
            },
            {
              text: "Section",
              value: "to_section",
              component: "m-pages-block-list-field",
              requires: { to_name: "dom" },
            },
            {
              text: "Link Value",
              value: "to_value",
              requiresNot: { to_name: "dom" },
              // component: "m-pages-to-value-field",
            },
            {
              text: "Target",
              value: "target",
              component: "m-pages-link-target-field",
            },
          ],
        },
        {
          requires: "advanced",
          fields: [
            { ...fields.width, min: 50 },
            fields.height,
            fields.textSize,
            fields.fontWeight,
          ],
        },
        {
          requires: "advanced",
          fields: [
            fields.rounded,
            {
              ...fields.elevation,
              cols: 5,
            },
          ],
        },
        {
          requires: "advanced",
          itemCols: 3,
          fields: fields.margins,
        },
        {
          itemCols: 3,
          fields: [
            {
              text: "Image",
              value: "image",
              type: "switch",
            },
            // {
            //   text: "Logo",
            //   value: "useLogo",
            //   type: "switch",
            // },
            // {
            //   text: "Avatar",
            //   value: "useAvatar",
            //   type: "switch",
            // },
            fields.outlined,
            fields.depressed,
            fields.isText,
            fields.dark,
            { ...fields.light, requires: "advanced" },
            { ...fields.mxAuto, requires: "advanced" },
            fields.advanced,
          ],
        },
        {
          requires: "advanced",
          justify: "start",
          // align: "start",
          itemClass: "shrink",
          fields: [
            { ...fields.authenticated, class: "shrink" },
            fields.unAuthenticated,
          ],
        },
        {
          fields: [fields.colorPicker],
        },
      ],
    },
  ],
};
