<template>
  <div>
    <m-subtitle :loading="sorting || loading" refresh @refresh="getMenu"
      >Left Menu
    </m-subtitle>

    <m-draggable
      :items="items"
      :item-text="
        (a) => {
          return a.text || a.name;
        }
      "
      @onSort="onSort"
    />
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      sorting: false,
      items: [],
    };
  },

  watch: {
    placement(v) {
      if (!v) {
        return (this.items = []);
      }

      this.getMenu();
    },
  },

  methods: {
    async onSort() {
      this.sorting = true;

      this.items.forEach(async (item, index) => {
        const form = {
          order: index + 1,
        };
        this.$api.post(`admin/menus/${item.id}`, form);
      });

      this.sorting = false;
    },

    async getMenu() {
      this.loading = true;

      const { data } = await this.$api.get(
        `admin/menus?placement=${this.placement}`
      );

      this.items = data.data;

      this.loading = false;
    },
  },

  //
  beforeMount() {
    if (this.placement) {
      this.getMenu();
    }
  },
};
</script>
