<template>
	<v-row no-gutters>
		<v-col>
			<app-autocomplete
				v-model="productId"
				label="Product"
				endpoint="admin/products"
				item-text="name"
				item-value="id"
				outlined
				hide-details
				clearable
				class="mx-2"
				@input="onProduct"
			/>
		</v-col>
		<v-col>
			<app-autocomplete
				v-if="!refreshingAddonField"
				v-model="itemId"
				:label="`${group.text} Addon`"
				:endpoint="addonFieldEndpoint"
				item-text="name"
				item-value="id"
				outlined
				hide-details
				clearable
				class="mx-2"
				@input="onAddon"
			/>
		</v-col>
	</v-row>
</template>

<script>
import appAutocomplete from '@/components/Autocomplete'

export default {
	components: {
		appAutocomplete,
	},
	props: {
		group: {
			type: Object,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			refreshingAddonField: false,
			productId: '',
			itemId: '',
			sendEmail: false,
			loading: false,
		}
	},

	computed: {
		addonFieldEndpoint() {
			if (this.productId) {
				return `admin/products/${this.productId}/addons`
			}
			return 'admin/addons'
		},
	},

	methods: {
		onAddon(value) {
			console.log('onAddon', value)
			this.$emit('input', [value])
		},
		onProduct() {
			this.refreshingAddonField = true
			setTimeout(() => {
				this.refreshingAddonField = false
			})
		},
	},
}
</script>
