<template>
  <m-pages-action-banner-container
    v-if="item"
    :is-mobile="isMobile && !isMobilePreview"
    :collapsed="collapsed"
    :left="item && item.left"
    :top="item && item.top"
    :no-fixed="noFixed || isMobilePreview || isBuilder"
    :force-fixed="forceFixed"
    @fixed="isFixed = $event"
  >
    <!-- item: {{ item }} -->
    <v-btn
      v-if="isFixed && collapsed"
      fab
      class="mx-auto"
      :color="item.buttonColor"
      dark
      @click="collapsed = false"
    >
      <v-icon v-text="'phone'" size="36" />
    </v-btn>

    <v-card
      v-if="!collapsed || !isFixed"
      :max-width="isMobilePreview ? 350 : ''"
      class="mx-auto"
      :elevation="item.elevation"
      :color="item.color"
      :dark="item.dark"
    >
      <v-row dense align="center" justify="start">
        <v-avatar
          v-if="item.image"
          size="57"
          class="ml-n4 my-n1 mr-3"
          :tile="item.imageTile"
          :class="item.imageTile ? 'm-rounded-right' : ''"
        >
          <v-img class="ma-3" :src="item.image" />
        </v-avatar>

        <div class="mr-3">
          <div class="font-weight-bold mb-n2">
            {{ isMobile && item.mobileTitle ? item.mobileTitle : item.title }}
          </div>
          <div class="subtitle-1 mb-n2">
            {{ isMobile && item.mobileText ? item.mobileText : item.text }}
          </div>
          <div class="caption grey--text">
            {{
              isMobile && item.mobileFooter ? item.mobileFooter : item.footer
            }}
          </div>
        </div>
        <div v-if="isMobile || item.showButtonOnDesktop" class="pr-3">
          <m-pages-button :item="{ ...item.button, color: item.buttonColor }">
            <v-icon v-text="item.buttonIcon" class="mr-1" />
            {{
              item.button && item.button.text ? item.button.text : "Button Text"
            }}
          </m-pages-button>
        </div>
      </v-row>

      <v-btn
        v-if="isFixed"
        icon
        absolute
        right
        top
        small
        :color="item.buttonColor"
        class="mt-n8 mr-n8"
        @click="collapsed = !collapsed"
      >
        <v-icon>cancel</v-icon>
      </v-btn>
    </v-card>
  </m-pages-action-banner-container>
</template>

<script>
import onResize from "../../mixins/on-resize";
import linkMixin from "../../mixins/on-link";
import { mapGetters } from "vuex";
export default {
  props: {
    // isMobile: Boolean,
    isMobilePreview: Boolean,
    noFixed: Boolean,
    isMobile: {
      type: Boolean,
      default() {
        return this.$isMobile;
      },
    },
  },

  mixins: [linkMixin], // onResize,

  data() {
    return {
      forceFixed: false,
      collapsed: false,
      isFixed: false,
      // isMobile: false,
    };
  },

  computed: {
    ...mapGetters({
      isBuilder: "MPages/isBuilder",
      setup: "MPages/parsedSetup",
      theme: "MPages/theme",
      item: "MPages/actionBanner",
    }),
    itemx() {
      return this.setup.actionBanner;
    },
  },

  // methods: {
  //   onResize({ target }) {
  //     this.forceFixed = target.innerWidth < 801;
  //     this.isMobile = target.innerWidth < 601;
  //   },
  // },

  // mounted() {
  //   this.isMobile = window.innerWidth < 801;
  //   console.log("action banner");
  // },
};
</script>
