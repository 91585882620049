<template>
  <div>
    <v-card>
      <v-container>
        <v-expand-transition>
          <v-toolbar v-if="more" flat tile>
            <slot name="toolbar" />
          </v-toolbar>
        </v-expand-transition>
        <v-tabs v-model="tab" right>
          <v-row no-gutters class="px-4 mt-n2">
            <v-btn icon large class="mt-2 mr-3" @click="more = !more">
              <v-icon>menu</v-icon>
            </v-btn>
            <slot name="top" />
          </v-row>
          <!-- <v-row dense justify="space-between">
            <v-col class="pt-3">
              <div class="text-h5 grey--text text-capitalize">
                {{ title }}
              </div>
            </v-col>
            <v-col class="mt-n3 ml-n4">
              <slot name="top" />
            </v-col>
          </v-row> -->
          <v-tab
            v-for="item in tabItems"
            :key="item.value"
            v-text="item.text"
          />

          <v-tab-item v-for="item in tabItems" :key="`${item.value}_item`">
            <slot :name="item.value" />
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Block",
    },
  },
  data() {
    return {
      tab: 2,
      more: false,
      tabItems: [
        {
          text: "Wrapper",
          value: "wrapper",
        },
        {
          text: "Container",
          value: "container",
        },
        {
          text: "Content",
          value: "content",
        },
        {
          text: "Schedule",
          value: "schedule",
          icon: "schedule",
        },
        {
          text: "Auth",
          value: "auth",
          icon: "lock",
        },
      ],
    };
  },
};
</script>
