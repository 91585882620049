<template>
  <div>
    <v-text-field
      v-model="pass"
      @input="onInput"
      autofocus
      :label="label"
      outlined
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'visibility' : 'visibility_off'"
      @click:append="show = !show"
    />

    <!-- 
            :append-icon="showPass ? 'visibility' : 'visibility_off'"
            :type="showPass ? 'text' : 'password'" -->
    <v-progress-linear
      :color="strengh.color"
      :value="strengh.value"
      class="mt-n5"
    />
    <div class="mb-6 caption">{{ strengh.hint }}</div>
  </div>
</template>

<script>
import { passStrength } from "../helpers";
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "New Password",
    },
    min: {
      type: Number,
      default: 50,
    },
  },

  data() {
    return {
      show: false,
      pass: "",
    };
  },

  beforeMount() {
    if (this.value) {
      this.pass = this.value;
    }
  },

  computed: {
    strengh() {
      return passStrength(this.pass);
    },
  },

  methods: {
    onInput() {
      if (this.strengh.value >= this.min) {
        this.$emit("input", this.pass);
      } else {
        this.$emit("input", "");
      }
    },
  },
};
</script>
