export default {
	controls: [
		'play-large',
		'play',
		'progress',
		'current-time',
		'mute',
		'volume',
		'captions',
		'settings',
		'pip',
		'airplay',
		'fullscreen',
	],
	muted: false,
};
