<template>
	<v-tabs>
		<v-tab>Button</v-tab>
		<v-tab>Text</v-tab>
		<v-tab>Heading</v-tab>
		<v-tab>Subheading</v-tab>
		<v-tab>Footer</v-tab>
		<v-tab>Tools</v-tab>

		<v-tabs-slider />

		<v-tab-item>
			<div class="mb-12 mt-4">
				<m-pages-button-form-v3 v-model="val.button" />
			</div>
		</v-tab-item>

		<v-tab-item>
			<m-pages-heading-form v-model="val.text" label="Button Text" />
		</v-tab-item>

		<v-tab-item>
			<m-pages-heading-form v-model="val.heading" />
		</v-tab-item>

		<v-tab-item>
			<m-pages-heading-form v-model="val.subheading" label="Subheading" />
		</v-tab-item>

		<v-tab-item>
			<m-pages-heading-form v-model="val.footer" label="Footer" />
		</v-tab-item>

		<v-tab-item>
			<v-row>
				<v-col>
					<m-pages-panel title="Buy Button">
						<v-row>
							<v-col v-for="option in bbTools" :key="option.value" cols="6" class="my-n3">
								<v-checkbox v-model="val[option.value]" :label="option.text" hide-details />
							</v-col>
						</v-row>
					</m-pages-panel>
				</v-col>

				<v-col>
					<m-pages-panel title="J-Box" :hide-content="!val.jBox">
						<template slot="toolbar">
							<v-spacer />
							<v-switch v-model="val.jBox" label="Active" hide-details />
						</template>
						<br />
						<!-- -->
						<v-slider v-model="val.jBoxWidth" label="J-Box Width" min="400" max="1000" thumb-label />

						<v-row class="mx-0">
							<v-slider
								v-model="val.jBoxBorderWidth"
								label="J-Box Border Width"
								min="0"
								max="24"
								thumb-label
							/>
							<v-slider v-model="val.jBoxElevation" label="J-Box Shadow" min="0" max="24" thumb-label />
						</v-row>
						<v-row>
							<m-color-picker v-model="val.jBoxColor" label="J-Box Color" btn-class="mx-3" />

							<m-color-picker v-model="val.jBoxBorderColor" label="J-Box Color" btn-class="mr-2" />

							<v-checkbox
								v-for="option in jBoxOptions"
								:key="option.value"
								v-model="val[option.value]"
								:label="option.text"
								:disabled="option.jBox && !val.jBox"
								class="mx-3 mt-1"
							/>
						</v-row>
					</m-pages-panel>
				</v-col>
			</v-row>
		</v-tab-item>
	</v-tabs>
</template>

<script>
import model from '../../mixins/model'
import appAutocomplete from '@/components/Autocomplete'
// import htmlEditor from "@/components/Tinymce";
export default {
	components: {
		appAutocomplete,
	},
	mixins: [model],

	data() {
		return {
			bbTools: [
				{
					text: 'Sub Arrow',
					value: 'subheadingArrow',
				},
				{
					text: 'Cards',
					value: 'cards',
				},
				{
					text: 'PayPal',
					value: 'paypal',
				},
				{
					text: 'Secure',
					value: 'secureNotice',
				},
			],
			jBoxOptions: [
				{
					text: 'J-Box Dark',
					value: 'jBoxDark',
					jBox: true,
				},
				{
					text: 'J-Box Border',
					value: 'jBoxBorder',
					jBox: true,
				},
			],
		}
	},
}
</script>
