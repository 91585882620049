<template>
	<v-hover v-slot:default="{ hover }" :disabled="noHover">
		<v-card
			:dark="dark"
			class="mx-auto text-left"
			maxx-width="344"
			outlined
			flat
			:elevation="hover && !active ? 9 : 2"
			@click="onClick"
		>
			<v-card tile flat v-if="!noImage" class="text-center" height="150" color="grey lighten-4">
				<v-img v-if="image" :src="image" height="150" href="#" />
				<v-icon v-else size="120" color="grey lighten-2" class="mt-4" v-text="imageIcon" />
				<!-- <v-icon style="position:absolute;right:20px;top:20px;">
          check_circle
        </v-icon>-->
			</v-card>
			<v-progress-linear
				v-if="!noProgress"
				:value="progress"
				absolute
				class="mt-n1"
				background-opacity="0"
				:color="active || hover ? color || 'red' : 'grey darken-2'"
				style="opacity:0.4"
			/>

			<!-- current time  -->
			<v-progress-linear
				v-if="!noProgress"
				:value="currentProgress"
				class="mt-n1"
				background-opacity="0"
				:color="active || hover ? color || 'red' : 'grey'"
				style="z-index:100"
			/>
			<div v-if="duration" class="caption text-right grey--text mr-4 mb-n4" :class="!title ? 'mt-n6' : 'mt-1'">
				<span v-if="currentTime && currentTime > -1">{{ currentTime | prettyTime }}</span>
				<span v-if="currentTime && currentTime > -1 && !noDuration && duration" v-text="' / '" />
				<span v-if="!noDuration">{{ duration | prettyTime }}</span>
			</div>
			<v-card-title v-if="title" v-text="title" />

			<v-card-subtitle v-if="subtitle" v-text="subtitle" class="pb-0 pb-4" />

			<v-sheet
				v-if="active"
				:style="`position:absolute;left:0px;top:0px;width:100%`"
				class="text-center caption white--text"
				tile
				color="red"
				>NOW PLAYING
			</v-sheet>
		</v-card>
	</v-hover>
</template>

<script>
import prettyTime from '../mixins/pretty-time';
export default {
	props: {
		dark: Boolean,
		active: Boolean,
		hasActive: Boolean,
		noHover: Boolean,
		noImage: Boolean,
		noPlay: Boolean,
		noProgress: Boolean,
		noDuration: Boolean,
		currentTime: {
			type: Number,
			default: 0,
		},
		progress: {
			type: Number,
			default: 0,
		},
		duration: {
			type: [String, Number],
			required: false,
		},
		title: {
			type: String,
			required: false,
		},
		subtitle: {
			type: String,
			required: false,
		},
		image: {
			type: String,
			required: false,
		},
		imageIcon: {
			type: String,
			default: 'ondemand_video',
		},
		color: {
			type: String,
			default: '',
		},
	},

	mixins: [prettyTime],

	computed: {
		currentProgress() {
			if (!this.duration || !this.currentTime) {
				return 0;
			}

			return (this.currentTime / this.duration) * 100;
		},
	},

	methods: {
		onClick() {
			this.$emit('click');
		},
	},
};
</script>
