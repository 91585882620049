export default {
  form: {
    img: {},
  },
  groups: [
    {
      // title: "UNSET SETUP",
      fields: [
        {},
        {
          text: "Title",
          value: "title",
        },

        {
          value: "img",
          // requires: "useAvatar",
          items: [
            {
              text: "Image",
              value: "src",
              placeholder: "https://",
            },
          ],
        },
      ],
    },
    {
      title: "Options",
      fields: [
        {
          justify: "start",
          itemCols: 2,
          items: [
            {
              text: "Dark",
              value: "dark",
              type: "switch",
            },
            {
              text: "Light",
              value: "light",
              type: "switch",
            },
            {
              component: "m-color-picker",
              text: "Color",
              value: "color",
              class: "mt-3",
            },
          ],
        },
      ],
    },
  ],
};
