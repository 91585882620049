<template>
  <v-list-group sub-group no-actionx>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ group.text }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <m-menu-item
      v-for="(item, index) in group.children"
      :key="index"
      :item="item"
    />
  </v-list-group>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
};
</script>
