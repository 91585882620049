<template>
  <v-card width="650">
    <v-row no-gutters justify="space-between">
      <v-col>
        <v-card-title>{{ val.name || "Menu Item" }}</v-card-title>
      </v-col>
      <v-col class="shrink pr-6">
        <v-switch v-model="val.active" label="Active" hide-details />
      </v-col>
      <!-- <v-col class="shrink pr-6">
        <v-switch v-model="val.auth_active" label="Auth" />
      </v-col>-->
    </v-row>

    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>Admin</v-tab>
        <v-tab>Link</v-tab>
        <v-tab>Auth</v-tab>

        <v-tab-item>
          <br />
          <v-text-field v-model="val.name" label="Name" />
          <v-textarea v-model="val.description" label="Description" rows="3" />
        </v-tab-item>

        <v-tab-item>
          <br />
          <v-text-field v-model="val.text" label="Text" />
          <v-text-field v-model="val.alt" label="Alt" />
          <v-text-field v-model="val.icon" label="Icon" />

          <v-row>
            <v-col>
              <v-select v-model="val.to_name" label="To Name" :items="linkItems.toNameItems" />
            </v-col>
            <v-col>
              <v-text-field
                v-model="val.to_value"
                label="To Value"
                :append-icon="val.to_name == 'url' ? 'launch' : ''"
                @click:append="onToValue"
              />
            </v-col>
          </v-row>

          <v-select v-model="val.target" label="Target" :items="linkItems.targetItems" clearable />

          <v-row class="mb-3">
            <v-switch v-model="val.highlight" label="Highlight" class="mx-3" />
            <v-switch
              v-model="val.exact"
              label="Exact"
              :disabled="val.to_name != 'route'"
              class="mx-3"
            />
            <v-switch
              v-model="val.no_follow"
              label="No Follow"
              :disabled="val.to_name != 'route'"
              class="mx-3"
            />
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <br />
          <m-pages-auth-form v-model="val.auth" :active="val.auth_active">
            <v-switch slot="active" v-model="val.auth_active" label="Auth Active" hide-details />
          </m-pages-auth-form>
        </v-tab-item>
      </v-tabs>

      <!--  -->
      <v-btn color="primary" @click="submit" v-text="`${itemId ? 'Save' : 'Add'} Menu Item`" />

      <!-- <m-raw-displayer :value="val.auth" title="Auth" /> -->
    </v-card-text>
  </v-card>
</template>

<script>
import linkItems from "@/mixins/fields/link-items";
export default {
  props: {
    itemId: {
      type: [String, Number],
      default: "",
    },

    // used for create
    menuId: {
      type: [String, Number],
      default() {
        return this.$route.params.id;
      },
    },

    endpoint: {
      type: String,
      default() {
        return `/admin/menu-items`;
      },
    },
  },

  watch: {
    itemId(val) {
      // empty
      this.val = {};

      if (val) {
        this.getItem();
      }
    },
  },

  data() {
    return {
      loading: false,
      tab: 1,
      val: {},
      linkItems,
    };
  },

  computed: {
    getEndpoint() {
      let url = this.endpoint;
      if (this.itemId) {
        url += `/${this.itemId}`;
      }
      return url;
    },
  },

  methods: {
    onToValue() {
      const name = this.val.to_name,
        value = this.val.to_value;

      if (!name || !value) {
        return;
      }

      if (name == "url") {
        return window.open(value);
      }

      if (name == "route") {
        const route = this.$router.options.routes.find((a) => a.name == name);
        if (!route) {
          return;
        }

        console.log("open route");
        return;
      }
    },

    async getItem() {
      const { data } = await this.$api.get(this.getEndpoint);
      this.val = { ...data.data };
    },

    async submit() {
      const form = { ...this.val };

      if (!this.itemId) {
        form.menu_id = this.menuId;
      }

      const { data } = await this.$api.post(this.getEndpoint, form);

      if (!this.itemId) {
        this.$emit("created", data.data);
      } else {
        this.$emit("updated", data.data);
      }
    },
  },

  beforeMount() {
    if (this.itemId) {
      this.getItem();
    }
  },
};
</script>
