<template>
  <div class="text-center">
    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on }">
        <v-btn x-large color="primary" icon dark v-on="on">
          <v-avatar v-if="user && user.avatar">
            <v-img :src="user.avatar | avatar"></v-img>
          </v-avatar>
          <v-icon v-else x-large>{{noAvatarIcon}}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- Dropdown Items  -->

        <template v-if="items">
          <v-list-item v-for="item in items" :key="item.name" exact :to="{name: item.name}">
            <v-list-item-icon class="mr-3">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title || item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- Logout  -->
        <v-list-item @click="$auth.logout()">
          <v-list-item-icon class="mr-3">
            <v-icon>{{ logoutIcon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ logoutText }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    logoutText: {
      type: String,
      default: "Log Out"
    },
    logoutIcon: {
      type: String,
      default: "exit_to_app"
    },
    noAvatarIcon: {
      type: String,
      default: "account_circle"
    }
  },
  data() {
    return {
      items: []
    };
  },
  beforeMount() {
    // get routes from router
    if (this.$router && this.$router.options.routes) {
      this.$router.options.routes.forEach(item => {
        if (item.meta && item.meta.authUserMenu) {
          this.items.push({
            name: item.name,
            path: item.path,
            title: item.meta.authTitle || item.meta.title,
            icon: item.meta.authIcon || item.meta.icon
          });
        }
      });
    }
  },

  filters: {
    avatar(url) {
      return `https://www.gravatar.com/avatar/${url}?d=mp`;
    }
  }
};
</script>