import Plyr from 'plyr';

export default {
	loadPlayer() {
		// create player
		// const listeners = null;
		const options = {
			title: 'Video Gallery Player',
			debug: false,
			// muted: this.muted,
			// poster: this.posterSrc,
			controls: [
				// 'play-large',
				'play',
				'progress',
				'current-time',
				'mute',
				'volume',
				'captions',
				'settings',
				'pip',
				'airplay',
				'fullscreen',
			],
			settings: ['captions', 'quality', 'speed', 'loop'],
			autoplay: false,
			autopause: true,
			// listeners,
			storage: { enbaled: true, key: 'galleryplayer' },
			// source: this.source,
		};

		const player = new Plyr(`#${this.id}`, options);

		// player.source = { ...this.source };

		// que events
		player.on('ready', this.onReady);
		player.on('play', this.onPlay);
		player.on('pause', this.onPause);
		player.on('end', this.onEnded);
		player.on('timeupdate', this.onTimeUpdate);
		player.on('seeking', () => {
			this.status = 'seeking';
		});
		player.on('seeked', this.onSeeked);
		player.on('ended', this.onEnded);
		// player.on('canplay', this.onCanPlay);
		// player.on('canplay', (event) => {
		// 	// 	player.currentTime = 40;
		// 	this.onCanPlay(player, event);
		// });

		this.player = player;

		this.loadSource();
	},

	loadSource() {
		let video;

		if (this.video) {
			video = { ...this.video };
		}

		const item = {
			src: video.src || '', //'https://player.vimeo.com/external/463478071.hd.mp4?s=e8886871244f0727ac30a64b063e4e91f0361ae6&profile_id=174',
			poster: video.poster || '', //'https://i.vimeocdn.com/video/967024030.jpg',
			// type: 'video/mp4',
			// size: video.size || 720,
		};

		const provider = !isNaN(video.src) ? 'vimeo' : video.src && video.src.length == 11 ? 'youtube' : false;

		if (provider) {
			item.provider = provider;
		} else {
			item.type = item.type ? item.type : 'video/mp4';
			item.size = video.size || 720;
		}

		this.provider = provider;

		const source = {
			type: video.type || 'video',
			title: video.title,
			sources: [item],
		};

		// check for start

		this.player.source = source;
	},
};
