import {
  dark,
  light,
  dense,
  extended,
  collapse,
  flat,
  prominent,
  short,
  elevation,
  color,
  // textColor,
} from "../fields";

export default {
  groups: [
    {
      title: "Toolbar Form",
      fields: [
        {
          fields: [
            dark,
            light,
            dense,
            extended,
            collapse,
            flat,
            prominent,
            short,
            elevation,
            { ...color, text: "Toolbar" },
            // { ...textColor, text: "Links" },
          ],
        },
        {
          fields: [],
        },
      ],
    },
  ],
};
