<template>
  <v-sheet
    :dark="item.dark"
    :light="item.light"
    :color="item.color"
    :flat="item.flat"
    :width="item.width"
    :min-width="item.minWidth"
    :max-width="item.maxWidth"
    :elevation="item.elevation ? item.elevation : ''"
    :rounded="item.rounded ? item.rounded : rounded ? rounded : ''"
    class="mx-auto text-center"
    :class="itemClass"
    :style="itemStyle"
  >
    <m-pages-text :item="item.heading" text-size="title" />
    <m-pages-text :item="item.subheading" text-size="caption" />
  </v-sheet>
</template>

<script>
export default {
  props: {
    rounded: {
      type: [String, Boolean],
      required: false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    itemClass() {
      const classList = [];

      ["px"].forEach((key) => {
        if (typeof this.item[key] != "undefined") {
          classList.push(`${key}-${this.item[key]}`);
        }
      });

      return classList;
    },

    itemStyle() {
      const style = {};

      if (this.item.border) {
        style.border = this.item.border;
      }

      return style;
    },
  },
};
</script>
