<template>
  <v-sheet
    :id="blockId"
    :color="item.color ? item.color : color ? color : ''"
    :dark="dark && !light"
    :light="light"
    :elevation="item.elevation"
    :rounded="item.rounded"
    :src="item.src"
    :tile="!item.rounded"
    :class="itemClass"
    :style="getItemStyle"
  >
    <div
      ref="wrapper"
      :class="
        !noPageSpacingY && theme.contentSpacingY
          ? `py-${theme.contentSpacingY}`
          : ''
      "
    >
      <slot />
    </div>
  </v-sheet>
</template>

<script>
import itemMixin from "../mixins/item-plus";

export default {
  props: {
    sticky: Boolean,
    dark: Boolean,
    light: Boolean,
    noPageSpacingY: Boolean,
    blockId: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      offsetTop: -1,
      isSticky: false,
    };
  },

  mixins: [itemMixin],

  computed: {
    getItemStyle() {
      const style = { ...this.itemStyle };

      if (this.isSticky) {
        style.position = "fixed";
        style.top = "0px";
        style.left = "0px";
        style.width = "100%";
        style.zIndex = 99999;
      }

      return style;
    },
  },

  methods: {
    onScroll() {
      let visible = window.scrollY < this.offsetTop;
      this.isSticky = !visible;
    },
  },

  beforeDestroy() {
    if (this.sticky) {
      window.removeEventListener("scroll", this.onScroll);
    }
  },

  mounted() {
    if (this.sticky && !this.isBuilder) {
      this.offsetTop = this.$refs.wrapper.offsetTop;
      window.addEventListener("scroll", this.onScroll, false);
    }
  },
};
</script>
