<template>
  <list-layout crumbs>
    <ml-table edit delete toggle-active create :create-fields="formFields" endpoint="admin/layouts">
      <template slot="row-actions" slot-scope="{ item }">
        <v-btn icon :to="{ name: 'layout-builder', params: { id: item.id } }">
          <v-icon class="mr-1">build</v-icon>
        </v-btn>
      </template>
    </ml-table>
  </list-layout>
</template>

<script>
// import placementField from "../mixins/fields/placement-field";
// import screenField from "../mixins/fields/screen-field";

import formFields from "./js/form";
export default {
  data() {
    return {
      formFields,
    };
  },
};
</script>
