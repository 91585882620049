<template>
	<div :id="id">
		<m-pages-heading v-if="block.heading && block.heading.text" :item="block.heading" />
		<m-pages-subheading v-if="block.subheading && block.subheading.text" :item="block.subheading" />

		<m-pages-video-gallery-player
			v-if="showPlayer"
			:id="getKey('player')"
			:video="video"
			:autoplay="autoplay"
			:start="start"
			:changed="changed"
			@ready="onPlayerReady"
			@timeUpdate="onPlayerTimeUpdate"
			@play="onPlayerPlay"
			@pause="onPlayerPause"
			@ended="onPlayerEnded"
		/>

		<v-row class="mt-6">
			<v-col
				v-for="(item, i) in items"
				:key="item.id"
				:cols="block.gallery.cols ? block.gallery.cols : 6"
				:sm="block.gallery.sm ? block.gallery.sm : 4"
				:md="block.gallery.md ? block.gallery.md : null"
				:lg="block.gallery.lg ? block.gallery.lg : 3"
				:xl="block.gallery.xl ? block.gallery.xl : null"
			>
				<!-- {{ item }} -->
				<m-pages-video-card
					:active="index == i"
					:has-active="index !== null"
					:title="!block.gallery.noTitle ? item.title || block.gallery.fallbackTitle : ''"
					:subtitle="!block.gallery.noSubtitle ? item.subtitle || block.gallery.groupSubtitle : ''"
					:color="video.color"
					:image="item.poster"
					:no-image="block.gallery.noImage"
					:no-duration="block.gallery.noDuration"
					:no-progress="block.gallery.noProgress"
					:no-hover="block.gallery.noHover"
					:duration="item.duration"
					:progress="item.currentTime"
					:current-time="!block.gallery.noCurrentTime ? item.currentTime : -1"
					:dark="block.gallery.dark"
					@click="play(i)"
				></m-pages-video-card>
				<!-- item.currentTime: {{ item.currentTime }}, {{ items[i].currentTime }} -->
			</v-col>
		</v-row>

		<!-- <m-raw-displayer :value="source" /> -->
	</div>
</template>

<script>
import js from './video-gallery';
export default js;
</script>
