import textAlign from "./align";
import textSizes from "./text-size";
import textDecoration from "./text-decoration";
import textTransform from "./text-transform";
import fontWeight from "./font-weight";
import textOpacity from "./text-opacity";
import rounded from "./rounded";

export default [
  {
    text: "Size",
    value: "textSize",
    type: "select",
    items: [...textSizes],
    clearable: true,
    // requires: "advanced",
    // cols: 2,
  },
  {
    text: "Font Weight",
    value: "fontWeight",
    type: "select",
    items: [...fontWeight],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  {
    text: "Text Align",
    value: "textAlign",
    type: "select",
    items: [...textAlign],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  {
    text: "Decoration",
    value: "textDecoration",
    type: "select",
    items: [...textDecoration],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  {
    text: "Transform",
    value: "textTransform",
    type: "select",
    items: [...textTransform],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  {
    text: "Text Opacity",
    value: "textOpacity",
    type: "select",
    items: [...textOpacity],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  {
    text: "Rounded",
    value: "rounded",
    type: "select",
    items: [...rounded],
    clearable: true,
    requires: "advanced",
    // cols: 2,
  },
  {
    text: "Margin X",
    value: "marginX",
    type: "slider",
    min: -12,
    max: 12,
    cols: 3,
    requires: "advanced",
  },
  {
    text: "Margin Y",
    value: "marginY",
    type: "slider",
    min: -12,
    max: 12,
    cols: 3,
    requires: "advanced",
  },
  {
    text: "Padding X",
    value: "paddingX",
    type: "slider",
    min: 0, //-12,
    max: 12,
    cols: 3,
    requires: "advanced",
  },
  {
    text: "Padding Y",
    value: "paddingY",
    type: "slider",
    min: 0, //-12,
    max: 12,
    // cols: 3,
    requires: "advanced",
  },
  {
    text: "Shadow",
    value: "elevation",
    type: "slider",
    min: 0, //-12,
    max: 24,
    cols: 3,
    requires: "advanced",
  },
  {
    text: "Italic",
    value: "fontItalic",
    type: "switch",
    class: "shrink",
    requires: "advanced",
  },
  // {
  //   text: "Flex",
  //   value: "flex",
  //   type: "switch",
  //   requires: "advanced",
  // },
  {
    text: "Inline Flex",
    value: "inlineFlex",
    type: "switch",
    requires: "advanced",
  },
  {
    type: "color",
    text: "Text Color",
    value: "color",
    isText: true,
    class: "shrink",
    requires: "advanced",
  },
  {
    type: "color",
    text: "Background Color",
    value: "backgroundColor",
    isText: true,
    class: "shrink",
    requires: "advanced",
  },
];

// import camelToText from "../../../helpers/camel-to-text";

// const items = [
//   {
//     text: "Background Image",
//     value: "src",
//     placeholder: "https://",
//   },
// ];

// const fields = {};

// items.forEach((value) => {
//   if (typeof value == "object") {
//     fields[value.value] = {
//       type: "text",
//       text: camelToText(value.value),
//       ...value,
//     };
//   } else {
//     fields[value] = {
//       type: "text",
//       text: camelToText(value),
//       value,
//     };
//   }
// });

// export default fields;
