<template>
  <div style="position:relative;z-index:5">
    <v-sheet class="elevation-4">
      <v-toolbar dense class="text-center" flat>
        <v-btn outlined>
          <v-icon class="mr-1">add_circle</v-icon>
          add new block
        </v-btn>
        <v-spacer />
        <editor-slider v-model="block.container.elevation" label="Shadow" class="mt-5 mx-2" :min="0" />

        <v-switch class="mt-5 mx-2" label="Expanded" v-model="block.container.fluid" />

        <v-switch class="mt-5 mx-2" label="Dark" v-model="block.container.dark" />

        <v-dialog v-model="textColor" width="300" hide-overlay>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>color_lens</v-icon>
            </v-btn>
          </template>
          <editor-panel title="Text Color" edge>
            <v-color-picker v-model="block.container.textColor" tile flat show-swatches />
          </editor-panel>
        </v-dialog>

        <v-dialog v-model="color" width="300" hide-overlay>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>format_paint</v-icon>
            </v-btn>
          </template>
          <editor-panel title="Background Color" edge>
            <v-color-picker v-model="block.container.color" tile flat show-swatches />
          </editor-panel>
        </v-dialog>

        <v-btn icon class="ml-4" @click="more = !more">
          <v-icon>menu</v-icon>
        </v-btn>
        <v-btn icon @click="onClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-expand-transition>
        <v-container v-if="more">
          <v-row>
            <v-col>
              <slider-group title="Margin" value="m" :min="-12" />
            </v-col>
            <v-col>
              <slider-group title="Padding" value="p" />
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>

      <v-fade-transition>
        <v-row v-if="over">
          <v-col class="mx-auto shrink">
            <v-btn outlined>
              <v-icon class="mr-1">add_circle</v-icon>
              add new block
            </v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-sheet>

    <!-- <v-card tile flat>
      <v-card-text>
        <editor-slider v-model="block.container.elevation" label="Shadow" :min="0" />
      </v-card-text>
    </v-card>
    Shadow Fix 
    <div class="elevation-4" style="height:5px;" /> -->
  </div>
</template>

<script>
import SliderGroup from "./form/SliderGroup";
import { mapGetters } from "vuex";

export default {
  components: {
    SliderGroup,
  },

  props: {
    over: Boolean,
    index: Number,
    // block: {
    //   type: Object,
    //   required: true,
    // },
  },
  data() {
    return {
      more: true,
      color: false,
      textColor: false,
    };
  },

  computed: {
    ...mapGetters({
      block: "editor/block",
    }),
    spacingCl() {
      if (!this.block || !this.block.container) {
        return null;
      }

      const cl = [],
        c = this.block.container;
      let n;
      if (c.ma && /(n){1}[0-9]{1,2}/.test(c.ma)) {
        n = c.ma.match(/[0-9]{1,2}/);
        console.log("VALID", n[0]);
        cl.push(`pt-${n[0]}`);
        cl.push(`mb-n${n[0]}`);
      }
      return cl;
    },
  },

  methods: {
    onClose() {
      this.emit("close");
    },
  },
};
</script>
