<template>
  <v-toolbar :title="title" dense flat>
    <v-spacer />
    <slot />

    <v-toolbar-items v-if="!isPartial" class="ml-4">
      <!-- <slot name="toolbar-items" /> -->
      <v-btn
        v-for="item in items"
        :key="item.id"
        text
        :class="value == item.value ? 'white primary--text' : ''"
        @click="$emit('input', item.value)"
      >{{ item.text }}</v-btn>
    </v-toolbar-items>
    <slot name="append" />
  </v-toolbar>
</template>

<script>
export default {
  props: {
    isPartial: Boolean,
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  beforeMount() {
    this.val = this.value;
  },

  data() {
    return {
      val: "",
      items: [
        {
          text: "Setup",
          value: "setup",
        },
        {
          text: "Content",
          value: "builder",
        },
        {
          text: "Preview",
          value: "preview",
        },
        {
          text: "Auth",
          value: "auth",
        },
      ],
    };
  },
};
</script>
