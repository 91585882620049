export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      valReady: false,
      val: [],
    };
  },

  computed: {
    valStr() {
      return JSON.stringify(this.val);
    },
  },

  watch: {
    valStr(str) {
      if (this.valReady) {
        this.$emit("input", JSON.parse(str));
      }
    },
  },

  beforeMount() {
    console.log("value", this.value);
    this.val = [...this.value];
    this.valReady = true;
  },
};
