<template>
  <v-hover v-slot:default="{ hover }" :disabled="!item.hover">
    <v-card
      :dark="item.dark"
      :light="item.light"
      :color="item.color"
      :flat="item.flat || flat"
      :width="item.width"
      :min-width="item.minWidth"
      :max-width="item.maxWidth"
      :elevation="
        hover ? item.hoverElevation || 12 : item.elevation ? item.elevation : ''
      "
      :rounded="item.rounded ? item.rounded : rounded ? rounded : ''"
      class="mx-auto"
      :class="itemClass"
      :style="itemStyle"
    >
      <slot />
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    rounded: {
      type: [String, Boolean],
      required: false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    flat: Boolean,
  },

  computed: {
    itemClass() {
      const classList = [];

      ["px"].forEach((key) => {
        if (typeof this.item[key] != "undefined") {
          classList.push(`${key}-${this.item[key]}`);
        }
      });

      return classList;
    },

    itemStyle() {
      const style = {};

      if (this.item.border) {
        style.border = this.item.border;
      }

      return style;
    },
  },
};
</script>
