import camelToText from "../../../helpers/camel-to-text";

const items = [
  "maxHeight",
  "maxWidth",
  "minHeight",
  "minWidth",
  "height",
  "width",
];

const fields = {};

items.forEach((key) => {
  fields[key] = {
    text: camelToText(key),
    value: key,
    type: "number",
    clearable: true,
  };
});

export default fields;
