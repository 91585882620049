<template>
	<v-hover v-slot:default="{ hover }" :disabled="!item.hover">
		<v-btn
			v-if="(!item.authenticated || authenticated) && (!item.unAuthenticated || !authenticated)"
			:elevation="hover ? item.hover : item.elevation ? item.elevation : ''"
			:dark="withTheme.dark"
			:light="withTheme.light"
			:block="withTheme.block"
			:loading="item.loading || loading || isLoading"
			:disabled="withTheme.disabled || disabled"
			:color="withTheme.color ? withTheme.color : color ? color : ''"
			:outlined="withTheme.outlined"
			:text="item.isText || isText ? true : false"
			:depressed="withTheme.depressed"
			:tile="withTheme.tile"
			:width="item.width"
			:height="item.height"
			:min-width="item.minWidth ? item.minWidth : minWidth ? minWidth : ''"
			:max-width="item.maxWidth"
			:min-height="item.minHeight ? item.minHeight : minHeight ? minHeight : ''"
			:class="itemClass"
			:style="itemStyle"
			:href="item.href"
			:target="item.target"
			:x-small="item.size && item.size == 'x-small'"
			:small="item.size && item.size == 'small'"
			:large="item.size && item.size == 'large'"
			:x-large="item.size && item.size == 'x-large'"
			:rel="item.noFollow || item.no_follow ? 'no-follow' : null"
			:tag="item.isLink ? 'a' : 'button'"
			@click="onClick"
		>
			<v-icon v-if="item.icon" class="mr-1" v-text="item.icon" />

			<!-- <m-pages-download-progress v-if="downloadData.loading" :value="downloadData" /> -->
			<!-- <template v-else> -->
			<slot>
				{{ item.text && typeof item.text == 'string' ? item.text : 'Button Text' }}
			</slot>
			<!-- </template> -->
		</v-btn>
	</v-hover>
</template>

<script>
import { mapGetters } from 'vuex'
import { keys as spacingKeys } from '../js/forms/fields/spacing'

import clickActionMixin from '../mixins/item-click-action'

const camelToDash = (camel) => camel.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase())
export default {
	props: {
		loading: Boolean,
		disabled: Boolean,
		mxAuto: Boolean,
		noTheme: Boolean,
		rounded: {
			type: [String, Boolean],
			required: false,
		},
		minWidth: {
			type: [Number, String],
			required: false,
		},
		minHeight: {
			type: [Number, String],
			required: false,
		},
		isText: Boolean,
		color: {
			type: String,
			default: '',
		},

		item: {
			type: Object,
			default() {
				return {}
			},
		},
	},

	data() {
		return {
			isLoading: false,
		}
	},

	mixins: [clickActionMixin],

	computed: {
		...mapGetters({
			theme: 'MPages/theme',
		}),
		withTheme() {
			if (this.noTheme) {
				return this.item
			}
			return { ...this.theme, dark: this.theme.colorDark, ...this.item }
		},

		to() {
			let route = null

			return route
		},

		href() {
			// if(this.action)
			if (!this.item.to_name || !this.item.to_value) {
				return ''
			}
			const name = this.item.to_name,
				value = this.item.to_value
			let href = ''

			if (name == 'url') {
				return value
			}

			if (name == 'dom') {
				return `#${value}`
			}

			if (name == 'phone') {
				return `tel:${value}`
			}

			return href
		},

		itemClass() {
			const classList = []
			let prefix

				// "px",
			;['rounded', 'fontWeight'].forEach((key) => {
				if (typeof this.item[key] != 'undefined') {
					prefix = camelToDash(key)
					classList.push(`${prefix}-${this.item[key]}`)
				}
			})
			;['textSize'].forEach((key) => {
				if (typeof this.item[key] != 'undefined') {
					classList.push(`text-${this.item[key]}`)
				}
			})

			if (this.item.mxAuto || this.mxAuto) {
				classList.push('mx-auto')
			}

			spacingKeys.forEach((k) => {
				if (this.item[k]) {
					classList.push(`${k}-${this.item[k]}`)
				}
			})

			return classList
		},
		itemStyle() {
			const style = {}

			if (this.item.textColor) {
				style.textColor = this.item.textColor
			}

			return style
		},
	},

	methods: {
		onClick(e) {
			// version 2

			if (this.item.clickAction) {
				return this.clickAction(e)
			}

			// version 1.
			const name = this.item.to_name || ''
			let value = this.item.to_value || ''

			if (name != 'url') {
				e.preventDefault()
			}

			if (name == 'dom') {
				if (this.item.section) {
					value = this.item.section
				}
				this.$vuetify.goTo(`#${value}`, {
					offset: 60,
				})
				setTimeout(() => {
					this.$vuetify.goTo(`#${value}`, {
						offset: 60,
					})
				}, 300)
			} else if (name == 'route') {
				if (value.indexOf('{') > -1) {
					value = JSON.parse(value)
					this.$router.push(value)
				} else {
					// regular route name
					this.$router.push({ name: value })
				}
			} else if (name == 'route_path') {
				this.$router.push({ path: value })
			}

			if (this.item.pauseVideo) {
				this.$store.dispatch('MPages/pauseVideo')
			}
		},
	},
}
</script>
