import { setPlayerColor, playerColorReady } from '../../../../helpers/set-player-color';

export default {
	mounted() {
		if (this.video.video && this.video.color) {
			if (playerColorReady(this.id)) {
				setPlayerColor(this.id, this.video.color);
				// console.log("DONE");
			} else {
				const wait = setInterval(() => {
					if (playerColorReady(this.id)) {
						clearTimeout(wait);
						setPlayerColor(this.id, this.video.color);
					}
				});
			}
		}
	},
};
