// import all from "./all";

// console.log("all", all);
export default {
  // ...all,
  content: {
    width: 400,
    height: 80,
    elevation: 4,
    dark: true,
    customSize: false,

    jBox: false,
    jBoxWidth: 500,
    jBoxBorderWidth: 4,
    jBoxBorderColor: "",
    jBoxDark: false,
    jBoxElevation: 4,

    color: "#1C70B9",
    button: {},
    text: {},
    heading: {},
    subheading: {},
  },
};
