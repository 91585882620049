<template>
  <v-expand-transition>
    <div
      v-show="!hide"
      ref="container"
      :class="fixed && !collapsed ? 'elevation-8' : ''"
      :style="getStyle"
    >
      <slot />
    </div>
  </v-expand-transition>
</template>
<script>
import onSroll from "../../mixins/on-scroll";

export default {
  props: {
    collapsed: Boolean,
    isMobile: Boolean,
    noFixed: Boolean,
    forceFixed: Boolean,
    top: Boolean,
    left: Boolean,
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  mixins: [onSroll],

  data() {
    return {
      hide: false,
      isOut: false,
      fixed: false,
      wait: null,
      container: {
        offsetTop: 0,
        clientHeight: 70,
      },
    };
  },

  computed: {
    getStyle() {
      let style = {};

      if (this.isMobile) {
        style.position = "fixed";
        style.bottom = "0px";
        style.left = "0px";
        style["z-index"] = 100000;
      } else if (this.forceFixed || (this.fixed && !this.noFixed)) {
        style["z-index"] = 100000;
        style.position = "fixed";

        if (this.top) {
          style.top = "30px";
        } else {
          style.bottom = "30px";
        }

        if (this.left) {
          style.left = "30px";
        } else {
          style.right = "30px";
        }
        //
      }
      return style;
    },
  },

  watch: {
    isOut() {
      this.hide = true;
      clearTimeout(this.wait);

      this.wait = setTimeout(() => {
        this.fixed = this.isOut;
        this.$emit("fixed", this.fixed);

        this.hide = false;
      }, 280);
    },
  },
  methods: {
    onScroll() {
      if (this.noFixed) {
        return (this.isOut = false);
      }
      this.isOut =
        window.scrollY >
        this.container.offsetTop + 0.4 * this.container.clientHeight;
    },
  },

  mounted() {
    this.container.offsetTop = this.$refs.container.offsetTop;
    this.container.clientHeight = this.$refs.container.clientHeight;
  },
};
</script>