<template>
  <v-progress-linear
    :indeterminate="indeterminate"
    color="primary"
    fixed
    style="left:0px;top:0px;z-index:100"
  />
</template>
<script>
export default {
  props: {
    indeterminate: Boolean,
  },
};
</script>