export default [
  {
    text: "Heading 1",
    value: "h1",
  },
  {
    text: "Heading 2",
    value: "h2",
  },
  {
    text: "Heading 3",
    value: "h3",
  },
  {
    text: "Heading 4",
    value: "h4",
  },
  {
    text: "Heading 5",
    value: "h5",
  },
  {
    text: "Heading 6",
    value: "h6",
  },
  {
    text: "Subtitle 1",
    value: "subtitle-1",
  },
  {
    text: "Subtitle 1",
    value: "subtitle-2",
  },
  {
    text: "Body 1",
    value: "body-1",
  },
  {
    text: "Body 2",
    value: "body-2",
  },
  {
    text: "Button",
    value: "button",
  },
  {
    text: "Caption",
    value: "caption",
  },
  {
    text: "Overline",
    value: "overline",
  },
];

/*

["h1", "Heading 1", "6rem", "300", "-0.09375rem", -1],
      ["h2", "Heading 2", "3.75rem", "300", "-0.03125rem", 0],
      ["h3", "Heading 3", "3rem", "400", "normal", 1],
      ["h4", "Heading 4", "2.125rem", "400", "0.015625rem", 2],
      ["h5", "Heading 5", "1.5rem", "400", "normal", 2],
      ["h6", "Heading 6", "1.25rem", "500", "0.009375rem", 3],
      ["subtitle-1", "Subtitle 1", "1rem", "400", "0.009375rem", 4],
      ["subtitle-2", "Subtitle 2", "0.875rem", "500", "0.00625rem", 4],
      ["body-1", "Body 1", "1rem", "400", "0.03125rem", 4],
      ["body-2", "Body 2", "0.875rem", "400", "0.015625rem", 4],
      ["button", "Button", "0.875rem", "500", "0.078125rem", 4],
      ["caption", "Caption", "0.75rem", "400", "0.025rem", 4],
      ["overline", "Overline", "0.75rem", "500", "0.1666666667em", 4]

      */
