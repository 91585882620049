<template>
  <div>
    <v-tabs>
      <v-tab>Hero</v-tab>
      <v-tab>Heading</v-tab>
      <v-tab>Subheading</v-tab>

      <v-tab-item>
        <v-row>
          <v-col>
            <m-pages-panel title="Cover Image">
              <v-text-field v-model="val.img.src" placeholder="https://" label="Image" />

              <v-select
                v-model="val.to"
                :disabled="!val.downArrow"
                label="Link Down Arrow To"
                :items="blockList"
              />
            </m-pages-panel>
          </v-col>
          <v-col>
            <v-row no-gutters class="px-2">
              <v-col>
                <v-switch v-model="val.downArrow" label="Down Arrow" hide-details class="mr-4" />
              </v-col>
              <v-col>
                <v-switch v-model="val.matchHeight" label="Match Height" hide-details class="mr-4" />
              </v-col>
              <v-col cols="12">
                <v-switch v-model="val.parallax" label="Parrax" hide-details class="mr-4" />
              </v-col>
            </v-row>
            <v-slider
              v-model="val.height"
              min="100"
              max="1000"
              label="Height"
              hide-details
              class="mt-4 mx-4"
            />
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <m-pages-heading-form v-model="val.heading" />
      </v-tab-item>

      <v-tab-item>
        <m-pages-heading-form v-model="val.subheading" label="Subheading" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import blockList from "../../mixins/block-list";
export default {
  mixins: [model, blockList],
  beforeMount() {
    ["heading", "subheading", "img"].forEach((i) => {
      if (!this.val[i]) {
        this.val[i] = {};
      }
    });
  },
};
</script>
