export default {
  methods: {
    onLink(item) {
      console.log("onLink", item);

      let url;

      if (typeof item == "string") {
        url = item;
      } else {
        if (item.value) {
          url = item.value;
        } else if (item.to) {
          url = item.to;
        }
      }

      if (url.indexOf("://") < 0) {
        url = "#" + url;
      }

      console.log("url", url);

      const options = {
        offset: 60,
      };

      if (document.querySelector(url)) {
        this.$vuetify.goTo(url, options);

        if (this.$store.getters["MPages/isBuilder"]) {
          setTimeout(() => {
            this.$vuetify.goTo(url, options);
          }, 300);
        }
      } else {
        console.log("NOT FOUND", url);
      }
    },
  },
};
