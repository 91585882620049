<template>
  <div>
    <v-snackbar
      v-for="msg in items"
      :key="msg.id"
      :value="true"
      :timeout="-1"
      :top="msg.top"
      :left="msg.left"
      :right="msg.right"
      :color="
        msg.color ? msg.color : colors[msg.type] ? colors[msg.type] : msg.type
      "
    >
      <v-layout align-center justify-center row fill-height class="text-center">
        <v-flex v-if="msg.title" class="px-2">
          <div class="title">T{{ msg.title }}</div>
        </v-flex>
        <v-flex class="px-2 pt-1">
          <span class="body-1">{{ msg.text }}</span>
        </v-flex>
        <v-flex v-if="msg.link && msg.link.url">
          <v-btn outlined :href="msg.link.url" target="_blank">{{
            msg.link.text
          }}</v-btn>
        </v-flex>
      </v-layout>
      <v-btn v-if="!msg.noClose" icon dark @click="close(msg.id)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: {},
    };
  },

  computed: {
    items() {
      return this.$store.state.msg.items;
    },
  },
  methods: {
    close(i) {
      this.$store.dispatch("msg/close", i);
    },
  },
};
</script>
