<template>
  <v-text-field
    v-model="slug"
    :label="label ? label : field.text"
    :loading="loading"
    :append-icon="success ? 'check' : ''"
    :hint="validSlug"
    @input="onInput"
  />
</template>
<script>
export default {
  props: {
    field: {
      type: Object,
      default() {
        return {};
      }
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    fieldValue: {
      type: String,
      required: false
    },
    col: {
      type: String,
      default: "slug"
    },
    endpoint: {
      type: String,
      default() {
        return `${this.$route.path}/${this.col}`;
      }
    }
  },

  data() {
    return {
      slug: "",
      validSlug: "",
      loading: false,
      success: false,
      wait: null
    };
  },

  watch: {
    value(val) {
      if (val != this.slug && val != this.validSlug) {
        this.slug = val;
      }
    }
  },

  beforeMount() {
    if (this.value) {
      this.slug = this.value;
    }
  },

  methods: {
    onInput() {
      this.success = false;
      if (
        !this.slug ||
        this.loading ||
        this.slug.substr(this.slug.length - 1) === " "
      ) {
        return;
      }

      clearTimeout(this.wait);

      this.wait = setTimeout(() => {
        this.checkSlug(this.slug);
      }, 400);
    },

    async checkSlug(slug) {
      this.loading = true;

      let url = `${this.endpoint}?${this.col}=` + encodeURIComponent(slug);

      if (this.field.value && this.field.value != "slug") {
        url += `&col=${this.field.value}`;
      }

      if (this.field.sep) {
        url += `&sep=${this.field.sep}`;
      }

      console.log({ url }, slug);
      let { data } = await this.$api.get(url);

      // changed
      if (this.slug != slug) {
        return this.checkSlug(this.slug);
      }

      // validate
      if (data.data) {
        this.validSlug = data.data;
        this.slug = data.data;
        this.$emit("input", this.validSlug);
        this.success = true;
      }

      this.loading = false;
    }
  }
};
</script>