// export default {
//   active: true,
//   dark: false,
//   heading: {},
//   subheading: {},
//   content: {},
// };

export default {
  name: "welcome",
  auth: {
    active: false,
    access: [],
  },
  schedule: {
    video: {
      active: false,
    },
    date: {
      active: false,
    },
    page: {
      active: false,
    },
  },
  container: {
    spacing: {},
  },
  wrapper: {
    spacing: {},
  },
  content: {
    heading: {
      text: "Welcome To {business.name}",
    },
    subheading: {
      text: "{business.slogan}",
    },
    content: {},
  },
};
