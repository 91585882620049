export default {
  // cols: { col: "", sm: "12", md: "4" },
  content: {
    cols: { col: "", sm: "12", md: "4" },
    heading: { text: "{business.name} Services" },
    subheading: { text: "We are here to serve you..." },
    content: { text: "Here are our services..." },
    // cols: {},
    items: [
      {
        id: "service_1593677106981",
        title: { text: "Service 1" },
        img: { src: "https://source.unsplash.com/weekly?one" },
        content: { html: "<p>First service description...</p>" },

        primaryAction: {
          text: "Call Now!",
          value: "contact-us",
        },
        secondaryAction: {
          text: "Read More",
          value: "read-more",
        },
        col: {},
      },
      {
        id: "service_1593677127881",
        title: { text: "Service 2" },
        img: { src: "https://source.unsplash.com/weekly?second" },
        content: { html: "<p>Second service description...</p>" },

        primaryAction: {
          text: "Call Now!",
          value: "contact-us",
        },
        secondaryAction: {
          text: "Read More",
          value: "read-more",
        },
        col: {},
      },
      {
        id: "service_1593677155823",
        title: { text: "Service 3" },
        img: { src: "https://source.unsplash.com/weekly?three" },
        content: { html: "<p>Third service description</p>" },

        primaryAction: {
          text: "Call Now!",
          value: "contact-us",
        },
        secondaryAction: {
          text: "Read More",
          value: "read-more",
        },
        col: {},
      },
    ],
  },
};
