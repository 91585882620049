<template>
  <list-layout crumbs>
    <ml-table :fields="fields" edit delete create endpoint="admin/downloads" />
  </list-layout>
</template>

<script>
import fields from "../mixins/fields/download";
export default {
  data() {
    return {
      fields,
    };
  },
};
</script>
