<template>
  <v-layout align-center justify-center row fill-height>
    <v-flex shrink>
      <v-card
        max-width="360"
        class="text-center py-4 px-4"
        :color="cardColor"
        elevation="12"
      >
        <v-card-text>
          <slot />

          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <div class="mb-3">{{ help ? helpIntro : intro }}</div>

          <form method="POST" @submit.prevent="submit">
            <v-text-field
              ref="email"
              v-model="form.email"
              label="Account Email"
              autofocus
            />

            <v-expand-transition>
              <v-text-field
                v-if="!help"
                v-model="form.password"
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                :type="showPass ? 'text' : 'password'"
                label="Password"
                class="mb-2"
                @click:append="showPass = !showPass"
              />
            </v-expand-transition>

            <v-btn
              type="submit"
              color="primary"
              large
              block
              depressed
              :disabled="submitDisabled"
              :loading="loading"
              :dark="!submitDisabled"
              >{{ help ? helpButton : button }}</v-btn
            >
          </form>

          <v-divider class="mt-9 mb-4" />

          <v-layout
            align-center
            justify-space-around
            row
            fill-height
            class="mt-6"
          >
            <v-flex xs6 shrink>
              <v-btn
                @click="onToggleHelp"
                text
                class="text-none font-weight-regular"
                >{{ help ? loginLink : recoveryLink }}</v-btn
              >
            </v-flex>
            <v-flex v-if="register" xs6 shrink>
              <v-btn text class="text-none font-weight-regular">
                <!-- Sign Up -->
                <!-- Become a Client -->
              </v-btn>
            </v-flex>
          </v-layout>

          <slot name="footer" />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
// import appLogo from
import isEmail from "validator/lib/isEmail";

export default {
  props: {
    cardColor: {
      type: String,
      default: "",
    },
    register: Boolean,
    title: {
      type: String,
      default: "",
    },
    intro: {
      type: String,
      default: "Please enter your email and password to login",
    },
    // helpTitle: {
    //   type: String,
    //   default: "Login Help",
    // },
    helpIntro: {
      type: String,
      default: "Please enter your email",
    },

    button: {
      type: String,
      default: "Log In",
    },

    helpButton: {
      type: String,
      default: "Request Log In Token",
    },
    recoveryLink: {
      type: String,
      default: "Login Help",
    },
    loginLink: {
      type: String,
      default: "Back To Login",
    },

    loginFailMsg: {
      type: String,
      default: "Unable to login",
    },
    invalidLoginMsg: {
      type: String,
      default: "Please enter your details",
    },
    successPath: {
      type: String,
      default: "/",
    },
    emitSuccess: Boolean,
  },

  data() {
    return {
      clicked: false,
      showPass: false,
      loading: false,
      recovery: false,
      help: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    valid() {
      if (!isEmail(this.form.email)) {
        return false;
      }

      if (!this.help && this.form.password.length < 3) {
        return false;
      }

      return true;
    },
    submitDisabled() {
      return (this.clicked && !this.valid) || this.loading;
    },
  },

  methods: {
    onToggleHelp() {
      this.help = !this.help;
      setTimeout(() => {
        this.$refs.email.focus();
      }, 310);
    },
    getToken() {
      const { email } = this.form;

      this.$auth
        .help({ email })
        .then(({ message }) => {
          this.loading = false;
          if (message) {
            this.$msg.success(message);
            setTimeout(() => {
              this.help = false;
            }, 1500);
          }
        })
        .catch(({ message }) => {
          this.loading = false;
          if (message) {
            this.$msg.error(message);
          }
        });
    },

    submit() {
      this.clicked = true;
      if (!this.valid) {
        this.$msg.error(this.invalidLoginMsg);
        return;
      }

      this.loading = true;

      if (this.help) {
        return this.getToken();
      }

      this.$auth.login(this.form).catch(({ message }) => {
        this.loading = false;

        if (message) {
          this.$msg.error(message);
        } else {
          this.$msg.error(this.loginFailMsg);
        }
      });
    },
  },
};
</script>
