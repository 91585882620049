<template>
  <component :is="tag" :class="textClass" :style="textStyle">
    <slot>{{ item.text }}</slot>
  </component>
</template>

<script>
import htmlTextField from "../mixins/html-text-field";
export default {
  mixins: [htmlTextField],
};
</script>
