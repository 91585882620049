// import all from "./all";

// console.log("all", all);
export default {
  container: {
    spacing: {},
    elevation: 0,
    noGutters: true,
  },
  wrapper: {
    spacing: {},
    elevation: 0,
  },
  content: {
    options: {
      spacingY: 4,
      paddingX: 4,
      px: 0,
      pt: 10,
      pb: 10,
      elevation: 0,
      copyright: true,
      color: "#212121FF",
      showMap: true,
      absolute: true,
      app: true,
      content: false,
      links: true,
      mapHeight: null,
    },
    items: [],
    content: {},
    copyright: {},
    links: [
      {
        text: "Terms",
        clickAction: "href",
        href: "#terms",
        children: [],
        id: "link_footer_1",
      },
      {
        text: "Privacy",
        clickAction: "href",
        href: "#privacy",
        children: [],
        id: "link_footer_2",
      },
    ],
  },
  disabled: false,
  expanded: true,
  dark: true,
  noPageSpacingY: true,
  noPageSpacingX: false,
  color: "",
  light: false,
};
