<template>
  <div>
    <v-card :max-width="maxWidth || 500" :class="!left ? 'mx-auto' : ''">
      <v-card-title v-if="title" v-text="title" />
      <v-card-text>
        <template v-if="success">
          <span v-html="success_content"></span>
        </template>
        <form v-else @submit.prevent="onSubmit">
          <div v-if="intro" v-html="intro"></div>
          <v-row>
            <v-col
              v-for="field in fields"
              :key="field.value"
              :cols="field.cols || 12"
            >
              <component
                :is="`form-builder-${field.type}-field`"
                v-model="form[field.name]"
                :attrs="field.attrs"
              />
            </v-col>
          </v-row>
          <v-btn
            type="submit"
            :disabled="!canSubmit"
            :loading="loading"
            :color="color || 'primary'"
            class="my-5"
            >{{ btnText || 'Submit' }}</v-btn
          >
        </form>

        <template v-if="hasFooter">
          <v-divider class="mt-3 mb-4"></v-divider>
          <div class="text-center" v-html="footer"></div>
        </template>
      </v-card-text>
      <slot />
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    disabled: Boolean,
    left: Boolean,
    isPreview: Boolean,
    success: Boolean,
    loading: Boolean,
    successNoFooter: Boolean,
    maxWidth: {
      type: [Number, String],
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    footer: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    success_content: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      form: {},
    }
  },

  beforeMount() {
    if (this.value) {
      this.form = { ...this.value }
    }
  },
  computed: {
    hasFooter() {
      if (this.successNoFooter && this.success) return false

      return !!this.footer.trim()
    },
    canSubmit() {
      let ok = true
      this.fields.forEach((field) => {
        if (ok && field.attrs.required && !this.form[field.name]) {
          ok = false
          return
        }
      })

      return ok
    },
  },

  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>
