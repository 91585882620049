<template>
  <v-tabs>
    <v-tab>Heading</v-tab>
    <v-tab>Subheading</v-tab>
    <v-tab>Content</v-tab>

    <v-tabs-slider />

    <v-tab-item>
      <m-pages-heading-form v-model="val.heading" />
    </v-tab-item>

    <v-tab-item>
      <m-pages-heading-form v-model="val.subheading" />
    </v-tab-item>

    <v-tab-item>
      <html-editor v-model="val.content.html" flat />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import model from "../../mixins/model";
import htmlEditor from "@/components/Tinymce";
export default {
  components: {
    htmlEditor,
  },
  mixins: [model],
};
</script>
