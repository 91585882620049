<template>
  <div>
    <v-row>
      <v-col>
        <editor-slider v-model="block.container.elevation" label="Shadow" :min="0" />
      </v-col>
      <v-col class="shrink">
        <v-switch class="my-0" label="Expanded" v-model="block.container.fluid" />
      </v-col>
      <v-col class="shrink">
        <v-switch class="my-0" label="Dark" v-model="block.container.dark" />
      </v-col>
      <v-col class="shrink">
        <v-btn fab class="mt-n2 elevation-1" small>
          <v-icon @click="showColor = !showColor" :color="block.container.color">lens</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- <v-expand-transition>
      <v-row>
        <v-col>
          <div class="body-1 text-center mb-2">
            Background Color
          </div>
          <v-color-picker v-model="block.container.color" tile class="mx-auto mb-8" />
        </v-col>
        <v-col>
          <div class="body-1 text-center mb-2">
            Custom Text Color
          </div>
          <v-color-picker v-model="block.container.textcolor" tile class="mx-auto mb-8" />
        </v-col>
      </v-row>
    </v-expand-transition> -->

    <v-row>
      <v-col>
        <slider-group title="Margin" value="m" :min="-12" />
      </v-col>
      <v-col>
        <slider-group title="Padding" value="p" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// form
import EditorSlider from "./Slider";
import SliderGroup from "./SliderGroup";
import formMixin from "../../mixins/form";

export default {
  components: {
    EditorSlider,
    SliderGroup,
  },

  mixins: [formMixin],

  data() {
    return {
      sheet: true,
      showColor: true,
    };
  },
};
</script>
