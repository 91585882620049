<template>
  <v-container>
    <m-pages-auth-form v-model="val" />
    <m-raw-displayer :value="val" />
  </v-container>
</template>

<script>
import model from "../mixins/model";
export default {
  mixins: [model],
};
</script>