export default {
  text: "Placement",
  value: "placement",
  type: "select",
  items: [
    {
      text: "Left",
      value: "left",
    },
    {
      text: "Right",
      value: "right",
    },
    {
      text: "Top",
      value: "top",
    },
    {
      text: "Bottom",
      value: "bottom",
    },
    {
      text: "Footer",
      value: "footer",
    },
    {
      text: "Aside",
      value: "aside",
    },
  ],
  editable: true,
  clearable: true,
};
