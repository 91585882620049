<template>
  <v-container fluid no-gutter class="pa-0">
    <app-block-container
      v-for="(block, index) in data.blocks"
      :key="`block${index}`"
      :c="block.container"
      :index="index"
    >
      <component :is="`${block.name}-block`" :block="block" />
    </app-block-container>
    <div class="yellow pa-2">
      <div class="title">Next Block</div>
    </div>

    <v-navigation-drawer app v-model="drawer">
      <div>drawer content</div>
    </v-navigation-drawer>

    <v-sheet>
      <!-- <editor-form /> -->
    </v-sheet>
    <!-- {{data}} -->
    <!-- <div v-for=""></div> -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { spacing } from "./helpers/spacing";
import HeaderBlock from "./components/blocks/Header";
import NavbarBlock from "./components/blocks/Navbar";
import EditorForm from "./components/form/Form";
import appBlockContainer from "./components/BlockContainer";

export default {
  components: {
    HeaderBlock,
    NavbarBlock,
    EditorForm,
    appBlockContainer
    // appBlockToolbar,
  },

  data() {
    return {
      drawer: true,
      spacing,
      ei: 0 // editing index
    };
  },

  computed: {
    ...mapGetters({
      data: "editor/data"
    })
  }
};
</script>
