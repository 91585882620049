// test
import blocks from "./_/blocks";

export default {
  ready: false,
  loading: false,
  data: {
    navbarHeight: 0,
    blocks: blocks,
  },
  blockIndex: 0, // active block
};
