<template>
  <v-select
    v-model="val"
    label="Target"
    :items="targetItems"
    clearable
    hide-details
    @input="onInput"
  />
</template>

<script>
import { targetItems } from "@/mixins/fields/link-items";
import formFieldMixin from "../../mixins/form-field";
export default {
  mixins: [formFieldMixin],
  data() {
    return {
      targetItems,
    };
  },
};
</script>
