import moment from 'moment'
// import { isEmpty } from "lodash";

export default {
  // getHeaders() {
  //   const headers = this.headers ? this.headers : this.apiHeaders;

  //   return headers;
  // },

  canCreate() {
    return this.create || this.permissions.can_create ? true : false
  },

  canEdit() {
    return this.edit || this.permissions.can_edit ? true : false
  },

  getCreateFields() {
    if (this.createFields && this.createFields.length) {
      return this.createFields
    }

    return this.dbCreateFields
  },

  searchFields() {
    return this.headers
      .filter((a) => a.search)
      .map((a) => {
        return a.value
      })
  },

  filteredItems() {
    if (!this.items || this.items.length < 1) {
      return []
    }

    const items = []
    // return [];

    let add, val

    const q = this.q ? this.q.toLowerCase() : ''

    this.items.forEach((item, index) => {
      if (item.created) {
        item.created = moment(item.created).format('MMMM Do YYYY')
      }
      if (item.updated) {
        item.updated = moment(item.updated).format('MMMM Do YYYY')
      }

      add = true

      if (this.q) {
        add = false

        this.searchFields.forEach((field) => {
          if (!add) {
            val = item[field].toString().toLowerCase()
            if (val.indexOf(q) > -1) {
              add = true
            }
          }
        })
      }

      if (add) {
        if (this.itemFilter) {
          item = this.itemFilter(item)
        }

        if (item) {
          items.push(item)
        }
      }
    })

    return items
  },
}
