import { tidyUrl } from "./helpers";

export default function({ options, data }) {
  return new Promise((resolve, reject) => {
    //
    const { store } = options;
    const { email } = data;
    const Api = store._vm.$api;
    const url = options.helpUrl || `${options.url}/recovery`;

    const recoveryUrl =
      options.recoveryUrl || tidyUrl(window.location.href) + "/recovery";
    const siteName = options.siteName || process.env.VUE_APP_APP_NAME;

    Api.post(url, { email, recoveryUrl, siteName })
      .then(({ data }) => {
        // setup user
        resolve(data);
      })
      .catch(({ response }) => {
        const data = response.data;
        data.status = response.status;
        reject(data);
      });
  });
}
