<template>
  <v-list-item :to="to" :href="href" :exact="item.exact" :target="item.target">
    <v-list-item-icon v-if="item.icon || item.highlight" class="mr-4">
      <v-icon :color="item.highlight ? 'red' : ''">
        {{
        item.icon || "whatshot"
        }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ item.text }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    to() {
      if (this.item.to_name && this.item.to_name == "route") {
        // support custom json object
        if (this.item.to_value.indexOf("{") > -1) {
          return JSON.parse(this.item.to_value);
        }
        return { name: this.item.to_value };
      }

      if (this.item.to_name && this.item.to_name == "route_path") {
        return { path: this.item.to_value };
      }

      return null;
    },

    href() {
      if (this.item.to_name && this.item.to_name == "url") {
        return this.item.to_value;
      }

      return null;
    },
  },
};
</script>
