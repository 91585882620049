<template>
  <item-layout crumbs :title="item.name">
    <ml-form
      slotx="actions"
      :fields="formFields"
      name="layout"
      title="Edit Layout"
      dialog
      dialog-icon="create"
      :endpoint="`admin/layouts/${this.$route.params.id}`"
    />
    <!-- {{ page.content }} -->
    <v-btn :to="{ name: 'layout-builder', params: { id: $route.params.id } }">
      <v-icon class="mr-1">build</v-icon>Layout Builder
    </v-btn>
  </item-layout>
</template>

<script>
import { mapGetters } from "vuex";
import formFields from "./js/form";

export default {
  data() {
    return {
      formFields,
      ready: false,
    };
  },

  computed: {
    ...mapGetters({
      item: "layout/data",
    }),
  },

  beforeMount() {
    this.$store
      .dispatch("layout/getItem", `admin/layouts/${this.$route.params.id}`)
      .then(() => {
        // console.log("partial READY!");
        this.ready = true;
      });
  },
};
</script>
