<template>
  <div>
    FORM FORM
    <!-- <m-pages-collection-field /> -->
    <ml-autocomplete
      v-model="val.form"
      endpointxx="admin/list/form"
      endpoint="admin/list/form"
      identifier
    />

    <v-text-field
      v-if="val.sendEmail"
      v-model="val.email"
      label="Custom Email"
      placeholder="defaults to admin email"
    ></v-text-field>

    <v-row>
      <v-switch
        v-for="opt in opts"
        :key="opt.value"
        v-model="val[opt.value]"
        :label="opt.text"
        class="mx-4"
      />
    </v-row>
    <dev-raw :value="{ val }" />
  </div>
</template>

<script>
import model from '../../mixins/model'
export default {
  mixins: [model],
  data() {
    return {
      opts: [
        {
          text: 'Allow Edit',
          value: 'allowEdit',
        },
        {
          text: 'Allow Multi-Submit',
          value: 'allowMultiSubmit',
        },
        {
          text: 'Admin Alert',
          value: 'showAdminAlert',
        },
        {
          text: 'Send Email',
          value: 'sendEmail',
        },
        {
          text: 'Forward on Submit',
          value: 'forwardOnSubmit',
        },
        {
          text: 'Limit Shows',
          value: 'limitShows',
        },
      ],
    }
  },

  mounted() {
    // this.val.form = ''
    // delete this.val.formId
  },
}
</script>
