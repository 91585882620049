<template>
  <v-main>
    <v-container fill-height>
      <v-img
        v-if="!noBgImage && bg"
        :src="bg.src"
        class="auth-login-page-bg"
        absolute
        :stylex="bg.filters ? `filter:${bg.filters}` : ''"
      />

      <div
        v-if="bg.lighten || bg.darken"
        class="auth-login-page-bg"
        :stylex="
          `background:rgba(${
            bg.lighten ? '255,255,255' : '0,0,0'
          },${bg.lighten || bg.darken})`
        "
      ></div>

      <v-fade-transition>
        <auth-login v-if="authReady && !authenticated" :card-color="cardColor">
          <slot />
          <template slot="footer">
            <v-btn text class="text-none mb-n2" color="grey" small
              >MasteryLabs.com</v-btn
            >
            <slot name="footer" />
          </template>
        </auth-login>
      </v-fade-transition>
    </v-container>

    <slot name="bottom" />
  </v-main>
</template>

<script>
  export default {
    props: {
      cardColor: {
        type: String,
        default: '',
      },
      noBgImage: Boolean,
    },
    computed: {
      loginPage() {
        return this.$store.state.auth.loginPage
      },
      bg() {
        if (!this.loginPage.bg) {
          return
        }

        const bg = this.loginPage.bg

        if (!bg.quality) {
          bg.quality = 0.8
        }

        let w = document.documentElement.clientWidth
        let h = document.documentElement.clientHeight

        if (bg.size) {
          const size = bg.size.split('x')
          w = size[0]
          h = size[1]
        }

        if (bg.quality) {
          w = Math.round(w * bg.quality)
          h = Math.round(h * bg.quality)
        }

        bg.size = `${w}x${h}`

        // console.log(bg)

        // set source
        if (bg.src && bg.src.indexOf('.') < 0) {
          bg.src = `https://source.unsplash.com/${bg.src}/${bg.size}/`
        }

        // use keyword
        else if (!bg.src) {
          bg.src = `https://source.unsplash.com/${bg.size}/?${bg.keywords ||
            'scenic'}`
        }

        // temp!
        bg.src = 'https://source.unsplash.com/T-LfvX-7IVg/1600x900'

        return bg
      },
    },

    // mounted() {
    //   setTimeout(() => {
    //     console.log("try");
    //     this.$router.push("/abc");
    //   }, 2000);
    // }
  }
</script>

<style scoped>
  .auth-login-page-bg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    filter: brightness(80%);
  }
</style>
