<template>
	<item-layout crumbs :title="download.name" :sub-title="download.version" :loading="!ready">
		<template slot="actions">
			<v-btn :loading="downloading" @click="downloadFile" depressed class="mr-1">
				<v-icon class="mr-1">get_app</v-icon>Download
				<!-- <span class="caption">{{ download.version }}</span> -->
			</v-btn>
			<ml-form
				:fields="form"
				name="download"
				title="Edit Download"
				dialog
				:endpoint="`admin/downloads/${this.$route.params.id}`"
			/>
		</template>

		<v-row>
			<v-text-field label="Public Key" outlined readonly class="mx-4" :value="download.public_key" />
			<v-text-field
				label="Secret Key"
				placeholder="*** hidden ***"
				outlined
				readonly
				class="mx-4"
				:value="secretKey"
			>
				<v-btn @click="getSecretKey" slot="append" depressed class="mt-n2">
					{{ secretKey ? 'Hide' : 'Reveal' }}
				</v-btn>
			</v-text-field>
		</v-row>

		<v-row>
			<vue-dropzone
				ref="myVueDropzone"
				id="download-dropzone"
				:options="dropzoneOptions"
				@vdropzone-success="onDropSuccess"
				@vdropzone-sending="onDropSending"
			/>
		</v-row>
	</item-layout>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import { mapGetters } from 'vuex'
import { form } from '../mixins/fields/download'

const FileDownload = require('js-file-download')

export default {
	components: {
		vueDropzone,
	},
	data() {
		return {
			ready: false,
			downloading: false,
			form,
			secretKey: '',
			dropzoneOptions: {
				url: '',
				thumbnailWidth: 150,
				maxFilesize: 500,
				headers: { 'My-Awesome-Header': 'header value' },
			},
		}
	},

	computed: {
		...mapGetters({
			download: 'download/data',
		}),
	},

	methods: {
		async downloadFile() {
			this.downloading = true
			let url = `admin/downloads-download/${this.$route.params.id}`

			const response = await this.$api.downloadBlob(url)
			const name = this.download.file_name
			await FileDownload(response.data, name)

			setTimeout(() => {
				this.downloading = false
			})

			// const url2 = window.URL.createObjectURL(new Blob([data]));
			// // window.open(url2);
			// const link = document.createElement("a");
			// link.href = url2;
			// link.setAttribute("download", "file.jpg"); //or any other extension
			// document.body.appendChild(link);
			// link.click();

			// axios({
			//   url: url, //your url
			//   method: "GET",
			//   responseType: "blob", // important
			// }).then((response) => {

			// });

			return
			this.$api.download(url).then(({ data }) => {
				console.log('data', data)
				FileDownload(data, 'report.jpeg')
			})
		},
		getSecretKey() {
			if (this.secretKey) {
				this.secretKey = ''
				return
			}
			const url = `admin/downloads/${this.$route.params.id}/secret-key`
			this.$api.get(url).then(({ data }) => {
				if (data.data) {
					this.secretKey = data.data
				}
			})
		},

		onDropSuccess(e) {
			console.log('onDropSuccess', e)
			this.ready = false
			this.getItem()
		},
		onDropSending(v) {
			console.log('onDropSending', v)
		},

		getItem() {
			this.$store.dispatch('download/getItem', `admin/downloads/${this.$route.params.id}`).then(() => {
				this.ready = true
			})
		},
	},

	beforeMount() {
		this.dropzoneOptions.url = this.$api.getBaseURL(`/admin/downloads-upload/${this.$route.params.id}`)
		this.dropzoneOptions.headers = this.$api.getAuthHeaders()

		this.getItem()
	},
}
</script>
