export default [
  {
    text: "None",
    value: "none",
  },
  {
    text: "Line Through",
    value: "line-through",
  },
  {
    text: "Overline",
    value: "overline",
  },
  {
    text: "Underline",
    value: "underline",
  },
];
