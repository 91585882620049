export default {
	loading() {
		return !this.canplay || ['', 'seeking'].includes(this.status);
	},

	showCenterPlay() {
		return this.canplay && ['ready', 'paused', 'ended', 'seeked'].includes(this.status);
	},
	poster() {
		return this.video && this.video.poster ? this.video.poster : false;
		// if (!this.validSource) {
		// 	return '';
		// }

		// const src = this.source.sources[this.index];

		// return src.poster ? src.poster : '';
	},

	showPoster() {
		return this.poster && ['', 'ready', 'ended'].includes(this.status);
	},
};
