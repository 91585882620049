export default {
  // onResize({ target }) {
  //   console.log("resize", target);
  //   this.$store.dispatch("MPages/setWidth", target.innerWidth);
  // },

  onRawEdit(v) {
    console.log("onRawEdit", v);
    let value = JSON.parse(v);
    if (typeof value == "object") {
      this.data = value;
    }
  },

  onDisabled(val) {
    if (val) {
      this.$msg.warning("This block will not visible while disabled");
    }
  },

  onAuth(val) {
    if (this.emitAuth) {
      this.$emit("auth", val);
    }
  },

  onBlockEvent(index, { name, value }) {
    console.log("onBlockEvent", name);
    const block = this.data.blocks[index];

    if (block.name == "video") {
      if (name == "ready") {
        block.content.video.duration = value;
        console.log("duration set", block.content.video);
      }
    }
  },

  onColClick(index) {
    // required to toggle col
    this.pendingSelectColIndex = index;

    setTimeout(() => {
      this.selectColIndex = index;
    });
  },

  onContainerClick(index) {
    if (this.isPreview) {
      return;
    }

    const block = this.data.blocks[index];

    if (block.name == "row") {
      if (
        block.id == this.blockId &&
        this.selectColIndex == this.pendingSelectColIndex
      ) {
        this.blockId = "";
      } else {
        this.blockId = block.id;
      }
      return;
    }
    // if toggle
    if (block.id == this.blockId) {
      return (this.blockId = "");
    }
    this.blockId = block.id;
  },

  onTab(id, index) {
    this.indexes.tab[id] = index;
  },

  onCol(id, index) {
    this.indexes.col[id] = index;
  },
};
