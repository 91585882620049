<template>
  <div v-if="ready">
    <m-crumbs />
    <div class="pt-3">
      <page-builder
        v-model="partial.content"
        title="Partial Builder"
        string-format
        is-partial
        emit-auth
        @auth="onAuth"
      >
        <template slot="toolbar">
          <v-btn
            color="primary"
            :loading="saving"
            class="mr-4"
            :disabled="savedStr == partial.content"
            @click="save"
          >Save</v-btn>
        </template>
      </page-builder>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import pageBuilder from "@/mods/m-pages/PageBuilder";

export default {
  components: {
    pageBuilder,
  },

  data() {
    return {
      ready: false,
      saving: false,
      endpoint: "",
      partialContent: "",
      savedStr: "",
      partial: {},
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "home" },
        },
        {
          text: "Pages",
          disabled: false,
          to: { name: "partials" },
        },
        {
          text: "Page " + this.$route.params.id,
          disabled: false,
          href: "#a",
          to: { name: "partial", params: { id: this.$route.params.id } },
        },
        {
          text: "Builder",
          disabled: true,
          href: "",
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      setData: "partial/setData",
    }),

    onAuth(value) {
      const auth = { ...value };
      this.partial.auth_active = auth.active ? 1 : 0;
      this.partial.auth = JSON.stringify(auth);
    },

    async save() {
      this.saving = true;
      const form = { ...this.partial };
      delete form.id;
      delete form.identifier;
      delete form.created;
      delete form.updated;

      let response = await this.$api.post(
        `admin/partials/${this.$route.params.id}`,
        form
      );

      this.saving = false;
    },
  },

  beforeMount() {
    this.endpoint = `admin/partials/${this.$route.params.id}`;

    this.$api.get(this.endpoint).then(({ data }) => {
      if (data.data) {
        this.crumbs[2].text = data.data.name;
        this.partial = data.data;
      }

      this.savedStr = this.partial.content;

      this.ready = true;
    });
  },
};
</script>
