const fields = [
	{
		text: 'ID',
		value: 'id',
		header: true,
	},
	{
		text: 'Name',
		value: 'name',
		header: true,
		editable: true,
	},
	{
		text: 'Title',
		value: 'title',
		editable: true,
	},
	{
		text: 'Slug',
		value: 'slug',
		editable: true,
		type: 'slug',
		// endpoint: "admin/product-slug",
	},
	{
		text: 'Layout',
		value: 'layout_id',
		editable: true,
		clearable: true,
		type: 'autocomplete',
		endpoint: 'admin/list/layout',
	},
	{
		text: 'Parent Page',
		value: 'parent_id',
		editable: true,
		clearable: true,
		type: 'autocomplete',
		endpoint: 'admin/list/page',
	},
	{
		text: 'Menu Placement',
		value: 'menu',
		editable: true,
		clearable: true,
		type: 'select',
		items: [
			{
				text: 'Left',
				value: 'left',
			},
			{
				text: 'Top',
				value: 'top',
			},
		],
	},

	{
		text: 'Menu Order',
		value: 'menu_order',
		type: 'number',
		min: 0,
		max: 100,
		header: true,
		editable: true,
	},
	{
		text: 'Client src',
		value: 'client_src',
		editable: true,
	},
	{
		text: 'No Topbar',
		value: 'no_topbar',
		type: 'switch',
		header: true,
		editable: true,
		cols: 4,
	},
	{
		text: 'No Profile',
		value: 'no_profile',
		type: 'switch',
		header: true,
		editable: true,
		cols: 4,
	},
	{
		text: 'No Menu',
		value: 'no_menu',
		type: 'switch',
		header: true,
		editable: true,
		cols: 4,
	},
	{
		text: 'Mini Menu',
		value: 'mini_menu',
		type: 'switch',
		header: true,
		editable: true,
		cols: 4,
	},
	{
		text: 'Active',
		value: 'active',
		type: 'switch',
		header: true,
		editable: true,
		cols: 4,
	},
	{
		text: 'Created',
		value: 'createdAt',
		header: true,
	},
	{
		text: 'Updated',
		value: 'updatedAt',
		header: true,
	},
	// {
	//   text: "",
	//   value: "actions",
	//   header: true,
	//   // width: 400,
	// },
]

export const headers = fields.filter((a) => a.header)

export const form = fields.filter((a) => a.editable)
