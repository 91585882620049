import { getObjectValue } from "./helpers";
export default function({ options, data }) {
  return new Promise((resolve, reject) => {
    //
    const { store, router, loginTo, useWrapper } = options;
    const { email, password } = data;
    const Api = store._vm.$api;
    const url = options.loginUrl || `${options.url}/login`;

    Api.post(url, { email, password })
      .then(({ data }) => {
        // setup user
        const token = getObjectValue(data, options.apiTokenKey);

        // set authorization header
        Api.setAuth(token);

        localStorage.setItem(options.localKey, token);

        const meUrl = options.meUrl || `${options.url}/me`;

        return Api.get(meUrl);
      })
      .then(({ data }) => {
        const user = getObjectValue(data, options.apiUserKey);
        store.commit("auth/SET", { user, loggedIn: true });

        // console.log("authLogin state", store.state.auth);

        if (useWrapper) {
          console.log("useWrapper");
        } else if (store.state.auth.toPath) {
          router.replace({ path: store.state.auth.toPath });
          store.commit("auth/SET", { toPath: "" });
        } else {
          console.log("else loginTo", loginTo);
          router.replace(loginTo);
        }

        // redirect to loginRoute
        resolve(user);
      })
      .catch((err) => {
        if (err.response) {
          // console.log("err", err);
          const data = err.response.data;
          data.status = err.response.status;
          reject(data);
        } else {
          reject(data);
        }
        //
      });
  });
}
