<template>
  <div>
    <v-text-field
      v-model="val"
      label="Video"
      :hint="hint"
      :loading="gettingDuration"
      @blur="onInput"
    />
    <m-pages-player
      style="display: none"
      v-if="gettingDuration"
      :src="this.val"
      @ready="onDuration"
    />
  </div>
</template>

<script>
import model from "../../mixins/model-str";

import { getVideoMin } from "@/helpers/video";

export default {
  props: {
    getDuration: Boolean,
  },

  mixins: [model],
  data() {
    return {
      hint: "",
      gettingDuration: false,
    };
  },

  methods: {
    onDuration(dur) {
      this.$emit("duration", dur);
      this.gettingDuration = false;
    },

    onInput() {
      this.val = getVideoMin(this.val);

      if (this.getDuration && this.val != "") {
        this.gettingDuration = true;
      }
    },
  },
};
</script>
