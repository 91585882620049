export const toNameItems = [
  {
    text: "Dialog",
    value: "dialog",
  },
  {
    text: "Section",
    value: "dom",
  },
  {
    text: "Page",
    value: "page",
  },
  {
    text: "Route Name",
    value: "route",
  },
  {
    text: "Route Path",
    value: "route_path",
  },
  {
    text: "Url",
    value: "url",
  },
];

export const targetItems = [
  {
    text: "New Window",
    value: "_blank",
  },
  {
    text: "Parent",
    value: "_parent",
  },
  {
    text: "Top",
    value: "_top",
  },
  {
    text: "Self",
    value: "_self",
  },
];
export default {
  targetItems,
  toNameItems,
};
