<template>
  <div class="text-center">
    <v-card v-if="!dialog" :width="width" class="mx-auto" :loading="loading">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>

    <v-dialog v-else v-model="open" :width="width" :persistent="persistent">
      <template v-slot:activator="{ on }">
        <v-btn :color="btnColor" outlined dark v-on="on">
          <v-icon v-if="dialogIcon" class="mr-1" v-text="dialogIcon" />
          {{ title }}
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat color="grey lighten-3">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="open = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <slot />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    dialog: Boolean,
    persistent: Boolean,
    dialogIcon: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: 500,
    },
    btnColor: {
      type: String,
      default: 'primary',
    },
  },

  data() {
    return {
      open: false,
    }
  },
}
</script>
