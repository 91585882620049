<template>
  <v-card :flat="flat">
    <m-toolbar :title="title" flat>
      <slot name="toolbar" />

      <template v-if="canClose">
        <v-spacer />
        <v-btn icon @click="$emit('close')">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </m-toolbar>
    <v-expand-transition>
      <div v-show="!hideContent">
        <slot v-if="edged" />
        <v-card-text v-else>
          <slot />
        </v-card-text>
      </div>
    </v-expand-transition>
    <v-card-actions v-if="hasActions">
      YES ACTIONS
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    canClose: Boolean,
    edged: Boolean,
    hideContent: Boolean,
    flat: Boolean,
    title: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      hasActions: false,
    };
  },

  beforeMount() {
    this.hasActions = this.$slots.actions ? true : false;
  },
};
</script>
