<template>
  <v-breadcrumbs :items="items" class="mb-n5">
    <template v-slot:item="{ item }">
      <div
        v-if="item.disabled"
        class="text-uppercase caption font-weight-bold grey--text"
        v-text="item.text"
      />
      <v-btn
        v-else
        :to="item.to"
        :disabled="item.disabled"
        text
        small
        class="px-1"
        exact
        v-text="item.text"
      />
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: {
    homeTitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: 'name',
    },
  },

  data() {
    return {
      items: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' },
        },
      ],
    }
  },

  beforeMount() {
    let path = ''

    let paths = this.$route.path.split('/')
    paths.shift()

    paths.forEach((item, i) => {
      path += '/' + item

      let text = i === 0 && this.homeTitle ? this.homeTitle : item

      text = text.replace(/-/g, ' ')

      this.items.push({
        text,
        disabled: paths.length == i + 1,
        to: { path: path },
      })

      if (!isNaN(item)) {
        this.getName(path, i + 1)
      }
    })
  },

  methods: {
    // parseName
    async getName(path, i) {
      const { data } = await this.$api.get(path)

      if (!data.data) {
        return
      }

      let text = this.text || 'name'

      if (text && text.indexOf(' ') > 0) {
        this.items[i].text = ''
        this.text.split(' ').forEach((text) => {
          this.items[i].text += data.data[text] + ' '
        })
      } else {
        this.items[i].text = data.data[text]
      }
    },
  },
}
</script>
