export default [
  {
    text: "Hide Controls",
    value: "noControls",
  },
  {
    text: "No Center Play",
    value: "noPlayLarge",
  },
  {
    text: "Trimmer",
    value: "trimmerActive",
  },
  {
    text: "VSL Model",
    value: "vslActive",
  },
  {
    text: "VSL Play",
    value: "vslPlay",
    vslActive: true,
  },
  {
    text: "VSL Image",
    value: "vslImage",
    vslActive: true,
  },
];
