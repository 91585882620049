<template>
  <v-dialog
    v-model="val"
    :max-width="maxWidth"
    @click:outside="$emit('input', false)"
  >
    <m-pages-toolbar :title="title">
      <v-spacer />
      <v-btn icon @click="$emit('input', false)">
        <v-icon>close</v-icon>
      </v-btn>
    </m-pages-toolbar>
    <v-card tile flat>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
    <!-- / / -->
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: [Number, String],
      default: 900,
    },
  },

  watch: {
    value() {
      this.val = this.value;
    },
  },

  data() {
    return {
      val: false,
    };
  },
};
</script>
