<template>
  <div
    class="text-h6 text-uppercasex text--disabled"
    :class="isFirst ? '' : 'mt-5'"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isFirst: Boolean,
  },
};
</script>
