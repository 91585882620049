<template>
  <div>
    <!-- <div>welcome blocks: {{block.heading}}</div> -->
    <m-pages-heading v-if="block.heading && block.heading.text" :item="block.heading" />
    <m-pages-subheading v-if="block.subheading && block.subheading.text" :item="block.subheading" />
    <div v-html="block.content.html" />
  </div>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
