/** @format */

export default [
  {
    label: "Email",
    icon: "mdi-email",
    color: "#ffa500",
    value: "email",
    hint: "Email Address",
  },
  {
    label: "Call",
    icon: "mdi-phone",
    color: "#ffa500",
    value: "phone",
    hint: "Full phone number including internation code (+ optional)",
  },
  {
    label: "Facebook",
    icon: "mdi-facebook",
    color: "#3b5998",
    value: "facebook",
    hint: "Facebook Page URL",
  },
  {
    label: "LinkedIn",
    icon: "mdi-linkedin",
    color: "#0075B2",
    value: "linkedin",
    hint: "LinkedIn  Page URL",
  },
  {
    label: "Messenger",
    icon: "mdi-facebook-messenger",
    color: "#0084ff",
    value: "messenger",
    hint: "Facebook Username",
  },
  {
    label: "Skype",
    icon: "mdi-skype",
    color: "#01ABEA",
    value: "skype",
    hint: "Skype username",
  },
  {
    label: "Twitter",
    icon: "mdi-twitter",
    color: "#2AA9E0",
    value: "twitter",
    hint: "Twitter Page URL",
  },
  {
    label: "WhatsApp",
    icon: "mdi-whatsapp",
    color: "#4DCB5B",
    value: "whatsapp",
    hint: "WhatsApp full phone number including internation code (+ optional)",
  },
  {
    label: "YouTube",
    icon: "mdi-youtube",
    color: "#FF0100",
    value: "youtube",
    hint: "YouTube Channel ID or URL",
  },
];
