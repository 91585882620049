<template>
  <m-pages-panel :title="title">
    <v-row no-gutters>
      <v-col
        v-for="field in fields"
        :key="field.value"
        :sm="field.value == 'a' ? 12 : 6"
        xs="12"
        class="text-center"
      >
        <v-expand-transition>
          <div v-if="(!advanced && !field.advanced) || advanced">
            <v-slider
              v-model="val[field.value]"
              hide-details
              min="-12"
              max="12"
              thumb-label
              @input="onInput"
            />
            <div class="caption">{{ field.text }}</div>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>

    <v-row align="start" justify="space-between" class="mt-4" no-gutters>
      <v-btn class="mt-4" hide-details text color="primary" @click="resetAll"
        >Reset All
      </v-btn>
      <v-checkbox v-model="advanced" label="Advanced" hide-details />
    </v-row>
  </m-pages-panel>
</template>

<script>
import fields from "../../mixins/spacing-direction";
import model from "../../mixins/model";

const defaultVal = {};
fields
  .map((a) => a.value)
  .forEach((k) => {
    defaultVal[k] = 0;
  });

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  mixins: [model],

  data() {
    return {
      advanced: false,
      fields,
      val: {
        ...defaultVal,
      },
    };
  },

  methods: {
    onInput() {},
    resetAll() {
      this.val = {
        ...defaultVal,
      };
    },
  },
};
</script>
