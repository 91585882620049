<template>
	<iframe
		v-if="setup.business && setup.business.gmap"
		:src="setup.business.gmap"
		:style="`width:100%;height:${height || 300}px`"
		frameborder="0"
	></iframe>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: {
		height: {
			type: [String, Number],
			default: '',
		},
	},
	computed: {
		...mapGetters({
			setup: 'MPages/setup',
		}),
	},
}
</script>
