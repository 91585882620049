<template>
  <div>
    <v-tabs>
      <v-tab
        v-for="panel in panels"
        :key="`tab${panel.value}`"
        :class="
          panel.value == 'options' || val.options[panel.value] ? '' : 'd-none'
        "
        v-text="panel.text"
      />

      <v-tabs-slider></v-tabs-slider>

      <v-tab-item
        v-for="panel in panels"
        :class="
          panel.value == 'options' || val.options[panel.value] ? '' : 'd-none'
        "
        :key="panel.value"
      >
        <component
          v-if="panel.component"
          v-bind:is="panel.component"
          v-model="val[panel.value]"
        />

        <!-- <m-pages-video-form-v2 v-model="val[panel.value]" /> -->
        <m-pages-form
          v-else
          v-model="val[panel.value]"
          :form="offer[panel.value]"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import offer from "../../js/forms/offer";

export default {
  mixins: [model],
  data() {
    return {
      offer,
      panel: null,
      panels: [
        {
          text: "Offer",
          value: "options",
        },
        {
          text: "Brandbar",
          value: "brandbar",
        },
        {
          text: "Heading",
          value: "heading",
        },
        {
          text: "Video",
          value: "video",
          component: "m-pages-video-form-v2",
        },
        {
          text: "Content",
          value: "content",
        },
        {
          text: "Buy Button",
          value: "buyButton",
          // component: "m-pages-button-form-v2",
          component: "m-pages-buy-button-form",
        },
        {
          text: "Footer",
          value: "footer",
        },
      ],
    };
  },
};
</script>
