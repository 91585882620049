<template>
  <div>
    <v-tabs>
      <v-tab>FAQs</v-tab>
      <v-tab>Heading</v-tab>
      <v-tab>Subheading</v-tab>

      <v-tabs-slider />

      <v-tab-item>
        <br />

        <v-row>
          <v-col>
            <m-pages-panel
              :title="`${editItemId ? 'Edit' : 'Add'} FAQ`"
              :close="editItemId != ''"
              @close="editItemId = ''"
            >
              <v-text-field ref="title" v-model="getForm.title.text" label="Title" />
              <html-editor v-model="getForm.content.html" flat min-height="380" class="mb-5" />

              <v-btn v-if="editItemId" class="my-4" @click="editItemId = ''">Done</v-btn>
              <v-btn v-else class="my-4" @click="addItem">Add FAQ</v-btn>
            </m-pages-panel>
          </v-col>
          <v-col>
            <m-pages-panel title="FAQs" edged>
              <div v-if="!val.items.length" class="pa-4 grey--text">You do not have any faqs</div>
              <m-draggable
                :items="val.items"
                :item-text="(a) => a.title.text"
                can-edit
                can-delete
                @onEdit="onEdit"
                @onDelete="onDelete"
              />
            </m-pages-panel>

            <!-- <m-pages-panel title="Extra Options">
          size, color, other...
            </m-pages-panel>-->
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <br />
        <m-pages-heading-form v-model="val.heading" />
      </v-tab-item>

      <v-tab-item>
        <br />
        <m-pages-heading-form v-model="val.subheading" />
      </v-tab-item>
    </v-tabs>

    <!-- <m-raw-displayer :value="getForm" title="getForm" /> -->
    <!-- /  -->
  </div>
</template>

<script>
import model from "../../mixins/model";
import form from "../../mixins/form";
import blockList from "../../mixins/block-list";
import htmlEditor from "@/components/Tinymce";
import genId from "@/helpers/gen-id";
export default {
  components: {
    htmlEditor,
  },
  mixins: [model, blockList, form],

  data() {
    return {
      editItemId: "",
      form: {
        title: {},
        content: {},
        col: {},
      },
    };
  },

  computed: {
    getForm() {
      if (this.editItemId) {
        const index = this.val.items.findIndex((a) => a.id === this.editItemId);
        return this.val.items[index];
      }
      return this.form;
    },
  },

  methods: {
    addItem() {
      const item = {
        id: genId("faq_"),
        ...this.form,
      };

      console.log("item", this.item);

      this.val.items.push(item);
      this.clearForm();
    },

    onEdit(item) {
      console.log("onEdit", item);
      this.editItemId = item.id;
      this.$refs.title.focus();
    },
    onDelete(item) {
      const index = this.val.items.findIndex((a) => a.id == item.id);
      this.val.items.splice(index, 1);
      console.log("onDelete", item);
    },
  },
};
</script>