<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="items"
      :label="label ? label : field.text"
      :item-text="itemText ? itemText : field.itemText"
      :item-value="itemValue ? itemValue : field.itemValue"
      :loading="loading"
      :search-input.sync="q"
      hide-no-data
      :outlined="outlined"
      :disabled="disabled"
      :return-object="returnObject"
      :hide-details="hideDetails"
      :clearable="!noClearable"
      :solo="solo"
      :append-icon="appendIcon"
      @click:append="$emit('click:append')"
      @focus="onFocus"
      @change="$emit('input', model)"
    />
  </div>
</template>

<script>
export default {
  props: {
    outlined: Boolean,
    hideDetails: Boolean,
    clearable: Boolean,
    noClearable: Boolean,
    solo: Boolean,
    identifier: Boolean,
    appendIcon: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default() {
        return {}
      },
    },
    label: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    returnObject: Boolean,
    value: {
      type: [String, Number],
      default: '',
    },
    endpoint: {
      type: String,
      default: '',
    },
    itemText: {
      type: [String, Function],
      default: 'text',
    },
    itemValue: {
      type: [String, Function],
      default: 'value',
    },
  },

  data() {
    return {
      set: false,
      model: null,
      loading: false,
      items: [],
      q: '',
      form: {
        page: 1,
      },
      //   searches: []
    }
  },

  watch: {
    value(v) {
      if (!this.set) {
        // this.model = v;
        this.set = true
        this.getValue(v)
      }
    },

    q() {
      if (this.q == null) {
        if (this.set) {
          this.$emit('input', '')
        }
        return
      }

      if (!this.loading) {
        this.getItems()
      }
    },
  },

  methods: {
    async getValue(id) {
      const form = {}
      if (this.identifier) form.value = 'identifier'
      let { data } = await this.$api.get(`${this.endpoint}/${id}`, form)

      if (data.data) {
        this.items.push(data.data)
        this.model = this.items[0][this.itemValue]
      }
    },

    onFocus() {
      if (this.loading) {
        return
      }
      this.getItems()
    },

    async getItems() {
      this.set = true
      this.loading = true

      const form = {
        ...this.form,
        q: this.q,
      }
      // this.form.q = this.q
      if (this.identifier) {
        form.value = 'identifier'
      }

      let { data } = await this.$api.get(this.endpoint, form)

      if (data.data && data.data.length > 0) {
        data.data.forEach((item) => {
          const index = this.items.findIndex(
            (a) => a[this.itemValue] === item[this.itemValue]
          )
          if (index < 0) {
            this.items.push(item)
          }
        })
      }
      if (this.q !== form.q) {
        return this.getItems()
      }
      this.loading = false
    },
  },

  beforeMount() {
    if (this.value) {
      this.model = this.value
      this.getValue(this.model)
    }
  },
}
</script>
