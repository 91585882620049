<template>
  <div>
    <div v-for="group in groups" :key="group.value">
      <!-- <div class="title" v-text="group.text" /> -->
      <v-row>
        <v-col v-for="item in items" :key="item.value">
          <ml-query-addon-filter
            v-if="item.value == 'addons' && isAddonFilter"
            :group="group"
            :item="item"
            @input="onChange(group.value, item.value, $event)"
          />

          <ml-query-filter
            v-else
            :label="`${group.text} ${item.text}`"
            :endpoint="item.endpoint"
            :item-text="item.itemText"
            :item-value="item.itemValue"
            @input="onChange(group.value, item.value, $event)"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="groupMatch[group.value]"
            :label="group.text"
            :items="matches"
            hide-details
            outlined
            @change="emitChange(group.value)"
          />
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-checkbox
        v-model="match"
        :label="matchLabel"
        true-value="any"
        false-value="all"
        class="mx-3"
      />
      <v-checkbox v-model="isAddonFilter" label="Use Addon Filter" class="mx-3" />
    </v-row>
    <!-- dev  -->
    <!-- <v-card color="blue lighten-5" class="my-6" flat>
      <v-card-text>{{ itemValues }}</v-card-text>
    </v-card>-->
  </div>
</template>

<script>
import model from "./mixins/model";
import props from "./mixins/props";
import mlQueryFilter from "./QueryFilter";
import mlQueryAddonFilter from "./QueryAddonFilter";

export default {
  components: {
    mlQueryFilter,
    mlQueryAddonFilter,
  },

  props,

  data() {
    return {
      isAddonFilter: false,
      match: this.matchDefault,
      groupMatch: {},
      itemValues: [],
    };
  },

  beforeMount() {
    this.groups.forEach((group) => {
      this.groupMatch[group.value] = this.matchDefault;
    });
  },

  methods: {
    onChange(group, item, values) {
      console.log("onChange", values);
      const index = this.itemValues.findIndex(
        (a) => a.group === group && a.item === item
      );

      const entry = { group, item, values };

      if (index < 0) {
        this.itemValues.push(entry);
      } else {
        this.itemValues[index].values = values;
      }

      return this.emitChange(group);

      /**
       * Emit Change: group
       */
      const items = this.itemValues.filter((a) => a.group === group);

      // str
      let str = "";

      if (items.length > 0) {
        items.forEach((item) => {
          if (item.values.length > 0) {
            str += `${item.item},` + item.values.join(",");
            str += "|";
          }
        });

        if (str != "") {
          str += this.groupMatch[group];
        }
      }

      this.$emit(group, str);
    },

    emitChange(group) {
      const items = this.itemValues.filter((a) => a.group === group);

      // str
      let str = "";

      if (items.length > 0) {
        items.forEach((item) => {
          if (item.values.length > 0) {
            str += `${item.item},` + item.values.join(",");
            str += "|";
          }
        });

        if (str != "") {
          str += this.groupMatch[group];
        }
      }

      this.$emit(group, str);
    },
  },
};
</script>