export default {
  color: "grey lighten-5",
  dark: false,
  dense: false,
  flat: true,
  toolbar: {
    color: "grey lighten-4",
    activeClass: "grey lighten-5",
    dark: false,
    dense: true,
    flat: true,
    class: "",
    itemSpacing: 3,
  },
  dialog: {
    maxWidth: 900,
    toolbar: {
      dense: true,
      flat: true,
      tile: true,
      dark: false,
      color: "grey lighten-3",
      class: "mb-2",
    },
  },

  tabs: [
    {
      text: "Block",
      value: "block",
    },
    {
      text: "Spacing",
      value: "spacing",
    },
    {
      text: "Auth",
      value: "auth",
    },
  ],
  colToolbar: {
    // color: "grey darken-3", // grey lighten-3
    color: "white", // grey lighten-3
    dark: false,
  },
};
