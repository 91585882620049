// import all from "./all";

// console.log("all", all);
export default {
  // ...all,
  content: {
    heading: {},
    subheading: {},
    content: {},
  },
};
