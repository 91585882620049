export default [
  {
    text: "primary",
    value: "primary",
  },
  {
    text: "secondary",
    value: "secondary",
  },
  {
    text: "disabled",
    value: "disabled",
  },
];
