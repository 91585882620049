import beforeMount from './before-mount';
import computed from './computed';
import data from './data';
import events from './events';
import methods from './methods';

// mixins
import blockMixin from '../../../mixins/block';

export default {
	mixins: [blockMixin],
	data,
	computed,
	methods: {
		...events,
		...methods,
	},
	beforeMount,
};
