<template>
  <div v-if="isMobile">
    <v-btn icon class="mr-2" @click="show = !show">
      <v-icon>menu</v-icon>
    </v-btn>

    <v-navigation-drawer v-model="show" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-if="title" class="title" v-text="title" />
          <v-list-item-subtitle v-if="subtitle" v-text="subtitle" />
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <template v-for="item in items">
          <v-list-item v-if="!item.children.length" :key="item.id" link>
            <v-list-item-title @click="onClick(item)">{{ item.text }}</v-list-item-title>
          </v-list-item>

          <!-- group  -->
          <v-list-group v-else :key="item.id">
            <template v-slot:activator>
              <v-list-item-title @click="onClick(item)">{{ item.text }}</v-list-item-title>
            </template>

            <!-- children  -->
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-title v-text="child.text"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    imgSrc: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      ready: false,
      show: false,
      right: null
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "MPages/isMobile",
      items: "MPages/navItems",
      isBuilder: "MPages/isBuilder"
    })
  },

  methods: {
    onClick(item) {
      console.log("item", item);
    }
  },

  mounted() {}
};
</script>