export default function(options) {
  return new Promise((resolve, reject) => {
    const { store, router, logoutTo } = options;

    // already logged
    if (!store.state.auth.loggedIn) {
      return resolve();
    }

    const Api = store._vm.$api;
    const url = options.logoutUrl || `${options.url}/logout`;

    const logUserOut = () => {
      localStorage.removeItem(options.localKey);
      store.commit("auth/SET", { loggedIn: false, user: {} });
      router.replace(logoutTo);
    };

    Api.post(url)
      .then(({ data }) => {
        logUserOut();
        resolve(data);
      })
      .catch(({ response }) => {
        logUserOut();
        const data = response.data;
        data.status = response.status;
        reject(data);
      });
  });
}
