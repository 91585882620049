<template>
  <v-switch label="LABLE" hide-details @change="$emit('input', $event)" />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      val: false
    };
  },

  watch: {
    //
  },

  beforeMount() {
    this.val = this.value;
  }
};
</script>