export default {
	onReady() {
		console.log('READY');
		this.status = 'ready';

		if (this.player.duration) {
			return this.onReadyWithDur();
		}

		const wait = setInterval(() => {
			if (this.player.duration) {
				clearInterval(wait);
				console.log('ready');
				this.onReadyWithDur();
			}
		}, 100);
	},

	onReadyWithDur(nowait = false) {
		// provider require extra wait time
		if (this.provider && !nowait) {
			console.log('PROVIDER WAIT...');
			setTimeout(() => {
				this.onReadyWithDur(true);
			}, 800);
			return;
		}

		this.canplay = true;

		// if (this.muted) {
		this.player.muted = this.muted;
		// }

		if (this.start) {
			this.player.currentTime = this.start;
		}

		console.log(this.player.muted, this.muted);

		if (this.autoplay) {
			this.player.play();
		}
	},

	onPlay() {
		// console.log('ON PLAY');
		this.status = 'playing';
		this.$emit('play');
	},

	onTimeUpdate() {
		// console.log('timeupdate');
		this.$emit('timeUpdate', this.player.currentTime);
	},

	onPause() {
		this.status = 'paused';
		this.$emit('pause');
	},

	onEnded() {
		console.log('ENDED');
		this.status = 'ended';
		this.$emit('ended');
	},

	onSeeked() {
		this.status = 'seeked';

		if (this.player.playing) {
			this.status = 'playing';
		}

		console.log('status', this.status);
	},
};
