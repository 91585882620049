<template>
  <div
    v-html="
      block.content.html ? getContent(block.content.html) : 'Text content...'
    "
  />
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
