<template>
  <img
    :src="img.src || `https://via.placeholder.com/${img.width || 200}x${img.height || 150}?text=IMAGE`"
    alt
    :style="imgStyle"
  />
</template>

<script>
export default {
  props: {
    img: {
      type: Object,
      required: true,
    },
  },

  computed: {
    imgStyle() {
      const style = {};

      const sizes = [
        "width",
        "height",
        "max-width",
        "min-width",
        "max-height",
        "min-height",
      ];

      sizes.forEach((i) => {
        if (this.img[i]) {
          style[i] = this.img[i] + "px";
        }
      });

      return style;
    },
  },
};
</script>
