<template>
	<div :id="id" :class="video.inset ? `pa-${video.inset}` : ''" style="width:100%">
		<div ref="playerContainer" :style="`max-width:${video.maxWidth}px`" class="mx-auto">
			<v-card
				:class="video.border ? `pa-${video.border}` : ''"
				:color="video.color"
				:elevation="video.elevation"
				:tile="video.tile"
				class="mx-auto"
				:max-width="maxWidth"
			>
				<!-- -->
				<v-responsive v-if="!videoSrc" :aspect-ratio="16 / 9">
					<v-layout fill-height>
						<v-row align="center" justify="center" fill-height class="grey lighten-2">
							<v-icon size="200" color="grey">play_arrow</v-icon>
						</v-row>
					</v-layout>
				</v-responsive>

				<div v-else style="position:relative">
					<vue-plyr
						ref="player"
						:emit="['play', 'pause', 'ready', 'ended', 'timeupdate']"
						:options="playerOptions"
						@play="onPlay"
						@pause="onPause"
						@ready="onReady"
						@ended="onEnded"
						@timeupdate="onTimeUpdate"
					>
						<div v-if="useIframe" class="plyr__video-embed">
							<iframe
								:src="videoSrc"
								allowfullscreen
								allowtransparency
								allow="autoplay;fullscreen;transparency"
							></iframe>
						</div>

						<video v-else-if="videoSrc" :poster="video.image ? video.image : ''">
							<source :src="videoSrc" type="video/mp4" size="720" />
							<!-- 
    <source src="video-1080p.mp4" type="video/mp4" size="1080">
              <track kind="captions" label="English" srclang="en" src="captions-en.vtt" default>-->
						</video>
					</vue-plyr>
					<v-fade-transition>
						<m-pages-link-container
							v-if="showEndedPoster || showPoster"
							:href="showEndedPoster && video.endedPosterLink ? video.endedPosterLink : ''"
							open-new
						>
							<v-img
								:src="showEndedPoster ? video.endedPoster : video.poster"
								style="position:absolute;left:0px;top: 0px;width:100%;height:100%"
								alt
							/>
						</m-pages-link-container>
					</v-fade-transition>
				</div>
			</v-card>
		</div>
		<!-- playerOptions: {{ playerOptions }} -->
	</div>
</template>

<script>
import computed from './player/js/computed';
import events from './player/js/events';
import methods from './player/js/methods';
import props from './player/js/props';
import watch from './player/js/watch';
// import stickyPlayer from "./player/js/sticky-player";
import mixins from './player/js/mixins';

export default {
	props,
	mixins,
	watch,
	computed,
	methods: {
		...methods,
		...events,
	},
	data() {
		return {
			status: '',
			pendingPause: false,
			pendingPlay: false,
			playerReady: false,
		};
	},
};
</script>

<style>
.m-pages-player-pip {
	position: fixed;
	right: 0px;
	top: 0px;
	width: 400px;
	z-index: 100000;
	transition: all 2s;
}
</style>
