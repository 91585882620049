<template>
  <v-btn small target="_blank" :href="debuggerUrl" :disabled="!url" depressed>FB Debugger</v-btn>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ""
    }
  },

  computed: {
    debuggerUrl() {
      return (
        "https://developers.facebook.com/tools/debug/sharing/?q=" +
        encodeURIComponent(this.url)
      );
    }
  }
};
</script>