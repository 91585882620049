export default {
  data() {
    return {
      pageCopied: false,
      canPastePage: false,
      copiedPage: "",
    };
  },

  methods: {
    copyPage() {
      const str = JSON.stringify(this.data);
      this.copiedPage = str;
      this.$localforage.setItem("copy_page", str);
      console.log("copy page", this.data);
    },
    pastePage() {
      this.data = JSON.parse(this.copiedPage);
    },
  },

  mounted() {
    this.$localforage.getItem("copy_page").then((value) => {
      if (value) {
        this.copiedPage = value;
      }
    });
  },
};
