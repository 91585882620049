<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Buy Button</v-tab>
      <v-tab v-if="val.options.label">Label</v-tab>
      <v-tab v-if="val.options.heading">Heading</v-tab>
      <v-tab v-if="val.options.content">Content</v-tab>
      <v-tab>Button</v-tab>
      <!-- <v-tab v-if="val.options.icon">Icon</v-tab> -->
      <v-tab v-if="val.options.footer">Footer</v-tab>
      <!-- <v-tab>Extra</v-tab> -->

      <v-tabs-slider></v-tabs-slider>

      <v-tab-item>
        <!-- <m-pages-form v-model="val.options" :form="bb.options" /> -->
        <v-row>
          <v-col>
            <v-text-field v-model="val.text" label="Button Text" />
            <v-text-field v-model="val.url" label="Checkout Url" />
            <v-slider
              v-if="val.options.useWidth"
              v-model="val.width"
              label="Width"
              min="200"
              max="800"
            />

            <v-slider
              v-if="val.options.cards || val.options.paypal"
              v-model="val.options.cardsSize"
              label="Cards Size"
              min="18"
              max="200"
            />
          </v-col>
          <v-col>
            <m-pages-panel title="Options">
              <v-row
                v-for="(row, index) in options"
                :key="index"
                align="start"
                justify="start"
              >
                <v-switch
                  v-for="option in row"
                  :key="option.value"
                  v-model="val.options[option.value]"
                  :label="option.text"
                  class="mx-3"
                />
              </v-row>
            </m-pages-panel>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item v-if="val.options.label">
        <!-- <m-pages-panel title="Label"> -->
        <m-pages-form v-model="val.label" :form="bb.label" />
        <!-- </m-pages-panel> -->
      </v-tab-item>

      <v-tab-item v-if="val.options.heading">
        <m-pages-form v-model="val.heading" :form="bb.heading" />
      </v-tab-item>

      <v-tab-item v-if="val.options.content">
        <m-editor v-model="val.content" />
      </v-tab-item>

      <!-- <v-tab-item v-if="val.options.icon">
        <m-pages-form v-model="val.button" :form="bb.button" />
      </v-tab-item> -->

      <v-tab-item>
        <m-pages-form v-model="val.button" :form="bb.button" />
      </v-tab-item>

      <v-tab-item v-if="val.options.footer">
        <m-editor v-model="val.footer" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import bb from "../../js/forms/buy-button";
import fields from "../../js/forms/fields";

export default {
  mixins: [model],

  data() {
    return {
      bb,
      tab: 1,
      options: [
        [
          {
            text: "Label",
            value: "label",
            type: "switch",
          },
          {
            text: "Heading",
            value: "heading",
            type: "switch",
          },
          {
            text: "Content",
            value: "content",
            type: "switch",
          },
          {
            text: "Icon",
            value: "icon",
            type: "switch",
          },
          {
            text: "Footer",
            value: "footer",
            type: "switch",
          },
        ],
        [
          fields.dark,
          fields.flat,
          fields.tile,
          fields.useWidth,
          {
            text: "Cards",
            value: "cards",
          },
          {
            text: "PayPal",
            value: "paypal",
          },
        ],
        // {
        //   text: "J-Box",
        //   value: "jbox",
        //   type: "switch",
        // },
        // fields.useIcon,
      ],
    };
  },
  beforeMount() {
    this.val.label = {};
  },
};
</script>
