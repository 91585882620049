<template>
  <div>
    <m-pages-panel title="Drip" :hide-content="!drip.active" :flat="!drip.active">
      <template slot="toolbar">
        <v-spacer />
        <v-switch
          v-model="drip.active"
          label="Drip Active"
          hide-details
          color="success"
          @change="onInput"
        />
      </template>
      <v-row>
        <v-col cols="6">
          <m-autocomplete
            v-model="drip.itemId"
            label="Drip"
            :endpoint="endpoint"
            outlined
            hide-details
            @input="onInput"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="drip.value"
            label="After"
            type="number"
            min="1"
            max="365"
            outlined
            hide-details
            @input="onInput"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="drip.units"
            label="Units"
            :items="timeUnits"
            outlined
            hide-details
            @input="onInput"
          />
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-if="drip.useTime">
          <v-col>
            <m-time-picker v-model="drip.time" outlined hide-details @input="onInput" />
          </v-col>
          <v-col>
            <m-timezone-picker v-model="drip.tz" outlined hide-details @input="onInput" />
          </v-col>
        </v-row>
      </v-expand-transition>

      <!-- drip: {{drip}} -->
      <v-expand-transition>
        <v-row v-if="drip.useWeekDay">
          <v-col cols="6">
            <v-select
              v-model="drip.weekDay"
              label="Week Day"
              :items="weekDays"
              outlined
              hide-details
              @input="onInput"
            />
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-row>
        <v-checkbox
          v-for="item in extras"
          :key="item.value"
          v-model="drip[item.value]"
          :label="item.text"
          class="ml-4"
          hide-details
          @change="onInput"
        />
      </v-row>
    </m-pages-panel>
  </div>
</template>

<script>
import timeUnits from "@/helpers/time/units";
import weekDays from "@/helpers/time/week-days";

export default {
  props: {
    value: {
      type: Object,
      required: false,
    },

    endpoint: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      timeUnits,
      weekDays,
      drip: {
        active: false,
        useTime: false,
        useWeekDay: false,
        itemId: "",
        value: 7,
        units: "d",
        weekDay: "",
      },
      extras: [
        {
          text: "Add Time",
          value: "useTime",
        },
        {
          text: "Add Weekday",
          value: "useWeekDay",
        },
      ],
    };
  },

  methods: {
    onInput() {
      this.$emit("input", { ...this.drip });
    },
  },

  beforeMount() {
    if (this.value) {
      this.drip = { ...this.value };
    }
  },
};
</script>