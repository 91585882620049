import item from "../../store/helpers/item";

// use to toggle menu
import menu from "../../store/menu";

const state = {
  ...item.state,
  ...menu.state,
};

const mutations = {
  ...item.mutations,
  ...menu.mutations,
  //
};

const getters = {
  ...item.getters,
  ...menu.getters,
};

const actions = {
  ...item.actions,
  ...menu.actions,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
