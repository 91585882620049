<template>
  <div>
    <m-pages-panel title="Placeholder">
      <v-row>
        <v-select v-model="val.name" label="Type" :items="items" class="mx-2" />
        <v-text-field v-model="val.value" v-if="val.name != 'content'" label="Value" class="mx-2" />
      </v-row>
    </m-pages-panel>
  </div>
</template>

<script>
import model from "../../mixins/model";
export default {
  mixins: [model],
  data() {
    return {
      items: [
        {
          text: "Partial",
          value: "partial",
        },
        // {
        //   text: "Block",
        //   value: "block",
        // },
        {
          text: "Content (all blocks)",
          value: "content", // allblocks
        },
      ],
    };
  },
};
</script>
