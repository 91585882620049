const margin = (v, shortText) => {
  return {
    shortText,
    text: "Margin " + v.toUpperCase(),
    value: `m${v}`,
    type: "slider",
    min: -12,
    max: 12,
    thumbLabel: true,
  };
};

const ma = margin("a", "All");
const mx = margin("x", "X");
const my = margin("y", "Y");
const mt = margin("t", "Top");
const mb = margin("b", "Bottom");
const ml = margin("l", "Left");
const mr = margin("r", "Right");
const ms = margin("s", "MS");
const me = margin("e", "ME");

export default {
  ma,
  mx,
  my,
  mt,
  mb,
  ml,
  mr,
  ms,
  me,
};

export const names = ["ma", "mx", "my", "mt", "mb", "ml", "mr", "ms", "me"];
