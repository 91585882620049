import setup from "./setup";
import login from "./login";
import logout from "./logout";
import update from "./update";
import help from "./help";
import auth from "./store";

import { validateRecoveryToken, recoveryTokenLogin } from "./recovery";

import mixin from "./mixin";
import defaultOptions from "./options";

export default {
  install(Vue, options) {
    const { store, router } = options;
    // options
    for (let k in defaultOptions) {
      if (typeof options[k] === "undefined") {
        options[k] = defaultOptions[k];
      }
    }

    if (!options.localKey) {
      options.localKey = `${options.name}_token`;
    }

    // components
    if (options.useComponents) {
      Vue.component("auth-login", require("./components/Login").default);
      Vue.component("auth-recovery", require("./components/Recovery").default);
      Vue.component("auth-user-menu", require("./components/UserMenu").default);
      Vue.component("auth-user-form", require("./components/UserForm").default);
      Vue.component(
        "auth-wrapper",
        require("./components/AuthWrapper").default
      );
    }

    if (options.useLoginPageComponent) {
      Vue.component("auth-login-page", require("./pages/Login").default);
    }

    // add mixin
    Vue.mixin(mixin(store));

    // register module
    store.registerModule("auth", auth); // WkRKRoBzKZk
    store.commit("auth/SET", { userFields: options.userFields });
    if (options.loginPage) {
      store.commit("auth/SET", { loginPage: options.loginPage });
    }

    // setup
    if (!options.disable) {
      setup(options);
    }

    // if (router) {
    //   router.beforeEach((to, from, next) => {
    //     if (store._vm.authReady && !store._vm.authenticated && to.path != "login") {
    //       console.log(to.name);
    //       // return next({ path: "login" });
    //     } else {
    //       next();
    //     }
    //   });
    // }

    Vue.prototype.$auth = {
      login(data) {
        return login({ options, data });
      },
      logout() {
        return logout(options);
      },
      help(data) {
        return help({ options, data });
      },
      update(data) {
        return update({ options, data });
      },
      validateRecoveryToken(token) {
        return validateRecoveryToken({ options, token });
      },
      recoveryTokenLogin(data) {
        return recoveryTokenLogin({ options, data });
      },
      start() {
        setup(options);
      },
    };

    // if (typeof window !== "undefined" && window.Vue) {
    //   window.Vue.use(apiArchitect);
    // }
  },
};
