const removeDeepItem = (id, items) => {
  let found = false;
  items.forEach((item, index) => {
    if (!found) {
      if (item.id == id) {
        items.splice(index, 1);
        found = true;
      } else if (item.children) {
        item.children = removeDeepItem(id, item.children);
      }
    }
  });

  return items;
};

export default removeDeepItem;
