export default [
  {
    text: "Top",
    value: "_top",
  },
  {
    text: "Self",
    value: "_self",
  },
  {
    text: "New Window",
    value: "_blank",
  },
  {
    text: "Parent",
    value: "_parent",
  },
];
