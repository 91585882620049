<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Header</v-tab>
      <v-tab :class="val.options.img ? '' : 'd-none'">Logo</v-tab>
      <v-tab :class="val.options.button ? '' : 'd-none'">Button</v-tab>
      <v-tab :class="val.options.button2 ? '' : 'd-none'">Second Button</v-tab>

      <v-tabs-slider></v-tabs-slider>

      <v-tab-item>
        <div>
          <m-pages-form v-model="val.options" :form="forms.options" />
        </div>

        <m-pages-form
          v-model="val.heading"
          :form="forms.heading"
          :label-filter="formLabelFilter"
        />
      </v-tab-item>

      <v-tab-item :class="val.options.img ? '' : 'd-none'">
        <m-pages-form v-model="val.img" :form="forms.img" />
      </v-tab-item>

      <v-tab-item :class="val.options.button ? '' : 'd-none'">
        <m-pages-form v-model="val.button" :form="forms.button" />
      </v-tab-item>

      <v-tab-item :class="val.options.button2 ? '' : 'd-none'">
        <m-pages-form v-model="val.button2" :form="forms.button" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import forms from "../../js/forms/header";

export default {
  mixins: [model],
  data() {
    return {
      forms,
      tab: 0,
      modelItem: {
        options: {},
        button: {},
        button2: {},
        img: {},
      },
      tabs: [
        {
          text: "Header",
          value: "header",
        },
        {
          text: "Logo",
          value: "img",
        },
        {
          text: "Button",
          value: "button",
        },
        {
          text: "Button 2",
          value: "button2",
        },
      ],
    };
  },
  methods: {
    formLabelFilter(item) {
      if (item.text === "Heading Text") {
        return "Business Name";
      }
      if (item.text === "Subheading Text") {
        return "Business Slogan";
      }

      return item.text;
    },
  },
};
</script>
