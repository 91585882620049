<template>
  <div>
    <div v-if="showEditor">
      <html-editor v-model="val" @input="onEditor"></html-editor>
      <v-btn block large @click="onDone" depressed class="mt-n4"
        >Done Editing</v-btn
      >
    </div>
    <v-expand-transition>
      <div
        v-if="!showEditor"
        v-html="value"
        style="cursor:pointer"
        @click="onClick"
      />
    </v-expand-transition>
  </div>
</template>

<script>
import htmlEditor from '@/components/Tinymce'
export default {
  components: {
    htmlEditor,
  },

  props: {
    value: {
      type: [Object, String],
      default: '',
    },
  },

  data() {
    return {
      val: '',
      showEditor: false,
    }
  },

  methods: {
    onDone() {
      this.$emit('input', this.val)
      this.showEditor = false
    },

    onEditor(v) {
      this.val = v
      // this.$emit("input", v);
      console.log('updated', v)
    },
    onClick() {
      this.val = this.value
      this.showEditor = true
      console.log('onClick', this.value)
    },
  },
}
</script>
