var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"hero"},[(_vm.parallax || _vm.block.parallax)?_c('v-parallax',{attrs:{"height":_vm.block.matchHeight
        ? _vm.imageHeight
        : _vm.block.height
        ? _vm.block.height
        : _vm.imageHeight,"src":_vm.block.img.src,"alt":_vm.block.img.alt,"dark":_vm.block.dark}},[_vm._t("default")],2):_c('v-img',{attrs:{"height":_vm.block.matchHeight
        ? _vm.imageHeight
        : _vm.block.height
        ? _vm.block.height
        : _vm.imageHeight,"src":_vm.block.img.src,"alt":_vm.block.img.alt,"dark":_vm.block.dark},on:{"load":_vm.onImageLoaded}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }