<template>
  <div>CARD {{block}}</div>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
