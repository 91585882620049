<template>
  <m-pages-card :item="card">
    <!-- padding: {{  }} -->
    <div
      v-if="item.options.label"
      :class="item.options.px ? `mx-n${item.options.px}` : ''"
    >
      <m-pages-label :item="item.label" />
    </div>

    <div class="text-center" :class="`pt-${item.options.spacingY}`">
      <!-- BUY BUTTON -->

      <!-- Heading  -->
      <template v-if="item.options.heading">
        <div :class="`pb-${item.options.spacingY} mb-n3`">
          <m-pages-text :item="item.heading.heading" text-size="h4" />
        </div>
        <div :class="`pb-${item.options.spacingY}`">
          <m-pages-text
            :item="item.heading.subheading"
            text-size="subtitle-1"
          />
        </div>
      </template>

      <!-- Custom Heading  -->
      <div
        v-if="item.options.content"
        v-html="item.content"
        :class="`pb-${item.options.spacingY}`"
      ></div>

      <!-- Button -->
      <div :class="`pb-${item.options.spacingY}`">
        <m-pages-button :item="item.button" :min-width="180" :min-height="50">
          <v-row v-if="item.options.icon" justify="space-around" align="center">
            <v-icon class="mr-n4" large>shopping_cart</v-icon>
            <div>{{ item.text || "Buy Now" }}</div>
            <div></div>
          </v-row>
          <template v-else>
            {{ item.text || "Buy Now" }}
          </template>
        </m-pages-button>
      </div>

      <div v-if="item.options.cards || item.options.paypal" class="mb-3">
        <m-pages-cards
          :size="item.options.cardsSize"
          :cards="item.options.cards"
          :paypal="item.options.paypal"
        />
      </div>

      <!-- Custom Footer  -->
      <div
        v-if="item.options.footer"
        v-html="item.footer"
        :class="`caption pb-${item.options.spacingY}`"
      ></div>
    </div>
  </m-pages-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    card() {
      const item = { ...this.item.options };

      console.log({ item });

      // item.width =
      // this.item.options.useWidth && this.item.width ? this.item.width : "";
      item.border = "dashed 4px red";

      return item;
    },
  },
};
</script>
