<template>
  <!-- from n12 to 12 -->
  <v-slider
    v-model="slider"
    :label="label"
    :min="min"
    :max="12"
    ticks
    thumb-label
    @start="sliding = true"
    @end="sliding = false"
    @input="onInput"
  />
</template>

<script>
export default {
  props: {
    min: {
      type: [Number, String],
      default: 0
    },
    value: {
      type: [Number, String],
      default: 0
    },
    label: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      slider: 0,
      sliding: false
    };
  },

  watch: {
    value() {
      if (!this.sliding) {
        this.getValue();
      }
    }
  },

  methods: {
    onInput(v) {
      if (v < 0) {
        v = `n${v - 2 * v}`;
      }
      this.$emit("input", v);
    },
    getValue() {
      if (typeof this.value === "number") {
        this.slider = this.value;
      } else {
        this.slider = parseInt(this.value.replace("n", "-"));
      }
    }
  },

  beforeMount() {
    if (this.value) {
      this.getValue();
    }
  }
};
</script>