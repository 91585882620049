export default {
	showPlayer() {
		return this.ready && this.index != null;
	},

	playerColor() {
		return this.block.player.color ? this.block.player.color : this.theme.color ? this.theme.color : '';
	},

	video() {
		if (!this.showPlayer) {
			return {};
		}

		return this.items[this.index];
	},

	source() {
		if (!this.showPlayer) {
			return {};
		}

		const video = this.video;

		const source = {
			src: video.src,
			poster: video.poster || '',
		};
		const provider = !isNaN(video.src) ? 'vimeo' : video.src && video.src.length == 11 ? 'youtube' : false;

		if (provider) {
			source.provider = provider;
		} else {
			source.type = 'video/mp4';
			source.size = video.size || 720;
		}

		return {
			type: 'video',
			title: video.title,
			sources: [source],

			// sources: this.items.map((v) => {
			// 	// console.log('items');
			// 	const item = {
			// 		src: v.src,
			// 		poster: v.poster || '',
			// 		size: 720,
			// 	};
			// 	const provider = !isNaN(v.src) ? 'vimeo' : v.src.length == 11 ? 'youtube' : '';

			// 	if (provider) {
			// 		item.provider = provider;
			// 	} else {
			// 		item.type = 'video/mp4';
			// 	}

			// 	return item;
			// }),
		};
	},
};
