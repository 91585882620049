import camelToText from "../../../helpers/camel-to-text";
const items = [
  "title",
  { text: "Image", value: "src", placeholder: "https://" },
  { text: "Link", value: "href", placeholder: "https://" },
];

const fields = {};

items.forEach((value) => {
  if (typeof value == "object") {
    fields[value.value] = {
      text: value.value,
      value: value,
      type: "text",
      ...value,
    };
  } else {
    fields[value] = {
      text: camelToText(value),
      value,
      type: "text",
    };
  }
});

export default fields;
