export default {
	onReady() {
		if (this.player.duration) {
			return this.onReadyWithDuration();
		}

		const wait = setInterval(() => {
			if (this.player.duration) {
				clearInterval(wait);
				return this.onReadyWithDuration();
			}
		}, 50);
	},
	onReadyWithDuration() {
		// console.log('player ready with duration', this.player.duration);
		// this.unmute();

		if (this.currentTime > -1) {
			this.player.currentTime = this.currentTime;
		}
		this.status = 'ready';
		this.$emit('ready', Math.floor(this.player.duration));

		if (this.seekto) {
			this.player.currentTime = this.seekto;
		}

		this.playerReady = true;

		this.unmute();

		if (this.autoplay) {
			this.playVideo();
		}
	},

	onPlay() {
		if (this.pendingPause) {
			this.pendingPause = false;
			return this.player.pause();
		}
		this.status = 'playing';
		this.$emit('play');
		// this.player.pip = true;
	},
	onPause() {
		if (this.pendingPlay) {
			this.pendingPlay = false;
			return this.player.play();
		}

		this.status = 'paused';
		this.$emit('pause');
	},

	onTimeUpdate() {
		this.$emit('timeUpdate', this.player.currentTime);
	},

	onEnded() {
		this.status = 'ended';
		this.$emit('ended');
	},
};
