export default {
  props: {
    blockList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
