export const setPlayerColor = (id, color) => {
  if (id) {
    id = `#${id}`;
  }

  const el = document.querySelector(`${id} .plyr__control--overlaid`);

  if (el) {
    el.style.backgroundColor = color;
    document.querySelector(
      `${id} .plyr--full-ui input[type=range]`
    ).style.color = color;
    const els = document.querySelectorAll(
      `${id} .plyr--full-ui input[type=range]`
    );
    els.forEach((el) => {
      el.style.color = color;
    });
  }
};

export const playerColorReady = (id) => {
  return document.querySelector(`#${id} .plyr__control--overlaid`)
    ? true
    : false;
};

export default setPlayerColor;
