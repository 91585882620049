<template>
  <v-text-field rows="1" v-model="val" :label="label" placeholder="+1234567890" hide-details />
</template>

<script>
import model from "../../mixins/model-str";
export default {
  props: {
    label: {
      type: String,
      default: "Checkout Code",
    },
  },
  mixins: [model],
};
</script>