<template>
  <v-textarea
    v-model="val"
    :rows="rows"
    :label="label"
    :hide-details="hideDetails"
    @input="onInput"
  />
</template>

<script>
import { encode, decode } from "js-base64";
export default {
  props: {
    hideDetails: Boolean,
    label: {
      type: String,
      default: "Email",
    },
    value: {
      type: String,
      default: "",
    },
    rows: {
      type: [String, Number],
      default: 3,
    },
  },

  watch: {
    value() {
      if (!this.ready) {
        this.ready = true;
        this.val = this.value ? decode(this.value) : "";
      }
    },
  },

  data() {
    return {
      val: "",
      ready: false,
    };
  },

  methods: {
    onInput() {
      this.$emit("input", encode(this.val));
    },
  },

  beforeMount() {
    if (this.value) {
      this.val = decode(this.value);
      this.ready = true;
    }
  },
};
</script>