import Editor from "./Editor";
import EditorPanel from "./layouts/Panel";
import EditorSlider from "./components/form/Slider";
import EditorBlockToolbar from "./components/BlockToolbar";
// import EditorBlockToolbar from "./components/BlockToolbar";
import defaultOptions from "./config/options";

// import components from "./components";
// import loadComponents from "./helpers/load-components";

import storeModule from "./store";

export default {
  install(Vue, options = {}) {
    const { store } = options;
    /**
     * Options
     */
    for (let k in defaultOptions) {
      if (typeof options[k] === "undefined") {
        options[k] = defaultOptions[k];
      }
    }

    /**
     * Components
     */

    Vue.component(options.name, Editor);
    Vue.component("editor-panel", EditorPanel);
    Vue.component("editor-slider", EditorSlider);
    Vue.component("editor-block-toolbar", EditorBlockToolbar);
    // Vue.component("editor-block-toolbar", EditorBlockToolbar);
    // loadComponents(Vue, components);

    /**
     * Store
     */
    store.registerModule("editor", storeModule); // WkRKRoBzKZk
  },
};
