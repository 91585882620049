<template>
  <div v-if="ready">
    <v-container>
      <!-- https://goo.gl/maps/9EG6gcjGkkaeFuqw7
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21575.023994927193!2d8.30526452977084!3d47.472806819068246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47906d299a38f2d9%3A0x9f467057910cc7ef!2sRestaurant%20Freihof!5e0!3m2!1sen!2sch!4v1599555339660!5m2!1sen!2sch" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
      <v-row>
        <v-col cols="7">
          <v-expansion-panels v-model="panel" class="mb-8">
            <v-expansion-panel v-for="field in fields" :key="field.value">
              <v-expansion-panel-header>
                <v-row justify="space-between">
                  <div>{{ field.title }}</div>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row no-gutters class="mx-n2">
                  <v-col
                    v-for="item in field.items"
                    :key="item.value"
                    :cols="item.cols || 12"
                    class="px-2"
                  >
                    <component
                      v-if="item.component"
                      v-bind:is="item.component"
                      v-model="val[field.value][item.value]"
                      :label="item.text"
                      inline-form
                    />

                    <v-text-field
                      v-else-if="!item.fieldType"
                      v-model="val[field.value][item.value]"
                      :label="item.text"
                      :placeholder="item.placeholder"
                    />
                    <v-textarea
                      v-else-if="item.fieldType == 'textarea'"
                      v-model="val[field.value][item.value]"
                      :label="item.text"
                      :rows="item.rows"
                    />

                    <v-slider
                      v-else-if="item.fieldType == 'slider'"
                      v-model="val[field.value][item.value]"
                      :label="item.text"
                      :min="item.min"
                      :max="item.max"
                    />

                    <v-switch
                      v-else-if="item.fieldType == 'switch'"
                      v-model="val[field.value][item.value]"
                      :label="item.text"
                    />
                    <v-checkbox
                      v-else-if="item.fieldType == 'checkbox'"
                      v-model="val[field.value][item.value]"
                      :label="item.text"
                    />
                  </v-col>
                </v-row>
                <!-- <m-raw-displayer :value="setup.tracking" /> -->

                <v-row v-if="field.copyBusiness">
                  <v-btn
                    small
                    depressed
                    class="mr-1"
                    @click="copyBusiness(field.value)"
                  >Copy Business Details</v-btn>
                  <debugger-btn />
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col>
          <v-fade-transition>
            <div
              v-if="panel == 2 && val.actionBanner.active"
              style="position:fixed;right:20px;bottom:100px;z-index:100"
            >
              <h3 class="mb-4 ml-12 grey--text">Desktop Preview</h3>
              <m-pages-action-banner :item="val.actionBanner" :theme="theme" />
              <div class="my-8"></div>

              <h3 class="mb-4 ml-12 grey--text">Mobile Preview</h3>
              <m-pages-action-banner
                :item="val.actionBanner"
                :theme="theme"
                is-mobile
                is-mobile-preview
                no-fixed
              />
            </div>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
    <span v-if="refresh"></span>
  </div>
</template>

<script>
import model from "../mixins/model-watch";
import socielFields from "../js/block-fields/social";

import debuggerBtn from "./components/DebuggerBtn";

import fields from "./js/setup-fields";
import { mapGetters } from "vuex";

export default {
  components: {
    debuggerBtn,
  },

  props: {
    blockList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  mixins: [model],

  data() {
    return {
      refresh: false,
      panel: null,
      ready: false,
      socielFields,
      fields,
      actionBannerLinkForm: {},
    };
  },

  computed: {
    ...mapGetters({
      theme: "MPages/theme",
      parsedSetup: "MPages/parsedSetup",
      setup: "MPages/setup",
      parsedActionBanner: "MPages/actionBanner",
    }),
  },

  methods: {
    // onLinkForm(field, value) {
    //   this.setup[field] = { ...this.setup[field], ...value };
    // },

    copyBusiness(value) {
      if (value == "seo") {
        this.seoCopyBusiness();
      }
      if (value == "og") {
        this.ogCopyBusiness();
      }
      if (value == "actionBanner") {
        console.log("copy");
        this.actionBannerCopyBusiness();
      }
      this.refresh = !this.refresh;
    },

    actionBannerCopyBusiness() {
      const biz = this.val.business;
      const banner = this.val.actionBanner;
      banner.title = "Hi, Lets Talk!"; // biz.name
      banner.text = `Call us on ${biz.phone}`;
      banner.footer = biz.location;
      banner.buttonText = "Call Now!";
      banner.mobileButtonText = "Tap To Call!";
      banner.icon = "phone";
      banner.image = biz.logo;
      banner.active = true;
    },

    seoCopyBusiness() {
      const seo = this.val.seo;
      const biz = this.val.business;
      seo.title = biz.name;
      seo.description = biz.slogan;
      seo.favicon = biz.logo;

      this.refresh = !this.refresh;
    },

    ogCopyBusiness() {
      const og = this.val.og;
      const biz = this.val.business;
      og.title = biz.name;
      og.description = biz.slogan;
      og.image = biz.logo;
      this.refresh = !this.refresh;
    },
  },

  beforeMount() {
    if (!this.val.contact) {
      this.val.contact = {};
    }
  },

  mounted() {
    this.fields.forEach((field) => {
      if (!this.val[field.value]) {
        this.val[field.value] = {};
      }
    });

    this.ready = true;
  },
};
</script>
