<template>
  <!-- authenticated check required  -->
  <v-card width="360" v-if="authenticated">
    <v-card-title>My Account</v-card-title>
    <v-card-text>
      <form @submit.prevent="submit">
        <v-text-field
          v-for="field in fields"
          :key="field.name"
          v-model="form[field.name]"
          :label="field.label"
          outlined
        />

        <new-pass v-if="changePass" v-model="newPass" />

        <v-switch v-model="changePass" class="mx-2 mt-n2" label="Change Password" />
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          :disabled="!valid || !changed"
        >Save Changes</v-btn>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import NewPass from "./NewPass";

export default {
  components: {
    NewPass,
  },

  data() {
    return {
      loading: false,
      newPass: "",
      changePass: false,
      showPass: false,
      str: "",
    };
  },

  beforeMount() {
    this.str = JSON.stringify(this.form);
  },

  computed: {
    fields() {
      return this.$store.state.auth.userFields;
    },
    form() {
      return this.user;
    },
    changed() {
      return (
        (this.changePass && this.newPass) ||
        JSON.stringify(this.form) != this.str
      );
    },

    valid() {
      if (this.changePass && !this.newPass) {
        return false;
      }

      let ok = true;

      this.fields.forEach((field) => {
        if (field.required && !this.form[field.name]) {
          ok = false;
        }
      });

      if (!isEmail(this.form.email)) {
        return false;
      }

      return ok;
    },
  },

  methods: {
    submit() {
      this.loading = true;

      const form = {};

      if (this.changePass) {
        form.password = this.newPass;
      }

      this.fields.forEach((field) => {
        form[field.name] = this.form[field.name];
      });

      console.log("update", form);
      this.$auth
        .update(form)
        .then(({ message }) => {
          this.loading = false;
          if (this.changePass) {
            this.newPass = "";
            this.changePass = false;
          }
          if (message) {
            this.$msg.success(message);
          }
          this.str = JSON.stringify(this.form);
        })
        .catch((err) => {
          if (err.message) {
            this.$msg.error(err.message);
          }
          this.loading = false;
        });
    },
  },
};
</script>
