export default [
  {
    text: "Square",
    value: "-0",
  },
  {
    text: "Small",
    value: "-sm",
  },
  {
    text: "Medium",
    value: "",
  },
  {
    text: "Large",
    value: "-lg",
  },
  {
    text: "X-Large",
    value: "-xl",
  },
  {
    text: "Pill",
    value: "-pill",
  },
  {
    text: "Circle",
    value: "-circle",
  },
  {
    text: "Top",
    value: "-t-xl",
  },
  {
    text: "Bottom",
    value: "-b-xl",
  },
  {
    text: "Left",
    value: "-l-xl",
  },
  {
    text: "Right",
    value: "-r-xl",
  },
  {
    text: "Top Left",
    value: "-tl-xl",
  },
  {
    text: "Top Right",
    value: "-tr-xl",
  },
  {
    text: "Bottom Left",
    value: "-bl-xl",
  },
  {
    text: "Bottom Right",
    value: "-br-pill",
  },
];
