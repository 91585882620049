<template>
  <list-layout crumbs>
    <ml-table
      edit
      delete
      toggle-active
      :create-fields="form"
      can-select
      show-select
      endpoint="admin/pages"
    >
      <template slot="row-actions" slot-scope="{ item }">
        <v-btn icon :to="{ name: 'page-builder', params: { id: item.id } }">
          <v-icon class="mr-1">build</v-icon>
        </v-btn>
      </template>

      <!-- <template v-slot:item-actions-prepend="{ item }">
        <v-btn @click="duplicate(item)">Duplicate</v-btn>
      </template> -->
    </ml-table>
  </list-layout>
</template>

<script>
import { form } from '../mixins/fields/page'
export default {
  data() {
    return {
      form,
      actions: [
        {
          text: 'Delete',
          value: 'delete',
        },
      ],
    }
  },

  // methods: {
  //   async duplicate(item) {
  //     const url = `admin/pages/${item.id}`
  //     const { data } = await this.$api.get(url)
  //     const form = {
  //       ...data.data,
  //       active: false,
  //     }

  //     const res = await this.$api.post('admin/pages', form)

  //     const id = res.data.data.id

  //     this.$router.push({ name: 'page', params: { id } })
  //   },
  // },
}
</script>
