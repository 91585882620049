import { loadComponents } from '@/helpers/loaders'
import FormBuilder from './FormBuilder'
import FormBuilderForm from './Form'

const loadFields = (Vue) => {
  loadComponents(
    Vue,
    require.context('./fields', true, /\.vue$/i),
    'formBuilder'
  )
}

const loadModule = (Vue, name = 'ml-form-builder') => {
  console.log('loadModule', name)
  loadComponents(
    Vue,
    require.context('./components', true, /\.vue$/i),
    'formBuilder'
  )

  loadComponents(
    Vue,
    require.context('./fields', true, /\.vue$/i),
    'formBuilder'
  )
  Vue.component(name, FormBuilder)
  Vue.component(`${name}-form`, FormBuilderForm)
}

export const loadFormOnly = (Vue, name = 'ml-form-builder') => {
  loadFields(Vue)
  Vue.component(`${name}-form`, FormBuilderForm)
}

export default loadModule
