<template>
  <m-pages-panel :title="title" edged>
    <template slot="toolbar">
      <v-spacer />
      <slot name="toolbar" />
      <slot name="active">
        <v-switch
          v-model="auth.active"
          label="Active"
          color="success"
          hide-details
          @change="onInput"
        />
      </slot>
    </template>

    <v-expand-transition>
      <v-expansion-panels
        v-if="!hideInactive || auth.active || active"
        v-model="panel"
        accordion
        class="elevation-0"
      >
        <v-expansion-panel v-for="(option, index) in options" :key="index">
          <v-expansion-panel-header class="elevation-0">
            <v-row no-gutters>
              <v-col class="title">{{ option.title }}</v-col>
              <v-col v-if="activePanels[option.value]" class="shrink pr-4">
                <v-icon
                  large
                  class="my-n2 font-weight-bold"
                  :color="auth.active || active ? 'active' : 'grey lighten-2'"
                  v-text="`check`"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mb-5">
              <v-col v-for="item in option.items" :key="item.value" cols="6">
                <m-autocomplete
                  v-model="auth[item.value]"
                  :label="item.text"
                  :endpoint="option.endpoint"
                  :item-text="option.itemText || 'name'"
                  hide-details
                  multiple
                  chips
                  outlined
                  @input="onInput"
                />
              </v-col>
            </v-row>
            <!-- auth: {{auth}} -->
            <m-pages-auth-drip-form
              v-if="option.drip"
              v-model="auth[`drip_${option.value}`]"
              :endpoint="option.endpoint"
              class="mb-5"
              @input="onInput"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-expand-transition>
  </m-pages-panel>
</template>

<script>
import { isEmpty } from "lodash";

export default {
  props: {
    active: Boolean, // support remote active
    hideInactive: Boolean,
    title: {
      type: String,
      default: "Authorization",
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      panel: null,
      auth: {
        active: false,
        access: [],
      },
      options: [
        {
          title: "Products",
          value: "products",
          endpoint: "admin/products",
          drip: true,
          items: [
            {
              text: "With Any of These Products",
              value: "products",
            },
            {
              text: "With All of These Products",
              value: "all_products",
            },
            {
              text: "Without Any of These Products",
              value: "no_products",
            },
            {
              text: "Without All of These Products",
              value: "no_all_products",
            },
          ],
        },
        // Addons
        {
          title: "Addons",
          value: "addons",
          endpoint: "admin/addons",
          drip: true,
          items: [
            {
              text: "With Any of These Addons",
              value: "addons",
            },
            {
              text: "With All of These Addons",
              value: "all_addons",
            },
            {
              text: "Without Any of These Addons",
              value: "no_addons",
            },
            {
              text: "Without All of These Addons",
              value: "no_all_addons",
            },
          ],
        },

        {
          title: "Roles (memberships)",
          value: "roles",
          endpoint: "admin/roles",
          drip: true,
          items: [
            {
              text: "With Any of These Roles (memberships)",
              value: "roles",
            },
            {
              text: "With All of These Roles (memberships)",
              value: "all_roles",
            },
            {
              text: "Without Any of These Roles (memberships)",
              value: "no_roles",
            },
            {
              text: "Without All of These Roles (memberships)",
              value: "no_all_roles",
            },
          ],
        },
        // Addons
        {
          title: "Tags",
          value: "tags",
          endpoint: "admin/tags",
          drip: true,
          items: [
            {
              text: "With Any of These Tags",
              value: "tags",
            },
            {
              text: "With All of These Tags",
              value: "all_tags",
            },
            {
              text: "Without Any of These Tags",
              value: "no_tags",
            },
            {
              text: "Without All of These Tags",
              value: "no_all_tags",
            },
          ],
        },

        {
          title: "Contacts",
          value: "contacts",
          endpoint: "admin/contacts",
          itemText: "email",
          items: [
            {
              text: "Following Contacts",
              value: "contacts",
            },
            {
              text: "Not These Contacts",
              value: "no_contacts",
            },
          ],
        },
      ],
    };
  },

  computed: {
    activePanels() {
      const values = {};

      const options = this.options;

      options.forEach((option) => {
        option.items.forEach((item) => {
          const dripActive =
            this.auth[`drip_${option.value}`] &&
            this.auth[`drip_${option.value}`].active;

          if (
            !values[option.value] &&
            (dripActive || !isEmpty(this.auth[item.value]))
          ) {
            values[option.value] = true;
          }
        });
      });

      return values;
    },
  },

  methods: {
    onInput() {
      this.$emit("input", { ...this.auth });
    },
  },

  beforeMount() {
    this.auth = { ...this.value };
  },
};
</script>
