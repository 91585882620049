<template>
  <!-- m-autocomplete in use by m-pages auth  -->
  <v-autocomplete
    v-model="model"
    :items="items"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    :loading="loading"
    :search-input.sync="q"
    :hide-details="hideDetails"
    :hide-no-data="hideNoData"
    :return-object="returnObject"
    :multiple="multiple"
    :disabled="!ready"
    :chips="chips"
    :outlined="outlined"
    :append-icon="appendIcon"
    @change="onChange"
    @focus="onFocus"
    @keyup="onKeyup"
  />
</template>

<script>
/**
 * Does it all for array, 4th July 2020
 * Use v-model and only save values (super clean and light weight)
 * Ready created for array also works for single
 */
export default {
  props: {
    hideDetails: Boolean,
    outlined: Boolean,
    hideNoData: Boolean,
    multiple: Boolean,
    chips: Boolean,
    appendIcon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: [String, Function],
      default: 'id',
    },
    orderBy: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      default: '',
    },
    searchLimit: {
      type: [Number, String],
      default: 100,
    },
    value: {
      type: [Array, Number, String],
      default() {
        return []
      },
    },
  },

  data() {
    return {
      model: null,
      ready: false,
      loading: false,
      started: false,
      items: [],
      q: '',
      form: {
        q: '',
      },
      searches: [],
    }
  },

  computed: {
    returnObject() {
      return true
    },
  },

  methods: {
    onChange(e) {
      if (!this.model || !this.model.length) {
        this.$emit('input', []) // or null
      }

      if (!this.multiple) {
        let value = this.model ? this.model[this.itemValue] : ''
        return this.$emit('input', value)
      }

      const values = this.model.map((a) => a[this.itemValue])

      this.$emit('input', values)
    },
    onFocus() {
      if (this.started) {
        return
      }

      this.started = true

      this.getItems()
    },

    onKeyup() {
      // searching or searched
      if (this.loading || this.searches.indexOf(this.q) > -1) {
        return
      }

      this.getItems()
    },

    getItem(item) {
      const value = {}
      value[this.itemValue] = item[this.itemValue] || ''
      value[this.itemText] = item[this.itemText] || ''

      return value
    },

    async getItems() {
      this.loading = true

      const form = {
        q: this.q,
        limit: this.searchLimit,
        orderby: this.orderBy || this.itemText,
      }

      this.searches.push(form.q)

      console.log('get items', this.endpoint)

      const { data } = await this.$api.get(this.endpoint, form)

      if (data.data) {
        data.data.forEach((item) => {
          if (this.items.findIndex((a) => a.id == item.id) < 0) {
            this.items.push(this.getItem(item))
          }
        })
      }

      // search changed
      if (this.form.q != form.q && this.searches.indexOf(this.q) < 0) {
        return this.getItems()
      }

      this.loading = false
    },

    async loadValues(page = 1) {
      this.loading = true

      const form = { limit: this.searchLimit, page }

      if (this.multiple) {
        const ids = [...this.value].join(',')
        form.ids = ids
      } else {
        // single id
        form.ids = this.model
      }

      const { data } = await this.$api.get(this.endpoint, form)

      if (data.data && data.data.length) {
        data.data.forEach((item) => {
          item = this.getItem(item)
          this.items.push(item)

          if (this.multiple) {
            this.model.push(item)
          }
        })
      }

      if (data.pagin && data.pagin.page > page) {
        return this.loadValues(page + 1)
      }

      this.loading = false
    },
  },

  async beforeMount() {
    // to be moved to method

    if (this.multiple) {
      this.model = []
      if (this.value && this.value.length) {
        await this.loadValues()
      }
    } else {
      this.model = this.value || ''
      if (this.model) {
        // loads single value
        await this.loadValues()
      }
    }

    this.ready = true
  },
}
</script>
