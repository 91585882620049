<template>
  <item-layout crumbs>
    <ml-form
      :fields="fields"
      name="setting"
      title="Settings"
      endpoint="admin/settings"
      class="mb-7"
    />

    <v-card width="500" class="mx-auto mb-7">
      <v-card-text>
        <v-text-field
          :value="sgWebhookUrl"
          label="SendGrid Webhook URL"
          readonly
        />
      </v-card-text>
    </v-card>
  </item-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      ready: false,
      fields: [
        {
          type: 'subheader',
          value: 'app',
          text: 'App',
        },
        {
          text: 'Site Url',
          value: 'site_url',
        },
        {
          text: 'Url Prefix',
          value: 'url_prefix',
        },
        {
          text: 'app_id',
          value: 'app_id',
        },
        {
          text: 'app_api',
          value: 'app_api',
        },
        {
          text: 'app_host',
          value: 'app_host',
        },
        {
          text: 'Storage Path',
          value: 'storage_path',
        },
        {
          text: 'Uploads Url',
          value: 'uploads_url',
        },
        {
          type: 'subheader',
          value: 'email',
          text: 'Email',
        },
        {
          text: 'Email Provider',
          value: 'email_provider',
        },
        {
          text: 'From Name',
          value: 'email_from_name',
        },
        {
          text: 'From Email',
          value: 'email_from_email',
        },
        {
          text: 'SendGrid API Key',
          value: 'email_sendgrid_api_key',
          type: 'hidden',
          // type: "password",
        },

        {
          type: 'subheader',
          value: 'wp',
          text: 'Auth',
        },
        {
          text: 'Admin Capability',
          value: 'admin_capability',
        },
        {
          text: 'JWT Secret',
          value: 'auth_jwt_secret',
        },
        {
          text: 'JWT Max Age',
          value: 'auth_jwt_age',
        },
        {
          text: 'Contact Attributes',
          value: 'auth_me_atts',
          placeholder: 'auth_me_atts',
        },
        {
          text: 'Updatables',
          value: 'auth_updatable',
          placeholder: 'auth_updatable',
        },
        {
          text: 'Recovery Url',
          value: 'auth_recovery_url',
          placeholder: 'auth_recovery_url',
        },
        {
          text: 'Recovery Token Length',
          value: 'auth_recovery_token_length',
          placeholder: 'auth_recovery_token_length eg. 32',
          type: 'number',
        },
        {
          text: 'Recovery Token Expire',
          value: 'auth_recovery_token_expire',
          placeholder: 'auth_recovery_token_expire eg. 35m',
        },
        {
          text: 'Debug On',
          value: 'debug',
          type: 'switch',
          cols: 6,
        },
        {
          text: 'Dev Mode On',
          value: 'is_dev',
          type: 'switch',
          cols: 6,
        },

        {
          text: 'Favicon',
          value: 'favicon',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      settings: 'setting/data',
    }),
    sgWebhookUrl() {
      return `${this.settings.site_url}/api/sendgrid/webhook`
    },
  },

  beforeMount() {
    this.$store.dispatch('setting/getItem', 'admin/settings').then(() => {
      this.ready = true
    })
  },
}
</script>
