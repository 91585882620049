export default {
	seekTo(value) {
		if (this.provider == 'vimeo') {
			console.log('vimeo setCurrentTime set', value);
			console.log(this.player);
			return this.player.setCurrentTime(value);
		}
	},

	togglePlay() {
		if (['playing', 'seeking'].includes(this.status)) {
			this.pause();
		} else {
			this.play();
		}
	},

	play() {
		if (!this.player.ready) {
			console.log('play: not ready');
			return;
		}
		console.log('PLAY', this.status);
		this.pendingPlay = true;

		this.player.play();
	},

	pause() {
		if (!this.player.ready) {
			console.log('pause: not ready');
			return;
		}

		this.player.pause();
	},
};
