<template>
	<v-app>
		<v-fade-transition>
			<slot v-if="appLoading" name="app-loading" />
		</v-fade-transition>

		<template v-if="loginScreen">
			<!-- <login-page /> -->
			<auth-login-page no-bg-imagex>
				<slot name="auth-header" />
				<msg-snackbar slot="bottom" />
			</auth-login-page>
		</template>

		<slot v-else />
	</v-app>
</template>

<script>
import loginPage from '@/pages/auth/Login';
import { mapGetters } from 'vuex';

export default {
	components: {
		loginPage,
	},

	watch: {
		authReady() {
			this.onAuthReady();
		},
		authenticated(value) {
			if (this.$route.name.indexOf('auth-') > -1) {
				if (value) {
					this.$router.replace({ name: 'dashboard' });
				} else {
					this.$router.replace({ name: 'home' });
				}
			}
		},
	},

	computed: {
		...mapGetters({
			requireLogin: 'auth/requireLogin',
		}),

		appLoading() {
			return !this.authReady;
		},
		loginScreen() {
			return this.authReady && !this.authenticated && (this.$route.meta.auth || this.requireLogin);
		},
	},

	methods: {
		onAuthReady() {
			// console.log("onAuthReady");
		},
	},

	beforeMount() {
		if (this.authReady) {
			this.onAuthReady();
		}
	},

	// mounted() {
	//   console.log("auth-wrapper");
	// },
};
</script>
