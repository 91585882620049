import item from "../../store/helpers/item";

const state = {
  ...item.state,
  baseEndpoint: "admin/settings",
};

const mutations = {
  ...item.mutations,
  //
};

const getters = {
  ...item.getters,
};

const actions = {
  ...item.actions,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
