const parseContent = (str, setup = {}) => {
  if (typeof str == 'object') {
    str = JSON.stringify(str)
    str = parseContent(str)
    console.log('parseContent: ', str, setup)
    return JSON.parse(str)
  }
  if (!str) {
    return str
  }
  let k, k2, group, regex

  for (k in setup) {
    group = setup[k]

    for (k2 in group) {
      regex = `({)[\\s]{0,2}(${k}\\.${k2})[\\s]{0,2}(})`
      regex = new RegExp(regex, 'g')
      str = str.replace(regex, group[k2])
    }
  }

  return str
}

export default parseContent
