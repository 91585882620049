<template>
  <v-row>
    <v-col v-for="item in items" :key="item.value">
      <v-text-field
        v-model="val[item.value]"
        :label="item.text"
        type="number"
        min="1"
        max="12"
        hint="1-12"
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
import model from "../../mixins/model-watch";

export default {
  mixins: [model],
  data() {
    return {
      items: [
        {
          text: "Cols",
          value: "col"
        },
        {
          text: "Small",
          value: "sm"
        },
        {
          text: "Medium",
          value: "md"
        },
        {
          text: "Large",
          value: "lg"
        }
        // {
        //     text: 'Large',
        //     value: 'lg'
        // }
      ]
    };
  }
};
</script>