import Vue from "vue";

const prefix = "MPages";
// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  "../components",
  // subdirectories
  true,
  // Only include "_base-" prefixed .vue files
  //   /_base-[\w-]+\.vue$/
  /\.vue$/i
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Get the PascalCase version of the component name
  const componentName = fileName
    // Remove the "./_" from the beginning
    .replace(/^\.\/_/, "")
    // Remove the file extension from the end
    .replace(/\.\w+$/, "")
    // Split up kebabs
    .split("-")
    // Upper case
    .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    // Concatenated
    .join("")
    .split("/")
    .pop();

  // Globally register the component

  Vue.component(
    prefix + componentName,
    componentConfig.default || componentConfig
  );
});
