<template>
  <div>
    <m-pages-row-toolbar
      v-model="val.row"
      :colsLen="val.cols.length"
      :col-index="colIndex"
      @addCol="isNewCol = true"
      @onCol="colIndex = $event"
    />

    <!-- forms  -->
    <v-fade-transition>
      <v-window v-if="!refreshingForm" v-model="colIndex" light>
        <!-- block form -->
        <v-window-item v-for="(col, index) in val.cols" :key="col.id">
          <m-pages-col-toolbar
            v-model="val.cols[index].col"
            :tab-index="tabIndex"
            @onTab="tabIndex = $event"
          />

          <v-container>
            <v-window v-model="tabIndex" light>
              <v-window-item>
                <m-pages-form
                  v-if="forms[val.cols[index].name]"
                  v-model="val.cols[index].content"
                  :form="forms[val.cols[index].name]"
                />
                <component
                  v-else
                  v-bind:is="`m-pages-${val.cols[index].name}-form`"
                  v-model="val.cols[index].content"
                />
              </v-window-item>
              <v-window-item>
                <v-row>
                  <v-col>
                    <m-pages-spacing-form title="Margin" v-model="val.cols[index].col.m" />
                  </v-col>
                  <v-col>
                    <m-pages-spacing-form title="Padding" v-model="val.cols[index].col.p" />
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-container>

          <m-pages-col-block-toolbar
            :first="index == 0"
            :last="index == val.cols.length - 1"
            @action="colAction($event, index)"
          />
        </v-window-item>
      </v-window>
    </v-fade-transition>

    <m-pages-dialog v-model="isNewCol" title="Add Column">
      <m-pages-add-block-form is-colx @add="addCol" />
    </m-pages-dialog>
  </div>
</template>

<script>
const arrayMove = require("array-move");

import clearObj from "@/helpers/clear-obj";
import genId from "@/helpers/gen-id";

import blockFields from "../../js/block-fields";
import model from "../../mixins/model";
import forms from "../../js/forms";

export default {
  props: {
    selectCol: {
      type: Number,
      default: null,
    },
  },

  watch: {
    selectCol(i) {
      this.colIndex = i;
    },
  },

  mixins: [model],

  data() {
    return {
      forms,
      isNewCol: false,
      colIndex: 0,
      tabIndex: 0,
      spacing: "mx-2",
      refreshingForm: false,
    };
  },

  methods: {
    refreshForm() {
      this.refreshingForm = true;
      setTimeout(() => {
        this.refreshingForm = false;
      });
    },

    colAction(action, index) {
      // console.log("colAction", action);

      const items = this.val.cols;

      if (action == "up") {
        arrayMove.mutate(items, index, index - 1);
        this.colIndex--;
        return this.refreshForm();
      }

      if (action == "down") {
        arrayMove.mutate(items, index, index + 1);
        this.colIndex++;
        return this.refreshForm();
      }

      if (action === "duplicate") {
        const item = { ...items[index] };
        item.id = genId("col_");
        this.val.cols = JSON.parse(JSON.stringify(items));
        return this.val.cols.splice(index, 0, item);
      }

      const id = items[index].id;

      if (action === "delete") {
        if (index > 0) {
          this.colIndex--; // clear delete index
        } else {
          this.colIndex = null;
        }
        return items.splice(index, 1);
      }
    },

    addCol({ value }) {
      const name = value;

      const id = genId(`col_${name}_`);

      const auth = clearObj(blockFields.auth);
      const schedule = clearObj(blockFields.schedule);
      const content = clearObj(blockFields[name]);

      const col = { ...blockFields.col };

      const block = {
        id,
        name,
        auth,
        schedule,
        col,
        ...content,
      };

      // add container default
      this.val.cols.push(block);
      this.colIndex = this.val.cols.length - 1;
    },

    addCol1({ value }) {
      return console.log("addCol", value);
      const name = value;
      // return console.log("add col", name);
      const id = genId(`col_${name}_`);
      const col = { ...blockFields.col };
      const content = { ...blockFields[name] };
      // const bg = {};

      const item = {
        col,
        id,
        name,
        content,
        // bg
      };

      this.val.cols.push(item);
      this.colIndex = this.val.cols.length - 1;
    },
  },

  beforeMount() {
    // this.addCol({ value: "text" });
  },
};
</script>
