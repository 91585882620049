<template>
  <v-menu open-on-hover offset-y tile :dark="dark">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text large class="font-weight-medium" v-bind="attrs" v-on="on">
        {{
        item.text
        }}
      </v-btn>
    </template>

    <v-list v-if="item.children && item.children.length" :color="color">
      <v-list-item v-for="(item, i) in item.children" :key="i" @click="onClick(item)">
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    dark: Boolean,
    color: {
      type: String,
      default: "",
    },

    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    onClick(e) {
      const name = this.item.to_name || "";
      let value = this.item.to_value || "";

      if (name != "url") {
        e.preventDefault();
      }

      if (name == "dom") {
        if (this.item.section) {
          value = this.item.section;
        }
        this.$vuetify.goTo(`#${value}`, {
          offset: 60,
        });
        setTimeout(() => {
          this.$vuetify.goTo(`#${value}`, {
            offset: 60,
          });
        }, 300);
      } else if (name == "route") {
        if (value.indexOf("{") > -1) {
          value = JSON.parse(value);
          this.$router.push(value);
        } else {
          // regular route name
          this.$router.push({ name: value });
        }
      } else if (name == "route_path") {
        this.$router.push({ path: value });
      }

      if (this.item.pauseVideo) {
        this.$store.dispatch("MPages/pauseVideo");
      }
    },
  },
};
</script>
