<template>
  <div>
    <v-tabs>
      <v-tab>Image</v-tab>
      <v-tab>Heading</v-tab>
      <v-tab>Subheading</v-tab>
      <v-tab>Content</v-tab>

      <v-tabs-slider />

      <v-tab-item>
        <br />
        <v-text-field v-model="val.img.src" label="Image" placeholder="https://" />
      </v-tab-item>

      <v-tab-item>
        <br />
        <m-pages-heading-form v-model="val.heading" />
      </v-tab-item>

      <v-tab-item>
        <br />
        <m-pages-heading-form v-model="val.subheading" />
      </v-tab-item>

      <v-tab-item>
        <br />
        <html-editor v-model="val.content.html" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import htmlEditor from "@/components/Tinymce";
export default {
  components: {
    htmlEditor,
  },
  mixins: [model],
};
</script>
