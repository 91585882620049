import model from "./model";

export default {
  mixins: [model],
  computed: {
    valueStr() {
      return JSON.stringify(this.value);
    },
  },
  watch: {
    valueStr(str) {
      if (str != this.valStr) {
        this.val = { ...this.value };
      }
    },
  },
};
