<template>
  <v-card>
    <v-toolbar dense flat color="grey lighten-3">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>

    <slot v-if="edge" />
    <v-card-text v-else>
      <slot />
    </v-card-text>

    <v-card-actions class="pt-0">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    edge: Boolean,
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
