<template>
  <v-card :loading="!ready">
    <v-card-title> Duplicate version 2</v-card-title>
    <v-card-text>
      <div>itemId: {{ itemId }}, endpoint: {{ endpoint }}</div>
    </v-card-text>

    <v-card-text>
      <ml-autocomplete
        endpoint="admin/list/product"
        :loading="productLoading"
        label="Select Product"
        @input="onProduct"
      />
      <v-row v-for="(item, i) in replaceItems" :key="i" align="center">
        <v-col>
          <v-text-field
            v-model="replaceItems[i].from"
            :label="`From ${item.value || ''}`"
            hide-details
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="replaceItems[i].to"
            label="To"
            :placeholder="item.value || ''"
            hide-details
            @input="onInput(item.value, $event)"
            @blur="onBlur(item.value, $event)"
          />
        </v-col>
        <v-col class="shrink">
          <v-icon @click="removeReplaceItem(i)">delete</v-icon>
        </v-col>
      </v-row>
      <v-icon @click="addReplaceItem">add</v-icon>
    </v-card-text>
    <v-card-actions class="mx-4 pb-8">
      <v-btn color="primary" :loading="loading" @click="submit"
        >Duplicate Now</v-btn
      >
      <v-spacer />
      <v-btn v-if="lastProductName" @click="setLastProductName">{{
        lastProductName
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    itemId: {
      type: [String, Number],
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      ready: false,
      loading: false,
      productLoading: false,
      form: {},
      data: {},
      replaceItems: [],
      blurred: {},
      lastProductName: '',
    }
  },

  methods: {
    async onProduct(id) {
      this.productLoading = true
      this.replaceItems = []
      const { data } = await this.$api.get(`/admin/products/${id}`)
      this.onProductData(data.data)
      this.productLoading = false
      this.$localforage.setItem('duplicateV2Product', id)
    },
    onBlur(key = '', { target }) {
      if (key) this.blurred[key] = true

      if (key === 'name') {
        this.$localforage.setItem('duplicateV2_LastProdName', target.value)
      }
    },

    setLastProductName() {
      const name = this.replaceItems.find((a) => a.value === 'name')
      if (name) {
        name.to = this.lastProductName
      }
      this.onInput('name', this.lastProductName)
    },

    onInput(key = '', value = '') {
      if (key !== 'name') {
        return
      }

      if (!this.blurred.slug) {
        const slug = this.replaceItems.find((a) => a.value === 'slug')
        if (slug) {
          slug.to = value.toLowerCase().replaceAll(' ', '-')
        }
      }

      if (!this.blurred.app_id) {
        const appId = this.replaceItems.find((a) => a.value === 'app_id')
        if (appId) {
          appId.to = value.toLowerCase().replaceAll(' ', '_')
        }
      }
    },
    onProductData(data) {
      this.replaceItems.push(
        ...[
          {
            from: data.name,
            to: '',
            value: 'name',
          },
          {
            from: data.slug,
            to: '',
            value: 'slug',
          },
          {
            from: data.app_id,
            to: '',
            value: 'app_id',
          },
        ]
      )
    },

    addReplaceItem() {
      this.replaceItems.push({
        from: '',
        to: '',
        value: 'custom',
      })
    },

    removeReplaceItem(index) {
      this.replaceItems.splice(index, 1)
    },

    async submit() {
      this.loading = true
      let str = JSON.stringify(this.data)

      this.replaceItems.forEach(({ from, to }) => {
        str = str.replaceAll(from, to)
      })

      const form = { ...JSON.parse(str), active: false }
      delete form.identifier
      delete form.id
      delete form.created
      delete form.updated

      await this.$api.post(this.endpoint, form)

      this.$emit('duplicated')

      this.loading = false
    },
  },

  async mounted() {
    const { data } = await this.$api.get(`${this.endpoint}/${this.itemId}`)
    this.data = data.data

    if (this.endpoint === '/admin/products') {
      this.onProductData(data.data)
    } else if (data.data.product_id) {
      const prod = await this.$api.get(
        `/admin/products/${data.data.product_id}`
      )
      if (prod && prod.data && prod.data.data) {
        this.onProductData(prod.data.data)
      }
    } else {
      const prodId = await this.$localforage.getItem('duplicateV2Product')
      if (prodId) {
        await this.onProduct(prodId)
      }
    }

    this.lastProductName = await this.$localforage.getItem(
      'duplicateV2_LastProdName'
    )

    this.ready = true
  },
}
</script>
