<template>
	<div>
		<v-row>
			<v-col>
				<v-select :label="clickAction.text" v-model="val.clickAction" :items="clickAction.items" hide-details />
			</v-col>
			<v-col>
				<!-- {{ val.clickAction }} -->
				<v-text-field
					v-if="!val.clickAction || val.clickAction == 'href'"
					v-model="val.href"
					:label="href.text"
					:placeholder="href.placeholder"
					hide-details
				/>
				<!-- <m-pages-download-field /> -->
				<component v-else v-model="val[val.clickAction]" :is="`m-pages-${val.clickAction}-field`" />
			</v-col>
			<v-col cols="3">
				<v-select
					v-model="val[target.value]"
					:label="target.text"
					:placeholder="target.placeholder"
					:items="target.items"
					:disabled="!['href', 'section', 'checkout'].includes(val.clickAction)"
					hide-details
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-for="select in selects" :key="select.value">
				<v-select
					v-model="val[select.value]"
					:label="select.text"
					:items="select.items"
					hide-details
					clearable
				/>
			</v-col>

			<v-col class="my-n3">
				<m-pages-rounded-field v-model="val.rounded" hide-details />
			</v-col>
		</v-row>

		<v-row v-if="isSizeSlider && !isAdvanced">
			<v-col v-for="slider in sizeSilders" :key="`${slider.value}slider`">
				<v-slider
					v-model="val[slider.value]"
					:label="slider.text"
					:min="slider.min"
					:max="slider.max"
					thumb-label
				/>
			</v-col>
		</v-row>

		<v-expand-transition>
			<div v-if="isAdvanced">
				<v-row>
					<v-col v-for="size in sizes" :key="size.value">
						<v-text-field v-model="val[size.value]" :label="size.text" type="number" clearable />
					</v-col>
				</v-row>

				<v-row>
					<v-col v-for="slider in sliders" :key="slider.value">
						<v-slider
							v-model="val[slider.value]"
							:label="slider.text"
							:min="slider.min"
							:max="slider.max"
							thumb-label
							hide-details
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col v-for="slider in spacings" :key="slider.value" cols="3">
						<v-slider
							v-model="val[slider.value]"
							:label="slider.text"
							:min="0"
							:max="slider.max"
							thumb-label
							hide-details
						/>
					</v-col>
				</v-row>
			</div>
		</v-expand-transition>

		<v-row>
			<v-checkbox
				v-for="option in options"
				:key="option.value"
				v-model="val[option.value]"
				:label="option.text"
				class="ma-4"
				hide-details
			/>
		</v-row>

		<v-row justify="space-between" class="pl-4 pr-8 mt-3">
			<v-row>
				<m-color-picker v-model="val.color" label="Color" depressed />
				<v-chip class="ml-3" close @click:close="clearCustomSize">Custom Size</v-chip>
			</v-row>

			<v-row justify="end">
				<v-switch v-model="isAdvanced" label="Advanced" hide-details class="mt-0 mx-3" />
				<v-switch v-model="isSizeSlider" label="Custom Size" hide-details class="mt-0 mx-3" />
			</v-row>
		</v-row>
	</div>
</template>

<script>
import {
	dark,
	light,
	outlined,
	depressed,
	size,
	block,
	disabled,
	tile,
	width,
	height,
	maxWidth,
	maxHeight,
	minWidth,
	minHeight,
	elevation,
	mt,
	mb,
	ml,
	mr,
	pt,
	pb,
	pl,
	pr,
	href,
	target,
	noFollow,
	clickAction,
	hover,
} from '../../js/forms/fields'
import model from '../../mixins/model'
export default {
	mixins: [model],

	data() {
		return {
			isAdvanced: false,
			isSizeSlider: false,
			href,
			target,
			clickAction,
			// primaries: [href, { value: "download" }, target],
			options: [dark, light, outlined, depressed, tile, block, disabled, noFollow],
			selects: [size],
			sizes: [width, height, minWidth, minHeight, maxWidth, maxHeight],
			sizeSilders: [
				{ ...width, min: 100, max: 750 },
				{ ...height, min: 16, max: 250 },
			],
			sliders: [elevation, hover],
			spacings: [mt, mb, pt, pb, ml, mr, pl, pr],
		}
	},

	methods: {
		clearCustomSize() {
			this.val.width = ''
			this.val.height = ''
			this.isSizeSlider = false
		},
	},
}
</script>
