/**
 * calls onDrop with valid drop.
 */
export default {
  data() {
    return {
      dragItem: -1,
      dragOver: -1,
    }
  },

  computed: {
    dragAbove() {
      return this.dragItem > this.dragOver
    },
    dragBelow() {
      return this.dragItem < this.dragOver
    },
  },

  methods: {
    dragstart(i) {
      this.dragItem = i
    },
    dragenter(i) {
      this.dragOver = i
    },
    dragend() {
      this.dragOver = -1
      this.dragItem = -1
    },
    drop() {
      if (this.dragItem !== this.dragOver) {
        this.onDrop(this.dragItem, this.dragOver)
        // this.$emit('drag-drop', { from: this.dragItem, to: this.dragOver })
      }
    },
  },
}
