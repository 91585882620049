export default function() {
	return {
		player: null,
		minHeight: 0,
		options: {},

		pendingPlay: false,

		canplay: false,
		status: '',
		changing: false,
		provider: '',

		pendingStatus: '',

		isFirstCanPlay: false,
	};
}
