<template>
  <v-sheet :color="row.color" :dark="row.dark" :tile="!row.rounded" :elevation="row.elevation">
    <v-row
      :class="rowClass"
      :style="rowStyle"
      :dense="row.dense"
      :no-gutters="row.noGutters"
      :align="row.align"
      :justify="row.justify"
    >
      <slot />
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    rowStyle() {
      const item = {};

      if (this.row.color) {
        // item.backgroundColor = this.row.color;
      }

      return item;
    },

    rowClass() {
      const cl = [],
        item = this.row;
      let k, v;

      // margin
      for (k in item.m) {
        v = item.m[k];
        if (v != "0") {
          cl.push(`m${k}-${v}`);
        }
      }

      // padding
      for (k in item.p) {
        v = item.p[k];
        if (v != "0") {
          cl.push(`p${k}-${v}`);
        }
      }

      return cl;
    },
  },
};
</script>
