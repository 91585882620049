<template>
  <div>
    <m-pages-builder-toolbar
      v-model="window"
      :title="title"
      :is-partial="isPartial"
    >
      <slot name="toolbar" />

      <v-btn outlined small class="mx-1" @click="copyPage">Copy Page</v-btn>
      <v-btn
        outlined
        small
        class="mx-1"
        :disabled="!copiedPage"
        @click="pastePage"
        >Paste Page</v-btn
      >

      <v-btn small outlined @click="isRawEdit = true">Raw Edit</v-btn>

      <v-btn :disabled="history.changes.length < 2" icon @click="undo">
        <v-icon>undo</v-icon>
      </v-btn>

      <v-btn :disabled="history.undos.length < 1" icon @click="redo">
        <v-icon>redo</v-icon>
      </v-btn>
    </m-pages-builder-toolbar>

    <!-- setup form  -->
    <app-auth v-if="window == 'auth'" v-model="data.auth" @input="onAuth" />
    <app-setup
      v-else-if="window == 'setup'"
      v-model="data.setup"
      :blockList="blockList"
    />
    <!-- CONTENT  -->
    <div v-if="window === 'builder'">
      <m-pages-page-container>
        <template v-for="(block, index) in data.blocks">
          <div
            :key="`container-${index}`"
            @mouseenter="overId = block.id"
            @mouseleave="overId = ''"
          >
            <div
              :class="
                overId != '' && overId != block.id && blockId != block.id
                  ? 'm-pages-builder-faded'
                  : ''
              "
              style="min-height:50px;"
              v-on:click="onContainerClick(index)"
            >
              <m-pages-wrapper
                :item="block.wrapper ? block.wrapper : {}"
                :blockId="block.id"
                :dark="block.dark"
                :light="block.light"
                :color="block.color"
                :sticky="block.sticky"
                :noPageSpacingY="block.noPageSpacingY"
              >
                <m-pages-container
                  :item="block.container"
                  :blockId="block.id"
                  :expanded="block.expanded"
                >
                  <m-pages-row v-if="block.name == 'row'" :row="block.row">
                    <div v-if="!block.cols.length">Empty Row</div>
                    <m-pages-col
                      v-for="(col, i) in block.cols"
                      :key="col.id"
                      :col="col.col"
                    >
                      <div v-on:click="onColClick(i)" style="min-height:50px;">
                        <component
                          v-bind:is="`m-pages-${col.name}-block`"
                          :id="block.id"
                          :block="parsedBlocks[index].cols[i].content"
                          :is-editor="!isPreview"
                          :refresh="refresh"
                          @onEvent="onBlockEvent(index, $event)"
                          >COMPONENT EDITOR</component
                        >

                        <!-- :block="col.content" -->
                      </div>
                    </m-pages-col>
                  </m-pages-row>

                  <!-- Regular Block (non column) -->
                  <component
                    v-else
                    v-bind:is="`m-pages-${block.name}-block`"
                    :id="block.id"
                    :block="parsedBlocks[index].content"
                    :is-editor="!isPreview"
                    :refresh="refresh"
                    @onEvent="onBlockEvent(index, $event)"
                  >
                    <app-html-editor
                      v-if="['author'].includes(block.name)"
                      v-model="parsedBlocks[index].content.content.html"
                    />
                  </component>
                </m-pages-container>
              </m-pages-wrapper>
            </div>

            <!-- Block Form  -->
            <v-expand-transition>
              <m-pages-block-form
                v-if="!isPreview && blockId == block.id"
                v-model="data.blocks[index]"
                :title="data.blocks[index].name"
                :blockList="blockList"
                :selectCol="selectColIndex"
                @close="blockId = ''"
              >
                <template slot="toolbar">
                  <!-- blockToolbarOptions: {{ blockToolbarOptions }} -->
                  <v-checkbox
                    v-for="option in blockToolbarOptions"
                    :key="option.value"
                    v-model="data.blocks[index][option.value]"
                    :label="option.text"
                    class="mx-2"
                    hide-details
                  />

                  <m-color-picker
                    v-model="data.blocks[index].color"
                    label="Color"
                    btn-class="mx-2"
                    depressed
                  />
                </template>
                <!-- :videoBlocks="videoBlocks" -->
                <v-row slot="top">
                  <v-switch
                    v-for="option in blockMasterOptions"
                    :key="option.value"
                    v-model="data.blocks[index][option.value]"
                    :label="option.text"
                    :color="option.color"
                    :disabled="
                      (option.noRequiresTheme &&
                        data.theme[option.noRequiresTheme]) ||
                        (option.requiresTheme &&
                          !data.theme[option.requiresTheme])
                    "
                    hide-details
                    class="mx-3"
                  />
                </v-row>

                <component
                  slot="content"
                  v-bind:is="`m-pages-${block.name}-form`"
                  v-model="data.blocks[index].content"
                  :id="data.blocks[index].id"
                  :blockList="blockList"
                />

                <m-pages-container-form
                  slot="container"
                  v-model="data.blocks[index].container"
                />

                <m-pages-wrapper-form
                  slot="wrapper"
                  v-model="data.blocks[index].wrapper"
                  :dark="block.dark"
                />

                <m-pages-schedule-form
                  slot="schedule"
                  v-model="data.blocks[index].schedule"
                  :dark="block.dark"
                />
                <!-- :videoBlocks="videoBlocks" -->

                <m-pages-auth-form
                  slot="auth"
                  v-model="data.blocks[index].auth"
                  :dark="block.dark"
                />
              </m-pages-block-form>
            </v-expand-transition>

            <!-- Block Toolbar  -->
            <v-expand-transition>
              <m-pages-block-toolbar
                v-if="overId == block.id"
                :first="index == 0"
                :last="index == data.blocks.length - 1"
                @action="blockAction($event, index)"
              >
                <v-btn outlined @click="newBlock(index)">
                  <v-icon class="mr-1">add</v-icon>Add Block
                </v-btn>
              </m-pages-block-toolbar>
            </v-expand-transition>
          </div>

          <!-- / block loop  -->
        </template>
      </m-pages-page-container>

      <!-- Add Block Button  -->

      <v-btn v-if="!data.blocks.length" depressed block large @click="newBlock">
        <v-icon class="mr-1">add</v-icon>Add Block
      </v-btn>

      <!-- Dev  -->
    </div>

    <app-preview
      v-if="window == 'preview'"
      :preview-src="previewSrc"
      :blocks="data.blocks"
    />
    <!-- / CONTENT END  -->

    <v-bottom-sheet
      v-model="isThemeToolbar"
      persistent
      hide-overlay
      no-click-animation
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey"
          fixed
          dark
          fab
          bottom
          right
          v-bind="attrs"
          v-on="on"
        >
          <v-icon large>settings</v-icon>
        </v-btn>
      </template>
      <v-sheet class="pb-8" tile>
        <div class="text-right" style="height:40px;">
          <v-btn
            class="mt-n7 mr-4"
            fab
            color="white"
            @click="isThemeToolbar = !isThemeToolbar"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <m-pages-theme-form v-if="isThemeToolbar" v-model="data.theme" />
      </v-sheet>
    </v-bottom-sheet>

    <!-- dialogs  -->
    <m-pages-dialog
      v-model="isNewBlock"
      title="Add Block"
      @close="isNewBlock = false"
    >
      <m-pages-add-block-form @add="addBlock" />
    </m-pages-dialog>

    <!-- Raw Edit  -->
    <v-dialog v-model="isRawEdit" fullscreen>
      <v-card v-if="isRawEdit">
        <v-toolbar dense flat color="grey lighten-4">
          <v-toolbar-title>Raw Edit</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="isRawEdit = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            :value="content"
            style="height:100%"
            @input="onRawEdit"
            label="Raw Edit"
            auto-grow
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import appSetup from './setup/Setup'
import appAuth from './auth/PageAuth'
import appPreview from './Preview'
import appHtmlEditor from './html-editor/HtmlEditor'
import js from './js'
export default {
  components: {
    appSetup,
    appAuth,
    appPreview,
    appHtmlEditor,
  },
  ...js,
}
</script>

<style lang="scss">
@import './scss/m-pages';
@import './scss/m-pages-builder';
</style>
