export default function({ options, data = {} }) {
  return new Promise((resolve, reject) => {
    //
    const Api = options.store._vm.$api;
    const url = options.updateUrl || `${options.url}/me`;
    const fields = options.updatableFields;
    const user = options.store.state.auth.user;

    // const form = {};

    // fields.forEach((field) => {
    //   // priotize sent in data over store
    //   if (typeof data[field] != "undefined") {
    //     form[field] = data[field];
    //   }
    //   // check store for value
    //   else if (typeof user[field] != "undefined") {
    //     form[field] = user[field];
    //   }
    // });

    Api.post(url, { ...data })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          const message = err.response.data.message;
          reject({ message });
        } else if (err.response) {
          reject(err.response);
        }
        reject(err);
      });
  });
}
