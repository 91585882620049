export default {
  data() {
    return {
      duplicateV2: {
        dialog: false,
        itemId: '',
      },
    }
  },
  methods: {
    onDuplicatedV2() {
      this.duplicateV2.itemId = ''
      this.duplicateV2.dialog = false
      this.doRefresh()
    },
    async duplicateItemV2(itemId) {
      this.duplicateV2.itemId = itemId
      this.duplicateV2.dialog = true
    },
  },
}
