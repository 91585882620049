<template>
  <item-layout :crumbs="!noCrumbs" :title="page.name">
    <template slot="actions">
      <v-switch
        v-if="ready"
        v-model="page.is_home"
        label="Home Page"
        hide-details
        class="mx-2"
        :loading="homePageLoading"
        @change="onHomePage"
      />
      <v-btn
        outlined
        class="mx-2"
        :to="{ name: 'page-builder', params: { id: id } }"
      >
        <v-icon class="mr-1">build</v-icon>Page Builder
      </v-btn>
      <ml-form
        :fields="form"
        name="page"
        title="Edit Page"
        dialog-icon="create"
        :endpoint="endpoint"
        dialog
      />
    </template>

    <v-btn :href="`/${page.slug}`" target="_blank">Preview</v-btn>

    <!-- {{ page.content }} -->
  </item-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { form } from '../mixins/fields/page'
export default {
  props: {
    smallGrid: Boolean,
    noCrumbs: Boolean,
    id: {
      type: [String, Number],
      default() {
        return this.$route.params.id
      },
    },
    endpoint: {
      type: String,
      default() {
        return `admin/pages/${this.id}`
      },
    },
  },

  data() {
    return {
      ready: false,
      homePageLoading: false,
      form,
    }
  },

  computed: {
    ...mapGetters({
      page: 'page/data',
    }),
  },

  methods: {
    async onHomePage(is_home) {
      this.homePageLoading = true

      await this.$api.post(this.endpoint, { is_home })

      this.homePageLoading = false
      console.log('onHomePage', this.endpoint)
    },
  },

  beforeMount() {
    this.$store.dispatch('page/getItem', this.endpoint).then(() => {
      this.ready = true
    })
  },
}
</script>
