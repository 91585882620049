export default [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Description",
    value: "description",
  },
];
