<template>
  <div>
    <v-row>
      <v-col>
        <m-pages-panel title="My Products Heading">
          <v-text-field v-model="val.heading.text" label="Services Heading" />
          <v-text-field v-model="val.subheading.text" label="Services Subheading" hide-details />
        </m-pages-panel>
      </v-col>
      <v-col>
        <m-pages-panel title="Extra Options">
          <v-row>
            <v-text-field v-model="val.selectText" label="Select Text" class="mx-3" />
            <v-text-field v-model="val.promoteText" label="Promote Text" class="mx-3" />
          </v-row>

          <m-pages-cols-form v-model="val.cols" />

          <v-row>
            <v-switch
              v-for="option in options"
              :key="option.value"
              v-model="val[option.value]"
              :label="option.text"
              class="mx-3"
            />
          </v-row>
        </m-pages-panel>
      </v-col>
    </v-row>
    <!-- <m-raw-displayer :value="getForm" title="getForm" /> -->
    <!-- /  -->
  </div>
</template>

<script>
import model from "../../mixins/model";
import form from "../../mixins/form";
import blockList from "../../mixins/block-list";
import htmlEditor from "@/components/Tinymce";
import genId from "@/helpers/gen-id";
export default {
  components: {
    htmlEditor,
  },
  mixins: [model, blockList, form],

  data() {
    return {
      editItemId: "",
      form: {
        title: {},
        img: {},
        content: {},
        primaryAction: {},
        secondaryAction: {},
        col: {},
      },
      options: [
        {
          text: "Contain Images",
          value: "contain",
        },
        {
          text: "Open Promote In New",
          value: "promoteOpenNew",
        },
      ],
    };
  },

  computed: {
    getForm() {
      if (this.editItemId) {
        const index = this.val.items.findIndex((a) => a.id === this.editItemId);
        return this.val.items[index];
      }
      return this.form;
    },
  },

  methods: {
    addService() {
      const item = {
        id: genId("service_"),
        ...this.form,
      };

      this.val.items.push(item);
      this.clearForm();
    },

    onEdit(item) {
      this.editItemId = item.id;
      this.$refs.title.focus();
    },
    onDelete(item) {
      const index = this.val.items.findIndex((a) => a.id == item.id);
      this.val.items.splice(index, 1);
    },
  },
};
</script>
