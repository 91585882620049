<template>
  <!-- <block-container :c="block.container"> -->
  <!-- <v-sheet style="position:relative;z-index:3">
    <v-container class="py-0">-->
  <v-row fill-height align="center" justify="start">
    <!-- logo  -->
    <v-col v-if="block.logo" class="shrink">
      <img :src="block.logo.src" :style="`height:${block.logo.height}px`" alt />
    </v-col>

    <!-- title  -->
    <v-col>
      <v-row v-if="block.title" class="display-1" v-text="block.title.text" />
      <v-row class="body-1" v-text="block.slogan.text" />
    </v-col>

    <!-- icon  -->
    <v-col class="shrink px-4">
      <v-icon :size="block.call.icon.size" v-text="block.call.icon.icon" />
    </v-col>
    <v-col class="shrink">
      <v-card width="200" flat color="rgba(0,0,0,0)">
        <div class="body-1 font-weight-medium" v-text="block.call.headline.text" />
        <div class="headline font-weight-bold mt-n1 primary--text" v-text="block.call.number.text" />
        <div class="mt-n1 grey--text" v-text="block.call.footer.text" />
      </v-card>
    </v-col>
  </v-row>
  <!-- </v-container>
    </v-sheet>-->
  <!-- </block-container> -->
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],
};
</script>
