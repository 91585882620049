<template>
  <m-pages-footer-block v-if="footer && footer.content" :block="footer.content" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      footer: "MPages/layoutFooter",
    }),
  },
};
</script>