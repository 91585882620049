<template>
  <div v-if="form">
    <ml-form-builder-form
      :success="success"
      v-bind="form"
      :loading="loading"
      @submit="onSubmit"
    >
      <v-btn
        v-if="success && canEdit"
        class="ml-4 mb-5"
        @click="success = false"
        depressed
        >Edit</v-btn
      >
    </ml-form-builder-form>
  </div>
</template>

<script>
import blockMixin from '../../mixins/block'
export default {
  mixins: [blockMixin],

  data() {
    return {
      ready: false,
      loading: false,
      success: false,
      form: null,
      canEdit: true,
    }
  },

  methods: {
    onSubmit(form) {
      this.loading = true

      this.$api
        .post(`form/${this.block.form}`, form)
        .then(({ data }) => {
          this.onSubmitted(data)
          this.loading = false
          this.success = true
        })
        .catch((err) => {
          this.loading = false
        })
    },

    onSubmitted(res) {
      if (res.success_content) {
        this.form.success_content = res.success_content
      } else if (res.forward_url) {
        window.top.location.href = res.forward_url
      }

      this.canEdit = !!res.can_edit
      this.success = true
    },
  },

  beforeMount() {
    this.$api
      .get(`form/${this.block.form}`)
      .then(({ data }) => {
        if (data.submitted) {
          this.success = true
        }

        this.canEdit = !!data.can_edit

        console.log(data)

        this.form = data.data

        this.ready = true
      })
      .catch(() => {
        console.log('not aviaable')
      })
  },
}
</script>
