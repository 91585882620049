export default {
	play() {
		setTimeout(() => {
			this.player.play();
		}, 100);
	},

	pause(value) {
		if (value) {
			this.$refs.player.player.pause();
		}
	},
	refresh() {
		// console.log("refresh video");
	},
	seekto(n) {
		// console.log("seekto", n);
		this.player.currentTime = n;
		// this.pendingPause = true;
		this.player.play();
		setTimeout(() => {
			this.player.pause();
		});
		// console.log(this.player);
	},

	vuexPauseVideo() {
		if (this.playerReady) {
			this.$refs.player.player.pause();
		}
	},

	autoplay(v) {
		console.log('autoplay', v);
		if (v && this.playerReady) {
			this.playVideo();
		}
	},
};
// console.log("watch");
