<template>
  <div @mouseenter="over = true" @mouseleave="over = false" :class="`px-${c.px ? c.px : 0}`">
    c.textColor: {{ c.textColor }}, blockIndex: {{ blockIndex }}, {{sheetClass}}
    <v-sheet
      :color="c.color"
      :dark="c.dark"
      :class="sheetClass"
      :width="c.width"
      :min-width="c.minHeight"
      :height="c.height"
      tile
      :style="`color:${c.textColor}`"
      style="position:relative;z-index:10"
    >
      <v-container :fluid="c.fluid" class="py-0" fill-height>
        <slot />
      </v-container>
    </v-sheet>

    <v-expand-transition>
      <editor-block-toolbar v-if="over || blockIndex === index" :index="index" :over="over" />
    </v-expand-transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { spacingValues as spacing } from "../helpers/spacing";
// import appBlockToolbar from "./BlockToolbar";

export default {
  components: {
    // appBlockToolbar,
  },

  props: {
    c: {
      type: Object,
      required: true
    },
    index: Number
  },

  data() {
    return {
      over: false
    };
  },

  computed: {
    ...mapGetters({
      blockIndex: "editor/blockIndex"
    }),
    sheetClass() {
      let items = [];
      const c = this.c;

      spacing.props.forEach(prop => {
        spacing.dirs.forEach(dir => {
          const k = prop + dir;
          if (c[k]) {
            items.push(`${k}-${c[k]}`);
          }
        });
      });

      const options = ["elevation"];
      options.forEach(e => {
        if (c[e]) {
          items.push(`${e}-${c[e]}`);
        }
      });

      return items;
    }
  }
};
</script>
