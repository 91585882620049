<template>
  <v-container class="py-0 mt-n4">
    <v-tabs>
      <v-tab>Theme</v-tab>
      <v-tab>Headings</v-tab>
      <v-tab>Subheadings</v-tab>

      <v-tabs-slider />

      <v-tab-item>
        <br />
        <v-toolbar dense flat>
          <v-switch
            v-for="item in switches"
            :key="item.value"
            v-model="val[item.value]"
            :label="item.text"
            hide-details
            class="mx-2"
          />

          <v-spacer />

          <m-color-picker
            v-for="item in colors"
            :key="item.value"
            v-model="val[item.value]"
            :label="item.text"
            outlinedx
            depressed
            btn-class="mx-2"
            :icon="item.icon"
          />
        </v-toolbar>
        <v-toolbar dense flat>
          <v-slider
            v-for="slider in sliders"
            :key="slider.value"
            v-model="val[slider.value]"
            :label="slider.text"
            min="0"
            max="12"
            thumb-label
            hide-details
            class="mx-2"
          />
        </v-toolbar>
      </v-tab-item>

      <v-tab-item>
        <br />
        <m-pages-heading-form v-model="val.heading" no-text />
      </v-tab-item>

      <v-tab-item>
        <br />
        <m-pages-heading-form v-model="val.subheading" label="Subheading" no-text />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import model from "../../mixins/model";
export default {
  mixins: [model],

  data() {
    return {
      sliders: [
        {
          text: "Spacing X",
          value: "contentSpacingX",
        },
        {
          text: "Spacing Y",
          value: "contentSpacingY",
        },
        {
          text: "Margin X",
          value: "marginX",
        },
        {
          text: "Margin Y",
          value: "marginY",
        },
        {
          text: "Shadow",
          value: "elevation",
        },
      ],
      switches: [
        {
          text: "Dark",
          value: "dark",
        },
        {
          text: "Mini",
          value: "mini",
        },
        {
          text: "Rounded",
          value: "rounded",
        },
        {
          text: "Themed Subtitles",
          value: "themedSubtitle",
        },
        {
          text: "Color Dark",
          value: "colorDark",
        },
      ],
      colors: [
        {
          text: "Theme",
          value: "color",
        },
        {
          text: "Content",
          value: "contentBgColor",
        },
        {
          text: "Background",
          value: "bgColor",
          icon: "format_paint",
        },
      ],
    };
  },

  methods: {
    onModel(theme) {
      console.log("themeChanged", this.val);
      this.$store.dispatch("MPages/setTheme", { ...this.val });
      // console.log("onModel", theme);
      // this.$store.dispatch("MPages/setTheme", theme);
    },
  },
};
</script>
