<template>
  <div>
    <m-pages-hero-block-container :block="block">
      <v-container fill-height>
        <v-row v-if="block.downArrow" style="height:80px;"></v-row>
        <v-row align="center" justify="center" fill-height class="text-center">
          <v-col style="text-shadow:rgba(0, 0, 0, 0.16) 2px 2px 4px">
            <m-pages-text :item="block.heading" />
            <m-pages-text :item="block.subheading" />
          </v-col>
        </v-row>
        <v-row v-if="block.downArrow" align-content="end">
          <v-btn height="80" width="80" class="mx-auto" icon @click="onLink(block.to)">
            <v-icon size="55" class="mx-auto">arrow_downward</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </m-pages-hero-block-container>
  </div>
</template>

<script>
import blockMixin from "../../mixins/block";
import linkMixin from "../../mixins/on-link";
export default {
  mixins: [blockMixin, linkMixin],

  data() {
    return {
      height: false,
    };
  },

  methods: {
    onImageLoaded() {
      console.log("onImageLoaded");
    },
    // onDownArrow() {
    //   let target = "#" + this.block.to;
    //   if (document.querySelector(target)) {
    //     this.$vuetify.goTo(target, {});
    //     setTimeout(() => {
    //       this.$vuetify.goTo(target, {});
    //     }, 300);
    //   }
    // }
  },
};
</script>
