<template>
  <v-select
    v-model="val"
    label="To Section"
    :items="items"
    clearable
    hide-details
    @input="onInput"
  />
</template>

<script>
import { mapGetters } from "vuex";
import formFieldMixin from "../../mixins/form-field";
export default {
  mixins: [formFieldMixin],
  computed: {
    ...mapGetters({
      items: "MPagesBuilder/blockList",
    }),
  },
};
</script>
