import actionFields from '@/mixins/fields/actions'

export default {
  refresh: Boolean,
  edit: Boolean,
  create: Boolean,
  dublicate: Boolean,
  // delete: Boolean,
  canDelete: Boolean,
  canSelect: Boolean,

  emitCreated: Boolean,
  closeOnCreated: Boolean,

  // rowToggleActive: Boolean,
  toggleActive: Boolean,

  cardsNoMore: Boolean,
  cards: Boolean,
  cardsOnly: Boolean,
  noToolbar: Boolean,
  showExpand: Boolean,
  showSelect: Boolean,
  searchFilters: Boolean,
  emitTotal: Boolean,
  cols: {
    type: [String, Number],
    default: null,
  },
  xl: {
    type: [String, Number],
    default: null,
  },
  lg: {
    type: [String, Number],
    default: null,
  },
  md: {
    type: [String, Number],
    default: null,
  },
  sm: {
    type: [String, Number],
    default: null,
  },
  xs: {
    type: [String, Number],
    default: null,
  },

  createFields: {
    type: Array,
    required: false,
  },

  headers: {
    type: Array,
    required: false,
  },
  filterItems: {
    type: Array,
    default: null,
  },

  fields: {
    type: Array,
    default: null,
  },

  endpoint: {
    type: String,
    default() {
      return this.$route.path
    },
  },
  // used for route name
  singular: {
    type: String,
    default() {
      return this.endpoint
        .split('/')
        .pop()
        .slice(0, -1)
    },
  },
  title: {
    type: String,
    default() {
      return this.endpoint
        .split('/')
        .pop()
        .split('?')[0]
    },
  },

  actions: {
    type: Array,
    required: false,
    default() {
      const actions = []

      actionFields.forEach((item) => {
        // console.log(item.value);
        if (this[item.value]) {
          actions.push(item)
        }
      })

      return actions
    },
  },

  itemFilter: {
    type: Function,
    default: null,
  },

  perPageOptions: {
    type: Array,
    default() {
      return [10, 25, 50, 100, 500]
    },
  },

  filters: {
    type: Array,
    required: false,
  },
}
