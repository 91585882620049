export default function() {
	return {
		index: null,
		// source: null,
		// galleryId: null,
		changed: false,

		autoplay: false,

		ready: false,
		// canplay: false,

		start: 0,
		items: [],

		//
		storingProgress: false,
	};
}
