<template>
  <app-page :page="page" />
</template>

<script>
import appPage from "./Page";
export default {
  components: {
    appPage,
  },

  data() {
    return {
      ready: false,
      connected: false,
      isMobilePreview: false,
      page: {},
    };
  },

  mounted() {
    window.addEventListener("message", this.onMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    onMessage(e) {
      if (e.data) {
        if (e.data.mPagesPage) {
          this.page = e.data.mPagesPage;

          // connected response
          if (!this.connected) {
            this.onConnected(e.source);
          }
        }

        if (e.data.mPagesPreview) {
          const isMobile = e.data.mPagesPreview != "desktop";
          this.$store.dispatch("MPages/setProp", { isMobile });
        }
      }
    },

    onConnected(source) {
      this.connected = true;
      source.postMessage({ mPagesPreviewWindowConnected: true }, "*");
    },
  },
};
</script>