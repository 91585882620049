<template>
  <v-col :class="colClass" :style="col.bgColor ? `background-color:${col.bgColor}` : ''">
    <v-sheet :elevation="col.elevation" :dark="col.dark" :color="col.color" :class="sheetClass">
      <slot />
    </v-sheet>
  </v-col>
</template>

<script>
import { isEmpty } from "lodash";
export default {
  props: {
    col: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  computed: {
    colClass() {
      const items = [];
      const col = this.col;
      let k;

      // if (col.elevation) {
      //   // items.push(`elevation-${col.elevation}`);
      // }

      // margin

      if (!isEmpty(col.m)) {
        for (k in col.m) {
          items.push(`m${k}-${col.m[k]}`);
        }
      }

      // padding
      if (col.col) {
        items.push(`col-${col.col}`);
      }
      const options = ["sm", "md", "lg"];
      options.forEach(option => {
        if (col[option]) {
          items.push(`col-${option}-${col[option]}`);
        }
      });

      return items;
    },

    sheetClass() {
      const items = [];
      const col = this.col;
      let k;

      // padding
      if (!isEmpty(col.p)) {
        for (k in col.p) {
          items.push(`p${k}-${col.p[k]}`);
        }
      }

      return items;
    },

    colStyle() {
      const value = {};

      if (this.col.color) {
        value.backgroundColor = this.col.color;
      }

      return value;
    }
  }
};
</script>