import { keys as spacingKeys } from "../js/forms/fields/spacing";

export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    tag: {
      type: String,
      default: "div",
    },
    inlineFlex: Boolean,
    fontItalic: Boolean,
    textSize: {
      type: String,
      required: false,
    },
    fontWeight: {
      type: String,
      required: false,
    },
    textDecoration: {
      type: String,
      required: false,
    },
    textTransform: {
      type: String,
      required: false,
    },
    textOpacity: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
    },
    marginX: {
      type: [String, Number],
      required: false,
    },
    marginY: {
      type: [String, Number],
      required: false,
    },
    paddingX: {
      type: [String, Number],
      required: false,
    },
    paddingY: {
      type: [String, Number],
      required: false,
    },
    elevation: {
      type: [String, Number],
      required: false,
    },
    rounded: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      textStyles: ["color", "backgroundColor"],
      textClassPrefixes: {
        textAlign: "text-",
        textSize: "text-",
        fontWeight: "font-weight-",
        textDecoration: "text-decoration-",
        textTransform: "text-",
        textOpacity: "text--",
        // marginX: "mx-",
        // marginY: "my-",
        // paddingX: "px-",
        // paddingY: "py-",
        elevation: "elevation-",
        rounded: "rounded-",
      },
      textClassBools: {
        flex: "d-flex",
        inlineFlex: "d-inline-flex",
        fontItalic: "font-italic",
        mxAuto: "mx-auto",
      },
      sizeStyles: {
        width: "width",
        height: "height",
        maxWidth: "max-width",
        minWidth: "min-width",
        maxHeight: "max-height",
        minHeight: "min-height",
      },
    };
  },

  computed: {
    textClass() {
      const classes = [];
      let k, val;

      for (k in this.textClassPrefixes) {
        val = this.item[k] ? this.item[k] : this._props[k];
        if (val) {
          if (typeof val == "number" && val < 0) {
            val = val - 2 * val;
            val = `n${val}`;
          }
          classes.push(`${this.textClassPrefixes[k]}${val}`);
        }
      }

      for (k in this.textClassBools) {
        if (this.item[k] ? this.item[k] : this._props[k]) {
          classes.push(this.textClassBools[k]);
        }
      }

      spacingKeys.forEach((k) => {
        if (this.item[k]) {
          classes.push(`${k}-${this.item[k]}`);
        }
      });

      return classes;
    },

    textStyle() {
      const style = {},
        item = this.item;

      let val;

      this.textStyles.forEach((k) => {
        val = item[k] ? item[k] : this._props[k];
        if (val) {
          style[k] = val;
        }
      });

      if (item.src) {
        style["background-image"] = `url(${item.src})`;
        style["background-size"] = item.backgroundSize
          ? item.backgroundSize
          : "cover";
        style["background-position"] = item.backgroundPosition
          ? item.backgroundPosition
          : "center center";
      }

      // sizes
      for (let k in this.sizeStyles) {
        if (item[k]) {
          style[this.sizeStyles[k]] = `${item[k]}px`;
        }
      }

      // style.maxWidth = "300px";

      return style;
    },
  },
};
