<template>
  <div>
    <v-text-field
      v-model="custom"
      :label="label"
      v-if="val == 'custom'"
      placeholder="cover"
      :autofocus="autofocus"
      clearable
      @input="onInput"
    />
    <v-select
      v-else
      v-model="val"
      :label="label"
      :items="items"
      @input="onSelect"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Background Size",
    },
    value: {
      type: String,
      required: false,
    },
  },

  beforeMount() {
    if (this.value) {
      this.setValue();
    }
  },

  data() {
    return {
      val: "cover",
      autofocus: false,
      custom: "",
      items: [
        {
          text: "Auto",
          value: "auto",
        },
        {
          text: "Cover",
          value: "cover",
        },
        {
          text: "Contain",
          value: "contain",
        },
        {
          text: "Initial",
          value: "initial",
        },
        {
          text: "inherit",
          value: "inherit",
        },
        {
          text: "Custom",
          value: "custom",
        },
      ],
    };
  },

  methods: {
    onInput() {
      if (!this.custom) {
        this.val = "";
      }
      this.$emit("input", this.custom);
    },

    onSelect() {
      if (this.val != "custom") {
        this.$emit("input", this.val);
      }

      this.autofocus = true;
    },

    setValue() {
      const values = this.items.map((a) => a.value);
      if (values.indexOf(this.value) > -1) {
        this.val = this.value;
      } else {
        this.val = "custom";
        this.custom = this.value;
      }
    },
  },
};
</script>
