import { isEmpty } from "lodash";

export default {
  props: {
    value: {
      type: Object,
      required: false,
      // default() {
      //   return {};
      // },
    },
  },

  data() {
    return {
      valReady: false,
      val: {},
    };
  },

  computed: {
    valStr() {
      return JSON.stringify(this.val);
    },
  },

  watch: {
    valStr(str) {
      if (this.valReady) {
        this.$emit("input", JSON.parse(str));

        if (typeof this.onModel == "function") {
          this.onModel({ ...this.val });
        }
      }
    },
  },

  beforeMount() {
    console.log("before");
    if (this.value && !isEmpty(this.value)) {
      console.log("has value", this.value);
      this.val = { ...this.value };
      this.valReady = true;
    } else {
      console.log("add watcher");
      this.$watch("value", function(newValue, oldValue) {
        console.log("VALUE CHANGED", { ...newValue });
        if (!this.valReady) {
          this.val = { ...this.value };
          this.valReady = true;
        } else {
          console.log("waching waste");
        }
      });
    }
  },
};
