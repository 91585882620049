export default [
  {
    text: "All",
    value: "a",
  },
  {
    text: "X",
    value: "x",
  },
  {
    text: "Y",
    value: "y",
  },
  {
    text: "Top",
    value: "t",
  },
  {
    text: "Bottom",
    value: "b",
  },
  {
    text: "Left",
    value: "l",
  },
  {
    text: "Right",
    value: "r",
  },
  {
    text: "margin-left/padding-left",
    value: "s",
    advanced: true,
  },
  {
    text: "margin-right/padding-right",
    value: "e",
    advanced: true,
  },
];
