<template>
  <v-row>
    <v-col v-if="!noText" cols="12">
      <v-text-field v-model="val.text" :label="label" hide-details />
    </v-col>
    <v-col cols="6">
      <v-select
        v-model="val.to_name"
        label="To Name"
        :items="linkItems.toNameItems"
        @input="val.to_value = ''"
        hide-details
      />
    </v-col>
    <v-col cols="6">
      <v-select
        v-if="val.to_name == 'dom'"
        v-model="val.to_value"
        :items="blockList"
        label="Link To"
        hide-details
      />

      <v-text-field
        v-else
        ref="toValue"
        v-model="val.to_value"
        label="To Value"
        :placeholder="val.to_name && val.to_name == 'url' ? 'https://' : ''"
        :append-icon="val.to_name == 'url' ? 'launch' : ''"
        hide-details
        @click:append="onClickUrlPreview"
      />
    </v-col>

    <v-col cols="6">
      <v-select
        v-model="val.target"
        label="Target"
        :items="linkItems.targetItems"
        clearable
        hide-details
      />
    </v-col>
    <v-col cols="6">
      <v-switch v-model="val.pauseVideo" label="Pause Video" />
    </v-col>
    <v-col cols="6">
      <v-switch v-model="val.no_follow" label="No Follow" />
    </v-col>
  </v-row>
</template>

<script>
import model from "@/mixins/model-watch";
import { mapGetters } from "vuex";
import linkItems from "@/mixins/fields/link-items";
export default {
  props: {
    // refresh: Boolean,
    noText: Boolean,
    label: {
      type: String,
      default: "Text",
    },
  },

  mixins: [model],

  data() {
    return {
      linkItems,
    };
  },

  computed: {
    ...mapGetters({
      blockList: "MPagesBuilder/blockList",
    }),
  },

  methods: {
    onClickUrlPreview() {
      const url = this.val.to_value;
      if ((this.val.to_name == "url" && !url) || url.indexOf("://") < 0) {
        this.$msg.error("Please enter a valid link");
        return this.$refs.toValue.focus();
      }

      window.open(url);
    },
  },
};
</script>
