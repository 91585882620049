<template>
  <div>
    <v-tabs>
      <v-tab>Video Sync</v-tab>

      <v-tab-item>
        <v-expand-transition>
          <div class="my-4">
            <m-pages-player
              v-if="isPreview && val.video.value"
              :video="video"
              :seekto="seekTo"
              play-pause-on-ready
              start-muted
            />
          </div>
        </v-expand-transition>

        <v-row class="mb-2">
          <v-col>
            <v-select
              v-model="val.video.value"
              :label="!val.video.value ? 'Select Video' : 'Video'"
              :items="videoList"
              filled
              :append-icon="val.video.value ? 'play_arrow' : ''"
              @click:append="isPreview = !isPreview"
              clearable
            />
          </v-col>
          <v-col>
            <v-row>
              <v-checkbox
                v-for="option in options"
                :key="option.value"
                v-model="val.video[option.value]"
                :label="option.text"
                class="mx-2"
              />
            </v-row>
          </v-col>
        </v-row>

        <div v-if="val.video.useShowTime" class="mb-4">
          <component
            :is="showTime.component"
            v-model="val.video.showTime"
            :label="showTime.text"
            :duration="video.duration"
            @input="onVideoTime"
          />
        </div>

        <component
          v-if="val.video.useHideTime"
          :is="hideTime.component"
          v-model="val.video.hideTime"
          :label="hideTime.text"
          :duration="video.duration"
          @input="onVideoTime"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import model from "../../mixins/model";
import prettyTime from "../../mixins/pretty-time";
import { mapGetters } from "vuex";
import {
  useShowTime,
  useHideTime,
  showOnReplay,
  showOnReturn,
  showTime,
  hideTime,
} from "../../js/forms/fields";

export default {
  mixins: [model, prettyTime],

  data() {
    return {
      showTime,
      hideTime,
      options: [useShowTime, useHideTime, showOnReplay, showOnReturn],
      isPreview: false,
      seekTo: null,
    };
  },

  computed: {
    ...mapGetters({
      videoList: "MPagesBuilder/videoList",
    }),
    video() {
      if (!this.val.video.value) {
        return false;
      }

      const index = this.videoList.findIndex(
        (a) => a.value == this.val.video.value
      );
      if (index < 0) {
        return false;
      }
      return this.videoList[index];
    },
  },

  methods: {
    onVideoTime(v) {
      this.seekTo = v;
    },
  },
};
</script>
