<template>
  <div>
    <v-row>
      <v-col :cols="cols">
        <v-text-field v-model="val.text" :label="label" hide-details />
      </v-col>
      <v-col :cols="cols">
        <v-select
          :label="clickAction.text"
          v-model="val.clickAction"
          :items="clickAction.items"
          hide-details
        />
      </v-col>
      <v-col :cols="cols">
        <v-text-field
          v-if="!val.clickAction || val.clickAction == 'href'"
          v-model="val.href"
          :label="href.text"
          :placeholder="href.placeholder"
          hide-details
        />
        <component v-else v-model="val[val.clickAction]" :is="`m-pages-${val.clickAction}-field`" />
      </v-col>
      <v-col :cols="cols">
        <v-select
          v-model="val[target.value]"
          :label="target.text"
          :placeholder="target.placeholder"
          :items="target.items"
          :disabled="!['href', 'section', 'checkout'].includes(val.clickAction)"
          hide-details
        />
      </v-col>
    </v-row>

    <v-btn
      v-if="!noBtn && !inlineForm"
      class="my-4"
      @click="onDone"
    >{{ isCreate ? 'Add Link' : 'Done' }}</v-btn>
  </div>
</template>

<script>
import {
  href,
  target,
  noFollow,
  clickAction,
  hover,
} from "../../js/forms/fields";
import model from "../../mixins/model-watch";
export default {
  props: {
    compact: Boolean,
    inlineForm: Boolean,
    // noText: Boolean,
    isCreate: Boolean,
    noBtn: Boolean,
    label: {
      type: String,
      default: "Text",
    },
  },

  mixins: [model],

  data() {
    return {
      isAdvanced: false,
      isSizeSlider: true,
      href,
      target,
      clickAction,
    };
  },

  computed: {
    cols() {
      return this.compact ? 6 : 6;
    },
  },

  methods: {
    onDone() {
      this.$emit("done");
    },
  },
};
</script>