export default {
  groups: [
    {
      fields: [
        {
          fields: [
            {
              text: "Url",
              value: "src",
              cols: 12,
            },
          ],
        },
        {
          requires: "advanced",
          fields: [
            {
              text: "Pre-Loader Url",
              value: "lazy-src",
              cols: 12,
            },
            {
              text: "Width",
              value: "width",
              cols: 3,
            },
            {
              text: "Height",
              value: "height",
              cols: 3,
            },

            {
              text: "Min Width",
              value: "min-width",
              type: "number",
              cols: 3,
            },
            {
              text: "Min Height",
              value: "min-height",
              type: "number",
              cols: 3,
            },
            {
              text: "Max Width",
              value: "max-width",
              type: "number",
              cols: 3,
            },
            {
              text: "Max Height",
              value: "max-height",
              type: "number",
              cols: 3,
            },
            {
              text: "Aspect Ratio",
              value: "aspect-ratio",
              cols: 3,
            },
            {
              text: "Position",
              value: "position",
              placeholder: "center enter",
              cols: 3,
            },
          ],
        },
        {
          fields: [
            {
              text: "Contain",
              value: "contain",
              type: "switch",
            },
            {
              text: "Dark",
              value: "dark",
              type: "switch",
            },
            {
              text: "Light",
              value: "light",
              type: "switch",
              requires: "advanced",
            },
            {
              text: "Advanced",
              value: "advanced",
              type: "switch",
            },
          ],
        },
      ],
    },
  ],
};
