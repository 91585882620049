<template>
  <div>
    <template v-if="form.rows && form.rows.length">
      <v-row v-for="(row, rowIndex) in form.rows" :key="`row${rowIndex}`">
        <v-col
          v-for="(col, colIndex) in row.cols"
          :key="`col${colIndex}`"
          :cols="col.cols ? col.cols : row.colCols ? row.colCols : 12"
        >
          <template v-if="col.panels && col.panels.length">
            <m-pages-panel
              v-for="(panel, panelIndex) in col.panels"
              :key="`panel${panelIndex}`"
              :title="panel.title"
            >
              <!-- GROUP  -->
              <v-sheet
                v-for="(group, groupIndex) in panel.groups"
                :key="`group${groupIndex}`"
                class="whitex pa-4 mb-4"
                :elevation="group.elevation"
                :color="group.color"
              >
                <v-divider
                  v-if="groupIndex > 0 && group.divider"
                  class="my-4"
                />

                <v-subheader
                  v-if="group.title"
                  v-text="group.title"
                  class="ma-n4"
                />

                <v-row
                  v-for="(field, fieldIndex) in group.fields"
                  :key="`row${rowIndex}panel${panelIndex}${fieldIndex}`"
                  :no-gutters="
                    group.noGutters || field.noGutters || form.noGutters
                  "
                  :align="
                    field.align
                      ? field.align
                      : group.align
                      ? group.align
                      : align
                  "
                  :justify="
                    field.align
                      ? field.align
                      : group.align
                      ? group.align
                      : form.justify
                      ? form.justify
                      : justify
                  "
                >
                  <v-col
                    v-for="item in field.fields"
                    :key="item.value"
                    :cols="
                      item.cols
                        ? item.cols
                        : field.itemCols
                        ? field.itemCols
                        : 12
                    "
                  >
                    <m-pages-form-field
                      v-if="field.value"
                      v-model="val[field.value][item.value]"
                      :item="item"
                    />
                    <m-pages-form-field
                      v-else
                      v-model="val[item.value]"
                      :item="item"
                    />
                  </v-col>
                </v-row>
              </v-sheet>
            </m-pages-panel>
          </template>

          <!-- no panels  -->
          <!-- GROUP  -->
          <template v-if="col.groups && col.groups.length">
            <v-sheet
              v-for="(group, groupIndex) in col.groups"
              :key="`group${groupIndex}`"
              class="whitex pa-4 mb-4"
              :elevation="group.elevation"
              :color="group.color"
            >
              <v-divider v-if="groupIndex > 0 && group.divider" class="my-4" />

              <v-subheader
                v-if="group.title"
                v-text="group.title"
                class="ma-n4"
              />

              <v-row
                v-for="(field, fieldIndex) in group.fields"
                :key="`row${rowIndex}col${colIndex}${fieldIndex}`"
                :no-gutters="
                  group.noGutters || field.noGutters || form.noGutters
                "
                :align="
                  field.align ? field.align : group.align ? group.align : align
                "
                :justify="
                  field.align
                    ? field.align
                    : group.align
                    ? group.align
                    : form.justify
                    ? form.justify
                    : justify
                "
              >
                <v-col
                  v-for="item in field.fields"
                  :key="item.value"
                  :cols="
                    item.cols
                      ? item.cols
                      : field.itemCols
                      ? field.itemCols
                      : group.cols
                  "
                  class="px-2"
                  :class="getColClass({ item, field, group })"
                >
                  <m-pages-form-field
                    v-if="field.value"
                    v-model="val[field.value][item.value]"
                    :item="item"
                  />
                  <m-pages-form-field
                    v-else
                    v-model="val[item.value]"
                    :item="item"
                  />
                </v-col>
              </v-row>
            </v-sheet>
          </template>
        </v-col>
      </v-row>
    </template>

    <template v-if="form.groups && form.groups.length">
      <v-sheet
        v-for="(group, groupIndex) in form.groups"
        :key="`group${groupIndex}`"
        :elevation="group.elevation"
        :color="group.color"
        :class="group.class"
      >
        <!-- <v-divider v-if="groupIndex > 0 && group.divider" class="my-4" /> -->
        <m-pages-form-subheader v-if="group.title" v-text="group.title" />

        <!-- :first="groupIndex == 0" -->
        <!-- :justify=" field.justify ? field.justify : group.justify ? group.justify
        : '' " -->
        <v-row
          v-for="(field, fieldIndex) in group.fields"
          :key="`group${groupIndex}field${fieldIndex}`"
          :no-gutters="group.noGutters || field.noGutters || form.noGutters"
          :align="field.align ? field.align : group.align ? group.align : align"
          :justify="
            field.align
              ? field.align
              : group.align
              ? group.align
              : form.justify
              ? form.justify
              : justify
          "
        >
          <template v-if="field.fields">
            <v-col
              v-for="item in field.fields"
              :key="item.value"
              :cols="
                item.cols
                  ? item.cols
                  : field.itemCols
                  ? field.itemCols
                  : group.cols
              "
              :class="getColClass({ item, field, group })"
            >
              <m-pages-form-field
                v-if="field.value"
                v-model="val[field.value][item.value]"
                :item="item"
                :label="getLabel(item)"
              />
              <m-pages-form-field
                v-else
                v-model="val[item.value]"
                :item="item"
                :label="getLabel(item)"
              />
            </v-col>
          </template>

          <!-- <v-col
            v-else
            :cols="field.cols || group.cols"
            :class="getColClass({ item, field, group })"
          >
            <m-pages-form-field
              v-model="val[field.value]"
              :item="field"
              :class="field.class"
              :label="getLabel(field)"
            />
          </v-col> -->
        </v-row>
      </v-sheet>
    </template>

    <!-- fields only  -->
    <template v-if="form.fields && form.fields.length">
      <v-row
        v-for="(field, fieldIndex) in form.fields"
        :key="`field${fieldIndex}`"
        :no-gutters="form.noGutters || field.noGutters || form.noGutters"
        :align="field.align ? field.align : group.align ? group.align : align"
        :justify="
          field.align
            ? field.align
            : group.align
            ? group.align
            : form.justify
            ? form.justify
            : justify
        "
      >
        <template v-if="field.fields">
          <v-col
            v-for="item in field.fields"
            :key="item.value"
            :class="getColClass({ item, field, group })"
          >
            <m-pages-form-field
              v-if="field.value"
              v-model="val[field.value][item.value]"
              :item="item"
            />
            <m-pages-form-field v-else v-model="val[item.value]" :item="item" />
          </v-col>
        </template>

        <v-col
          v-else
          :cols="field.cols || group.cols"
          :class="[
            field.colClass,
            field.requires && !val[field.requires] ? 'd-none' : '',
          ]"
        >
          <m-pages-form-field
            v-model="val[field.value]"
            :item="field"
            :label="getLabel(field)"
            :class="field.class"
          />
        </v-col>
      </v-row>
    </template>

    <!-- items only  -->
    <v-row
      v-if="form.fields && form.fields.length"
      :no-gutters="form.noGutters"
      :align="form.align ? form.align : align"
      :justify="form.justify ? form.justify : justify"
    >
      <v-col
        v-for="item in form.fields"
        :key="item.value"
        :cols="field.itemCols"
        class="px-2"
        :class="getColClass({ item, field })"
      >
        <m-pages-form-field
          v-model="val[item.value]"
          :item="item"
          :label="getLabel(item)"
        />
      </v-col>
    </v-row>

    <!-- <v-row v-if="form.fields && form.fields.length">
      <v-col v-for="field in form.fields" :key="field.value" :cols="field.cols ? field.cols : 12">
        <m-pages-form-field v-model="val[field.value]" :item="field" />
      </v-col>
    </v-row>-->
  </div>
</template>

<script>
import model from "../../mixins/model";
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    labelFilter: {
      type: Function,
      required: false,
    },
    align: {
      type: String,
      default: "center",
    },
    justify: {
      type: String,
      default: "start",
    },
  },
  mixins: [model],
  methods: {
    getLabel(item) {
      if (this.labelFilter) {
        return this.labelFilter(item);
      }
      return item.text;
    },

    getColClass(data) {
      const classes = [];

      ["field", "item", "group"].forEach((key) => {
        if (data[key]) {
          const item = data[key];

          let ok = true;

          if (item.requires) {
            if (typeof item.requires == "object") {
              for (let k in item.requires) {
                if (!this.val[k] || this.val[k] != item.requires[k]) {
                  ok = false;
                }
              }
            } else {
              if (!this.val[item.requires]) {
                ok = false;
              }
            }
          }

          if (ok && item.requiresNot) {
            if (typeof item.requiresNot == "object") {
              for (let k in item.requiresNot) {
                if (this.val[k] && this.val[k] == item.requiresNot[k]) {
                  ok = false;
                }
              }
            } else {
              if (this.val[item.requires]) {
                ok = false;
              }
            }
          }

          if (!ok) {
            classes.push("d-none");
          }
        }
      });

      return classes;
    },

    beforeReady() {
      if (this.form.form) {
        let k;
        for (k in this.form.form) {
          if (typeof this.val[k] == "undefined") {
            if (typeof this.form.form[k] == "object") {
              this.val[k] = { ...this.form.form[k] };
            } else {
              this.val[k] = this.form.form[k];
            }
          }
        }
      }
    },
  },
};
</script>
