<template>
  <v-slider v-model="slider" :label="label" :min="-12" :max="12" hide-details ticks thumb-label />

  <!-- @click="onChange"
  @end="onChange"-->
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "0"
    },
    label: {
      type: String,
      default: ""
    },

    zero: Boolean
  },

  watch: {
    zero() {
      this.slider = 0;
    },
    slider() {
      if (this.ready) {
        this.onChange();
      }
    }
  },

  beforeMount() {
    let value = this.value;
    if (value.indexOf("n") === 0) {
      value = parseInt(value.substring(1));
      value = value - value - value;
    }

    this.slider = parseInt(value);

    this.ready = true;
  },

  data() {
    return {
      ready: false,
      slider: 0
    };
  },

  methods: {
    onChange() {
      let value = this.slider;
      if (value < 0) {
        value = value - value - value;
        value = "n" + value.toString();
      } else {
        value = value.toString();
      }

      this.$emit("input", value);
    }
  }
};
</script>