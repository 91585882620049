import ui from "./ui";
const state = {
  ui,
  blockList: [],
  blocks: [],
};

const getters = {
  ui(state) {
    return state.ui;
  },
  dialog(state) {
    return state.ui.dialog;
  },
  toolbar(state) {
    return state.ui.toolbar;
  },
  blockList(state) {
    return state.blockList;
  },
  blocks(state) {
    return state.blocks;
  },
  videoList(state) {
    const items = state.blocks.filter((a) => a.name == "video");

    return items.map((a) => {
      return {
        value: a.id,
        text: a.content.name ? a.content.name : a.name,
        duration: a.content.video.duration ? a.content.video.duration : 0,
        src: a.content.video.src ? a.content.video.src : "",
      };
    });
  },
};

const mutations = {
  SET_PROP(state, payload) {
    for (let k in payload) {
      state[k] = payload[k];
    }
  },
};

const actions = {
  setProp({ commit }, payload) {
    commit("SET_PROP", payload);
  },
  setBlockList({ commit }, blockList) {
    commit("SET_PROP", { blockList });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
