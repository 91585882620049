<template>
  <v-row class="my-n3">
    <v-col v-for="group in groups" :key="group" cols="6">
      <m-pages-panel :title="group">
        <v-row dense>
          <v-col
            v-for="field in fields[group]"
            :key="field.value"
            :cols="field.value.indexOf('a') > 0 ? 12 : 6"
          >
            <div
              class="text-center grey--text text--darken-2"
              v-text="field.shortText"
            />
            <m-pages-spacing-slider v-model="val[field.value]" />
          </v-col>
        </v-row>
        <v-row justify="end" class="pr-3">
          <v-btn small depressed @click="resetGroup(group)"
            >Reset {{ group }}</v-btn
          >
        </v-row>
      </m-pages-panel>
    </v-col>
  </v-row>
</template>

<script>
import model from "../../mixins/model";
import { padding, margin } from "../../js/forms/fields";
export default {
  mixins: [model],
  data() {
    return {
      groups: ["margin", "padding"],
      fields: {
        padding,
        margin,
      },
    };
  },
  methods: {
    resetGroup(group) {
      for (let k in this.fields[group]) {
        this.val[k] = "";
      }
      this.val = {};
    },
  },
};
</script>
