<template>
  <v-card class="mx-auto" max-width="344">
    <v-img
      v-if="item.image"
      :src="item.image"
      :height="image.height || 200"
    ></v-img>

    <v-card-title>{{
      item.cardTitle ? item.cardTitle : item.title || item.name
    }}</v-card-title>

    <v-card-subtitle>{{
      item.subtitle ? item.subtitle : item.id ? `ID: ${item.id}` : ''
    }}</v-card-subtitle>

    <v-card-text>
      <slot />
    </v-card-text>

    <v-card-actions v-if="actions">
      <ml-actions :actions="actions" emit-as-action @action="onAction" />
      <template v-if="!noMore">
        <v-spacer />

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </template>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>{{ item.more ? item.more : item }}</v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    emitAsAction: Boolean,
    noMore: Boolean,
    item: {
      type: Object,
      // required: true
      default() {
        return {}
      },
    },
    actions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      show: false,
    }
  },

  methods: {
    onAction(value) {
      if (this.emitAsAction) {
        return this.$emit('action', value)
      }

      this.$emit(value)
    },
  },
}
</script>
