<template>
  <div>
    <m-progress-linear v-if="loading" indeterminate />
    <m-crumbs v-if="crumbs" />
    <v-container no-gutters fluid>
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    crumbs: Boolean,
  },
}
</script>
