// const state = {
//   data: {
//     dialog: false,
//     drawer: null,
//   },
// };

// const getters = {
//   data(state) {
//     return state.data;
//   },
// };

// export default {
//   namespaced: true,
//   state,
//   getters,
// };

import item from "../../store/helpers/item";

const state = {
  ...item.state,
  baseEndpoint: "admin/layouts",
};

const mutations = {
  ...item.mutations,
  //
};

const getters = {
  ...item.getters,
};

const actions = {
  ...item.actions,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
