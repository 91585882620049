<template>
  <div>
    <v-card v-if="ready" max-width="360" min-width="330" class="py-4 mx-auto">
      <v-card-text>
        <slot>
          <div class="title">Create A New Password</div>
        </slot>
      </v-card-text>
      <v-card-text>
        <form @submit.prevent="submit">
          <new-pass
            v-model="form.newpass"
            :min="40"
            label="Enter a New Password"
          />
          <v-text-field
            v-model="confirmPass"
            type="password"
            label="Confirm New Password"
            outlined
          />

          <v-btn
            type="submit"
            :loading="loading"
            :disabled="!valid"
            block
            large
            color="primary"
            >{{ create ? createButton : recoveryButton }}</v-btn
          >
        </form>
      </v-card-text>
    </v-card>

    <v-overlay :value="!ready">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import NewPass from "./NewPass";
export default {
  components: {
    NewPass,
  },

  props: {
    token: {
      type: String,
      default() {
        if (this.$route.params && this.$route.params.token) {
          return this.$route.params.token;
        }
        return "";
      },
    },
    createButton: {
      type: String,
      default: "Save New Password",
    },
    recoveryButton: {
      type: String,
      default: "Save New Password",
    },
  },

  data() {
    return {
      showPass: false,
      ready: false,
      loading: false,
      create: false,
      // validToken: false,
      url: "",
      form: {
        newpass: "aaaaA",
        token: "",
      },
      confirmPass: "aaaaA",
    };
  },

  computed: {
    valid() {
      return (
        this.form.newpass.length > 4 && this.form.newpass === this.confirmPass
      );
    },
  },

  methods: {
    submit() {
      this.loading = true;

      this.$auth.recoveryTokenLogin({ ...this.form }).catch(({ message }) => {
        this.loading = false;
        if (message) {
          this.$msg.error(message);
        }
        // return console.log("replace: auth-login");

        this.$router.replace({ name: "auth-login" });
      });
    },
  },

  beforeMount() {
    if (!this.token) {
      this.$msg.error("Link is invalid - token not found");
      this.$router.push({ name: "auth-login" });
      return;
    }

    const token = this.token;

    this.$msg.info("Validating link, one moment please...");

    this.$auth
      .validateRecoveryToken(token)
      .then(() => {
        this.form.token = token;
        this.ready = true;
        this.$msg.clearAll();
        this.$msg.success("Valid Link! Create New Password");
      })
      .catch(() => {
        this.$msg.clearAll();
        this.$msg.error("Link is invalid or has expired");
        return console.log("replace: auth-login");
        this.$router.replace({ name: "auth-login" });
      });
  },
};
</script>
