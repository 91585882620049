// import textSizes from "./partials/text-sizes";
// import textDecoration from "./partials/text-decoration";
// import textTransform from "./partials/text-transform";
// import fontWeight from "./partials/font-weight";
// import textOpacity from "./partials/text-opacity";

// import textFields from "./partials/text-fields";
import textFields from "./fields/select-options/text-fields";
export const headingFields = [
  {
    fields: [
      {
        value: "heading",
        itemCols: 3,
        fields: [
          {
            text: "Heading Text",
            value: "text",
            cols: 9,
          },
          ...textFields,
        ],
      },
    ],
  },
  {
    fields: [
      {
        value: "subheading",
        itemCols: 3,
        fields: [
          {
            text: "Subheading Text",
            value: "text",
            cols: 9,
          },
          ...textFields,
        ],
      },
    ],
  },

  // Options
  {
    fields: [
      {
        // justify: "start",
        fields: [
          {
            text: "Advanced",
            value: "advanced",
            type: "switch",
          },
        ],
      },
    ],
  },
];

export default {
  form: {
    heading: {},
    subheading: {},
  },
  groups: headingFields,
};
