import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapGetters({
      isBuilder: "MPages/isBuilder",
    }),

    itemClass() {
      let cl = [];

      ["px", "pb", "pt"].forEach((key) => {
        if (this.item[key]) {
          const val = this.item[key];
          cl.push(`${key}-${val}`);
        }
      });

      return cl;
    },

    itemStyle() {
      const style = {};

      if (this.item.textColor) {
        style.color = this.item.textColor;
      }

      return style;
    },
  },
};
