<template>
	<div>
		<m-pages-toolbar :item="block.toolbar">
			<v-app-bar-nav-icon class="d-flex d-sm-none" @click="drawer = !drawer" />
			<v-spacer v-if="['center', 'right'].includes(block.alignLinks)" />
			<v-toolbar-items class="d-none d-sm-flex">
				<template v-for="(item, i) in leftItems">
					<m-pages-toolbar-item
						v-if="item.children && item.children.length"
						:key="i"
						:item="item"
						:dark="block.itemDark"
						:color="block.itemColor"
					/>
					<m-pages-button v-else :key="i" is-text :item="item" />
				</template>
			</v-toolbar-items>
			<v-spacer v-if="['center', 'left'].includes(block.alignLinks)" />

			<m-pages-button v-if="block.options.secondaryButton" :item="block.secondaryButton" />
			<m-pages-button v-if="block.options.primaryButton" :item="block.primaryButton" />
		</m-pages-toolbar>

		<!-- Navigartion Drawer  -->
		<v-navigation-drawer v-if="!$vuetify.breakpoint.smAndUp" app v-model="drawer">
			<v-list nav>
				<template v-for="item in leftItems">
					<v-list-item v-if="!item.children.length" :key="item.id" link>
						<v-list-item-title @click="onClick($event, item)">{{ item.text }}</v-list-item-title>
					</v-list-item>

					<!-- group  -->
					<v-list-group v-else :key="item.id">
						<template v-slot:activator>
							<v-list-item-title @click="onClick($event, item)">{{ item.text }}</v-list-item-title>
						</template>

						<!-- children  -->
						<v-list-item v-for="(child, i) in item.children" :key="i" link>
							<v-list-item-title v-text="child.text"></v-list-item-title>
						</v-list-item>
					</v-list-group>
				</template>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import blockMixin from '../../mixins/block';
// import linkMixin from "../../mixins/on-link";
import clickActionMixin from '../../mixins/item-click-action';
import appMenu from '@/components/Menu';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		appMenu,
	},
	//linkMixin,
	mixins: [blockMixin, clickActionMixin],

	data() {
		return {
			drawer: false,
			ready: false,
		};
	},

	computed: {
		...mapGetters({
			isMobile: 'MPages/isMobile',
			menu: 'menu/menu',
		}),
		leftItems() {
			const items = [];

			if (this.block.items && this.block.items.length) {
				items.push(...this.block.items);
			}

			if (this.block.menu && this.block.menu.items) {
				items.push(...this.block.menu.items);
			}

			return items;
		},
	},
	methods: {
		...mapActions({
			setNavItems: 'MPages/setNavItems',
			toggleMenu: 'menu/toggle',
		}),

		onClick(e, item) {
			if (item.clickAction) {
				return this.clickAction(e, item);
			}
		},
	},

	beforeMount() {
		// console.log("set nav items", this.block.items);
		this.setNavItems(this.block.items);
	},
	mounted() {
		setTimeout(() => {
			this.ready = true;
		});
	},
};
</script>
