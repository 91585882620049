<template>
  <v-parallax :height="block.height" :src="block.img.src" :alt="block.img.alt" :dark="block.dark">
    <v-container fill-height>
      <v-row align="center" justify="center" fill-height>
        <v-col class="text-center">
          <m-pages-text v-if="block.heading && block.heading.text" :item="block.heading" tag="div" />

          <m-pages-text
            v-if="block.subheading && block.subheading.text"
            :item="block.subheading"
            tag="div"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import blockMixin from "../../mixins/block";
export default {
  mixins: [blockMixin],

  data() {
    return {
      height: false,
      imageHeight: 500,
    };
  },

  methods: {
    onImageLoaded() {
      console.log("onImageLoaded");
    },
  },
};
</script>
