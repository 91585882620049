import item from "../../store/helpers/item";

const state = {
  ...item.state,
  auth: {},
};

const mutations = {
  ...item.mutations,
  //
};

const getters = {
  ...item.getters,
  // auth(state) {
  //   return state.auth;
  // },
};

const actions = {
  ...item.actions,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
