export default [
  {
    text: "Edit",
    value: "edit",
    icon: "edit",
  },
  {
    text: "Dublicate",
    value: "dublicate",
    icon: "library_add",
  },
  {
    text: "Delete",
    value: "delete",
    icon: "delete",
  },
];
