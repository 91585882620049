import brandbar from "./brandbar";
import heading from "./heading";
import video from "./video";
import content from "./content";
// import button from "./button";
import footer from "./mini-footer";
import buyButton from "./buy-button";
// fields
import fields from "./fields";

const options = {
  groups: [
    {
      fields: [
        {
          fields: [fields.paddingX, fields.spacingY, fields.elevation],
        },
        {
          // ...fields.justify,
          itemCols: 2,
          fields: [
            {
              text: "Brandbar",
              value: "brandbar",
              type: "switch",
            },
            {
              text: "Heading",
              value: "heading",
              type: "switch",
            },
            {
              text: "Video",
              value: "video",
              type: "switch",
            },
            {
              text: "Content",
              value: "content",
              type: "switch",
            },
            {
              text: "BuyButton",
              value: "buyButton",
              type: "switch",
            },
            {
              text: "Footer",
              value: "footer",
              type: "switch",
            },
          ],
        },

        // advanced
        {
          fields: [
            fields.dark,
            fields.flat,
            fields.tile,
            {
              ...fields.rounded,
            },
            {
              ...fields.maxWidth,
              cols: 2,
            },
            fields.colorPicker,
          ],
        },
      ],
    },
  ],
};

export default {
  options,
  brandbar,
  heading,
  video,
  content,
  // button,
  buyButton,
  footer,
};
