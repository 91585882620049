export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    attrs: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
