import label from "./label";
import heading from "./heading";
import button from "./button";
import fields from "./fields";

// console.log("heading", [...heading.groups]);
// export default {
// const form = {
//   heading: {},
//   button: {},
// };

const options = {
  groups: [
    {
      fields: [
        {
          fields: [fields.paddingX, fields.spacingY, fields.elevation],
        },
        {
          // ...fields.justify,
          itemCols: 3,
          justify: "space-around",
          align: "center",
          fields: [
            {
              text: "Heading",
              value: "heading",
              type: "switch",
            },
            {
              text: "Content",
              value: "content",
              type: "switch",
            },
            {
              text: "Footer",
              value: "footer",
              type: "switch",
            },
            {
              text: "J-Box",
              value: "jbox",
              type: "switch",
            },
            fields.useIcon,
            fields.dark,
            fields.flat,
            fields.tile,
          ],
        },

        // advanced
        {
          // itemCols: 3,
          fields: [
            { ...fields.rounded, cols: 4 },
            { ...fields.width, min: 80 },
            fields.minWidth,
            fields.maxWidth,
            { ...fields.colorPicker, class: "shrink mt-3" },
          ],
        },
      ],
    },
  ],
};

export default {
  options,
  label,
  heading,
  button,
};
