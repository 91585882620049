<template>
  <v-dialog v-model="dialog" width="300" hide-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :icon="label == '' || iconOnly"
        :hide-details="hideDetails"
        v-bind="attrs"
        :class="btnClass"
        :depressed="depressed"
        :text="text"
        :outlined="outlined"
        v-on="on"
        class="text-none body-1 grey--text text--darken-1"
      >
        <v-icon
          v-text="icon ? icon : 'color_lens'"
          :color="label ? value : ''"
          :class="label && !iconOnly ? 'mr-1' : ''"
        />
        {{ !iconOnly && label ? label : "" }}
      </v-btn>
    </template>

    <m-panel :title="title || label" edged>
      <template slot="toolbar">
        <v-spacer />
        <v-btn icon @click="trash">
          <v-icon>delete</v-icon>
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>

      <v-color-picker
        v-model="model"
        tile
        flat
        show-swatches
        @input="$emit('input', model)"
      />
    </m-panel>
  </v-dialog>
</template>

<script>
export default {
  props: {
    text: Boolean,
    iconOnly: Boolean,
    depressed: Boolean,
    outlined: Boolean,
    btnClass: {
      type: String,
      default: "",
    },

    hideDetails: Boolean,
    defaultColor: {
      type: String,
      default: "", // #CCCCCCFF
    },

    value: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    icon: {
      type: [String],
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      model: "",
    };
  },

  beforeMount() {
    this.model = this.value || this.defaultColor;
  },

  methods: {
    trash() {
      // this.model = "";
      console.log("emit input to nothing");
      this.$emit("input", "");
    },
  },
};
</script>
