<template>
  <div>
    duration: {{ duration }}
    <v-range-slider
      v-if="duration > 0"
      label="Trimmer"
      min="0"
      :max="duration"
      thumb-label
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: false,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      val: [0, 0],
    };
  },

  mounted() {
    if (this.duration && !this.val[1]) {
      this.val[1] = this.duration;
    }
  },
};
</script>
